import { mainActionTypes } from 'redux/common/types';

const namespace = 'app';

type appActionsTypes =
  | mainActionTypes
  | 'CREATE'
  | 'CLEAR'
  | 'DUPLICATE'
  | 'CHECK'
  | 'REPORT'
  | 'SHARE'
  | 'UNSHARE';

type appResource =
  | 'UUID'
  | 'APP'
  | 'APP_LOADING'
  | 'PERSONAL_DRIVE'
  | 'SELECTED_PERSONAL_DRIVE'
  | 'TEAM'
  | 'PROJECT'
  | 'SCENE'
  | 'TEAMS'
  | 'PROJECTS'
  | 'PROJECT_DATA'
  | 'PROJECT_NAME'
  | 'SCENES'
  | 'SCENE_MEMBERS'
  | 'SCENE_INVITE'
  | 'SCENE_PUBLIC'
  | 'SCENE_NAME'
  | 'RECENT_SCENES'
  | 'DRIVE_STATUS'
  | 'PROJECT_STATUS'
  | 'SCENE_STATUS'
  | 'TEAM_MEMBERS_PUBLIC'
  | 'PROJECT_ACCESS'
  | 'PROJECT_MEMBER'
  | 'PROJECT_INVITE'
  | 'PROJECT_MEMBER_ROLE'
  | 'PROJECT_OWNERSHIP'
  | 'TEAM_MEMBER_PRIVILEGE'
  | 'SCENE_MEMBERS_PUBLIC'
  | 'SCENE_ACCESS'
  | 'SCENE_OWNER'
  | 'SCENE_MEMBERS_ROLE'
  | 'SCENE_IS_PUBLIC'
  | 'SCENE_THUMBNAIL'
  | 'SCENE_LAST_MODIFIED'
  | 'SCENES_ORDERING'
  | 'SCENE_TEMPLATES'
  | 'PENDING_SCENE'
  | 'SHARED_PROJECTS'
  | 'PAYMENT_INFO'
  | 'SORTABLE_PROJECT'
  | 'PERSONAL_DRIVE_CUSTOMER_ID'
  | 'PERSONAL_DRIVE_STATUS'
  | 'PERSONAL_DRIVE_IS_CANCELED'
  | 'DRIVE_IS_CANCELED'
  | 'THEME'
  | 'BUG';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const appAction = (
  type: appActionsTypes,
  resource?: appResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
