import { clipsActionTypes } from './actions';

export const clipsReducer = (state = initState, action) => {
  switch (action.type) {
    case clipsActionTypes.SET_CLIPS:
      const clips = action.payload;
      return { ...state, clips };

    case clipsActionTypes.FETCH_CLIPS_FAIL:
      return { ...state, clips: {} };

    case clipsActionTypes.FETCH_SCENE:
      return { ...state, ...action.payload.videoSrc };
    case clipsActionTypes.SET_START_AT:
      return {
        ...state,
        [action.shotID]: {
          ...state[action.shotID],
          startAt: action.startAt,
        },
      };
    default:
      return state;
  }
};

const initState = { clips: null };
