export default {
    control: {
        backgroundColor: "#343a40",
        color: "white",
        fontSize: 14,
        fontWeight: "normal",
        borderRadius:"5px"
    },

    highlighter: {
        overflow: "hidden"
    },

    input: {
        margin: 0
    },

    "&singleLine": {
        control: {
            display: "inline-block",

            width: 130
        },

        highlighter: {
            padding: 1,
            border: "2px inset transparent"
        },

        input: {
            padding: 1,

            border: "2px inset"
        }
    },

    "&multiLine": {
        control: {
            fontFamily: "Roboto",

        },

        highlighter: {
            padding: 9
        },

        input: {
            padding: 9,
            paddingRight: '30px', 
            minHeight: 20,
            outline: 0,
            borderRadius: "5px"
        }
    },

    suggestions: {
        list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: 10
        },

        item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",

            "&focused": {
                backgroundColor: "#cee4e5"
            }
        }
    }
};
