import './TeamLibrary.scss';

import React, { useEffect, useState } from 'react';
import Library from '../library/Library';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDownloadedItems,
  fetchFavoriteItems,
  fetchPendingItems,
  fetchPurchasedItems,
} from 'redux/slices/items/actions';
import {
  downloadedItemsIdsSelector,
  downloadedItemsPendingSelector,
  downloadedItemsSelector,
  favoriteItemIdsSelector,
  favoriteItemsSelector,
  finishedDownloadedItemsSelector,
  instantiatedItemsSelector,
  itemsProgressSelector,
  libraryVersionSelector,
  pendingItemsSelector,
  purchasedInstantiatedItems,
  userAssetsSelector,
} from 'redux/slices/items/selectors';
import { favoriteItemsPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import { subCategory } from '../library/types';
import { userNameSelector } from 'redux/slices/auth/authSelectors';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';

const sidebarItems: subCategory[] = [
  // { id: 'All', text: 'All' },
  { id: 'CameraItems', text: 'Cameras' },
  { id: 'LightItems', text: 'Lights' },
  { id: 'CharacterItems', text: 'Characters' },
  { id: 'PropItems', text: 'Props' },
  { id: 'EnvironmentItems', text: 'Film Sets' },
];

const itemTypes = {
  All: '*',
  Cameras: 'CameraItems',
  Lights: 'LightItems',
  Characters: 'CharacterItems',
  Props: 'PropItems',
  Animations: 'AnimationItems',
};

const CHUNK_SIZE = 20;

const TeamLibrary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  let selectedCategory = '';
  if (params.category) selectedCategory = params.category as string;
  const userName = useSelector(userNameSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const libVersion = useSelector(libraryVersionSelector);

  const [displayItems, setDisplayItems] = useState<any[]>([]);

  const userItems = useSelector(userAssetsSelector);

  const favoriteItemIds = useSelector(favoriteItemIdsSelector);
  const favoriteItems = useSelector(favoriteItemsSelector);

  const downloadedItemsIds = useSelector(downloadedItemsIdsSelector);
  const downloadedItems = useSelector(downloadedItemsSelector);

  const finishedDownloadedItems = useSelector(finishedDownloadedItemsSelector);
  const pendingItems = useSelector(pendingItemsSelector);

  const purchasedItems = useSelector(purchasedInstantiatedItems);

  const itemsProgress = useSelector(itemsProgressSelector);
  const pendingItemsCount = Object.values(itemsProgress).filter(
    (progress) => progress < 100
  ).length;

  const downloadedItemsPending = useSelector(downloadedItemsPendingSelector);

  // Select a category if none was selected initially
  useEffect(() => {
    if (!selectedCategory) history.push({ search: `?category=${'saved'}` });
  }, [selectedCategory]);

  useEffect(() => {
    if (favoriteItemIds && Object.values(favoriteItemIds).length > 0)
      dispatch(fetchFavoriteItems(Object.values(favoriteItemIds)));
  }, [favoriteItemIds]);

  useEffect(() => {
    if (downloadedItemsIds && Object.keys(downloadedItemsIds).length > 0) {
      dispatch(
        fetchDownloadedItems(
          Object.keys(downloadedItemsIds),
          teamId,
          0,
          CHUNK_SIZE
        )
      );
    }
  }, [downloadedItemsIds, teamId]);

  useEffect(() => {
    dispatch(fetchPurchasedItems(teamId, libVersion, 'All'));
  }, [teamId, libVersion]);

  useEffect(() => {
    const ids = Object.keys(itemsProgress).map((itemID) => ({ itemID }));
    dispatch(fetchPendingItems(teamId, libVersion, ids));
  }, [teamId, libVersion, itemsProgress]);

  useEffect(() => {
    if (selectedCategory === 'purchased') {
      setDisplayItems(purchasedItems);
    } else if (selectedCategory === 'downloads') {
      // setDisplayItems(downloadedItems.filter((item) => item.isPurchased));
      setDisplayItems(
        purchasedItems.filter(
          ({ itemID }) => !!downloadedItemsIds[itemID] || itemsProgress[itemID]
        )
      );
    } else if (selectedCategory === 'saved') {
      setDisplayItems(favoriteItems);
    } else {
      setDisplayItems(
        userItems.filter(({ itemType }) => itemType === selectedCategory)
      );
    }
  }, [
    selectedCategory,
    favoriteItems,
    downloadedItems,
    purchasedItems,
    userItems,
  ]);

  const subcategorySelect = (id) => {
    history.push({ search: `?category=${id}` });
  };

  const onItemClick = (item) => {
    (window as any).selectObject({ ...item });
    (window as any).addInstantiatedItems(item.itemID);
  };

  const onPageReachBottom = () => {
    // console.log('reach bottom', downloadedItemsPending, displayItems.length);

    if (selectedCategory === 'downloads') {
      const downloadsSize = Object.keys(downloadedItemsIds).length;
      const pendingItemsSize = Object.keys(itemsProgress).length;

      if (!downloadedItemsPending) {
        // if (finishedDownloadedItems.length < downloadsSize) {
        //   dispatch(
        //     fetchDownloadedItems(
        //       Object.keys(downloadedItemsIds),
        //       teamId,
        //       displayItems.length,
        //       CHUNK_SIZE
        //     )
        //   );
        // }

        if (pendingItems.length < pendingItemsSize) {
          const ids = Object.keys(itemsProgress).map((itemID) => ({ itemID }));
          dispatch(fetchPendingItems(teamId, libVersion, ids));
        }
      }
    }
  };

  const mainSidebarItems: subCategory[] = [
    { id: 'purchased', text: 'Purchased' },
    {
      id: 'downloads',
      text: 'Downloads',
      badge: pendingItemsCount === 0 ? undefined : pendingItemsCount,
    },
    { id: 'saved', text: 'Saved' },
  ];

  let itemsPending = false;
  let extraItemsPending = false;
  if (selectedCategory === 'downloads') {
    if (downloadedItemsPending) {
      extraItemsPending = true;
      if (displayItems.length === 0) itemsPending = true;
    }
  }

  return (
    <Library
      title={`${userName ?? 'User'}'s Assets`}
      mainSubcategories={mainSidebarItems}
      sidebarItems={sidebarItems}
      selectedSidebarItem={selectedCategory}
      onSidebarItemClick={subcategorySelect}
      items={displayItems}
      // itemsPending={favItemsPending && filteredItems.length === 0}
      itemsPending={itemsPending}
      extraItemsPending={extraItemsPending}
      onItemClick={onItemClick}
      onScrollReachBottom={onPageReachBottom}
      onPageReachBottomDependencies={[
        downloadedItemsPending,
        displayItems.length,
        selectedCategory,
      ]}
    />
  );
};

export default TeamLibrary;
