import { userDataActionTypes } from './actions/userData';

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case userDataActionTypes.SET_USER_TEAMS:
      return { ...state, userTeams: action.payload };
    case userDataActionTypes.SET_USER_PROJECTS:
      return { ...state, userProjects: action.payload };
    case userDataActionTypes.SET_USER_SCENES:
      return { ...state, userScenes: action.payload };
    case userDataActionTypes.SET_USER_DATA:
      return { ...state, userData:action.payload};
    case userDataActionTypes.SET_ROLE:
      return { ...state, role: action.payload.toUpperCase() };
    default:
      return state;
  }
};

const initState = {
  role: null,
  userTeams: {},
  userProjects: {},
  userScenes: {},
  userData: null
};
