import produce from 'immer';
import { Action } from 'redux/common/types';
import { settingsAction, settingsSlice } from './types';

export const settingsReducer = produce(
  (state: settingsSlice = initState, action: Action) => {
    switch (action.type) {
      case settingsAction('SET', 'SETTINGS'): {
        state = { ...state, ...action.payload };
        return state;
      }

      case settingsAction('SET', 'RES_OPTIONS'): {
        state.resOptions = action.payload;
        return state;
      }

      default:
        return state;
    }
  }
);

const initState: settingsSlice = {
  autoSave: 5,
  res: 'Medium',
  fps: '60',
  resOptions: [],
  resMin: 1,
  resValue: 100,
};
