import { projectSelector, sceneSelector } from 'redux/slices/app/selectors';
import {
  setSelectedProjectId,
  setSelectedTeamId,
  subscribeSceneMembers,
  subscribeScenePermissions,
  subscribeTeams,
  unsubscribeSceneMembers,
  unsubscribeScenePermissions,
} from 'redux/slices/app/actions';
import {
  subscribeScene,
  subscribeTeam,
  unsubscribeScene,
  unsubscribeTeam,
} from 'redux/slices/app/actions';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeProject, unsubscribeProject } from 'redux/slices/app/actions';
import {
  scenesArraySelector,
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  selectedTeamIdSelector,
  teamsSelector,
} from 'redux/slices/app/selectors';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

export const useFetchUserData = (sceneIdFromUrl?) => {
  if (sceneIdFromUrl === '#') {
    sceneIdFromUrl = undefined;
  }

  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);

  const selectedTeamId = useSelector(selectedTeamIdSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  const scene = useSelector(sceneSelector);
  const project = useSelector(projectSelector);

  const availableTeams = useSelector(teamsSelector);

  const scenes = useSelector(scenesArraySelector);

  // Set sceneId from url
  useEffect(() => {
    if (sceneIdFromUrl) {
      // dispatch(setSelectedSceneId(sceneIdFromUrl));
    }
  }, [dispatch, sceneIdFromUrl]);

  // // Fetch teamId using projectId
  // useEffect(() => {
  //   // if (selectedProjectId && !selectedTeamId)
  //   // dispatch(fetchTeamForProject(selectedProjectId));
  // }, [dispatch, selectedProjectId, selectedTeamId]);

  // // Fetch teams list for user
  useEffect(() => {
    if (userId && _.isEmpty(availableTeams)) {
      // dispatch(fetchTeamsForUser(userId));
      dispatch(subscribeTeams(userId));
    }
  }, [dispatch, userId]);

  // // Fetch projects list for team
  // useEffect(() => {
  //   if (
  //     selectedTeamId &&
  //     !_.isEmpty(userProjects) &&
  //     _.isEmpty(availableProjects)
  //   ) {
  //     // dispatch(fetchProjectsForTeam(selectedTeamId, userProjects));
  //   }
  // }, [dispatch, selectedTeamId, userProjects]);

  // Fetch scenes list for project
  // useEffect(() => {
  //   if (
  //     selectedProjectId &&
  //     !_.isEmpty(userScenes) &&
  //     _.isEmpty(availableScenes)
  //   ) {
  //     // dispatch(fetchScenesForProject(selectedProjectId, userScenes));
  //   }
  // }, [dispatch, selectedProjectId, userScenes]);

  // ----------------------------------------------------- New Schema

  useEffect(() => {
    if (sceneId) {
      dispatch(subscribeScene(sceneId));
    }
    return () => {
      dispatch(unsubscribeScene(sceneId));
    };
  }, [dispatch, sceneId]);

  useEffect(() => {
    if (selectedTeamId) {
      dispatch(subscribeTeam(selectedTeamId));
    }
    return () => {
      dispatch(unsubscribeTeam());
    };
  }, [dispatch, selectedTeamId]);

  useEffect(() => {
    if (selectedProjectId && selectedProjectId !== 'shared') {
      dispatch(subscribeProject(selectedProjectId));
    }
    return () => {
      dispatch(unsubscribeProject(selectedProjectId));
    };
  }, [dispatch, selectedProjectId]);

  useEffect(() => {
    if (scenes) {
      scenes.forEach(({ id }) => {
        dispatch(subscribeSceneMembers(id));
        dispatch(subscribeScenePermissions(id));
      });
    }

    return () => {
      if (scenes) {
        scenes.forEach(({ id }) => {
          dispatch(unsubscribeSceneMembers(id));
          dispatch(unsubscribeScenePermissions(id));
        });
      }
    };
  }, [dispatch, scenes]);

  useEffect(() => {
    if (scene?.project_id) {
      dispatch(setSelectedProjectId(scene?.project_id));
    }
  }, [dispatch, scene]);

  useEffect(() => {
    if (project?.team_id) {
      dispatch(setSelectedTeamId(project.team_id));
    }
  }, [dispatch, project]);
};
