import _ from 'lodash';
import produce from 'immer';
import { Action } from 'redux/common/types';
import { curvesAction, curvesSlice } from './types';

export const curvesReducer = produce(
  (state: curvesSlice = initState, action: Action) => {
    switch (action.type) {
      case curvesAction('SET', 'CURVES'): {
        let minX = 0;
        let minY = 0;
        const curves = _.cloneDeep(action.payload);

        curves.forEach(({ keyframesData }) => {
          keyframesData.forEach((keyFrame) => {
            keyFrame.type = 'free';
            keyFrame.curveType = 'weighted';
            // minX = Math.min(
            //   minX,
            //   keyFrame.position.x,
            //   keyFrame.left.x,
            //   keyFrame.right.x
            // );

            // minY = Math.min(
            //   minY,
            //   keyFrame.position.y,
            //   keyFrame.left.y,
            //   keyFrame.right.y
            // );
          });
        });

        // curves.forEach(({ keyframesData }) => {
        //   keyframesData.forEach((keyFrame) => {
        //     keyFrame.position.x = (keyFrame.position.x - minX) * 100;
        //     keyFrame.left.x = (keyFrame.left.x - minX) * 100;
        //     keyFrame.right.x = (keyFrame.right.x - minX) * 100;

        //     keyFrame.position.y = (keyFrame.position.y - minY) * 100;
        //     keyFrame.left.y = (keyFrame.left.y - minY) * 100;
        //     keyFrame.right.y = (keyFrame.right.y - minY) * 100;

        //     console.log(keyFrame);
        //   });
        // });
        state.data = curves;
        state.offsets = { x: minX, y: minY };
        return state;
      }

      case curvesAction('SET', 'X_RANGE'): {
        state.xRange = action.payload;
        return state;
      }

      case curvesAction('SET', 'Y_RANGE'): {
        state.yRange = action.payload;
        return state;
      }

      // case curvesAction('SET', 'SCREEN_HEIGHT'): {
      //   state.screen.height = action.payload;
      //   return state;
      // }

      // case curvesAction('SET', 'SCREEN_WIDTH'): {
      //   state.screen.width = action.payload;
      //   return state;
      // }

      default:
        return state;
    }
  }
);

const initState: curvesSlice = {
  offsets: { x: 0, y: 0 },
  xRange: { min: 0, max: window.innerWidth },
  yRange: { min: 0, max: window.innerHeight },
  screen: { width: window.innerWidth, height: window.innerHeight  },
  data: [],
};
