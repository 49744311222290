import _ from 'lodash';
import { createSelector } from 'reselect';
import { CurvesSelector } from './types';

export const curveOffsetsSelector = (store: CurvesSelector) =>
  store.curves.offsets;

export const curveXRangeSelector = (store: CurvesSelector) =>
  store.curves.xRange;

export const curveYRangeSelector = (store: CurvesSelector) =>
  store.curves.yRange;

export const curveScreenSelector = (store: CurvesSelector) =>
  store.curves.screen;

export const curvesSelector = createSelector(
  [
    (store: CurvesSelector) => store.curves.data,
    curveXRangeSelector,
    curveYRangeSelector,
    curveScreenSelector,
  ],
  (initCurves, xRange, yRange, screen) => {
    const curves = _.cloneDeep(initCurves);

    const transformPoint = ({ x, y }) => {
      const ret = { x: 0, y: 0 };
      ret.x =
        (x - xRange.min) * (window.innerWidth / (xRange.max - xRange.min));
      ret.y =
        (y - yRange.min) * (window.innerHeight / (yRange.max - yRange.min));
      ret.y = window.innerHeight - ret.y;
      return ret;
    };

    curves.forEach((curve) => {
      curve.keyframesData.forEach((keyFrame) => {
        keyFrame.position = transformPoint(keyFrame.position);
        if (keyFrame.left) keyFrame.left = transformPoint(keyFrame.left);
        if (keyFrame.right) keyFrame.right = transformPoint(keyFrame.right);
      });
    });

    return curves;
  }
);
