export const jobTitles = [
  'Executive Producer',
  'Line Producer',
  'Production Designer',
  'Production Coordinator',
  'Production Assistant',
  'Director',
  '1st AD',
  '2nd AD',
  'Writer',
  'Storyboard Artist',
  'Prop Master',
  'Set Construction',
  'Scenic Artist',
  'Set Decorator',
  'DP',
  '1st AC',
  '2nd AC',
  'Camera Operator',
  'Steadicam Operator',
  'Videographer',
  'Best Boy',
  'Gaffer',
  'Key Grip',
  'Location Scout',
  'Location Manager',
  'Costume Designer',
  'Costume Supervisor',
  'Hair Stylist',
  'Makeup Artist',
  'Script Supervisor',
  'Sound Mixer',
  'Special Effects Coordinator',
  'Stunt Coordinator',
];
