import './Weather.scss';
import React from 'react';
import ImageWithText from '../common/image-with-text/ImageWithText';
import firebase from 'firebase-main';

type item = {
  id: string;
  src: string;
  alt: string;
  text: string;
  isSelected?: boolean;
};

const skyItems: item[] = [
  { id: 'clear-sky', src: 'clear-sky', alt: 'clear sky', text: 'Clear Sky' },
  { id: 'cloudy1', src: 'cloudy1', alt: 'cloudy 1', text: 'Cloudy 1' },
  { id: 'cloudy2', src: 'cloudy2', alt: 'cloudy 2', text: 'Cloudy 2' },
  { id: 'cloudy3', src: 'cloudy3', alt: 'cloudy 3', text: 'Cloudy 3' },
  { id: 'cloudy4', src: 'cloudy4', alt: 'cloudy 4', text: 'Cloudy 4' },
  { id: 'foggy', src: 'foggy', alt: 'foggy', text: 'Foggy' },
];

const stormItems: item[] = [
  {
    id: 'light-rain',
    src: 'light-rain',
    alt: 'light rain',
    text: 'Light Rain',
  },
  {
    id: 'heavy-rain',
    src: 'heavy-rain',
    alt: 'heavy rain',
    text: 'Heavy Rain',
  },
  {
    id: 'light-snow',
    src: 'light-snow',
    alt: 'light snow',
    text: 'Light Snow',
  },
  {
    id: 'heavy-snow',
    src: 'heavy-snow',
    alt: 'heavy snow',
    text: 'Heavy Snow',
  },
  { id: 'storm', src: 'storm', alt: 'storm', text: 'Storm' },
];

const Weather = () => {
  const renderItems = (items: item[]) =>
    items.map(({ id, src, alt, text }) => {
      // const isSelected = selectedItem === text;
      const isSelected = false;
      return (
        <ImageWithText
          key={src}
          className={`info-card ${isSelected ? 'selected' : ''}`}
          src={`/backlot-library/scene-settings/weather/${src}.svg`}
          alt={alt}
          text={text}
          onClick={() => {
            if (isSelected) {
              (window as any).onWeatherChange('');
            } else {
              (window as any).onWeatherChange(text);
              firebase
                .analytics()
                .logEvent('scene_settings', { weather: text });
            }
          }}
        />
      );
    });

  const renderSkyItems = renderItems(skyItems);
  const renderStormItems = renderItems(stormItems);

  return (
    <div className="weather">
      <div className="title">
        <b>Weather</b>
      </div>
      <div id="body">
        <div className="info">
          <div className="sky-items">{renderSkyItems}</div>
          <div className="grow" />
          {/* <div className="storm-items">{renderStormItems}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Weather;
