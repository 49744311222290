import './PersonalProjectTab.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  projectMembersArraySelector,
  selectedProjectIdSelector,
  projectPendingInvitesArraySelector,
  shareProjectPendingSelector,
  updateProjectMemberRolePendingSelector,
} from 'redux/slices/app/selectors';
import {
  shareProject,
  updateProjectMemberRole,
  unsharePersonalProject,
  deleteProjectInvitation,
} from 'redux/slices/app/actions';
import Loader from 'common/components/loader/Loader';

export const PersonalProjectTab = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState<'Reviewer' | 'Editor'>('Editor');

  const projectId = useSelector(selectedProjectIdSelector);
  const projectMembers = useSelector(projectMembersArraySelector) ?? [];
  const pendingInvites = useSelector(projectPendingInvitesArraySelector) ?? [];

  const shareProjectPending = useSelector(shareProjectPendingSelector);
  const updateProjectMembersPending = useSelector(
    updateProjectMemberRolePendingSelector
  );

  return (
    <div className="personal-project-tab">
      <div className="invite-member">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(shareProject(projectId, email, role));
          }}
        >
          <input
            type="text"
            placeholder="user@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select value={role} onChange={(e) => setRole(e.target.value as any)}>
            <option value="Editor">Editor</option>
            <option value="Reviewer">Reviewer</option>
          </select>
          <button type="submit" disabled={shareProjectPending}>
            <Loader
              show={shareProjectPending}
              spinnerSize={12}
              spinnerColor="white"
            />
            Invite
          </button>
        </form>
        <p>
          Reviewers are external members who can view & comment on scene content
          exported to the Backlot site.
        </p>
      </div>

      <div className="members">
        <h2 className="title">Project Access</h2>

        {projectMembers.map(({ id, role, name }) => (
          <div className="member" key={id}>
            <div className="name">{name}</div>
            <Loader
              show={updateProjectMembersPending[id] === true}
              spinnerSize={20}
            >
              <select
                value={role}
                onChange={(e) => {
                  console.log(e.target.value);
                  const newRole = +e.target.value;
                  if (newRole === 3) {
                    dispatch(unsharePersonalProject(projectId, id));
                  } else if (role !== newRole)
                    dispatch(
                      updateProjectMemberRole(
                        projectId,
                        id,
                        newRole === 1 ? 'Editor' : 'Reviewer'
                      )
                    );
                }}
              >
                <option value="1">Editor</option>
                <option value="2">Reviewer</option>
                <option value="3" style={{ color: 'red' }}>
                  Remove User
                </option>
              </select>
            </Loader>
          </div>
        ))}

        {pendingInvites && (
          <>
            <h2>Pending Invitations</h2>
            {pendingInvites.map(({ id, role, email }) => (
              <div className={`member`} key={id}>
                <div className="name">{`${email} (${
                  role === 1 ? 'Editor' : 'Reviewer'
                })`}</div>
                <button
                  className="remove-btn"
                  onClick={() =>
                    dispatch(deleteProjectInvitation(projectId, id))
                  }
                >
                  Delete Invitation
                </button>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
