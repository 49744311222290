import { Action } from 'redux/common/types';
import { appActionTypes } from 'redux/slices/app/actions';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

export const localStorageMiddleware = (store) => (next) => (action: Action) => {
  next(action);
  const userId = userIdSelector(store.getState());
  if (!userId) return;
  switch (action.type) {
    case appActionTypes.SET_SELECTED_TEAM_ID: {
      try {
        const storage = JSON.parse(localStorage.getItem(userId) ?? '{}');
        if (action.payload)
          localStorage.setItem(
            userId,
            JSON.stringify({ ...storage, teamId: action.payload ?? '' })
          );
      } catch (e) {}
      return;
    }
  }
};
