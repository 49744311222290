import firebase from 'firebase-main';
import { setRegistrationError } from './../auth/actions/register';
import { thunkAction } from './../../common/types';
import { Action } from 'redux/common/types';
import { paymentAction, stripeAction } from './types';
import { setRegistrationPending } from '../auth/actions/register';
import { personalDriveIdSelector } from '../app/selectors';

//  ------------------------------------------------------------------------------ Visa Info
export const fetchPaymentInfo = (teamId): Action => ({
  type: stripeAction('FETCH', 'PAYMENT_INFO'),
  meta: {
    cloudRequest: {
      name: 'billingInfo',
      body: { teamId },
      onSuccessDispatches: [setPaymentInfo(teamId)],
      onFailDispatches: [fetchPaymentInfoFail],
    },
  },
});

export const setPaymentInfo = (teamId) => (data): Action => ({
  type: stripeAction('SET', 'PAYMENT_INFO'),
  payload: { teamId, data },
});

export const fetchPaymentInfoFail = (err): Action => ({
  type: stripeAction('FETCH', 'PAYMENT_INFO', 'FAIL'),
  payload: err,
});
//  -------------------------------------------------------------------- Team Drive Purchase
export const purchaseTeamDrive = (teamName, stripeId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setRegistrationPending(true));
    dispatch({
      type: stripeAction('PURCHASE', 'TEAM_DRIVE'),
    });

    await firebase
      .functions()
      .httpsCallable('createTeamDrive')({
        paymentMethodId: stripeId,
        teamName,
        // deleteUserOnFail: true,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.message === 'requires_action') {
          dispatch(setPaymentAction(res.data));
        } else {
          dispatch(setRegistrationPending(false));
          dispatch(confirmTeamDrivePaymentSuccess());
        }
      });
  } catch (err: any) {
    console.error(err);

    dispatch(setRegistrationError(err ?? 'error'));
    dispatch(setRegistrationPending(false));
    dispatch({
      type: 'TOASTER',
      meta: {
        toaster: {
          type: 'error',
          message: 'Failed to register',
          description: err,
        },
      },
    });
  }
};

//  -------------------------------------------------------------------- Personal Drive Purchase
export const updatePersonalDrive: thunkAction = (stripeId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setRegistrationPending(true));

    const driveId = personalDriveIdSelector(getState());

    const params: any = {
      paymentMethodId: stripeId,
      // deleteUserOnFail: true,
    };

    await firebase
      .functions()
      .httpsCallable('updateUserDrive')(params)
      .then((res) => {
        console.log(res.data);
        if (res.data.message === 'requires_action') {
          dispatch(setPaymentAction(res.data));
        } else if (res.data.message === 'success') {
          dispatch(setRegistrationPending(false));
          dispatch(setRegistrationError(null));
          dispatch(confirmUserDrivePaymentUpdateSuccess());
          dispatch(fetchPaymentInfo(driveId));
        } else {
          dispatch(setRegistrationPending(false));
          dispatch(setRegistrationError(null));
        }
      });
  } catch (err: any) {
    console.error(err);

    dispatch(setRegistrationError(err ?? 'error'));
    dispatch(setRegistrationPending(false));
    dispatch({
      type: 'TOASTER',
      meta: {
        toaster: {
          type: 'error',
          message: 'Failed to register',
          description: err,
        },
      },
    });
  }
};

export const confirmUserDriveUpdatePayment = (responseToken): Action => ({
  type: stripeAction('CONFIRM', 'PAYMENT'),
  meta: {
    cloudRequest: {
      name: 'updateUserDriveConfirmationResponse',
      body: { responseToken },
      onSuccessDispatches: [confirmUserDrivePaymentUpdateSuccess],
      onFailDispatches: [confirmUserDrivePaymentUpdateFail],
    },
  },
});

export const confirmUserDrivePaymentUpdateSuccess: thunkAction = () => (
  dispatch,
  getState
) => {
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'SUCCESS'),
    meta: {
      toaster: {
        type: 'success',
        message: 'Subscription updated successfully!',
      },
    },
  });
};

export const confirmUserDrivePaymentUpdateFail: thunkAction = (err) => (
  dispatch,
  getState
) => {
  console.error(err);
  dispatch(setRegistrationError(`Failed to confirm payment ${err}`));
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to confirm Payment',
        description: err,
      },
    },
  });
};

//  ------------------------------------------------------------------------------ Fetch Prices
export const fetchDrivesPrices = (): Action => ({
  type: stripeAction('FETCH', 'DRIVES_PRICES'),
  meta: {
    cloudRequest: {
      name: 'getSubscriptionCost',
      body: {},
      onSuccessDispatches: [setDrivesPrices],
      onFailDispatches: [fetchDrivesPricesFail],
    },
  },
});

export const setDrivesPrices = (prices): Action => ({
  type: stripeAction('SET', 'DRIVES_PRICES'),
  payload: prices,
});

export const fetchDrivesPricesFail = (err): Action => ({
  type: stripeAction('FETCH', 'DRIVES_PRICES', 'FAIL'),
  payload: err,
});

export const fetchLicensePrices = (
  teamId,
  licensesNum,
  type: 'Editor' | 'Reviewer'
): Action => ({
  type: stripeAction('FETCH', 'LICENSE_PRICES'),
  payload: { teamId, licenses: licensesNum, type },
  meta: {
    cloudRequest: {
      name: 'calculateSubscriptionCost',
      body: { teamId, licenses: licensesNum, type },
      onSuccessDispatches: [setLicensePrices(teamId, licensesNum, type)],
      onFailDispatches: [fetchLicensePricesFail(teamId, licensesNum, type)],
    },
  },
});

export const setLicensePrices = (
  teamId,
  licensesNum,
  type: 'Editor' | 'Reviewer'
) => (data): Action => ({
  type: stripeAction('SET', 'LICENSE_PRICES'),
  payload: { teamId, licenses: licensesNum, type, cost: data.cost },
});

export const fetchLicensePricesFail = (
  teamId,
  licensesNum,
  type: 'Editor' | 'Reviewer'
) => (err): Action => ({
  type: stripeAction('FETCH', 'LICENSE_PRICES', 'FAIL'),
  payload: { teamId, licenses: licensesNum, type, err },
});

//  ------------------------------------------------------------------------------ Register
export const setPaymentAction = (paymentAction: paymentAction): Action => ({
  type: stripeAction('SET', 'PAYMENT_ACTION'),
  payload: paymentAction,
});

export const confirmUserDrivePayment = (responseToken): Action => ({
  type: stripeAction('CONFIRM', 'PAYMENT'),
  meta: {
    cloudRequest: {
      name: 'createUserDriveConfirmationResponse',
      body: { responseToken },
      onSuccessDispatches: [confirmUserDrivePaymentSuccess],
      onFailDispatches: [confirmUserDrivePaymentFail],
    },
  },
});

export const confirmUserDrivePaymentSuccess: thunkAction = () => (
  dispatch,
  getState
) => {
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'SUCCESS'),
  });
};

export const confirmUserDrivePaymentFail: thunkAction = (err) => (
  dispatch,
  getState
) => {
  console.error(err);
  dispatch(setRegistrationError(`Failed to confirm payment ${err}`));
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to confirm Payment',
        description: err,
      },
    },
  });
};

export const confirmTeamDrivePayment = (responseToken): Action => ({
  type: stripeAction('CONFIRM', 'PAYMENT'),
  meta: {
    cloudRequest: {
      name: 'createTeamDriveConfirmationResponse',
      body: { responseToken },
      onSuccessDispatches: [confirmTeamDrivePaymentSuccess],
      onFailDispatches: [confirmTeamDrivePaymentFail],
    },
  },
});

export const confirmTeamDrivePaymentSuccess: thunkAction = () => (
  dispatch,
  getState
) => {
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'SUCCESS'),
    meta: {
      toaster: {
        type: 'success',
        message: 'Team Drive purchased successfully!',
      },
    },
  });
};

export const confirmTeamDrivePaymentFail: thunkAction = (err) => (
  dispatch,
  getState
) => {
  console.error(err);
  dispatch(setRegistrationError(`Failed to confirm payment ${err}`));
  dispatch(setRegistrationPending(false));
  dispatch({
    type: stripeAction('CONFIRM', 'PAYMENT', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to confirm Payment',
        description: err,
      },
    },
  });
};

//------------------------------- Update payment method ----------------------------------------------
export const updatePaymentMethod: thunkAction = (
  teamId,
  paymentMethodId,
  done
) => async (dispatch, getState) => {
  dispatch({ type: stripeAction('UPDATE', 'PAYMENT_METHOD') });

  const body = {
    teamId,
    paymentMethodId,
  };

  firebase
    .functions()
    .httpsCallable('updatePaymentMethod')(body)
    .then((res) => {
      dispatch(updatePaymentMethodSuccess(res.data));
      done();
    })
    .catch((error) => {
      dispatch(updatePaymentMethodFail(error));
    });
};

export const updatePaymentMethodSuccess = (cardData): Action => {
  return {
    // type: teamActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
    type: stripeAction('UPDATE', 'PAYMENT_METHOD', 'SUCCESS'),
    payload: cardData,
    meta: {
      toaster: {
        type: 'success',
        message: 'Successful payment method update.',
      },
    },
  };
};

export const updatePaymentMethodFail = (errorMessage): Action => {
  return {
    // type: teamActionTypes.UPDATE_PAYMENT_METHOD_FAIL,
    type: stripeAction('UPDATE', 'PAYMENT_METHOD', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Update payment method failed.',
        description: errorMessage,
      },
    },
  };
};

//  ------------------------------------------------------------------------------ Team Editor License
export const purchaseTeamEditorLicense = (teamId, numOfLicenses): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE'),
  meta: {
    cloudRequest: {
      name: 'buyTeamLicense',
      body: { teamId, licenses: numOfLicenses, type: 'Editor' },
      onSuccessDispatches: [purchaseTeamEditorLicenseSuccess],
      onFailDispatches: [purchaseTeamEditorLicenseFail],
    },
  },
});

export const purchaseTeamEditorLicenseSuccess = (): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE', 'SUCCESS'),
  meta: {
    toaster: {
      type: 'success',
      message: 'Editor licenses purchased successfully!',
    },
  },
});

export const purchaseTeamEditorLicenseFail = (err): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to purchase licenses',
      description: err,
    },
  },
});

//  ------------------------------------------------------------------------------ Team Reviewer License
export const purchaseTeamReviewerLicense = (teamId, numOfLicenses): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE'),
  meta: {
    cloudRequest: {
      name: 'buyTeamLicense',
      body: { teamId, licenses: numOfLicenses, type: 'Reviewer' },
      onSuccessDispatches: [purchaseTeamReviewerLicenseSuccess],
      onFailDispatches: [purchaseTeamReviewerLicenseFail],
    },
  },
});

export const purchaseTeamReviewerLicenseSuccess = (): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE', 'SUCCESS'),
  meta: {
    toaster: {
      type: 'success',
      message: 'Reviewer licenses purchased successfully!',
    },
  },
});

export const purchaseTeamReviewerLicenseFail = (err): Action => ({
  type: stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to purchase licenses',
      description: err,
    },
  },
});

//  ------------------------------------------------------------------------------ Team Members
export const addTeamMember = (teamId, email, role): Action => ({
  type: stripeAction('ADD', 'TEAM_MEMBER'),
  meta: {
    cloudRequest: {
      name: 'addUserToTeam',
      body: { teamId, email, role },
      onSuccessDispatches: [addTeamMemberSuccess],
      onFailDispatches: [addTeamMemberFail],
    },
  },
});

export const addTeamMemberSuccess = (): Action => ({
  type: stripeAction('ADD', 'TEAM_MEMBER', 'SUCCESS'),
});

export const addTeamMemberFail = (err): Action => ({
  type: stripeAction('ADD', 'TEAM_MEMBER', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to add member',
      description: err,
    },
  },
});

export const clearPaymentAction = (): Action => ({
  type: stripeAction('CLEAR', 'PAYMENT_ACTION'),
});
