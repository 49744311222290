// import { commentActionTypeFactory } from 'redux/slices/comments/actions';

const initState = {
  sceneCommentsSubscriptionPending: false,
};
export const sceneCommentsLoadersReducer = (state = initState, action) => {
  switch (action.type) {
    // case commentActionTypeFactory('SUBSCRIBE', 'SCENE_COMMENT'):
    //   return { ...state, sceneCommentsSubscriptionPending: true };
    // case commentActionTypeFactory('SUBSCRIBE', 'SCENE_COMMENT', 'SUCCESS'):
    // case commentActionTypeFactory('SUBSCRIBE', 'SCENE_COMMENT', 'FAIL'):
    //   return { ...state, sceneCommentsSubscriptionPending: false };

    default:
      return state;
  }
};
