import './ProjectSettings.scss';
import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { FaEllipsisV } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeProject, setSortableProject } from 'redux/slices/app/actions';
import {
  inPersonalDriveSelector,
  selectedProjectIdSelector,
  selectedProjectNameSelector,
  selectedTeamIdSelector,
  sharedProjectsSelector,
} from 'redux/slices/app/selectors';
import { useHistory } from 'react-router-dom';
import RenameProjectModal from './rename-project-modal/RenameProjectModal';
import { ShareTeamProjectModal } from 'backlot-menu/components/share/team-share/ShareTeamProjectModal';
import { SharePersonalProjectModal } from 'backlot-menu/components/share/personal-share/SharePersonalProjectModal';

const ProjectSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showShareProjectModal, setShowShareProjectModal] = useState(false);

  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const projectName = useSelector(selectedProjectNameSelector);
  const sharedProjects = useSelector(sharedProjectsSelector);
  //   removeProject;

  const inSharedProject = sharedProjects && projectId in sharedProjects;

  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete this project?`,
      icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
      content: `The project ${projectName} will not be removed forever, it can be restored from the recycle bin`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(removeProject(teamId, projectId, projectName));
        history.push(`/menu/recent`);
      },
      onCancel() {},
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setShowRenameModal(true)}>
        Rename Project
      </Menu.Item>

      {/* {!inSharedProject && (
        <Menu.Item onClick={() => setShowShareProjectModal(true)}>
          Share Project
        </Menu.Item>
      )} */}

      {!inSharedProject && (
        <Menu.Item onClick={() => dispatch(setSortableProject(true))}>
          Rearrange Scenes
        </Menu.Item>
      )}

      {!inSharedProject && (
        <Menu.Item onClick={showDeleteConfirm} style={{ color: 'red' }}>
          Delete Project
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {showRenameModal && (
        <RenameProjectModal
          onClose={() => {
            setShowRenameModal(false);
          }}
        />
      )}

      {showShareProjectModal && inPersonalDrive && (
        <SharePersonalProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}

      {showShareProjectModal && !inPersonalDrive && (
        <ShareTeamProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}

      <Dropdown
        transitionName=""
        //   visible={showDropdown}
        //   onVisibleChange={(visible) => setShowDropdown(visible)}
        className="project-settings-dropdown"
        overlay={menu}
        trigger={['click']}
      >
        {/* <img className="screen-content-card-icon" src='/assets/images/menu/scene-dots.svg' /> */}
        <FaEllipsisV className="screen-content-card-icon" color="white" />
      </Dropdown>
    </>
  );
};

export default ProjectSettings;
