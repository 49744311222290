import './GalleryBody.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  galleryImagesSelector,
  pendingImageSelector,
} from 'redux/slices/itemGallery/selectors';
import Loader from 'common/components/loader/Loader';

const GalleryBody = () => {
  const images = useSelector(galleryImagesSelector);
  const pendingImg = useSelector(pendingImageSelector);

  const renderImages = images.map(({ url, timestamp }) => (
    <div
      key={timestamp}
      className="image-card"
      onClick={() => {
        (window as any).selectImage(url);
      }}
    >
      <div className="img" style={{ backgroundImage: `url('${url}')` }}></div>
    </div>
  ));

  const renderPendingImg = pendingImg && (
    <div className="image-card">
      <div className="img" style={{ backgroundImage: `url('${pendingImg}')` }}>
        <Loader show centerSpinner></Loader>
      </div>
    </div>
  );

  return (
    <div id="gallery-body">
      {renderImages}
      {renderPendingImg}
    </div>
  );
};

export default GalleryBody;
