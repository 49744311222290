import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'redux/slices/auth/actions/login';
import {
  setSelectedProjectId,
  setSelectedSceneId,
  setSelectedTeamId,
  setUUID,
} from 'redux/slices/app/actions';
import {
  addInstantiatedItems,
  setItemDownloadedLocally,
  setItemProgress,
  setLibraryVersion,
  setPropAnimations,
  setPropSearchAnimations,
  setSubcategoryDownloadedLocally,
} from 'redux/slices/items/actions';
import { setBundles } from 'redux/slices/bundles/actions';
import {
  setCurves,
  setHeight,
  setWidth,
  setXRange,
  setYRange,
} from 'redux/slices/curves/actions';
import { setResOptions, setSettings } from 'redux/slices/settings/actions';
import {
  setCharacterId,
  setSelectedOption,
} from 'redux/slices/character/actions';

export const useWindowListeners = () => {
  const dispatch = useDispatch(null);

  const loginUser = (e) => {
    const { email, password } = e.params;
    dispatch(login(email, password));
  };

  const onDownloadProgress = (e) => {
    const { addressableID, progress } = e.params;
    dispatch(setItemProgress(addressableID, +progress));
  };

  const onDownloadedLocally = (e) => {
    const items = e.params.items.split(',');
    console.log(items);

    const itemsObj = {};
    items.forEach((item) => {
      itemsObj[item] = true;
    });

    dispatch(setItemDownloadedLocally(itemsObj));
  };

  const onAddInstantiatedItems = (e) => {
    const items = e.params.items.split(',');
    console.log(items);
    dispatch(addInstantiatedItems(items));
  };

  const onSubcategoryProgress = (e) => {
    const { subcategory, progress, progressText } = e.params;
    dispatch(
      setSubcategoryDownloadedLocally(subcategory, progress, progressText)
    );
  };

  const onSetTeamId = (e) => {
    const { teamId } = e.params;
    dispatch(setSelectedTeamId(teamId));
  };

  const onsetProjectId = (e) => {
    const { projectId } = e.params;
    dispatch(setSelectedProjectId(projectId));
  };

  const onSetSceneId = ({ params }) => {
    dispatch(setSelectedSceneId(params.sceneId));
  };

  const onLibraryVerChange = ({ params }) => {
    const { version } = params;
    dispatch(setLibraryVersion(version));
  };

  const onSetBundles = ({ params }) => {
    // setData(params.bundles);
    dispatch(setBundles(params.bundles));
    console.log(params.bundles);
  };
  const onSetPropAnimations = ({ params }) => {
    // setData(params.bundles);
    dispatch(setPropAnimations(params.items));
    dispatch(setPropSearchAnimations(params.items));
  };

  const onSetXRange = ({ params }) => {
    dispatch(setXRange(params.min, params.max));
  };
  const onSetYRange = ({ params }) => {
    dispatch(setYRange(params.min, params.max));
  };

  const onSetCurves = ({ params }) => {
    const curves = params.curves;
    dispatch(setCurves(curves));
  };

  const onScreenUpdate = ({ params }) => {
    const { width, height } = params;
    dispatch(setWidth(width));
    dispatch(setHeight(height));
  };

  const onSetSettings = ({ params }) => {
    dispatch(setSettings(params));
  };

  const onSetResOptions = ({ params }) => {
    dispatch(setResOptions(params.options));
  };

  const onSetUUID = ({ params }) => {
    dispatch(setUUID(params.uuid));
  };

  const onSetCharacterId = ({ params }) => {
    dispatch(setCharacterId(params.id));
  };

  const setCharacterOption = ({ params }) => {
    dispatch(setSelectedOption(params.option, params.value));
  };

  useEffect(() => {
    window.addEventListener('login', loginUser);

    window.addEventListener('downloadProgress', onDownloadProgress);
    window.addEventListener('downloadedLocally', onDownloadedLocally);
    window.addEventListener('addInstantiatedItems', onAddInstantiatedItems);
    window.addEventListener('downloadSubcategory', onSubcategoryProgress);

    window.addEventListener('setTeamId', onSetTeamId);
    window.addEventListener('setProjectId', onsetProjectId);
    window.addEventListener('setSceneId', onSetSceneId);
    window.addEventListener('setLibraryVersion', onLibraryVerChange);
    window.addEventListener('setBundles', onSetBundles);
    window.addEventListener('setPropAnimations', onSetPropAnimations);
    window.addEventListener('setAnimationCurves', onSetCurves);
    window.addEventListener('setXRange', onSetXRange);
    window.addEventListener('setYRange', onSetYRange);
    window.addEventListener('setScreenDimensions', onScreenUpdate);
    window.addEventListener('setSettings', onSetSettings);
    window.addEventListener('setResOptions', onSetResOptions);
    window.addEventListener('setUUID', onSetUUID);

    // Character Wizard
    window.addEventListener('setCharacterOption', setCharacterOption);
    window.addEventListener('setCharacterId', onSetCharacterId);

    return () => {
      window.removeEventListener('login', loginUser);

      window.removeEventListener('downloadProgress', onDownloadProgress);
      window.removeEventListener('downloadedLocally', onDownloadedLocally);
      window.removeEventListener(
        'addInstantiatedItems',
        onAddInstantiatedItems
      );
      window.removeEventListener('downloadSubcategory', onSubcategoryProgress);

      window.removeEventListener('setTeamId', onSetTeamId);
      window.removeEventListener('setProjectId', onsetProjectId);
      window.removeEventListener('setSceneId', onSetSceneId);
      window.removeEventListener('setLibraryVersion', onLibraryVerChange);
      window.removeEventListener('setBundles', onSetBundles);
      window.removeEventListener('setPropAnimations', onSetPropAnimations);
      window.removeEventListener('setAnimationCurves', onSetCurves);
      window.removeEventListener('setXRange', onSetXRange);
      window.removeEventListener('setYRange', onSetYRange);
      window.removeEventListener('setScreenDimensions', onScreenUpdate);
      window.removeEventListener('setSettings', onSetSettings);
      window.removeEventListener('setResOptions', onSetResOptions);
      window.removeEventListener('setUUID', onSetUUID);

      // Character Wizard
      window.removeEventListener('setCharacterOption', setCharacterOption);
      window.removeEventListener('setCharacterId', onSetCharacterId);
    };
  }, []);
};
