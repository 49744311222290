// interface downloadSlice {
//     downloads:[
//         {
//             id: string,
//             type: string,
//             name: string,
//             progress: number,
//             downloaded: string,
//             paused: boolean,
//             isOwner: number,
//             isPack: number,
//           }
//     ]
// }
export const downloadsSelector = (store) =>
  store.downloads;
export const x =0;