import firebase from 'firebase-main';
import { Action } from 'redux/common/types';

export const cloudFunctionsMiddleware =
  (store) => (next) => (action: Action) => {
    next(action);
    if (action.meta?.cloudRequest) {
      const {
        name,
        body,
        onSuccessDispatches = [],
        onFailDispatches = [],
      } = action.meta.cloudRequest;

      firebase
        .functions()
        .httpsCallable(name)(body)
        .then((res) => {
          onSuccessDispatches.forEach((actionCreator) =>
            store.dispatch(actionCreator(res.data))
          );
        })
        .catch((err) => {
          console.error(err);
          console.error(err.message);
          console.error(err.details);
          onFailDispatches.forEach((actionCreator) =>
            store.dispatch(actionCreator(err.message))
          );
        });
    }
  };
