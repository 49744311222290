import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './rootReducer';
import { firebaseRealtimeApiMiddleware } from './middlewares/firebaseRealtimeApiMiddleware';
import { firebaseStorageApiMiddleware } from './middlewares/firebase-storage';
import { toasterMiddleware } from './middlewares/toasterMiddleware';
import { authMiddleware } from './middlewares/auth';
import { libraryDbMiddleware } from './middlewares/libraryDb';
import { algoliaItemsMiddleware } from './middlewares/algolia-items';
import { cloudFunctionsMiddleware } from './middlewares/cloud-functions';
import { firebaseLogger } from './middlewares/firebase-logger';
import { localStorageMiddleware } from './middlewares/local-storage';
import { amplitudeMiddleware } from './middlewares/amplitude';
import { blueprintPlayerMiddleware } from './middlewares/blueprint';

const composeEnhancers = composeWithDevTools({});
export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(
    applyMiddleware(
      reduxThunk,
      // firebaseLogger,
      cloudFunctionsMiddleware,
      firebaseRealtimeApiMiddleware,
      firebaseStorageApiMiddleware,
      libraryDbMiddleware,
      toasterMiddleware,
      algoliaItemsMiddleware,
      blueprintPlayerMiddleware,
      authMiddleware,
      localStorageMiddleware,
      amplitudeMiddleware,
    )
  )
);
