import './Billing.scss';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  personalDriveIsCanceledSelector,
  personalDriveIsActiveSelector,
  selectedTeamIsActiveSelector,
  selectedTeamIdSelector,
  inPersonalDriveSelector,
  personalDriveCustomerIdSelector,
  selectedTeamIsCanceledSelector,
} from 'redux/slices/app/selectors';
import { paymentDataSelector } from 'redux/slices/stripe/selectors';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import firebase from 'firebase-main';
import { useStripe } from '@stripe/react-stripe-js';
import {
  purchaseTeamEditorLicenseFail,
  purchaseTeamEditorLicenseSuccess,
  purchaseTeamReviewerLicenseFail,
  purchaseTeamReviewerLicenseSuccess,
} from 'redux/slices/stripe/actions';
import AddCreditModal from './add-credit-modal/AddCreditModal';
import moment from 'moment';
import { useHistory } from 'react-router';
import Loader from 'common/components/loader/Loader';
import { CancelSubModal } from './cancel-sub-modal/CancelSubModal';
import { PurchaseTeamModal } from './purchase-team-modal/PurchaseTeamModal';
import { ResumeSubModal } from './resume-sub-modal/ResumeSubModal';
import EditPaymentModal from './editPaymentModal/EditPaymentModal';
import LicenseModal from './license-modal/LicenseModal';
import InvoiceHistory from './invoice-history/InvoiceHistory';
import { InAppPurchaseHistory } from './in-app-purchase-history/InAppPurchaseHistory';

const fetchCardLogo = (cardType) => {
  switch (cardType) {
    case 'visa':
      return '/banks-logos/visa-logo.png';
    case 'mastercard':
      return '/banks-logos/mastercard-logo.png';
    case 'amex':
      return '/banks-logos/american-express-logo.jpg';
    case 'discover':
      return '/banks-logos/discover-logo.png';
    case 'diners':
      return '/banks-logos/dinnrs-club-logo.png';
    case 'jcb':
      return '/banks-logos/JCB-logo.jpg';
    case 'unionpay':
      return '/banks-logos/unionpay-logo.png';
    default:
      return undefined;
  }
};

export const Billing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showBuyTeamModal, setShowBuyTeamModal] = useState(false);
  const [showBuyPersonalModal, setShowBuyPersonalModal] = useState(false);
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);

  const stripe = useStripe();
  const [showEditorLicenseModal, setShowEditorLicenseModal] = useState(false);
  const [showReviewerLicenseModal, setShowReviewerLicenseModal] =
    useState(false);

  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const [showResumeSubModal, setShowResumeSubModal] = useState(false);

  const [resumeSubscriptionPending, setResumeSubscriptionPending] =
    useState(false);
  const [licensePending, setLicensePending] = useState(false);

  const teamId = useSelector(selectedTeamIdSelector);

  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const personalDriveIsActive = useSelector(personalDriveIsActiveSelector);
  const personalDriveIsCanceled = useSelector(personalDriveIsCanceledSelector);

  const personalDriveIsAvailable = useSelector(personalDriveCustomerIdSelector);

  const teamIsActive = useSelector(selectedTeamIsActiveSelector);
  const teamIsCanceled = useSelector(selectedTeamIsCanceledSelector);

  const isActive = inPersonalDrive ? personalDriveIsActive : teamIsActive;
  const isCanceled = inPersonalDrive ? personalDriveIsCanceled : teamIsCanceled;

  const billingInfo = (useSelector(paymentDataSelector) ?? {})[teamId];

  const showResumeConfirm = isActive && isCanceled;
  const params = queryString.parse(history.location.search);

  const confirmResume = () => {
    Modal.confirm({
      title: 'Resume Subscription',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to resume subscription',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        setResumeSubscriptionPending(true);
        firebase
          .functions()
          .httpsCallable('resumeSubscription')({
            teamId,
          })
          .then(() => {
            setResumeSubscriptionPending(false);
          })
          .catch((err) => {
            setResumeSubscriptionPending(false);
            console.error(err);
          });
      },
    });
  };

  const onPurchaseLicense = async (licenses, type: 'Editor' | 'Reviewer') => {
    setLicensePending(true);
    try {
      const res = await firebase.functions().httpsCallable('buyTeamLicense')({
        teamId,
        licenses,
        type: type,
      });

      if (res?.data?.message === 'requires_action') {
        await stripe?.confirmCardPayment(res.data.client_secret, {
          payment_method: res.data.paymentMethodId,
        });

        await firebase
          .functions()
          .httpsCallable('buyTeamLicenseConfirmationResponse')({
          responseToken: res.data.responseToken,
        });
      }
      setLicensePending(false);

      dispatch(
        type === 'Editor'
          ? purchaseTeamEditorLicenseSuccess()
          : purchaseTeamReviewerLicenseSuccess()
      );

      if (type === 'Editor') setShowEditorLicenseModal(false);
      else setShowReviewerLicenseModal(false);
    } catch (err: any) {
      setLicensePending(false);
      dispatch(
        type === 'Editor'
          ? purchaseTeamEditorLicenseFail(err)
          : purchaseTeamReviewerLicenseFail(err)
      );
    }
  };

  return (
    <Tab.Pane active = {params?.tab === "billing"} id="billing">
      {showBuyTeamModal && (
        <PurchaseTeamModal onClose={() => setShowBuyTeamModal(false)} />
      )}
      {/* {showBuyPersonalModal && (
        <PurchasePersonalModal onClose={() => setShowBuyPersonalModal(false)} />
      )} */}
      {showCancelSubModal && (
        <CancelSubModal
          teamId={teamId}
          onClose={() => setShowCancelSubModal(false)}
        />
      )}

      {showResumeSubModal && (
        <ResumeSubModal
          teamId={teamId}
          onClose={() => setShowResumeSubModal(false)}
        />
      )}

      {showAddCreditModal && (
        <AddCreditModal
          teamId={teamId}
          onCancel={() => setShowAddCreditModal(false)}
        />
      )}

      {showEditorLicenseModal && (
        <LicenseModal
          title="Purchase Editor Licenses"
          purchaseLoader={licensePending}
          onCancel={() => setShowEditorLicenseModal(false)}
          onSubmit={(numOfLicenses) => {
            // dispatch(purchaseTeamEditorLicense(teamId, numOfLicenses));
            onPurchaseLicense(numOfLicenses, 'Editor');
          }}
          type="Editor"
        />
      )}
      {showReviewerLicenseModal && (
        <LicenseModal
          purchaseLoader={licensePending}
          title="Purchase Reviewers Licenses"
          onCancel={() => setShowReviewerLicenseModal(false)}
          onSubmit={(numOfLicenses) => {
            // dispatch(purchaseTeamReviewerLicense(teamId, numOfLicenses));
            onPurchaseLicense(numOfLicenses, 'Reviewer');
          }}
          type="Reviewer"
        />
      )}
      <h6>BILLING SETTINGS</h6>

      {/* {inPersonalDrive && !isActive && !isCanceled && ( */}
      {/* {
        <div className="section new-drive">
          <label className="title">New Drive</label>
          <p>
            Purchase a new enterprise drive where you can create a team and add
            team members for collaboration.
          </p>
          <button
            type="button"
            className="subscribe-btn"
            onClick={() => setShowBuyTeamModal(true)}
          >
            Purchase Enterprise Drive
          </button>

          {!personalDriveIsAvailable && (
            <>
              <p>
                Purchase a personal drive where you can share your projects and
                scenes with anyone.
              </p>
              <button
                type="button"
                className="subscribe-btn"
                // onClick={() => setShowBuyPersonalModal(true)}
                onClick={() => history.push('/purchase')}
              >
                Purchase Personal Drive
              </button>
            </>
          )}
        </div>
      } */}
      <div className="section">
        <label className="title">Payment Method</label>
        <p>
          Your payment method will be used for all in-app purchases, as well as
          for your drive's subscription fees.
        </p>
        {/* {!isActive ? ( */}
        {/* <div className="small text-muted mb-3">
          You have not added a payment method
        </div> */}
        {/* ) : ( */}
        <Row className="no-gutters ">
          {/* {isAdmin === true ? <PaymentDetails /> : null} */}
          {/* <PaymentDetails /> */}
          <div className="payment-method">
            {/* <div className="credit-info">{`Free credit remaining: $${credit}`}</div> */}
            <div className="d-flex align-items-center my-2">
              <div className="bank-logo">
                <img
                  src={
                    billingInfo !== null
                      ? fetchCardLogo(billingInfo?.card?.brand)
                      : undefined
                  }
                  width="60px"
                  height="40px"
                />
              </div>

              <span className="mx-2">
                ending in {billingInfo?.card?.last4}{' '}
              </span>
            </div>
            <EditPaymentModal teamId={teamId} />
          </div>
        </Row>
        {/* )} */}
      </div>

      <div className="section">
        <label className="title">Wallet</label>
        <div className="wallet">
          ${' '}
          {billingInfo?.wallet
            ? (billingInfo?.wallet / 100).toFixed(2)
            : '0.00'}
        </div>
        <p>
          You can add credit to your wallet that will be used when purchasing
          bundles. <br /> Please note that this will only be added to the
          currently selected drive and will not be used for the subscription
          fees.
        </p>
        <button
          type="button"
          className="subscribe-btn"
          onClick={() => setShowAddCreditModal(true)}
        >
          + Add Credit
        </button>
      </div>

      {!inPersonalDrive && !isCanceled && isActive && (
        <div className="section">
          <label className="title">Licenses</label>
          <div className="purchase-licenses">
            <p>
              This drive currently has{' '}
              <b>{billingInfo?.number_of_editor_licenses}</b> editor licenses
              and <b>{billingInfo?.number_of_reviewer_licenses}</b> reviewer
              licenses. <br />
              There are <b>{billingInfo?.number_of_active_editors}</b> active
              editors and <b>{billingInfo?.number_of_active_reviewers}</b>{' '}
              active reviewers
            </p>
            <button
              type="button"
              className="subscribe-btn"
              onClick={() => {
                setShowEditorLicenseModal(true);
              }}
            >
              + Buy Editor License
            </button>

            <button
              type="button"
              className="subscribe-btn"
              style={{ marginLeft: '1rem' }}
              onClick={() => {
                setShowReviewerLicenseModal(true);
              }}
            >
              + Buy Reviewer License
            </button>
          </div>
        </div>
      )}

      {personalDriveIsAvailable && (
        <div className="section">
          <label className="title">Subscription Status</label>
          {isActive && !isCanceled && (
            <>
              <p>This drive is currently active.</p>
              {billingInfo?.end_date && (
                <p style={{ marginTop: '-1rem' }}>
                  Subscription renewal date:{' '}
                  {moment((billingInfo?.end_date ?? 0) * 1000).calendar()}
                </p>
              )}
              <button
                type="button"
                className="cancel-sub-btn"
                onClick={() => setShowCancelSubModal(true)}
              >
                Cancel Subscription
              </button>
            </>
          )}

          {isCanceled && (
            <>
              <p>
                Your subscription has been terminated. You have until{' '}
                {moment((billingInfo?.end_date ?? 0) * 1000).calendar()} to
                continue using Backlot.
              </p>
              <button
                type="button"
                className="subscribe-btn"
                onClick={() =>
                  showResumeConfirm
                    ? confirmResume()
                    : setShowResumeSubModal(true)
                }
                disabled={resumeSubscriptionPending}
              >
                <Loader
                  show={resumeSubscriptionPending}
                  spinnerSize={16}
                  spinnerColor="white"
                  backgroundStyle={{ display: 'inline', marginRight: '1rem' }}
                />
                Resume Subscription
              </button>
            </>
          )}
          {/* <button className="btn btn-info" type="button">
            Add Payment Method
          </button> */}
        </div>
      )}
      <div className="form-group mb-0 section">
        <label className="title">Payment History</label>

        <InvoiceHistory teamId={teamId} />
        {/* <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
            You have not made any payment.
          </div> */}
      </div>

      <div className="form-group mb-0 section">
        <label className="title">In-App Purchases</label>

        <InAppPurchaseHistory teamId={teamId} />
        {/* <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
            You have not made any payment.
          </div> */}
      </div>
    </Tab.Pane>
  );
};
