import './ImageWithText.scss';
import React from 'react';

interface propTypes {
  className?: string;
  src: string;
  alt: string;
  text: string;
  onClick?: (e: any) => void;
}

const ImageWithText = ({
  className = '',
  src,
  alt,
  text,
  onClick = () => {},
}: propTypes) => {
  return (
    <div className={className} style={{ display: 'flex' }} onClick={onClick}>
      <img src={src} alt={alt} onClick={onClick} />
      <div onClick={onClick}>{text}</div>
    </div>
  );
};

export default ImageWithText;
