import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  selectedSceneNameSelector,
} from 'redux/slices/app/selectors';
import { renameSharedScene, renameScene } from 'redux/slices/app/actions';
import { renameScenePendingSelector } from 'redux/slices/loaders/loadersSelectors';
import { useHistory } from 'react-router';

const RenameSceneModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useSelector(selectedProjectIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);
  const sceneName = useSelector(selectedSceneNameSelector) ?? '';
  const [sceneType, setSceneType] = useState('INT.');
  const [name, setName] = useState(sceneName);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const renamePending = useSelector(renameScenePendingSelector);

  const inSharedScenes = history.location.pathname === '/menu/shared-scenes';

  useEffect(() => {
    const [sType, sName] = sceneName.split('.');
    setName(sName);
    setSceneType(sType + '.');
  }, [sceneName]);

  const submit = () => {
    console.log(sceneType + name);
    if (inSharedScenes) dispatch(renameSharedScene(sceneId, sceneType + name));
    else dispatch(renameScene(projectId, sceneId, sceneType + name));
    setFormSubmitted(true);
  };

  useEffect(() => {
    if (formSubmitted && !renamePending) {
      onClose();
    }
  }, [formSubmitted, renamePending]);

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="duplicate-scene-modal"
      title={'Rename Scene'}
      visible={true}
      centered
      onCancel={onClose}
      maskClosable={!renamePending}
      onOk={submit}
      confirmLoading={renamePending}
    >
      <form>
        <Select
          value={sceneType}
          onChange={(v) => {
            setSceneType(v);
          }}
          style={{ marginRight: '1rem' }}
          options={[
            { label: 'INT.', value: 'INT.' },
            { label: 'EXT.', value: 'EXT.' },
          ]}
        />
        <input
          value={name}
          onChange={(e) => setName(e.target.value.toLocaleUpperCase())}
        />
      </form>
    </Modal>
  );
};

export default RenameSceneModal;
