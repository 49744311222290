import produce from 'immer';
import { Action } from 'redux/common/types';
import { scenesActionTypeFactory } from './actions';

const initialState = {
  users: {},
};

export const scenesReducer = produce((state = initialState, action: Action) => {
  switch (action.type) {
    case scenesActionTypeFactory('SET', 'USERS'):
      state.users = action.payload ?? {};
      break;

    default:
      break;
  }
  return state;
});
