// import Axios from 'axios';
import firebase from 'firebase-main';
import _ from 'lodash';
export const libraryDbRequestTypes = {
  GET: 'get',
  POST: 'post',
};

// const devApiUrl = 'https://us-central1-prefilm-78ac1.cloudfunctions.net';
// const prodApiUrl = 'https://us-central1-prefilm-production.cloudfunctions.net';

export const libraryDbMiddleware = (store) => (next) => (action) => {
  next(action);
  if (action.meta?.libraryDb) {
    // const {
    //   url = '',
    //   requestType = null,
    //   params = {},
    //   onSuccessDispatches = [],
    //   onFailDispatches = [],
    //   onEmptyDispatches = [],
    //   dataPreparers = [],
    // } = action.meta.libraryDb;

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${
    //       store.getState().firebase.auth.stsTokenManager.accessToken
    //     }`,
    //   },
    // };

    // const api = `${process.env.REACT_APP_REALTIME_URL}${url ? `/${url}` : ''}`;

    // let request: any = null;
    // switch (requestType) {
    //   case libraryDbRequestTypes.GET:
    //     // request = Axios.get(api, params, config);
    //     // request = firebase.functions().httpsCallable('')
    //     break;
    //   case libraryDbRequestTypes.POST:
    //     // request = Axios.post(api, params, config);
    //     break;
    //   default:
    //     return;
    // }

    // request
    //   .then((res) => {
    //     let { data } = res;
    //     dataPreparers.forEach((fn) => (data = fn(data)));
    //     // console.log(data);
    //     onSuccessDispatches.forEach((action) => store.dispatch(action(data)));

    //     if (_.isEmpty(data))
    //       onEmptyDispatches.forEach((action) => store.dispatch(action(data)));
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     onFailDispatches.forEach((action) => store.dispatch(action(err)));
    //   });
  }
};
