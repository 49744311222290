import './LicenseModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, InputNumber, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLicensePrices } from 'redux/slices/stripe/actions';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import {
  licensePricePending,
  licensesPricesSelector,
} from 'redux/slices/stripe/selectors';
import PaymentSummary from './paymentSummary/PaymentSummary';

const LicenseModal = ({
  title = 'Edit Subscription',
  onCancel = () => {},
  onSubmit = (numOfLicenses) => {},
  purchaseLoader = false,
  type,
}) => {
  const dispatch = useDispatch();
  const teamId = useSelector(selectedTeamIdSelector);
  const [licenses, setLicenses] = useState(2);

  const licensesPrice = useSelector(licensesPricesSelector);
  const fetchLicensePricePending = useSelector(licensePricePending);

  useEffect(() => {
    dispatch(fetchLicensePrices(teamId, licenses, type));
  }, [dispatch, licenses]);

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="buy-team-license-modal"
      title={title}
      visible
      onCancel={onCancel}
      maskClosable={false}
      onOk={() => onSubmit(licenses)}
      //   okText={editingNumOfLicensesPending === true ? 'Updating...' : 'Update'}
      okButtonProps={
        {
          // disabled:
          //   newNumOfLicenses < minLicensesLength ||
          //   editingNumOfLicensesPending === true,
        }
      }
      footer={[
        <Button key="back" onClick={onCancel} disabled={purchaseLoader}>
          Cancel
        </Button>,
        <Popconfirm
          key="pop"
          title="Are you sure you want to update your subscriptions?"
          onConfirm={() => onSubmit(licenses)}
          okText="Yes"
          cancelText="No"
        >
          <Button key="submit" type="primary" loading={purchaseLoader}>
            Update
          </Button>
        </Popconfirm>,
      ]}
    >
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <span className="mr-3"> Number of licenses: </span>
          <InputNumber
            type="number"
            min={1}
            value={licenses}
            onChange={(e) => setLicenses(e as number)}
            step={1}
          />
        </div>
        {/* <span style={{ color: 'red' }}>
          You can not get less than {minLicensesLength} licenses. If you need
          so, please change one of the ADMINS or EDITORS to REVIEWER
        </span> */}
        {/* <span style={{ color: 'red' }}>
          {renewalDate ? `Renewal Date: ${renewalDate}` : ''}
        </span> */}
        <PaymentSummary
          loader={fetchLicensePricePending}
          cost={licensesPrice.cost}
          credit={0}
        />
      </div>
    </Modal>
  );
};

export default LicenseModal;
