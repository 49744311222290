import { StripeSlice } from './types';

interface StripeSelector {
  stripe: StripeSlice;
}

export const paymentActionSelector = (store: StripeSelector) =>
  store.stripe.paymentAction;

export const paymentActionPendingSelector = (store: StripeSelector) =>
  !store.stripe.paymentAction;

export const purchaseTeamEditorLicensePendingSelector = (
  store: StripeSelector
) => store.stripe.loaders.purchaseTeamEditorLicensePending;

export const purchaseTeamReviewerLicensePendingSelector = (
  store: StripeSelector
) => store.stripe.loaders.purchaseTeamReviewerLicensePending;

export const licensePricePending = (store: StripeSelector) =>
  store.stripe.loaders.licensePricePending;

export const paymentDataSelector = (store: StripeSelector) =>
  store.stripe.paymentInfo;

export const addTeamMemberPendingSelector = (store: StripeSelector) =>
  store.stripe.loaders.addTeamMemberPending;

export const teamDrivePriceSelector = (store: StripeSelector) =>
  store.stripe.teamDrivePrice;

export const personalDrivePriceSelector = (store: StripeSelector) =>
  store.stripe.personalDrivePrice;

export const licensesPricesSelector = (store: StripeSelector) =>
  store.stripe.licensePrices;
