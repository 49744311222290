import './LibrarySidebar.scss';
import React, { forwardRef, useEffect, useState } from 'react';
import Loader from 'common/components/loader/Loader';
import { subCategory } from '../types';
import { FaCaretDown, FaCaretLeft } from 'react-icons/fa';

type propTypes = {
  mainSubcategories?: subCategory[];
  subCategories: subCategory[];
  subCategoriesPending: boolean;
  selectedSubCategory: string;
  isFocused: boolean;
  onSubMenuClick?: (subMenu: string) => void;
  onSubMenuDownload?: (subMenu: string) => void;
};

const LibrarySidebar = (
  {
    mainSubcategories = [],
    subCategories = [],
    subCategoriesPending = false,
    selectedSubCategory = '',
    isFocused = false,
    onSubMenuClick = () => {},
    onSubMenuDownload = () => {},
  }: propTypes,
  containerRef
) => {
  const [refsArray, setRefsArray] = useState<any[]>([]);
  const [focusItem, setFocusItem] = useState(0);
  const [expanded, setExpanded] = useState({});
  const selectedSubCategoryIndex = subCategories.findIndex(
    ({ text }) => text === selectedSubCategory
  );

  useEffect(() => {
    setRefsArray(subCategories.map(() => React.createRef()));
  }, [subCategories]);

  useEffect(() => {
    if (selectedSubCategory.includes('_')) {
      const [cat] = selectedSubCategory.split('_');
      setExpanded({ ...expanded, [cat]: true });
    }
  }, [selectedSubCategory]);

  const renderSubMenus = (subCategories: subCategory[]) =>
    subCategories.map(
      ({ id, text, downloadable, isDownloaded, subList, badge }, index) => {
        const selectable = !subList;
        const collapsible = !!subList;
        const isSelected = selectedSubCategory === id;
        const isFocused = focusItem === index;
        const isExpanded = expanded[id];

        return (
          <React.Fragment key={index}>
            <li
              tabIndex={0}
              ref={refsArray[index]}
              className={`${isSelected && 'selected'}`}
              onClick={() => {
                // if (selectable) onSubMenuClick(text);
                onSubMenuClick(id);
                if (collapsible && !isExpanded)
                  setExpanded({ ...expanded, [id]: true });
                else if (collapsible && isExpanded)
                  setExpanded({ ...expanded, [id]: false });
              }}
              onKeyPress={(e) => {
                if (selectable && e.key === 'Enter' && isFocused) {
                  onSubMenuClick(id);
                }
              }}
            >
              <span className="category">{text}</span>
              {badge && <span className="badge">{badge}</span>}

              {/* {downloadable && !isDownloaded && selectable && (
              <img
                className="cloud-icon"
                src={`/backlot-library/library/cloud-download.svg`}
                alt={`cloud`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSubMenuDownload(text);
                }}
              />
            )} */}

              {(collapsible && !isExpanded && (
                <FaCaretLeft className="caret" size={14} />
              )) ||
                (collapsible && isExpanded && (
                  <FaCaretDown size={14} className="caret" />
                ))}
            </li>
            {collapsible && isExpanded && (
              <ul className="expanded-list">
                {(subList ?? []).map(
                  ({ name: subItem, isDownloaded: subItemDownloaded }) => {
                    const isSelected =
                      selectedSubCategory === `${text}_${subItem}`;
                    return (
                      <li
                        key={subItem}
                        className={`${isSelected && 'selected'} sub-menu`}
                        onClick={() => {
                          onSubMenuClick(`${text}_${subItem}`);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && isFocused) {
                            onSubMenuClick(subItem);
                          }
                        }}
                      >
                        <span className="sub-menu-name">{subItem}</span>

                        {/* {!subItemDownloaded && (
                        <img
                          className="cloud-icon"
                          src={`/backlot-library/library/cloud-download.svg`}
                          alt={`cloud`}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSubMenuDownload(text);
                          }}
                        />
                      )} */}
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </React.Fragment>
        );
      }
    );

  // Focuses on the current focusItem
  useEffect(() => {
    if (isFocused && (refsArray[focusItem] as any)?.current) {
      (refsArray[focusItem] as any).current.focus();
    }
  }, [refsArray, focusItem, isFocused]);

  useEffect(() => {
    if (isFocused) {
      setFocusItem(selectedSubCategoryIndex);
    }
  }, [isFocused]);

  const scrollToItem = (itemRef) => {
    if (containerRef?.current && itemRef?.current) {
      const container = containerRef.current;
      const item = itemRef.current;
      container.scrollTo(0, item.offsetTop - container.clientHeight / 2);
    }
  };

  // Allows navigation using up/down arrow keys
  const onKeyDown = (e) => {
    if (!isFocused) return;
    if (e.key === 'ArrowUp') {
      let nextSelectedSubcategory = focusItem - 1;
      if (nextSelectedSubcategory < 0)
        nextSelectedSubcategory = subCategories.length - 1;

      setFocusItem(nextSelectedSubcategory);
      e.preventDefault();
      scrollToItem(refsArray[nextSelectedSubcategory]);
    } else if (e.key === 'ArrowDown') {
      let nextSelectedSubcategory = focusItem + 1;
      if (nextSelectedSubcategory >= subCategories.length)
        nextSelectedSubcategory = 0;

      setFocusItem(nextSelectedSubcategory);
      e.preventDefault();
      scrollToItem(refsArray[nextSelectedSubcategory]);
    }
  };

  return (
    <div
      id="lib-sidebar"
      className={`${isFocused && 'focused'}`}
      tabIndex={0}
      ref={containerRef}
      onKeyDown={onKeyDown}
    >
      <Loader
        show={subCategoriesPending}
        centerSpinner
        backgroundStyle={{ height: '100%', width: '100%' }}
      >
        <ul>
          {renderSubMenus(mainSubcategories)}
          <li className="separator"></li>
        </ul>
        <ul>{renderSubMenus(subCategories)}</ul>
      </Loader>
    </div>
  );
};

export default forwardRef(LibrarySidebar);
