import { toasterTypes } from './../middlewares/toasterMiddleware/types';
import { Action } from 'redux/common/types';

export const action = (actionType: string, payload?: any): Action => {
  return { type: actionType, payload };
};

export const toasterAction = (
  toasterType: toasterTypes,
  message: string,
  description?: string
): Action => {
  return {
    type: 'TOASTER',
    meta: {
      toaster: { type: toasterType, message, description },
    },
  };
};
