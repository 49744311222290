import React from 'react';
import { Modal, List, Avatar } from 'antd';

const MembersModal = ({ visible, title, onClose, members }) => {
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      visible={visible}
      title={title}
      onCancel={onClose}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={members}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.thumbnail} />}
              title={<a>{item.name}</a>}
              description={item.email}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default MembersModal;
