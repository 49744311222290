import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  projectsSelector,
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  selectedSceneNameSelector,
} from 'redux/slices/app/selectors';
import firebase from 'firebase-main';
import { toasterAction } from 'redux/common/actions';
import { dispatchEvents } from 'redux/middlewares/amplitude';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

const DuplicateSceneModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);
  const sceneName = useSelector(selectedSceneNameSelector) ?? '';
  const curProjectId = useSelector(selectedProjectIdSelector);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [sceneType, setSceneType] = useState('INT.');
  const [name, setName] = useState(sceneName);
  const projects = useSelector(projectsSelector);

  // const duplicateScenePending = useSelector(duplicateScenePendingSelector);
  const [duplicateScenePending, setDuplicateScenePending] = useState(false);

  useEffect(() => {
    setSelectedProject(curProjectId);
  }, [curProjectId]);

  useEffect(() => {
    const [sType, sName] = sceneName.split('.');
    setName(sName);
    setSceneType(sType + '.');
  }, [sceneName]);

  const submit = async () => {
    if (selectedProject === -1) return;
    // dispatch(duplicateScene(sceneId, sceneType + name));
    setDuplicateScenePending(true);
    try {
      await firebase.functions().httpsCallable('duplicateScene')({
        sceneId,
        name: sceneType + name,
        projectId: selectedProject,
      });
      setDuplicateScenePending(false);
      dispatchEvents([
        {
          event_type: 'duplicate_scene',
          user_id: userId,
          event_properties: { sameProject: curProjectId === selectedProject },
        },
      ]);
      onClose();
    } catch (err: any) {
      console.error(err);
      setDuplicateScenePending(false);
      dispatch(toasterAction('error', 'Failed to duplicate scene', err));
    }
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="duplicate-scene-modal"
      title={'Duplicate Scene'}
      visible={true}
      centered
      onCancel={onClose}
      // maskClosable={false}
      onOk={submit}
      confirmLoading={duplicateScenePending}
      //   footer={null}
    >
      <form>
        <label style={{ display: 'block', marginTop: '1rem' }}>
          Scene Name
        </label>
        <Select
          value={sceneType}
          disabled={duplicateScenePending}
          onChange={(v) => {
            setSceneType(v);
          }}
          style={{ marginRight: '1rem' }}
          options={[
            { label: 'INT.', value: 'INT.' },
            { label: 'EXT.', value: 'EXT.' },
          ]}
        />
        <input
          value={name}
          disabled={duplicateScenePending}
          onChange={(e) => setName(e.target.value.toLocaleUpperCase())}
        />

        <label style={{ display: 'block', marginTop: '1rem' }}>Project</label>
        <Select
          style={{ width: '200px' }}
          onChange={(id) => setSelectedProject(id)}
          disabled={duplicateScenePending}
          value={selectedProject}
        >
          {projects?.map(({ name, id }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </form>
    </Modal>
  );
};

export default DuplicateSceneModal;
