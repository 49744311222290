import React from 'react';
import { openCommentsModal } from 'redux/slices/comments/actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';

const ClipModal = ({ clipUrl, comments }) => {
  const dispatch = useDispatch();
  const sceneId = useSelector(selectedSceneIdSelector);
  const userId = useSelector(userIdSelector);
  const userRole = useSelector(userRoleSelector);
  const userName = useSelector(userNameSelector);

  // let showCommentsModal = useSelector(
  //   (state) => state.modals.toggleClipCommentsModal === clipUrl
  // );

  const onCommentSubmit = (comment, tags) => {
    const date = moment().toISOString();
    // dispatch(
    //     addClipComment(
    //         comment,
    //         clipUrl,
    //         sceneId,
    //         userId,
    //         userName,
    //         userRole,
    //         date,
    //         tags
    //     )
    // );
  };

  return (
    <React.Fragment>
      {/* {showCommentsModal ? (
        <CommentsModal
          hide={() => dispatch(openCommentsModal(''))}
          comments={comments}
          path={clipUrl}
          onCommentSubmit={onCommentSubmit}
        />
      ) : null} */}
    </React.Fragment>
  );
};

export default ClipModal;
