import './BacklotCharacterWizard.scss';
import React, { useEffect, useState } from 'react';
import { BaseCharacter } from './base/BaseCharacter';
import { useDispatch, useSelector } from 'react-redux';
import { libraryVersionSelector } from 'redux/slices/items/selectors';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import {
  fetchBaseCharacters,
  fetchOptions,
  fetchOptionTypes,
} from 'redux/slices/character/actions';
import {
  characterIdSelector,
  optionTypesSelector,
} from 'redux/slices/character/selectors';
import {
  ColorInput,
  SliderInput,
  WizardBuilder,
  GridSelect,
} from './WizardBuilder';
import { FaSave } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { SaveCharacterModal } from './SaveCharacterModal';

export const BacklotCharacterWizard = () => {
  const dispatch = useDispatch();

  const characterId = useSelector(characterIdSelector);

  const [showCharacterModal, setShowCharacterModal] = useState(false);
  const [tab, setTab] = useState(0);

  const [tabs] = useState([
    // <BaseCharacter />,
    <WizardBuilder
      sections={[
        {
          inputs: [
            <GridSelect name="baseCharacters" label="Base Characters" />,
          ],
        },
      ]}
    />,
    <WizardBuilder
      className="body-wizard"
      sections={[
        {
          inputs: [
            <ColorInput label="Skin Color" name="skinColor" />,
            <SliderInput label="Height" name="height" defaultValue={0} />,
            <SliderInput label="Weight" name="weight" defaultValue={0.5} />,
            <SliderInput label="Muscle" name="muscle" defaultValue={0} />,
            <SliderInput
              label="Body Curve"
              name="bodyCurve"
              defaultValue={0}
            />,
          ],
        },
        {
          title: 'Advanced',
          inputs: [
            <SliderInput name="neck" label="Neck" defaultValue={0} />,
            <SliderInput name="waist" label="Waist" defaultValue={0} />,
            <SliderInput name="breasts" label="Breasts" defaultValue={0.5} />,
            <SliderInput name="hips" label="Hips" defaultValue={0} />,
            <SliderInput name="ribcage" label="Ribcage" defaultValue={0} />,
            <SliderInput name="thighs" label="Thighs" defaultValue={0} />,
            <SliderInput name="pregnant" label="Pregnant" defaultValue={0} />,
          ],
        },
      ]}
    />,
    <WizardBuilder
      className="face-wizard"
      sections={[
        {
          inputs: [
            <ColorInput label="Eye Color" name="eyeColor" />,
            <GridSelect label="Hair Style" name="Hair" />,
            <ColorInput label="Hair Color" name="hairColor" />,
            <GridSelect label="Facial Hair" name="FacialHair" />,
            <ColorInput label="Facial Hair Color" name="facialHairColor" />,
            <SliderInput label="Age" name="age" defaultValue={0.5} />,
            <SliderInput
              label="Face Roundness"
              name="faceRoundness"
              defaultValue={0}
            />,
            <SliderInput label="Jaw Shape" name="jawShape" defaultValue={0} />,
          ],
        },
        {
          title: 'Advanced',
          inputs: [
            <SliderInput
              name="jawPosition"
              label="Jaw Position"
              defaultValue={0}
            />,
            <SliderInput name="jawSize" label="Jaw Size" defaultValue={0} />,
            <SliderInput name="cheeks" label="Cheeks" defaultValue={0.5} />,
            <SliderInput name="ears" label="Ears" defaultValue={0} />,
            <SliderInput name="eyes" label="Eyes" defaultValue={0} />,
            <SliderInput name="noseSize" label="Nose Size" defaultValue={0} />,
            <SliderInput
              name="nosePosition"
              label="Nose Position"
              defaultValue={0}
            />,
            <SliderInput
              name="mouthSize"
              label="Mouth Size"
              defaultValue={0}
            />,
            <SliderInput
              name="mouthPosition"
              label="Mouth Position"
              defaultValue={0}
            />,
          ],
        },
      ]}
    />,
    <WizardBuilder
      className="clothing-wizard"
      sections={[
        {
          inputs: [
            <GridSelect label="Shirt" name="Shirt" multiSelect colored />,
            <GridSelect label="Jacket" name="Jacket" multiSelect colored />,
            <GridSelect label="Pants" name="Pants" multiSelect colored />,
            <GridSelect
              label="Underwear"
              name="Underwear"
              multiSelect
              colored
            />,
            <GridSelect label="Shoes" name="Shoes" colored />,
            <GridSelect
              label="Accessories"
              name="Accessory"
              multiSelect
              colored
            />,
          ],
        },
      ]}
    />,
    // <BodyWizard />,
    // <FaceWizard />,
    // <ClothingWizard />,
  ]);

  const libVersion = useSelector(libraryVersionSelector);
  const optionTypes = useSelector(optionTypesSelector);
  const teamId = useSelector(selectedTeamIdSelector);

  useEffect(() => {
    if (optionTypes)
      Object.keys(optionTypes).forEach((optionType) =>
        dispatch(
          fetchOptions(
            libVersion,
            // TODO Remove
            characterId ?? '85c37bf7-fde4-4376-99a9-f2ac98371eb6',
            optionType,
            teamId
          )
        )
      );
  }, [optionTypes, libVersion, teamId, characterId]);

  useEffect(() => {
    dispatch(
      fetchOptionTypes(
        libVersion,
        characterId ?? '85c37bf7-fde4-4376-99a9-f2ac98371eb6'
      )
    );

    dispatch(fetchBaseCharacters(libVersion));
  }, [libVersion, characterId]);

  return (
    <div className="backlot-wizard">
      {showCharacterModal && (
        <SaveCharacterModal close={() => setShowCharacterModal(false)} />
      )}
      <div className="top-bar">
        <div
          className="save-character"
          onClick={() => setShowCharacterModal(true)}
        >
          <FaSave size={12} />
        </div>
        <div
          className="close-wizard"
          onClick={() => {
            (window as any).onCloseWizard();
          }}
        >
          <IoMdCloseCircle size={14} />
        </div>
      </div>
      <div className="tabs">
        <div
          className={`tab${tab === 0 ? ' selected' : ''}`}
          onClick={() => setTab(0)}
        >
          Base
        </div>
        <div
          className={`tab${tab === 1 ? ' selected' : ''}`}
          onClick={() => setTab(1)}
        >
          Body
        </div>
        <div
          className={`tab${tab === 2 ? ' selected' : ''}`}
          onClick={() => setTab(2)}
        >
          Face
        </div>
        <div
          className={`tab${tab === 3 ? ' selected' : ''}`}
          onClick={() => setTab(3)}
        >
          Clothing
        </div>
      </div>

      <div>
        {/* <PresetFaces /> */}
        {tabs[tab]}
      </div>
    </div>
  );
};
