import React from 'react';
import { FaThumbsUp } from 'react-icons/fa';
import './Like.css';

const Like = (props) => {
  return (
    <div className="like-container">
      <div className="like-background">
        <FaThumbsUp />
        {/* <i className="fa fa-thumbs-up fa-1x like-hand" /> */}
      </div>
      <span style={{ color: 'white', margin: 'auto' }}>
        {props.likesLength}
      </span>
    </div>
  );
};

export default Like;
