import React, { useState, FunctionComponent } from 'react';
import { Select, Slider, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  optionsSelector,
  selectedOptionsSelector,
} from 'redux/slices/character/selectors';
import { setSelectedOption } from 'redux/slices/character/actions';
import { ColorPicker } from 'backlot-character-wizard/color-picker/ColorPicker';
import { FaCaretDown, FaCaretRight, FaUndo } from 'react-icons/fa';
import { ChromePicker } from 'react-color';

type optionInput = {
  displayName: string;
  name: string;
  defaultValue?: any;
};

type section = {
  title?: string;
  inputs?: JSX.Element[];
};

type propTypes = { className?: string; sections: section[] };

export const WizardBuilder = ({ className = '', sections = [] }: propTypes) => {
  return (
    <div className={`wizard ${className}`}>
      {sections.map((section, index) => (
        <React.Fragment key={section.title ?? '' + index}>
          {section.title && <h3>{section.title}</h3>}
          {section.inputs}
        </React.Fragment>
      ))}
    </div>
  );
};
export const ColorInput = ({ label = '', name }) => {
  const dispatch = useDispatch();
  const selectedOptions = useSelector(selectedOptionsSelector);
  return (
    <div key={name} className="eye-color color-input">
      <span>{label}</span>
      <ColorPicker
        color={selectedOptions[name]}
        onChange={(v) => {
          dispatch(setSelectedOption(name, v));
          (window as any).onCharacterOptionChange(name, v);
        }}
      />
    </div>
  );
};

export const SelectInput = ({ label = '', name }) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(optionsSelector);
  const selectedOptions = useSelector(selectedOptionsSelector);
  return (
    <div className="option-input" key={name}>
      <div className="name">{label}</div>
      <Select
        className="option-selector"
        dropdownClassName="character-option-selector-drop-menu"
        value={selectedOptions[name]}
        onChange={(v) => {
          dispatch(setSelectedOption(name, v));
          (window as any).onCharacterOptionChange(name, v);
        }}
      >
        {Object.values(selectOptions[name] ?? {}).map((option) => (
          <Select.Option key={option.itemID} value={option.itemID}>
            <img src={option.thumbnail} alt="" />
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const SliderInput = ({ label = '', name, defaultValue = 0.5 }) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(optionsSelector);
  const selectedOptions = useSelector(selectedOptionsSelector);
  return (
    <div className="option-input" key={name}>
      <div className="name">{label}</div>
      <Slider
        className="slider"
        min={0}
        max={1}
        step={0.01}
        value={selectedOptions[name]}
        onChange={(v) => {
          dispatch(setSelectedOption(name, v));
          (window as any).onCharacterOptionChange(name, v);
        }}
      />
      <FaUndo
        className="undo"
        onClick={() => {
          dispatch(setSelectedOption(name, defaultValue));
          (window as any).onCharacterOptionChange(name, defaultValue);
        }}
      />
    </div>
  );
};

export const GridSelect = ({
  label = '',
  name,
  multiSelect = false,
  colored = false,
}) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(optionsSelector);
  const selectedOptions = useSelector(selectedOptionsSelector);
  const empty = Object.values(selectOptions[name] ?? {}).length === 0;
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className="option-input-grid" key={name}>
      <div
        className="name"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {!empty &&
          (collapsed ? <FaCaretRight size={12} /> : <FaCaretDown size={12} />)}
        {label}
      </div>
      {empty && <div className="no-data">No data available at the moment</div>}
      <div className={`grid${collapsed ? ' collapsed' : ''}`}>
        {Object.values(selectOptions[name] ?? {}).map((option) => {
          const selected = multiSelect
            ? (selectedOptions[name] ?? {})[option.itemID]
            : selectedOptions[name] === option.itemID;

          const color = (selectedOptions[name + '_color'] ?? {})[option.itemID];

          return (
            <React.Fragment key={option.itemID}>
              <div
                className={`grid-item${selected ? ' selected' : ''}`}
                onClick={() => {
                  if (multiSelect) {
                    dispatch(
                      setSelectedOption(name, {
                        ...selectedOptions[name],
                        [option.itemID]: !selected,
                      })
                    );
                    (window as any).onCharacterOptionChange(
                      name,
                      option.itemID
                    );
                  } else {
                    dispatch(
                      setSelectedOption(name, selected ? null : option.itemID)
                    );
                    (window as any).onCharacterOptionChange(
                      name,
                      option.itemID
                    );
                  }
                }}
              >
                <div
                  className="grid-img"
                  style={{ background: `url("${option.thumbnail}")` }}
                />
                {colored && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Tooltip
                      overlay={
                        <ChromePicker
                          color={color ?? { r: 255, g: 255, b: 255, a: 1 }}
                          onChange={(color) => {
                            dispatch(
                              setSelectedOption(name + '_color', {
                                ...selectedOptions[name + '_color'],
                                [option.itemID]: color.rgb,
                              })
                            );
                            (window as any).onCharacterOptionChange(
                              name + '_color',
                              { id: option.itemID, color: color.rgb }
                            );
                          }}
                        />
                      }
                      trigger="click"
                    >
                      <div
                        className="color-picker"
                        style={{
                          backgroundColor: `rgba(${color?.r ?? 255},${
                            color?.g ?? 255
                          },${color?.b ?? 255},${color?.a ?? 1})`,
                        }}
                      ></div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
