import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button, Form, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase-main';
import { toasterAction } from 'redux/common/actions';
import {
  inPersonalDriveSelector,
  selectedTeamIdSelector,
  selectedTeamNameSelector,
} from 'redux/slices/app/selectors';
import {
  teamImageSelector,
  userRoleInTeamSelector,
} from 'redux/slices/team/selectors';
import { ImageCrop } from 'common/components/ImageCrop/ImageCrop';
import Loader from 'common/components/loader/Loader';
import { useHistory } from 'react-router-dom';

export const DriveSettings = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(userRoleInTeamSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const teamName = useSelector(selectedTeamNameSelector);
  const teamImage = useSelector(teamImageSelector);
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const [pending, setPending] = useState(false);

  const [name, setName] = useState(teamName ?? '');

  useEffect(() => {
    setName(teamName ?? '');
  }, [teamName]);

  const [imgBlob, setImgBlob] = useState<Blob>();
  const [imgUrl, setImgUrl] = useState<string>('');

  const onSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    let imgUrl = null;
    if (imgBlob) {
      try {
        imgUrl = await (
          await firebase
            .storage()
            .ref(`teams/${teamId}/${teamId}.jpg`)
            .put(imgBlob)
        ).ref.getDownloadURL();

        await firebase
          .database()
          .ref(`teams/${teamId}/public`)
          .update({ thumbnail: imgUrl });
      } catch (err: any) {
        console.error(err);
        dispatch(toasterAction('error', 'Failed to upload image', err));
        setPending(false);
        return;
      }
    }

    try {
      await firebase.database().ref(`teams/${teamId}`).update({ name });
    } catch (err: any) {
      console.error(err);
      dispatch(toasterAction('error', 'Failed to update drive name', err));
      setPending(false);
      return;
    }

    setPending(false);
  };

  const onReset = () => {
    setImgBlob(undefined);
    setImgUrl('');
    setName(teamName ?? '');
  };

  if (!inPersonalDrive && userRole !== 'Admin' && userRole !== 'Editor')
    return null;
  return (
    <Tab.Pane active = {params?.tab === "driveSettings"} id="driveSettings">
      <h6>DRIVE INFORMATION</h6>
      <hr />
      <Form onSubmit={onSubmit} onReset={onReset}>
        <Form.Group>
          <Form.Label>Drive Image</Form.Label>
          <div>
            <div className="uploader">
              <ImageCrop
                onImageLoad={(url, blob) => {
                  setImgBlob(blob);
                  setImgUrl(url);
                }}
                backgroundImgSrc={imgUrl.length > 0 ? imgUrl : teamImage}
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Drive Name</Form.Label>
          <Form.Control
            required
            type="text"
            id="name"
            placeholder="Enter drive name"
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Group>

        <Button type="submit" disabled={pending}>
          <Loader
            show={pending}
            spinnerSize={16}
            spinnerColor="white"
            backgroundStyle={{ display: 'inline', marginRight: '1rem' }}
          ></Loader>
          Update Drive
        </Button>
        <Button type="reset" className="btn btn-light">
          Reset Changes
        </Button>
      </Form>
    </Tab.Pane>
  );
};
