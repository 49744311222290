import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';

export const logoutActionTypes = {
  LOGOUT: 'LOGOUT',
  LOGOUT_FAIL: 'LOGOUT_FAIL',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export const logout = () => {
  return {
    type: logoutActionTypes.LOGOUT,
  };
};

export const logoutSuccess = () => {
  return {
    type: logoutActionTypes.LOGOUT_SUCCESS,
  };
};

export const logoutFail = (errorMessage) => {
  return {
    type: logoutActionTypes.LOGOUT_FAIL,
    meta: {
      ...toaster(toasterType.error, 'Failed to sign out', errorMessage),
    },
  };
};
