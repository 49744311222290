import { useEffect, useRef } from 'react';

/**
 *  This hook fires the onTopReached callback once the scroll bar
 *  attached to the ref has reached the top.
 *
 * @param {React.MutableRefObject<any>} ref
 * @param {Function} onTopReached
 */
const useScrollReachTop = (
  ref: React.MutableRefObject<any>,
  onTopReached = () => {}
) => {
  const topReached = (component) => {
    const { scrollTop, scrollHeight, clientHeight } = component;
    return scrollTop <= 10;
  };
  useEffect(() => {
    if (!ref) return;
    const component = ref.current;

    const listener = () => {
      if (topReached(component)) {
        onTopReached();
      }
    };

    if (component) {
      component.addEventListener('scroll', listener);
    }
    return () => {
      if (component) {
        component.removeEventListener('scroll', listener);
      }
    };
  }, [ref, onTopReached]);

  useEffect(() => {
    if (!ref) return;
    if (topReached(ref.current)) {
      onTopReached();
    }
  });
};

export default useScrollReachTop;
