import { fireRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRequestTypes';
import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';
import { addShotDisplay, idPreparer } from './preparers';

export const clipsActionTypes = {
  SUBSCRIBE_CLIPS: 'SUBSCRIBE_CLIPS',
  SUBSCRIBE_CLIPS_SUCCESS: 'SUBSCRIBE_CLIPS_SUCCESS',
  SUBSCRIBE_CLIPS_EMPTY: 'SUBSCRIBE_CLIPS_EMPTY',
  SUBSCRIBE_CLIPS_FAIL: 'SUBSCRIBE_CLIPS_FAIL',

  UNSUBSCRIBE_CLIPS: 'UNSUBSCRIBE_CLIPS',

  FETCH_CLIPS: 'FETCH_CLIPS',
  FETCH_CLIPS_EMPTY: 'FETCH_CLIPS_EMPTY',
  FETCH_CLIPS_FAIL: 'FETCH_CLIPS_FAIL',
  FETCH_CLIPS_SUCCESS: 'FETCH_CLIPS_SUCCESS',
  SET_CLIPS: 'SET_CLIPS',

  UPDATE_CLIP_META: 'UPDATE_CLIP_META',
  UPDATE_CLIP_META_SUCCESS: 'UPDATE_CLIP_META_SUCCESS',
  UPDATE_CLIP_META_FAIL: 'UPDATE_CLIP_META_FAIL',
};

export const subscribeToClips = (sceneId) => {
  return {
    type: clipsActionTypes.SUBSCRIBE_CLIPS,
    meta: {
      firebaseRealtimeApi: {
        url: `shots/${sceneId}`,
        req: fireRequestTypes.SUBSCRIBE,
        id: 'clips',
        // dataPrepareFunctions: [idPreparer()],
        onSuccessDispatches: [fetchClipsSuccess, setClips],
        onEmptyResponseDispatches: [fetchClipsEmpty, setClips],
        onFailDispatches: [fetchClipsFail],
        dataPrepareFunctions: [
          addShotDisplay()
        ]
      },
    },
  };
};

export const unSubscribeToClips = () => {
  return {
    type: clipsActionTypes.UNSUBSCRIBE_CLIPS,
    meta: {
      req: fireRequestTypes.UNSUBSCRIBE,
      id: 'clips',
    },
  };
};

export const fetchClips = (sceneId) => {
  return {
    type: clipsActionTypes.FETCH_CLIPS,
    meta: {
      firebaseRealtimeApi: {
        url: `shots/${sceneId}/videos`,
        req: fireRequestTypes.GET,
        dataPrepareFunctions: [idPreparer()],
        onSuccessDispatches: [fetchClipsSuccess, setClips],
        onEmptyResponseDispatches: [fetchClipsEmpty, setClips],
        onFailDispatches: [fetchClipsFail],
        dataPrepareFunctions: [
          addShotDisplay()
        ]
      },
    },
  };
};

export const fetchClipsSuccess = () => {
  return {
    type: clipsActionTypes.FETCH_CLIPS_SUCCESS,
  };
};

export const fetchClipsEmpty = () => {
  return {
    type: clipsActionTypes.FETCH_CLIPS_EMPTY,
  };
};

export const fetchClipsFail = (errorMessage) => {
  let notif = toaster(toasterType.error, 'Failed to fetch clips', errorMessage);
  if (typeof errorMessage === 'string' && errorMessage.includes('permission')) {
    notif = toaster(toasterType.info, 'No clips available yet');
  }
  return {
    type: clipsActionTypes.FETCH_CLIPS_FAIL,
    meta: {
      ...notif,
    },
  };
};

export const setClips = (clips) => {
  return {
    type: clipsActionTypes.SET_CLIPS,
    payload: clips,
  };
};

export const updateClipMeta = (clipId, sceneId, newData) => {
  return {
    type: clipsActionTypes.UPDATE_CLIP_META,
    payload: newData,
    meta: {
      firebaseRealtimeApi: {
        url: `shots/${sceneId}/videos/${clipId}/metadata`,
        req: fireRequestTypes.UPDATE,
        onSuccessDispatches: [updateClipMetaSuccess],
        onFailDispatches: [updateClipMetaFail],
      },
    },
  };
};

export const updateClipMetaSuccess = () => {
  return {
    type: clipsActionTypes.UPDATE_CLIP_META_SUCCESS,
    meta: {
      ...toaster(toasterType.success, 'Successful update.'),
    },
  };
};

export const updateClipMetaFail = (errorMessage) => {
  return {
    type: clipsActionTypes.UPDATE_CLIP_META_FAIL,
    meta: {
      ...toaster(toasterType.error, 'Failed to update ', errorMessage),
    },
  };
};
