import './NotFoundPage.scss';
import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <h1>Error loading view</h1>
    </div>
  );
};

export default NotFoundPage;
