import './CreateProjectModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  inPersonalDriveSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import AddMembersModal from './AddMembersModal';
import { createNewScenePendingSelector } from 'redux/slices/loaders/loadersSelectors';
import Loader from 'common/components/loader/Loader';
import {
  createNewProject,
  createPersonalProject,
} from 'redux/slices/app/actions';
import { useHistory } from 'react-router';

export const CreateProjectModal = ({ onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const teamId = useSelector(selectedTeamIdSelector);

  const createNewScenePending = useSelector(createNewScenePendingSelector);

  const [showCreateSceneModal, setShowCreateSceneModal] = useState(true);

  const [projectName, setProjectName] = useState('');
  const [projectAccess, setProjectAccess] = useState('public');

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const formReady =
    // selectedTemplateIndex !== -1 &&
    projectName.length > 0 && projectAccess.length > 0;

  useEffect(() => {
    if (formSubmitted && !createNewScenePending) {
      // onCancel();
    }
  }, [formSubmitted, createNewScenePending]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        event.stopPropagation();
        console.log('Enter key was pressed. Run your function.');
        onSubmit();
        // callMyFunction();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  const onSubmit = () => {
    // e.preventDefault();

    if (formReady) {
      if (projectAccess !== 'public') {
        setShowAddMembersModal(true);
      } else {
        onFinish();
      }
    }
  };

  const onFinish = (teamMembers?) => {
    if (inPersonalDrive) {
      dispatch(createPersonalProject(projectName, teamId, userId));
      setShowCreateSceneModal(false);
      setShowAddMembersModal(false);
      onCancel();
      setFormSubmitted(true);
      return;
    }
    let newMembers;
    if (teamMembers) {
      newMembers = { [userId]: { id: userId, name: userName } };
      teamMembers.forEach(({ id, name }) => {
        newMembers = { ...newMembers, [id]: { id, name } };
      });
    }

    console.log(newMembers);

    dispatch(
      createNewProject(
        projectName,
        projectAccess === 'public',
        teamId,
        userId,
        newMembers
      )
    );
    setShowCreateSceneModal(false);
    setShowAddMembersModal(false);
    history.push('/menu/project');
    onCancel();
    setFormSubmitted(true);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="create-project"
      title={null}
      // maskClosable={false}
      visible={showCreateSceneModal}
      keyboard={false}
      onCancel={() => {
        if (!createNewScenePending) onCancel();
      }}
      footer={null}
      centered
    >
      {showAddMembersModal && (
        <AddMembersModal
          onCancel={() => {
            setShowAddMembersModal(false);
          }}
          onSubmit={(members) => {
            onFinish(members);
            setShowAddMembersModal(false);
          }}
        />
      )}
      <form
        className="project-name-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="create-project-header">Create a Project</div>
        <div className="scene-info">
          <input
            type="text"
            value={projectName}
            placeholder="New Project"
            onChange={(e) => setProjectName(e.target.value)}
            autoFocus
            required
          />
        </div>

        <div className="create-project-footer">
          {!inPersonalDrive && (
            <>
              <div>Access</div>
              <div>
                <Select
                  value={projectAccess}
                  onChange={(v) => {
                    setProjectAccess(v);
                  }}
                  options={[
                    { label: 'Entire Team', value: 'public' },
                    { label: 'Private', value: 'private' },
                  ]}
                />
              </div>
            </>
          )}
          <div className="cancel">
            <button type="button" onClick={onCancel}>
              Cancel
            </button>
          </div>
          <div className="create">
            <button
              type="submit"
              disabled={!formReady || createNewScenePending}
            >
              <Loader show={createNewScenePending} spinnerSize={12} />
              {createNewScenePending ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
