import { mainActionTypes } from 'redux/common/types';

export type settingsSlice = {
  autoSave: number;
  res: string;
  fps: string;
  resOptions: { name: string; value: number }[];
  resMin: number;
  resValue: number;
};

export interface SettingsSelector {
  settings: settingsSlice;
}

const namespace = 'settings';

type settingsActionsTypes = mainActionTypes;

type settingsResource = 'SETTINGS' | 'SETTING' | 'RES_OPTIONS';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const settingsAction = (
  type: settingsActionsTypes,
  resource?: settingsResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
