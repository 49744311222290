import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { curvesSelector } from 'redux/slices/curves/selectors';
import { Curve } from './Curve';

// const WIDTH = 2200;
// const HEIGHT = 1100;

const curveColor = ['red', 'green', 'blue', 'orange', 'cyan'];

export const Editor = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const curves = useSelector(curvesSelector);

  // const { WIDTH, HEIGHT } = curves.reduce(
  //   (acc, cur) => ({
  //     WIDTH: Math.max(cur.maxX, acc.WIDTH),
  //     HEIGHT: Math.max(cur.maxY + Math.abs(cur.minY), acc.HEIGHT),
  //   }),
  //   { WIDTH: 1000, HEIGHT: 1000 }
  // );

  const calcMousePos = ({ x, y }) => {
    return {
      x,
      y,
      // x: x / zoom + xOffset - 30 / zoom,
      // y: y / zoom + yOffset - 30 / zoom,
    };
  };

  return (
    <svg
      // width={WIDTH}
      // height={HEIGHT}
      onMouseMove={(e) => {
        // console.log(xOffset, '\n', e.clientX / zoom + xOffset, '\n', e.clientX);

        if (isDragging) {
          const mousePos = calcMousePos({ x: e.clientX, y: e.clientY });
          setMouseX(mousePos.x);
          setMouseY(mousePos.y);
        }
      }}
      onMouseUp={(e) => {
        setIsDragging(false);
      }}
    >
      {/* <Curve
        curveIndex={-2}
        curveColor="blue"
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        mouseX={mouseX}
        mouseY={mouseY}
        maxWidth={window.innerWidth}
        maxHeight={window.innerHeight}
        calcMousePos={calcMousePos}
      /> */}

      {curves.map(({ instanceID, name, keyframesData }, index) => {
        return (
          <Curve
            key={instanceID}
            curveIndex={index}
            curveColor={curveColor[index % curveColor.length]}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            mouseX={mouseX}
            mouseY={mouseY}
            // maxWidth={window.innerWidth}
            // maxHeight={window.innerHeight}
            initialKeyFrames={keyframesData}
            calcMousePos={calcMousePos}
          />
        );
      })}
    </svg>
  );
};
