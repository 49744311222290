import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';

const PrivateRoute = ({
  component: Component = null as any,
  extraProps = null as any,
  children = null as any,
  match = { url: '' } as any,
  exact = false,
  ...rest
}) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  const isLogin = !isEmpty(auth);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      exact
      {...rest}
      render={(props) =>
        isLogin ? (
          children || <Component {...props} {...extraProps} />
        ) : (
          <Redirect to={`${match.url}/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
