import { produce } from 'immer';
import { teamAction } from './types';
import { teamActionTypes } from './actions';
import { mapRolesToInt } from './util';

const initState = {
  teamImage: null,
  teamMembers: [],
  userExisted: null, // Boolean for checking user is found in the database to add in the team or invite him.
  userData: null,
  pendingInvites: {},
  numOfLicenses: null,
  numOfActiveUsers: null,
  newNumOfLicenses: null,
  paymentMethodId: null,
  credit: null,
  cardData: null,
  renewalDate: null,
  newSubscriptionCost: null,
  requiresAction: null, // Object holds client_secret and paymentmethodId for payment that requires action
  invoices: null,
  licensesForm: {
    newNumOfLicenses: null,
    newSubscriptionCost: null,
  },
};

export const teamReducer = produce((state = initState, action) => {
  switch (action.type) {
    case teamActionTypes.SET_TEAM_IMAGE:
      return { ...state, teamImage: action.payload };

    case teamActionTypes.FETCH_TEAM_MEMBERS_SUCCESS:
      return { ...state, teamMembers: action.payload };
    case teamActionTypes.FETCH_PENDING_INVITES_SUCCESS:
      return { ...state, pendingInvites: action.payload };

    case teamActionTypes.EDIT_USER_ROLE_IN_TEAM_SUCCESS:
      // const userIndex = state.teamMembers.findIndex((member) => member.id === action.payload.userId)

      state.teamMembers = state.teamMembers.map((member: any) => {
        if (member.id === action.payload.userId) {
          member.roleInTeam = mapRolesToInt(action.payload.roleInTeam);
        }
        return member;
      });
      return state;

    case teamActionTypes.REMOVE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamMembers: state.teamMembers.filter(
          (member: any) => member.id !== action.payload
        ),
      };

    case teamActionTypes.CHECK_USER_EXISTANCE:
      return { ...state, userData: null, userExisted: null };
    case teamActionTypes.USER_EXISTED:
      return {
        ...state,
        userData: action.payload,
        userExisted: true,
      };
    case teamActionTypes.USER_NOT_EXISTED:
      return {
        ...state,
        userExisted: false,
      };

    case teamActionTypes.SET_REQUIRES_ACTION_DATA:
      return { ...state, requiresAction: action.payload };

    case teamActionTypes.SET_PAYMENT_METHOD_ID:
      return { ...state, paymentMethodId: action.payload };

    case teamActionTypes.SET_PAYMENT_INFO_DATA:
      return {
        ...state,
        cardData: action.payload.card,
        credit: action.payload.balance,
        numOfLicenses: action.payload.number_of_licenses,
        numOfActiveUsers: action.payload.number_of_active_users,
        renewalDate: action.payload.end_date,
      };

    case teamActionTypes.GET_NEW_SUBSCRIPTION_COST: {
      if (state.licensesForm.newNumOfLicenses !== action.payload) {
        state.licensesForm.newSubscriptionCost = null;
      }
      state.licensesForm.newNumOfLicenses = action.payload;
      return state;
    }

    case teamActionTypes.GET_NEW_SUBSCRIPTION_COST_SUCCESS: {
      const { licenses = null, cost = NaN } = action.payload;
      if (state.licensesForm.newNumOfLicenses === licenses) {
        state.licensesForm.newSubscriptionCost = cost;
        // return {
        //   ...state,
        //   newSubscriptionCost: action.payload,
        // };
      }
      return state;
    }

    case teamActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS:
      return { ...state, cardData: action.payload };

    case teamActionTypes.EDIT_LICENSES_NUMBER:
      return { ...state, newNumOfLicenses: action.payload };

    case teamActionTypes.EDIT_LICENSES_NUMBER_SUCCESS:
      return { ...state, numOfLicenses: action.payload };

    case teamAction('SET', 'INVOICES'): {
      state.invoices = action.payload ?? [];
      return state;
    }

    case teamAction('GET', 'INVOICES', 'FAIL'): {
      state.invoices = [];
      return state;
    }

    default:
      return state;
  }
});
