import produce from 'immer';
import { itemsActionTypes } from 'redux/slices/items/actions';

export const libraryItemsReducer = produce((state = initState, action) => {
  switch (action.type) {
    case itemsActionTypes.FETCH_ITEMS:
      state.itemsLoadPending++;
      break;
    case itemsActionTypes.SET_ITEMS:
    case itemsActionTypes.ADD_ITEMS:
    case itemsActionTypes.FETCH_ITEMS_FAIL:
      state.itemsLoadPending--;
      break;

    case itemsActionTypes.SEARCH_ITEMS:
      state.searchItemsPending++;
      break;

    // case itemsActionTypes.CLEAR_SEARCH_ITEMS:
    case itemsActionTypes.SET_SEARCH_ITEMS:
    case itemsActionTypes.ADD_SEARCH_ITEMS:
    case itemsActionTypes.SEARCH_ITEMS_FAIL:
      state.searchItemsPending--;
      break;

    case itemsActionTypes.FETCH_LIKED_ITEMS:
      state.favoriteItemsPending = true;
      break;

    case itemsActionTypes.FETCH_LIKED_ITEMS_FAIL:
    case itemsActionTypes.SET_LIKED_ITEMS:
      state.favoriteItemsPending = false;
      break;

    case itemsActionTypes.FETCH_SUB_CATEGORIES:
      state.subcategoriesPending++;
      break;

    case itemsActionTypes.SET_SUB_CATEGORIES:
    case itemsActionTypes.FETCH_SUB_CATEGORIES_FAIL:
      state.subcategoriesPending--;
      break;

    default:
      break;
  }
  return state;
});

const initState = {
  itemsLoadPending: 0,
  searchItemsPending: 0,
  favoriteItemsPending: false,
  subcategoriesPending: 0,
};
