import { Action } from './../../common/types';
import {
  IComment,
  commentActionStatus,
  commentActionType,
  CommentsCollection,
  commentType,
  IReply,
} from './types';

export const commentsNamespace = 'comments';

const commentTypeToUrl = {
  SCENE_COMMENT: 'scene',
  SNAPSHOT_COMMENT: 'snapshots',
  OBJECT_COMMENT: 'objects',
  SHOT_COMMENT: 'shot',
};

export const commentAction = (
  actionType: commentActionType,
  commentType?: commentType,
  commentActionStatus?: commentActionStatus
) => {
  let action = `${commentsNamespace}/`;
  if (actionType) action += actionType;
  if (commentType) action += `_${commentType}`;
  if (commentActionStatus) action += `_${commentActionStatus}`;
  return action;
};

const getCommentsUrl = (
  commentType: commentType,
  sceneId: string,
  containerRefId?: string,
  commentId?: string,
  replyCommentId?: string
) => {
  if (!commentTypeToUrl[commentType]) {
    console.error(commentTypeToUrl[commentType]);
    throw "Couldn't create the comment's url";
  }
  let url = `comments/${sceneId}/${commentTypeToUrl[commentType]}`;
  if (containerRefId) url += `/${containerRefId}`;
  if (commentId) url += `/${commentId}`;
  if (replyCommentId) url += `/replies/${replyCommentId}`;
  return url;
};

// ------------------------------------------------------------------ Subscribe to comments

export const subscribeComments = (
  commentType: commentType,
  sceneId: string,
  containerRefId?: string,
  limit?: number
): Action => {
  return {
    type: commentAction('SUBSCRIBE', commentType),
    meta: {
      firebaseRealtimeApi: {
        id: commentType,
        req: 'SUBSCRIBE',
        url: getCommentsUrl(commentType, sceneId, containerRefId),
        onSuccessDispatches: [
          setComments(commentType),
          subscribeCommentsSuccess(commentType),
        ],
        onEmptyResponseDispatches: [
          setComments(commentType),
          subscribeCommentsSuccess(commentType),
        ],
        onFailDispatches: [subscribeCommentsFail(commentType)],
        limitToLast: limit,
        // dataPrepareFunctions: [markUserComments(userId), processReplies()],
      },
    },
  };
};

export const unsubscribeComments = (commentType: commentType): Action => {
  return {
    type: commentAction('UNSUBSCRIBE', commentType),
    meta: {
      firebaseRealtimeApi: {
        id: commentType,
        req: 'UNSUBSCRIBE',
      },
    },
  };
};

export const setComments = (commentType: commentType) => (
  comments: CommentsCollection
): Action => {
  return {
    type: commentAction('SET', commentType),
    payload: comments,
  };
};

export const subscribeCommentsSuccess = (commentType: commentType) => (
  comments: CommentsCollection
): Action => {
  return {
    type: commentAction('SUBSCRIBE', commentType, 'SUCCESS'),
    payload: comments,
  };
};

export const subscribeCommentsFail = (commentType: commentType) => (
  comments: CommentsCollection
): Action => {
  return {
    type: commentAction('SUBSCRIBE', commentType, 'FAIL'),
    payload: comments,
  };
};

// ---------------------------------------------------------------  Add Comments

export const addComment = (
  commentType: commentType,
  comment: IComment,
  sceneId: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('ADD', commentType),
    payload: comment,
    meta: {
      firebaseRealtimeApi: {
        req: 'PUSH',
        url: getCommentsUrl(commentType, sceneId, containerRefId),
        onSuccessDispatches: [addCommentSuccess(commentType)],
        onFailDispatches: [addCommentFail(commentType)],
        onEmptyResponseDispatches: [addCommentSuccess(commentType)],
      },
    },
  };
};

export const addReply = (
  commentType: commentType,
  comment: IReply,
  parentCommentId: string,
  sceneId: string,
  containerRefId?: string
): Action => {
  const url = `${getCommentsUrl(
    commentType,
    sceneId,
    containerRefId,
    parentCommentId
  )}/replies`;

  return {
    type: commentAction('ADD', commentType),
    payload: comment,
    meta: {
      firebaseRealtimeApi: {
        req: 'PUSH',
        url,
        onSuccessDispatches: [addCommentSuccess(commentType)],
        onFailDispatches: [addCommentFail(commentType)],
        onEmptyResponseDispatches: [addCommentSuccess(commentType)],
      },
    },
  };
};

export const addCommentSuccess = (commentType: commentType) => (): Action => {
  return {
    type: commentAction('ADD', commentType, 'SUCCESS'),
  };
};

export const addCommentFail = (commentType: commentType) => (): Action => {
  return {
    type: commentAction('ADD', commentType, 'FAIL'),
  };
};

// ------------------------------------------------------------ Likes

export const likeComment = (
  commentType: commentType,
  sceneId: string,
  commentId: string,
  userId: string,
  userName: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('LIKE', commentType),
    payload: { [userId]: userName },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url:
          getCommentsUrl(commentType, sceneId, containerRefId, commentId) +
          '/likes',
      },
    },
  };
};

export const likeReply = (
  commentType: commentType,
  parentId: string,
  commentId: string,
  sceneId: string,
  userId: string,
  userName: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('LIKE', commentType),
    payload: { [userId]: userName },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url:
          getCommentsUrl(
            commentType,
            sceneId,
            containerRefId,
            parentId,
            commentId
          ) + '/likes',
      },
    },
  };
};

export const unlikeComment = (
  commentType: commentType,
  sceneId: string,
  commentId: string,
  userId: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('UNLIKE', commentType),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url:
          getCommentsUrl(commentType, sceneId, containerRefId, commentId) +
          `/likes/${userId}`,
      },
    },
  };
};

export const unlikeReply = (
  commentType: commentType,
  parentId: string,
  commentId: string,
  sceneId: string,
  userId: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('UNLIKE', commentType),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url:
          getCommentsUrl(
            commentType,
            sceneId,
            containerRefId,
            parentId,
            commentId
          ) + `/likes/${userId}`,
      },
    },
  };
};

// ---------------------------------------------------------------- Edit Comments

export const editComment = (
  commentType: commentType,
  sceneId: string,
  commentId: string,
  text: string,
  tags = {},
  containerRefId?: string
): Action => {
  return {
    type: commentAction('EDIT', commentType),
    payload: { text, tags },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url: getCommentsUrl(commentType, sceneId, containerRefId, commentId),
      },
    },
  };
};

export const editReply = (
  commentType: commentType,
  parentId: string,
  commentId: string,
  sceneId: string,
  text: string,
  tags = {},
  containerRefId?: string
): Action => {
  return {
    type: commentAction('EDIT', commentType),
    payload: { text, tags },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url: getCommentsUrl(
          commentType,
          sceneId,
          containerRefId,
          parentId,
          commentId
        ),
      },
    },
  };
};

// --------------------------------------------------------------------- Delete Comments

export const deleteComment = (
  commentType: commentType,
  sceneId: string,
  commentId: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('DELETE', commentType),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url: getCommentsUrl(commentType, sceneId, containerRefId, commentId),
      },
    },
  };
};

export const deleteReply = (
  commentType: commentType,
  parentCommentId: string,
  commentId: string,
  sceneId: string,
  containerRefId?: string
): Action => {
  return {
    type: commentAction('DELETE', commentType),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url: getCommentsUrl(
          commentType,
          sceneId,
          containerRefId,
          parentCommentId,
          commentId
        ),
      },
    },
  };
};

// -------------------------------------------------------------------------------------- OLD

export const clearComments = (): Action => {
  return {
    type: commentAction('CLEAR'),
  };
};

// Replies on comment
export const addCommentReply = (
  parentId,
  text,
  shotID = null,
  sceneId,
  userId,
  userName,
  userRole,
  date,
  tags
): Action => {
  const commentReply = {
    parentId,
    text,
    shotID,
    sceneId,
    commentorId: userId,
    commentorName: userName,
    role: userRole,
    date,
    tags,
  };

  let url = `scenes/${sceneId}/comments/`;

  return {
    type: 'ADD_COMMENT_REPLY',
    payload: commentReply,
    meta: {
      firebaseRealtimeApi: {
        req: 'PUSH',
        url: url,
      },
    },
  };
};

export const openCommentsModal = (id: string): Action => {
  return {
    type: commentAction('OPEN_COMMENTS_MODAL'),
    payload: {
      id,
    },
  };
};

export const closeCommentsModal = (): Action => {
  return {
    type: commentAction('CLOSE_COMMENTS_MODAL'),
  };
};

export const toggleCommentsMenu = (): Action => {
  return {
    type: commentAction('TOGGLE_COMMENTS_MENU'),
  };
};

export const closeCommentsMenu = (): Action => {
  return {
    type: commentAction('CLOSE_COMMENTS_MENU'),
  };
};
