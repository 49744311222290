import './Login.css';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Col, Container, Row } from 'react-bootstrap';
// import { login } from 'redux/slices/auth/actions/login';
// import { loginPendingSelector } from 'redux/slices/loaders/loadersSelectors';
// import InputWithImage from '../../../backlot-menu/components/input/input-with-icon/InputWithImage';
import Loader from '../loader/Loader';

const Login = () => {
  // const dispatch = useDispatch();

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  // const onSubmit = () => {
  //   dispatch(login(email, password));
  // };
  return (
    <Loader fullscreen={true} backgroundOpacity={1} show={true}>
      {/* <Container fluid className="login-container">
        <Row className="full-height">
          <Col xs={12} sm={6} md={5} lg={4} className="px-5">
            <form className="flex-container">
              <div className="logo mb-5">
                <img src="/assets/images/menu/logo.png" alt="Backlot logo" />
              </div>

              <InputWithImage
                className="mt-5 px-4"
                src="/assets/images/menu/login/envelop.svg"
                alt="Envelop"
                type="text"
                placeholder="Email Address"
                disabled={loginPending}
                value={email}
                onChange={setEmail}
                onEnterClick={onSubmit}
                required
              />

              <InputWithImage
                className="my-5 px-4"
                src="/assets/images/menu/login/lock.svg"
                alt="Lock"
                type="password"
                placeholder="Password"
                disabled={loginPending}
                value={password}
                onChange={setPassword}
                onEnterClick={onSubmit}
                required
              />
            </form>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={7}
            lg={8}
            className="full-height full-width px-0 d-none d-sm-block"
          >
            <img
              src="/assets/images/menu/login/login-image.png"
              className="full-height full-width"
              alt="Hero for login page"
            />
          </Col>
        </Row>
      </Container> */}
    </Loader>
  );
};

export default Login;
