import React, { useState } from 'react';
import './EditSceneModal.scss';
import { Modal, Select, Tabs } from 'antd';
import NewMembers from './NewMembers/NewMembers';
import Members from './Members/Members';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import {
  sceneIsPublicSelector,
  sceneMembersSelector2,
  sceneOwnerIdSelector,
  selectedSceneIdSelector,
  teamMembersSelector2,
} from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';

import {
  addSceneMember,
  removeSceneMember,
  transferSceneOwnership,
  updateSceneAccess,
} from 'redux/slices/app/actions';

const EditSceneModal = ({
  onClose,
  title, // Selected project name or selected scene name
}) => {
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  const sceneIsPublic = useSelector(sceneIsPublicSelector);
  const sceneMembers = useSelector(sceneMembersSelector2) ?? {};
  const teamMembers = useSelector(teamMembersSelector2) ?? {};

  const sceneOwnerId = useSelector(sceneOwnerIdSelector) ?? '';
  const sceneOwnerName = (teamMembers[sceneOwnerId] ?? {}).name;

  const [newMember, setNewMember] = useState<any>(null);

  const userIsOwner = userId === sceneOwnerId;

  let members = Object.values(sceneIsPublic ? teamMembers : sceneMembers);
  members = members.filter(({ id }) => id !== sceneOwnerId);
  let nonSceneMembers = Object.values(teamMembers).filter(
    ({ id }) => !sceneMembers[id] && id !== sceneOwnerId
  );

  const editors = members.filter(({ role = 2 }) => role < 2);
  const reviewers = members.filter(({ role }) => role === 2);

  const updateSceneType = (isPublic) => {
    // if (isPublic !== sceneIsPublic)
    // dispatch(updateSceneAccess(sceneId, isPublic));
  };

  const removeMember = (userId) => {
    // dispatch(removeSceneMember(sceneId, userId));
  };

  const addMember = (userId, userName) => {
    if (userId && userName) {
      // dispatch(addSceneMember(sceneId, userId, userName));
      setNewMember(null);
    }
  };

  const transferOwnership = (userId) => {
    dispatch(transferSceneOwnership(sceneId, userId));
  };

  const renderDropArrow = (
    <img className="drop-arrow" src="/menu/dropdown.svg" alt="dropdown arrow" />
  );

  const teamScene = (
    <div className="scene-type">
      <img src="/menu/public-scene.svg" alt="team scene" />
      <p>
        <b>Team Scene.</b> All users on your team can access this scene
      </p>
    </div>
  );

  const privateScene = (
    <div className="scene-type">
      <img
        src="/menu/private-scene.svg"
        alt="private scene"
        style={{ marginRight: '0.25rem' }}
      />
      <p>
        <b>Private Scene.</b> Only users invited by the Scene Owner can access
        this scene
      </p>
    </div>
  );

  const sceneTypeMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => updateSceneType(true)}>
        {teamScene}
      </Menu.Item>
      <Menu.Item key="1" onClick={() => updateSceneType(false)}>
        {privateScene}
      </Menu.Item>
    </Menu>
  );

  const editorsOptionsMenu = (userId) => (
    <Menu>
      {userIsOwner && (
        <Menu.Item key="0" onClick={() => transferOwnership(userId)}>
          Transfer Scene Ownership
        </Menu.Item>
      )}
      {userIsOwner && sceneIsPublic === false && (
        <Menu.Item key="1" onClick={() => removeMember(userId)}>
          Remove User
        </Menu.Item>
      )}
    </Menu>
  );

  const reviewersOptionsMenu = (userId) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          removeMember(userId);
        }}
      >
        Remove User
      </Menu.Item>
    </Menu>
  );

  const renderEditors = editors.map(({ id, name }) => (
    <div key={id} className="member">
      <div className="member-name">
        {name}
        {id === userId && '(You)'}
      </div>
      <div className="member-role">Editor</div>
      {userIsOwner && (
        <Dropdown
          transitionName=""
          overlay={editorsOptionsMenu(id)}
          trigger={['click']}
        >
          {renderDropArrow}
        </Dropdown>
      )}
    </div>
  ));

  const renderReviewers = reviewers.map(({ id, name }) => (
    <div key={id} className="member">
      <div className="member-name">
        {name}
        {id === userId && '(You)'}
      </div>
      <div className="member-role">Web View-Only</div>
      {userIsOwner && sceneIsPublic === false && (
        <Dropdown
          transitionName=""
          overlay={reviewersOptionsMenu(id)}
          trigger={['click']}
        >
          {renderDropArrow}
        </Dropdown>
      )}
    </div>
  ));

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="scene-permission-modal"
      title={title}
      visible={true}
      centered
      onCancel={onClose}
      footer={null}
    >
      <div className="scene-type-dropdown">
        {sceneIsPublic ? teamScene : privateScene}
        {userIsOwner && (
          <Dropdown
            transitionName=""
            overlay={sceneTypeMenu}
            trigger={['click']}
          >
            {renderDropArrow}
          </Dropdown>
        )}
      </div>

      {sceneIsPublic === false && userIsOwner && (
        <div className="add-member">
          <div className="member-text-box">
            <Select
              value={newMember}
              placeholder="Add team members"
              // mode="multiple"
              style={{ width: '100%' }}
              onChange={(id, selectedValuesArray) => setNewMember(id)}
              options={nonSceneMembers.map(({ id, name }) => {
                return {
                  label: name,
                  key: id,
                  value: id,
                };
              })}
            />
          </div>

          <div className="add-member">
            <button
              disabled={!newMember}
              onClick={() => {
                addMember(newMember, teamMembers[newMember].name);
              }}
            >
              Add
            </button>
          </div>
        </div>
      )}

      <div className="all-members">
        <div className="member">
          <div className="member-name">
            {sceneOwnerName}
            {sceneOwnerId === userId && '(You)'}
          </div>

          <div className="member-role">Owner</div>
          {/* {renderDropArrow} */}
        </div>

        <div className="editors">{renderEditors}</div>

        <div className="reviewers">{renderReviewers}</div>
      </div>
      {/* {members && (
        <Tabs defaultActiveKey="1">
          <TabPane tab={`Members (${members.length})`} key="1">
            <Members members={members} removeMember={removeMember} />
          </TabPane>
          <TabPane tab={`+ New Member`} key="2">
            <NewMembers nonMembers={nonMembers} addMember={addMember} />
          </TabPane>
        </Tabs>
      )} */}
    </Modal>
  );
};

export default EditSceneModal;
