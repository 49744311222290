import './Main.css';
import CommentsPanel from 'backlot-comments/components/comments-panel/CommentsPanel';
import { useFetchUserData } from 'common/hooks/useFetchUserData';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRole } from 'redux/slices/auth/actions/userData';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { useRouteMatch } from 'react-router-dom';
import { sceneCommentsArraySelector } from 'redux/slices/comments/selectors';
import { sceneCommentsSubscriptionPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import {
  subscribeComments,
  unsubscribeComments,
} from 'redux/slices/comments/actions';
import {
  setSelectedProjectId,
  setSelectedSceneId,
  setSelectedTeamId,
  subscribeProject,
  subscribeScene,
  subscribeTeam,
  unsubscribeProject,
  unsubscribeScene,
  unsubscribeTeam,
} from 'redux/slices/app/actions';
import {
  selectedProjectSelector,
  selectedSceneIdSelector,
  selectedSceneSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';

const initialCommentsSize = 10;

const Main = () => {
  const dispatch = useDispatch();

  const match = useRouteMatch();
  // const sceneId = (match.params as any).sceneId;
  const teamId = useSelector(selectedTeamIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);
  const scene = useSelector(selectedSceneSelector);
  const project = useSelector(selectedProjectSelector);
  const userId = useSelector(userIdSelector);
  // useFetchUserData(sceneId);

  // useEffect(() => {
  //   if (userId && sceneId) dispatch(subscribeToComments(userId, sceneId));
  // }, [dispatch, userId, sceneId]);

  useEffect(() => {
    if (userId) dispatch(fetchRole(userId));
  }, [userId]);

  const [maxCommentsSize, setMaxCommentsSize] = useState(initialCommentsSize);

  const comments = useSelector(sceneCommentsArraySelector);

  const sceneCommentsSubscriptionPending = useSelector(
    sceneCommentsSubscriptionPendingSelector
  );

  useEffect(() => {
    if (teamId) {
      dispatch(subscribeTeam(teamId));
    }
    return () => {
      dispatch(unsubscribeTeam());
    };
  }, [dispatch, teamId]);

  useEffect(() => {
    if (sceneId) {
      dispatch(subscribeScene(sceneId));
    }
    return () => {
      if (sceneId) {
        dispatch(unsubscribeScene(sceneId));
      }
    };
  }, [dispatch, sceneId]);

  // useEffect(() => {
  //   if (scene && scene.project_id) {
  //     dispatch(setSelectedProjectId(scene.project_id));
  //     dispatch(subscribeProject(scene.project_id));
  //   }
  //   return () => {
  //     if (scene && scene.project_id) {
  //       dispatch(unsubscribeProject(scene.project_id));
  //     }
  //   };
  // }, [scene]);

  // useEffect(() => {
  //   if (project && project.team_id) {
  //     // dispatch(setSelectedTeamId(project.team_id));
  //     // dispatch(subscribeTeam(project.team_id));
  //   }
  //   return () => {
  //     if (project && project.team_id) {
  //       // dispatch(unsubscribeTeam(project.team_id));
  //     }
  //   };
  // }, [project]);

  useEffect(() => {
    if (sceneId) {
      dispatch(
        subscribeComments('SCENE_COMMENT', sceneId, undefined, maxCommentsSize)
      );
    }

    return () => {
      dispatch(unsubscribeComments('SCENE_COMMENT'));
    };
  }, [sceneId, maxCommentsSize]);

  useEffect(() => {
    return () => {
      setMaxCommentsSize(initialCommentsSize);
    };
  }, [sceneId]);

  const fetchCommentsPending =
    sceneCommentsSubscriptionPending && maxCommentsSize === initialCommentsSize;

  const extraCommentsPending =
    sceneCommentsSubscriptionPending && maxCommentsSize !== initialCommentsSize;

  const onReachTop = () => {
    if (
      !sceneCommentsSubscriptionPending &&
      maxCommentsSize === comments.length
    ) {
      setMaxCommentsSize(maxCommentsSize + 5);
    }
  };

  return (
    <div id="comments-main">
      {/* <button
        onClick={() => {
          dispatch(logout());
        }}
      >
        LogOut
      </button> */}
      <CommentsPanel comments={comments} onReachTop={onReachTop} />
    </div>
  );
};

export default Main;
