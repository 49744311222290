import './TeamMembers.scss';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, List, Menu, Popconfirm } from 'antd';
import { mapRolesInTeam } from './listExtras/ListExtras';
import {
  isAdminSelector,
  numOfActiveUsersSelector,
  numOfLicensesSelector,
  pendingInvitesSelector,
  userRoleInTeamSelector,
} from 'redux/slices/team/selectors';
import NewMember from './newMember/NewMember';
import { removeInvitation } from 'redux/slices/team/actions';
import {
  selectedTeamIdSelector,
  teamMembersSelector2,
} from 'redux/slices/app/selectors';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import {
  addTeamMemberPendingSelector,
  paymentDataSelector,
} from 'redux/slices/stripe/selectors';
import { addTeamMember } from 'redux/slices/stripe/actions';
import { Tab } from 'react-bootstrap';
import {
  makeAdmin,
  makeBillingAdmin,
  updateTeamMemberRole,
} from 'redux/slices/app/actions';
import firebase from 'firebase-main';
import { toasterAction } from 'redux/common/actions';
import { useHistory } from 'react-router-dom';

const TeamMembers = () => {
  const dispatch = useDispatch();

  const [showAddEditorModal, setShowAddMemberModal] = useState(false);
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const userId = useSelector(userIdSelector);
  const userRole = useSelector(userRoleInTeamSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const pendingInvites = useSelector(pendingInvitesSelector);
  const isAdmin = useSelector(isAdminSelector);
  const numOfLicenses = useSelector(numOfLicensesSelector);
  const numOfActiveUsers = useSelector(numOfActiveUsersSelector);
  const teamMembers = useSelector(teamMembersSelector2);
  let teamMembersArray = Object.values(teamMembers ?? {}).filter(
    ({ id }) => id !== userId
  );

  const addMemberPending = useSelector(addTeamMemberPendingSelector);

  const paymentInfo = (useSelector(paymentDataSelector) ?? {})[teamId];

  useEffect(() => {
    if (userRole === 'Reviewer') {
      // history.push(`/settings/${teamId}`);
    }
  }, [userRole]);

  const deleteInvitation = (invitationId) => {
    dispatch(removeInvitation(teamId, invitationId));
  };

  const addMember = (email, role) => {
    dispatch(addTeamMember(teamId, email, role));
  };

  // if (userRole !== 'Admin' && userRole !== 'Editor') return null;

  const changeRole = (role, userId) => {
    // dispatch(editUserRoleInTeam(userId, teamId, role));
    dispatch(updateTeamMemberRole(userId, teamId, role));
  };

  const onMakeAdminClick = (userId) => {
    dispatch(makeAdmin(teamId, userId));
  };

  const onMakeBillingAdminClick = (userId) => {
    dispatch(makeBillingAdmin(teamId, userId));
  };

  const deleteMember = async (userId) => {
    // dispatch(removeTeamMember(userId, teamId));
    try {
      await firebase.functions().httpsCallable('removeUserFromTeam')({
        teamId,
        userId,
      });
    } catch (err: any) {
      console.error(err);
      dispatch(toasterAction('error', 'Failed to remove user', err));
    }
  };

  const rolesMenu = (userId) => {
    const member = teamMembersArray.find(({ id }) => id === userId);
    return (
      <Menu>
        {member?.role !== 1 && (
          <Menu.Item onClick={() => changeRole('Editor', userId)}>
            Editor
          </Menu.Item>
        )}
        {member?.role !== 2 && (
          <Menu.Item onClick={() => changeRole('Reviewer', userId)}>
            Reviewer
          </Menu.Item>
        )}
        {!(member as any)?.isAdmin && (
          <Menu.Item onClick={() => onMakeAdminClick(userId)}>Admin</Menu.Item>
        )}
        {!(member as any)?.isBillingAdmin && (
          <Menu.Item onClick={() => onMakeBillingAdminClick(userId)}>
            Billing Admin
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Tab.Pane active={params?.tab === 'team'} id="team">
      <div className="d-flex flex-column w-100 team-members-settings">
        <h6>Members</h6>
        <hr />
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: '2rem' }}
        >
          <span className="team-members-header"> Team Members </span>
          <>
            <span className="licenses-length">
              {/* ({numOfLicenses - numOfActiveUsers} licenses available) */}
              <span>
                {(paymentInfo?.number_of_editor_licenses ?? 0) -
                  (paymentInfo?.number_of_active_editors ?? 0)}{' '}
                Editor licenses available
              </span>
              <span>
                {(paymentInfo?.number_of_reviewer_licenses ?? 0) -
                  (paymentInfo?.number_of_active_reviewers ?? 0)}{' '}
                Reviewer licenses available
              </span>
            </span>
            <div className="flex-grow-1" />

            {isAdmin && (
              <>
                <span
                  className="new-member-btn"
                  onClick={() => setShowAddMemberModal(true)}
                >
                  + New Member
                </span>
                {showAddEditorModal && (
                  <NewMember
                    onCancel={() => {
                      setShowAddMemberModal(false);
                    }}
                    onSubmit={(email, role) => {
                      addMember(email, role);
                    }}
                    submitPending={addMemberPending}
                  />
                )}
              </>
            )}
          </>
        </div>
        {/* {isAdmin && (
          <div
            className="aqua-link mt-0 mb-4 text-center"
            onClick={() =>
              history.push(`/settings/${teamId}/billing?editMembership=true`)
            }
          >
            Need more licenses?
          </div>
        )} */}
        <div className="team-members">
          {teamMembersArray.map((member) => (
            <div key={member.id + member.role} className="team-member">
              <div className="user">
                <div className="name">
                  {member.name}{' '}
                  {(member as any).isAdmin && (
                    <span className="tag">Admin</span>
                  )}
                  {(member as any).isBillingAdmin && (
                    <span className="tag">Billing Admin</span>
                  )}
                </div>
                <div className="email">{member.email}</div>
              </div>

              <div className="role">{mapRolesInTeam(member.role)}</div>
              {/* <div className="make-admin">Make Admin</div> */}
              {/* <div className="make-billing-admin">Make Billing Admin</div> */}

              <div className="edit-user">
                <Dropdown
                  transitionName=""
                  overlay={rolesMenu(member.id)}
                  trigger={['click']}
                >
                  <span>Edit</span>
                </Dropdown>
              </div>
              <div className="remove">
                <Popconfirm
                  title="Are you sure remove this member?"
                  onConfirm={() => deleteMember(member.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span>Remove</span>
                </Popconfirm>
              </div>
            </div>
          ))}
        </div>
        {/* <List
          className="team-members"
          itemLayout="horizontal"
          dataSource={teamMembers}
          bordered={false}
          renderItem={(item: any) => (
            <List.Item
              extra={
                <ListExtras
                  roleInTeam={item.role}
                  userId={item.id}
                  isAdmin={item.isAdmin}
                  isBillingAdmin={item.isBillingAdmin}
                  showOptions={isAdmin}
                />
              }
            >
              <List.Item.Meta
                // eslint-disable-next-line
                title={<a>{item.name}</a>}
                description={
                  <span className="team-member-email">{item.email} </span>
                }
              />
            </List.Item>
          )}
        /> */}

        {pendingInvites.length > 0 && (
          <>
            <div
              className="d-flex align-items-baseline"
              style={{ marginBottom: '2rem' }}
            >
              <span className="team-members-header"> Pending Invites </span>
            </div>
            <List
              className="team-members"
              itemLayout="horizontal"
              dataSource={pendingInvites}
              bordered={false}
              renderItem={(item) => (
                <List.Item
                  extra={
                    isAdmin && (
                      <>
                        <span className="team-member-role mr-5">
                          {mapRolesInTeam(item.role)}
                        </span>
                        <Popconfirm
                          title="Are you sure remove this invitation?"
                          onConfirm={() => deleteInvitation(item.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <span className="remove-team-member-btn">
                            {' '}
                            Remove{' '}
                          </span>
                        </Popconfirm>
                      </>
                    )
                  }
                >
                  <List.Item.Meta
                    description={
                      <span className="team-member-email">
                        {item.email} (Pending)
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
          </>
        )}
      </div>
    </Tab.Pane>
  );
};

export default TeamMembers;
