import './NewMember.css';
import React from 'react';
import { Modal, Button, Input, Form, Select } from 'antd';

const NewMember = ({
  onSubmit = (email, role) => {},
  onCancel = () => {},
  submitPending = false,
}) => {
  const onFinish = (value) => {
    const { email, role } = value;
    onSubmit(email, role);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title="Add New Team Member"
      visible
      onCancel={onCancel}
      footer={null}
      closable={false}
      destroyOnClose={true}
    >
      <div className="d-flex flex-column">
        <Form className="d-flex flex-column" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'This is not a valid email!',
                required: true,
              },
            ]}
          >
            <Input placeholder="Add by email..." />
          </Form.Item>

          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="Editor">Editor</Select.Option>
              <Select.Option value="Reviewer">Reviewer</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item className="align-self-end m-0">
            <Button className="mx-3" onClick={onCancel}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit" loading={submitPending}>
              Invite
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewMember;
