import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './LikesPopover.css';
import { useSelector } from 'react-redux';
import Like from '../../Like/Like';
import { LoadingOutlined } from '@ant-design/icons';
import { Img } from 'react-image';
import { teamMembersSelector2 } from 'redux/slices/app/selectors';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />
);

/**
 * This component renders these components:
 *  1- Like Button that toggle the like status of the comment.
 *  2- Text that shows how many people liked this comment. If they are less than three, then there names
 *      will be displayed.
 *  3- A popover that displays all the names of the people who liked this comment.
 */
const LikesPopover = ({ likes }) => {
  // const commentsModal = useSelector<any>(
  //   (state) => state.modals.toggleClipCommentsModal !== ''
  // );
  const sideBar = useSelector<any>((state) => state.showSidebar);
  // const users: any = useSelector<any>((state) => state.app.users);
  const teamMembers = useSelector(teamMembersSelector2) ?? {};

  // This state handles the z-index of the popover.
  // If the CommentsModal is open or the sidebar is open, then the z-index of the popover is set to be higher than
  //  there z-index. Else, the z-index of the popover is set to 0 to be lower than the z-index of the header.
  // const [zIndex, setZIndex] = useState(0);
  // useEffect(() => {
  //   if (commentsModal === true || sideBar === true) {
  //     setZIndex(5000);
  //   }
  // }, [commentsModal, sideBar]);

  // The content to be displayed in the popover.

  let keys = Object.keys(likes);
  const popoverContent = keys.map((key) => {
    // let user = users.find((user) => user.id === key);
    const user = teamMembers[key];
    if (!user) {
      return null;
    }
    return (
      <div className="d-flex justify-content-start like-items" key={user.id}>
        <Img
          src={user.thumbnail ?? '/default-profile-image.jpg'}
          style={{ width: '25px', height: '25px' }}
          loader={antIcon}
        />
        <span className="mx-3"> {user.name} </span>
      </div>
    );
  });

  const popover = (
    <Popover
      id="popover-basic"
      style={{ backgroundColor: 'white', position: 'relative', zIndex: 50 }}
    >
      <Popover.Title
        as="h3"
        style={{ backgroundColor: '#091119', color: 'white' }}
      >
        Liked by
      </Popover.Title>
      <Popover.Content className="likes-content p-0">
        {popoverContent}
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="likes-container">
      <div className="likes-position">
        <OverlayTrigger
          // className="over-lay"
          trigger={['click', 'focus']}
          delay={{ show: 300, hide: 300 }}
          placement={'top'}
          overlay={popover}
        >
          <button id="mask-btn">
            <Like likesLength={keys.length} />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default LikesPopover;
