import React, { useState } from 'react';
import './Comment.scss';
import { Row, Col } from 'react-bootstrap';
import LikesPopover from '../../popovers/likesPopover/likesPopover';
import moment from 'moment';
import UserPopover from '../../popovers/UserPopover/UserPopover';
import { useSelector, useDispatch } from 'react-redux';
import CommentOptions from './CommentOptions/CommentOptions';
import EditComment from './EditComment/EditCommet';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';
import {
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import CommentReplies from './CommentReplies/CommentReplies';
import { commentType } from 'redux/slices/comments/types';
import {
  deleteComment,
  editComment,
  likeComment,
  likeReply,
  unlikeComment,
  unlikeReply,
  deleteReply,
  editReply,
} from 'redux/slices/comments/actions';
/**
 * Maps commenters' position to a color with which it will be displayed
 */
export const positionToColor = {
  GAFFER: 'rgba(255, 212, 23, 0.6)',
  DIRECTOR: 'rgba(136, 88, 234, 0.6)',
  DP: 'rgba(0, 255, 87, 0.69)',
};

type propTypes = {
  commentType: commentType;
  commentorId: string;
  commentorName: string;
  role: string;
  timeStamp: string;
  text: string;
  likes?: { [key: string]: string }[];
  commentId: string;
  shotID?: string;
  myComment?: boolean;
  player?: any;
  tags?: { [key: string]: any };
  replies?: any;
  parentId?: any;
  replyOnReply?: any; // Used in replying on a reply by mentioning the user of that reply
  containerRefId?: string;
};

/**
 *  Renders a comment using the commenter's name, position (eg. director, gaffer etc..),
 *  timestamp, text, and likes.
 */
const CommentCard = ({
  commentType,
  commentorId,
  commentorName,
  role,
  timeStamp,
  text = '',
  likes = [],
  commentId,
  shotID,
  myComment = false,
  player,
  tags,
  replies,
  parentId,
  replyOnReply, // Used in replying on a reply by mentioning the user of that reply
  containerRefId,
}: propTypes) => {
  const dispatch = useDispatch();
  const [showReplies, setShowReplies] = useState(false);
  const userID: string = useSelector<any>(
    (state) => state.firebase.auth.uid
  ) as string;
  const userName = useSelector(userNameSelector);
  const userRole = useSelector(userRoleSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  myComment = myComment || commentorId === userID;

  // const myComment = useSelector(
  //   userCommentOwnershipSelector(commentId, shotID)
  // );

  /**
   * This function takes the comment string that is saved in the database,
   * and checks if the comment holds tags, if yes, then it will render these tags as anchor tags that
   * shows that tagged person profile.
   * @param {String} text
   */
  const renderCommentText = (text) => {
    let splittedText = text.split('@');
    // Not tags
    if (splittedText.length === 1) {
      return text;
    }

    let renderedComment = splittedText.map((chunk, index) => {
      if (index === 0) {
        return chunk;
      }

      if (chunk.charAt(0) === '[' && chunk.charAt(1) === '(') {
        let tag = chunk.split(')]');
        //This is not a tag
        if (tag.length === 1) {
          return chunk;
        } else {
          let [taggedName, taggedID] = tag[0].substring(2).split(',');
          return (
            <React.Fragment key={taggedID + index + commentId}>
              <UserPopover userId={taggedID} key={taggedID}>
                <button className="tag" style={{ color: 'rgb(27, 149, 224)' }}>
                  {'@' + taggedName}
                </button>
              </UserPopover>
              {tag.slice(1).join()}
            </React.Fragment>
          );
        }
      } else {
        let splittedString = chunk.split(' ');
        if (
          /^\d+:\d+$/.test(splittedString[0]) ||
          /^\d+:\d+:\d+$/.test(splittedString[0])
        ) {
          let time = splittedString[0];
          return (
            <React.Fragment key={index + '_' + commentId}>
              <button
                className="tag"
                key={index + '_' + commentId}
                style={{ color: 'rgb(27, 149, 224)' }}
                onClick={() => {
                  if (shotID != null) {
                    player.current.seekTo(
                      moment
                        .duration(
                          time.split(':').length === 2 ? `00:${time}` : time
                        )
                        .asSeconds()
                    );
                  }
                }}
              >
                {'@' + time}
              </button>
              {' ' + splittedString.slice(1).join(' ')}
            </React.Fragment>
          );
        }
        return '@' + chunk;
      }
    });

    return renderedComment;
  };

  let likedBool = false;
  let hasLikes = false;

  if (likes.length !== 0) {
    // If there are likes for the comment.
    hasLikes = true;

    let ids = Object.keys(likes);
    if (ids.includes(userID)) {
      // If the logged in user liked this comment.
      likedBool = true;
    }
  }

  const toggleLike = () => {
    if (likedBool === false) {
      if (!parentId) {
        dispatch(
          likeComment(
            commentType,
            sceneId,
            commentId,
            userID,
            userName,
            containerRefId
          )
        );
      } else {
        dispatch(
          likeReply(
            commentType,
            parentId,
            commentId,
            sceneId,
            userID,
            userName,
            containerRefId
          )
        );
      }
    } else {
      if (!parentId) {
        dispatch(
          unlikeComment(commentType, sceneId, commentId, userID, containerRefId)
        );
      } else {
        dispatch(
          unlikeReply(
            commentType,
            parentId,
            commentId,
            sceneId,
            userID,
            containerRefId
          )
        );
      }
    }
  };

  const onDeleteComment = (commentId) => {
    if (parentId) {
      dispatch(
        deleteReply(commentType, parentId, commentId, sceneId, containerRefId)
      );
    } else {
      dispatch(deleteComment(commentType, sceneId, commentId, containerRefId));
    }
  };

  const onEditComment = (text, tags) => {
    if (parentId) {
      dispatch(
        editReply(
          commentType,
          parentId,
          commentId,
          sceneId,
          text,
          tags,
          containerRefId
        )
      );
    } else {
      dispatch(
        editComment(commentType, sceneId, commentId, text, tags, containerRefId)
      );
    }
  };

  const [editing, setEditing] = useState(false);
  const toggleCommentEdit = () => {
    setEditing(!editing);
  };

  let replyBtn: any = null;

  // Check if this a comment or a reply on a comment to adjust the function of the reply button
  if (parentId) {
    replyBtn = (
      <p className="like-btn mx-1 mb-0" onClick={replyOnReply}>
        Reply
      </p>
    );
  } else {
    replyBtn = (
      <p
        className="like-btn mx-1 mb-0"
        onClick={() => setShowReplies(!showReplies)}
      >
        {showReplies ? 'Hide' : 'Reply'}
      </p>
    );
  }

  return (
    // <Container id={'commentID:' + commentId} fluid className="p-0">
    <div id={'commentID:' + commentId} className="comment p-0">
      {/* <Row className="comment"> */}
      {/* <div className="comment"> */}
      <div className="body">
        <div className="comment-header">
          <div className="title">
            <div className="name">{commentorName}</div>
            <div
              style={{
                color: positionToColor[role]
                  ? positionToColor[role]
                  : 'rgba(255, 255, 255, 0.5)',
              }}
              className="position"
            >
              {role}
            </div>
          </div>
          <div className="flex-grow-1" />
          {myComment && (
            <div>
              <CommentOptions
                commentID={commentId}
                toggleEdit={toggleCommentEdit}
                shotID={shotID}
                onDeleteComment={onDeleteComment}
              />
            </div>
          )}
        </div>

        {/**
         * This row holds the comments text and the likes part
         */}
        <div className="text">
          {!editing ? (
            <React.Fragment>
              <div>
                <p style={{ color: 'white' }}>{renderCommentText(text)}</p>
              </div>
              {hasLikes && (
                <LikesPopover
                  likes={likes}
                  // commentID={commentId}
                  // likedBool={likedBool}
                />
              )}
            </React.Fragment>
          ) : (
            <EditComment
              text={text}
              commentID={commentId}
              toggleEdit={toggleCommentEdit}
              shotID={shotID}
              tags={tags}
              onEditComment={onEditComment}
            />
          )}
        </div>
      </div>
      {/* </div> */}

      <div>
        <div className={hasLikes === true ? 'pt-3' : 'pt-1'}>
          <div
            className="d-flex align-items-center"
            style={{ minWidth: '150px' }}
          >
            <p className="like-btn mx-1 mb-0" onClick={toggleLike}>
              {likedBool ? 'Unlike' : 'Like'}
            </p>

            {replyBtn}

            <div className="comment-timeStamp mx-1">
              {moment(timeStamp).calendar()}
            </div>
          </div>
        </div>
      </div>

      {
        // If this comment is not a reply
        !parentId ? (
          <Row>
            <Col>
              <CommentReplies
                commentsType={commentType}
                commentReplies={replies}
                parentId={commentId}
                showReplies={showReplies}
                containerRefId={containerRefId}
                setShowReplies={() => setShowReplies(true)}
              />
            </Col>
          </Row>
        ) : null
      }
    </div>
  );
};

export default CommentCard;
