import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form } from 'antd';
import './ReactStripe.css';
import { useHistory } from 'react-router-dom';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      fontFamily: 'Open Sans, sans-serif',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement
      options={CARD_OPTIONS as any}
      onChange={onChange}
      className="StripeElement"
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`Paybutton SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <Button type="primary" className="ResetButton" onClick={onClick}>
    Back
  </Button>
);

const CheckoutForm = ({
  submitBtnText,
  onSubmit,
  onSuccess,
  onFail,
  additionalFields,
  processing,
  history,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [token, setToken] = useState<boolean | null>(null);
  const [form] = Form.useForm();

  // const requiresAction = useSelector(requiresActionSelector);

  // useEffect(() => {
  //   if (requiresAction !== null) {
  //     stripe
  //       .confirmCardPayment(requiresAction.client_secret, {
  //         payment_method: requiresAction.paymentMethodId,
  //       })
  //       .then((result) => {
  //         dispatch(
  //           createPaymentConfirmationResponse(requiresAction.responseToken)
  //         );
  //         if (result.error) {
  //           // Start code flow to handle updating the payment details.
  //           // Display error message in your UI.
  //           // The card was declined (i.e. insufficient funds, card has expired, etc).
  //           setError(result.error);
  //           dispatch(onFail(result.error));
  //           // dispatch(createTeamInvitationFail("Wrong confirmation!"))
  //           dispatch(setRequiresActionData(null));
  //           // throw result;
  //         } else {
  //           if (result.paymentIntent.status === 'succeeded') {
  //             // Show a success message to your customer.
  //             // There's a risk of the customer closing the window before the callback.
  //             // We recommend setting up webhook endpoints later in this guide.
  //             dispatch(onSuccess());
  //              history.push(`/login`);
  //             dispatch(setRequiresActionData(null));
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         // React does not have access to the actual error object in development
  //         // setError(error)
  //         dispatch(onFail(error));
  //         dispatch(setRequiresActionData(null));
  //       });
  //   }
  // }, [requiresAction]);
  const handleSubmit = async (formValues) => {
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card')?.focus();
      return;
    }

    if (cardComplete) {
      // setProcessing(true);
    }

    // Use your card Element with other Stripe.js APIs
    const { error: paymentMethodError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as any,
      });

    if (paymentMethodError) {
      setError(error);
      dispatch(onFail(error));
    } else {
      if (paymentMethod) {
        onSubmit(paymentMethod.id);
      } else {
        dispatch(onFail('Payment method is needed!'));
      }
    }

    // setProcessing(false);

    // if (payload.error) {
    //   setError(payload.error);
    // } else {
    //   setToken(payload);
    //   const paymentData = {
    //     stripeToken: payload.token.id,
    //     ...formValues
    //   }
    //   // dispatch(usersActions.sendPaymentDetails(paymentData))
    //   console.log(paymentData)
    // }
  };

  const reset = () => {
    setError(null);
    setToken(false);
  };

  return token ? (
    <div className="AppWrapper">
      <div className="Result">
        <div className="ResultTitle" role="alert">
          Payment successful
        </div>
        <div className="ResultMessage">
          Thanks for trying Stripe Elements. No money was charged, but we
          generated a stripe token for you.
        </div>
        <ResetButton onClick={reset} />
      </div>
    </div>
  ) : (
    <div className="AppWrapper">
      <Form
        name="React_Stripe"
        layout={'vertical'}
        form={form}
        onFinish={handleSubmit}
      >
        {additionalFields}
        <Form.Item>
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </Form.Item>
        {error && <ErrorMessage>{error?.message}</ErrorMessage>}
        <div className="d-flex justify-content-center">
          <SubmitButton
            processing={processing}
            error={error}
            disabled={!stripe}
          >
            {submitBtnText}
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
};

const App = ({
  submitBtnText,
  onSubmit,
  onSuccess,
  onFail,
  additionalFields = null,
  processing,
}) => {
  const history = useHistory();
  return (
    <CheckoutForm
      submitBtnText={submitBtnText}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      onFail={onFail}
      additionalFields={additionalFields}
      processing={processing}
      history={history}
    />
  );
};

export default App;
