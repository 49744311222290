import { mainActionTypes } from 'redux/common/types';

export type curvesSlice = {
  offsets: { x: number; y: number };
  xRange: { min: number; max: number };
  yRange: { min: number; max: number };
  screen: { width: number; height: number };
  data: ICurve[];
};

export interface CurvesSelector {
  curves: curvesSlice;
}

const namespace = 'curves';

type curvesActionsTypes = mainActionTypes;

type curvesResource =
  | 'CURVES'
  | 'CURVE'
  | 'X_RANGE'
  | 'Y_RANGE'
  | 'SCREEN_WIDTH'
  | 'SCREEN_HEIGHT';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const curvesAction = (
  type: curvesActionsTypes,
  resource?: curvesResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};

export interface ICurve {
  instanceID: string;
  name: string;
  minTime: number;
  maxTime: number;
  minValue: number;
  maxValue: number;
  keyframesData: {
    position: {
      x: number;
      y: number;
    };
    left?: {
      x: number;
      y: number;
    };
    right?: {
      x: number;
      y: number;
    };
  }[];
}
