import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { inPersonalDriveSelector } from 'redux/slices/app/selectors';
import ProjectSettings from './project-settings/ProjectSettings';
import Members from './members/Members';
import { Dropdown } from 'antd';
import { SharePersonalProjectModal } from 'backlot-menu/components/share/personal-share/SharePersonalProjectModal';
import { ShareTeamProjectModal } from 'backlot-menu/components/share/team-share/ShareTeamProjectModal';
import CreateSceneModal from './newScene/create-scene-modal/CreateSceneModal';

export const TopBar = ({
  title,
  members,
  view,
  setView,
  activeTab,
  setActiveTab,
  sceneOrderType = 0,
  sceneOrderTypes,
  onSceneOrderTypeChange = (type) => {},
  showMembers = true,
  showShareBtn = true,
  showNewSceneBtn = true,
  showProjectSettings = true,
}) => {
  const [showNewSceneModal, setShowNewSceneModal] = useState(false);
  const [showShareProjectModal, setShowShareProjectModal] = useState(false);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  return (
    <div>
      {showShareProjectModal && inPersonalDrive && (
        <SharePersonalProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}

      {showShareProjectModal && !inPersonalDrive && (
        <ShareTeamProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}
      {showNewSceneModal && (
        <CreateSceneModal onCancel={() => setShowNewSceneModal(false)} />
      )}

      <div className="screen-top">
        <div className="title">{title}</div>
        <div className="right-aligned">
          {/* {selectedProjectId && !inPersonalDrive && ( */}
          {showMembers && (
            <Members
              //   members={projectMembers}
              members={members}
              membersModalTitle="Project Members"
            />
          )}
          {/* {selectedProjectId !== null && ( */}
          <>
            {/* {showShareBtn && (
              <button
                className="share-btn"
                onClick={() => setShowShareProjectModal(true)}
              >
                Share
              </button>
            )} */}
            {showNewSceneBtn && (
              <button
                className="new-scene-btn"
                onClick={() => setShowNewSceneModal(true)}
              >
                New Scene
              </button>
            )}
          </>
          {/* )} */}
        </div>
        {/* {selectedProjectId && <ProjectSettings />} */}
      </div>
      <div className="second-row">
        <div className="tabs">
          <div
            className={`tab${activeTab === 0 ? ' active' : ''}`}
            onClick={() => setActiveTab(0)}
          >
            Scenes
          </div>
          {/* <div
            className={`tab${activeTab === 1 ? ' active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            Media
          </div>
          <div
            className={`tab${activeTab === 2 ? ' active' : ''}`}
            onClick={() => setActiveTab(2)}
          >
            History
          </div> */}
        </div>

        <div className="right-aligned">
          <Dropdown
            transitionName=""
            className="scene-sort-dropdown"
            trigger={['click']}
            overlay={
              <div className="scene-order-types">
                {sceneOrderTypes.map((type, index) => (
                  <div
                    key={index}
                    onClick={() => onSceneOrderTypeChange(index)}
                  >
                    {type}
                  </div>
                ))}
              </div>
            }
          >
            <div>
              <span>{sceneOrderTypes[sceneOrderType]}</span>
              <img
                className="arrow"
                src="/menu/sidebar/arrow-down.svg"
                alt="arrow"
              />
            </div>
          </Dropdown>
          {/* <div
            className="view-icon"
            onClick={() =>
              view === 'list' ? setView('grid') : setView('list')
            }
          > */}
          {view === 'list' ? (
            <img
              className="view-icon"
              onClick={() =>
                view === 'list' ? setView('grid') : setView('list')
              }
              src="/menu/grid.svg"
              alt="Link for the grid list page"
            />
          ) : (
            <img
              className="view-icon"
              onClick={() =>
                view === 'list' ? setView('grid') : setView('list')
              }
              src="/menu/list.svg"
              alt="Link for the list page"
            />
          )}
          {/* </div> */}
          {/* <div className="" onClick={() => setView('list')}>
                  <span>
                    <img src="/menu/list.svg" alt="Link for the list page" />
                  </span>
                </div> */}

          {/* {selectedProjectId && <ProjectSettings />} */}
          {showProjectSettings && <ProjectSettings />}
        </div>
      </div>
    </div>
  );
};
