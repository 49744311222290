import useDebounce from 'common/hooks/useDebounce';
import { useEffect, useState } from 'react';

/**
 *  This hook fires the onBottomReached callback once the scroll bar
 *  attached to the ref has reached the bottom.
 *
 * @param {Ref} ref
 * @param {Function} onBottomReached
 */
const useScrollReachBottom = (
  ref,
  onBottomReached = () => {},
  dependencies?
) => {
  const checkBottomReached = (component) => {
    const { scrollTop, scrollHeight, clientHeight } = component;
    return scrollTop + clientHeight >= scrollHeight - (1 * scrollHeight) / 100;
  };

  // const [bottomReached, setBottomReached, bottomReachedDebounced] =
  //   useDebouncedState(false, 1000);

  const [bottomReached, setBottomReached] = useState({ v: false });

  const [bottomReachedDebounced]: any = useDebounce(bottomReached, 200);

  useEffect(() => {
    if (!ref) return;
    const component = ref.current;

    const listener = () => {
      if (!bottomReached.v && checkBottomReached(component)) {
        setBottomReached({ v: true });
        // onBottomReached();
      }
    };

    if (component) {
      component.addEventListener('scroll', listener);
    }
    return () => {
      if (component) {
        component.removeEventListener('scroll', listener);
      }
    };
  }, [ref, onBottomReached, bottomReached]);

  useEffect(() => {
    if (!ref) return;
    if (checkBottomReached(ref.current)) {
      // onBottomReached();
      // console.log('reached bottom');

      setBottomReached({ v: true });
    }
  }, [...(dependencies ?? []), ref]);

  // useEffect(() => {
  //   if (bottomReachedDebounced) {
  //     console.log('debounced eh bas');

  //     onBottomReached();
  //     setBottomReached(false);
  //   }
  // }, [bottomReachedDebounced]);

  useEffect(() => {
    if (bottomReachedDebounced.v) {
      onBottomReached();
      setBottomReached({ v: false });
    }
  }, [bottomReachedDebounced]);
};

export default useScrollReachBottom;
