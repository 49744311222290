import './ManageAddressablesModal.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Table } from 'antd';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { bundlesSelector } from 'redux/slices/bundles/selectors';
import { removeBundle } from 'redux/slices/bundles/actions';

const ManageAddressablesModal = ({ onClose = () => {} }) => {
  const dispatch = useDispatch();
  const data = useSelector(bundlesSelector)
    ?.map((bundle) => ({
      ...bundle,
      delete: bundle.key,
    }))
    .sort((a, b) => a.category.localeCompare(b.category));

  const totalSize =
    data
      ?.map(({ size }) => size)
      .reduce((prev = 0, cur = 0) => prev + cur, 0) ?? 0;

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Type',
      dataIndex: 'itemType',
      key: 'itemType',
      sorter: (a, b) => a.itemType.localeCompare(b.itemType),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (size) => `${(size / 1e9).toFixed(2)} GB`,
      sorter: (a, b) => a.size - b.size,
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (key) => (
        <FaTrash
          className="trash-icon"
          onClick={() => {
            if (key) {
              dispatch(removeBundle(key));
              (window as any).onBundleDelete(key);
            }
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="manage-addressables-modal"
      visible
      onCancel={onClose}
      footer={null}
    >
      <div className="title">
        <span>Manage Storage</span>
        <span className="total-size">{(totalSize / 1e9).toFixed(2)} GB</span>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={!data}
      ></Table>
    </Modal>
  );
};

export default ManageAddressablesModal;
