import { produce } from 'immer';
import { Action } from 'redux/common/types';
import { characterAction, CharacterSlice } from './types';

export const characterReducer = produce(
  (state: CharacterSlice = initialState, action: Action) => {
    switch (action.type) {
      case characterAction('SET', 'CHARACTER_ID'): {
        state.characterId = action.payload;
        return state;
      }

      case characterAction('SET', 'OPTIONS_TYPES'): {
        state.optionTypes = action.payload;
        return state;
      }

      case characterAction('ADD', 'OPTIONS'): {
        if (action.payload.optionType) {
          state.options[action.payload.optionType] = {
            ...state.options[action.payload.optionType],
            ...action.payload.options.items,
          };
        }
        return state;
      }

      case characterAction('SET', 'SELECTED_OPTION'): {
        if (action.payload.optionType) {
          state.selectedOptions[action.payload.optionType] =
            action.payload.value;
        }
        return state;
      }

      case characterAction('SET', 'BASE_CHARACTERS'): {
        state.options.baseCharacters = {
          ...state.options.baseCharacters,
          ...action.payload.items,
        };
        return state;
      }

      default: {
        return state;
      }
    }
  }
);

const initialState: CharacterSlice = {
  characterId: null,
  optionTypes: null,
  options: {},
  selectedOptions: {},
  baseCharacters: null,
};
