import { firebaseReducer } from 'react-redux-firebase';
import { appReducer } from './slices/app/reducer';
import { reducer as formReducer } from 'redux-form';
import { authReducer } from './slices/auth/authReducer';
import { clipsReducer } from './slices/clips/reducer';
import { commentsReducer } from './slices/comments/reducer';
import { itemsReducer } from './slices/items/reducer';
import { loadersReducer } from './slices/loaders/loadersReducer';
import { projectReducer } from './slices/project/reducer';
import { itemGalleryReducer } from './slices/itemGallery/reducer';
import { scenesReducer } from './slices/scenes/reducer';
import { bundlesReducer } from './slices/bundles/reducer';
import { curvesReducer } from './slices/curves/reducer';
import { settingsReducer } from './slices/settings/reducer';
import { characterReducer } from './slices/character/reducer';
import { teamReducer } from './slices/team/reducer';
import { stripeReducer } from './slices/stripe/reducer';
import { DownloadsReducer } from './slices/downloads/reducer';
import { blueprintsReducer } from './slices/blueprints/reducer';

const { combineReducers } = require('redux');

export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  app: appReducer,
  auth: authReducer,
  blueprint: blueprintsReducer,
  settings: settingsReducer,
  comments: commentsReducer,
  clips: clipsReducer,
  items: itemsReducer,
  character: characterReducer,
  itemsGallery: itemGalleryReducer,
  loaders: loadersReducer,
  project: projectReducer,
  scenes: scenesReducer,
  bundles: bundlesReducer,
  curves: curvesReducer,
  team: teamReducer,
  stripe: stripeReducer,
  form: formReducer,
  downloads: DownloadsReducer,
});
