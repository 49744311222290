export const mapRolesToInt = (role) => {
    switch (role) {
        case "Admin":
            return 0
        case "Editor":
            return 1
        case "Reviewer":
            return 2
    }
} 

export const mapRolesToString = (role) => {
    switch (role) {
        case 0:
            return "Admin"
        case 1:
            return "Editor"
        case 2:
            return "Reviewer"
    }
} 