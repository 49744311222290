import './ListExtras.css';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import { removeTeamMember } from 'redux/slices/team/actions';
import {
  makeAdmin,
  makeBillingAdmin,
  updateTeamMemberRole,
} from 'redux/slices/app/actions';

export const mapRolesInTeam = (role) => {
  switch (role) {
    case 0:
      return 'Admin';
    case 1:
      return 'Editor';
    case 2:
      return 'Reviewer';
  }
};

const roles = ['Editor', 'Reviewer'];

const ListExtras = ({
  userId,
  roleInTeam,
  isAdmin,
  isBillingAdmin,
  showOptions,
}) => {
  const dispatch = useDispatch();
  const teamId = useSelector(selectedTeamIdSelector);
  // const isAdmin = useSelector(isAdminSelector);

  const changeRole = (role) => {
    // dispatch(editUserRoleInTeam(userId, teamId, role));
    dispatch(updateTeamMemberRole(userId, teamId, role));
  };

  const onMakeAdminClick = () => {
    dispatch(makeAdmin(teamId, userId));
  };

  const onMakeBillingAdminClick = () => {
    dispatch(makeBillingAdmin(teamId, userId));
  };

  const deleteMember = (userId) => {
    dispatch(removeTeamMember(userId, teamId));
  };

  const rolesMenu = (
    <Menu>
      {roles.map((role) => {
        return (
          <Menu.Item key={role} onClick={() => changeRole(role)}>
            {role}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const editUserBtn = (
    <Dropdown transitionName="" overlay={rolesMenu} trigger={['click']}>
      <span className="edit-team-member-btn"> Edit User </span>
    </Dropdown>
  );

  const removeUserBtn = (
    <Popconfirm
      title="Are you sure remove this member?"
      onConfirm={() => deleteMember(userId)}
      okText="Yes"
      cancelText="No"
    >
      <span className="remove-team-member-btn"> Remove </span>
    </Popconfirm>
  );
  return (
    <div className="d-flex">
      <span className="team-member-role"> {mapRolesInTeam(roleInTeam)} </span>
      {showOptions && (
        <>
          {!isAdmin && (
            <Button
              onClick={onMakeAdminClick}
              style={{ backgroundColor: 'rgba(0,0,0,0)', marginLeft: '1rem' }}
            >
              Make Admin
            </Button>
          )}
          {!isBillingAdmin && (
            <Button
              onClick={onMakeBillingAdminClick}
              style={{ backgroundColor: 'rgba(0,0,0,0)', marginLeft: '1rem' }}
            >
              Make Billing Admin
            </Button>
          )}
          {editUserBtn}
          {removeUserBtn}
        </>
      )}
    </div>
  );
};

export default ListExtras;
