import './BacklotDownloads.scss';
import _ from 'lodash';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FaPauseCircle, FaPlay, FaPlayCircle } from 'react-icons/fa';
import { IoIosCloseCircle, IoMdCheckmarkCircle } from 'react-icons/io';
import { addDownloadItem, togglePause } from 'redux/slices/downloads/actions';
import { useDispatch, useSelector } from 'react-redux';
import { downloadsSelector } from 'redux/slices/downloads/selectors';
const { TabPane } = Tabs;

type itemType =
  | 'CameraItems'
  | 'LightItems'
  | 'CharacterItems'
  | 'PropItems'
  | 'EnvironmentItems'
  | 'Templates';

type download = {
  id: string;
  type: itemType;
  name: string;
  progress: number;
  downloaded: string;
  paused: boolean;
  isOwner: number;
  isPack: number; 
};

const displayItem: { [key: string]: { name: string; imgSrc: string } } = {
  CameraItems: { name: 'Camera', imgSrc: '/backlot-library/home/camera.svg' },
  LightItems: { name: 'Light', imgSrc: '/backlot-library/home/light.svg' },
  CharacterItems: {
    name: 'Character',
    imgSrc: '/backlot-library/home/character.svg',
  },
  PropItems: { name: 'Prop', imgSrc: '/backlot-library/home/prop.svg' },
  EnvironmentItems: {
    name: 'Environment',
    imgSrc: '/backlot-library/home/environment.svg',
  },
  Templates: {
    name: 'Template',
    imgSrc: '/backlot-library/home/template.svg',
  },
};

const initState: download[] = [
  {
    id: '0',
    type: 'EnvironmentItems',
    name: 'Urban City Streets',
    progress: 54,
    downloaded: '5.3GB/ 16.1GB',
    paused: false,
    isOwner: 0,
    isPack: 0,
  },
  {
    id: '1',
    type: 'PropItems',
    name: 'Furniture Pack',
    progress: 100,
    downloaded: '0.5GB/ 1.65GB',
    paused: false,
    isOwner: 0,
    isPack: 1,
  },
  {
    id: '2',
    type: 'CharacterItems',
    name: 'Jessica',
    progress: 100,
    downloaded: '0.2GB/ 0.4GB',
    paused: false,
    isOwner: 1,
    isPack: 0,
  },
];

const BacklotDownloads = () => {
  const downloads = useSelector(downloadsSelector);
  const dispatch = useDispatch()
  const userDownloads = downloads.filter(({ isOwner, isPack }) => isOwner === 1 && isPack === 0);
  const downloadedPacks = downloads.filter(({ isPack }) => isPack === 1);
  // const runningDownloads = downloads.filter(({ progress }) => progress < 100);
  // const finishedDownloads = downloads.filter(({ progress }) => progress >= 100);

  /** Removes items with progress >= 100 */
  // useEffect(() => {
  //   const filteredDownloads = downloads.filter(
  //     ({ progress }) => progress < 100
  //   );
  //   if (filteredDownloads.length !== downloads.length)
  //     setDownloads(filteredDownloads);
  // }, [downloads]);

  // const setDownloadItem = (newId, name, type, progress, downloaded , isOwner, isPack) => {
  //   const itemIndex = downloads.findIndex(({ id }) => id === newId);
  //   // Add new item
  //   console.log({ newId, name, type, progress, downloaded, isOwner, isPack, itemIndex } , "set download items")
  //   if (itemIndex === -1) {
  //     const newDownloads: download[] = [
  //       ...downloads,
  //       { id: newId, name, type, progress, downloaded, paused: false , isOwner, isPack},
  //     ];
  //     setDownloads(newDownloads);
  //   }
  //   // Update existing item
  //   else {
  //     const updatedItem: download = {
  //       ...downloads[itemIndex],
  //       name,
  //       type,
  //       progress,
  //       downloaded,
  //     };
  //     let newDownloads: download[] = [
  //       ...downloads.slice(0, itemIndex),
  //       updatedItem,
  //       ...downloads.slice(itemIndex + 1, downloads.length),
  //     ];
  //     setDownloads(newDownloads);
  //   }
  // };

  useEffect(() => {
    const downloadItem = (e) => {
      const { id, name, type, progress, downloaded, isOwner, isPack } = e.params;
      //console.log({ id, name, type, progress, downloaded, isOwner, isPack } , "use effect")
      dispatch(addDownloadItem(id, name, type, +progress, downloaded, isOwner, isPack));
    };

    window.addEventListener('downloadItem', downloadItem);

    return () => {
      window.removeEventListener('downloadItem', downloadItem);
    };
  }, [window, downloads]);

  // const togglePause = (itemId) => {
  //   const itemIndex = downloads.findIndex(({ id }) => id === itemId);
  //   if (itemIndex >= 0) {
  //     const newDownloads = _.cloneDeep(downloads);
  //     newDownloads[itemIndex] = {
  //       ...downloads[itemIndex],
  //       paused: !downloads[itemIndex].paused,
  //     };
  //     setDownloads(newDownloads);
  //   }
  // };

  const renderEmptyDownloads = (
    <div className="empty-downloads">No downloads</div>
  );

  const renderDownloads = (downloads) => downloads.map(
    ({ id, name, type, downloaded, progress, paused }) => (
      <li key={id} className="download-item" onClick = {()=>{(window as any).SelectDownloadItem(id);}}>
        <div className="icon">
          <img src={displayItem[type]?.imgSrc} alt="item type" />
        </div>
        <div className="details">
          <div className="name">
            {name}
            {progress >= 100 && <IoMdCheckmarkCircle />}
          </div>
          <div className="type">{displayItem[type]?.name}</div>
          {progress < 100 && (
            <>
              <div className="progress-bar">
                <ProgressBar now={progress} />
              </div>
              <div className="info">
                <div className="percentage">{`${progress} %`}</div>
                <div className="downloaded">{downloaded}</div>
              </div>
            </>
          )}
        </div>

        {progress < 100 && (
          <div className="options">
            <div className="pause">
              {paused ? (
                <FaPlayCircle
                  size={12}
                  onClick={() => {
                    (window as any).resumeDownload(id);
                    dispatch(togglePause(id));
                  }}
                />
              ) : (
                <FaPauseCircle
                  size={12}
                  onClick={() => {
                    (window as any).pauseDownload(id);
                    dispatch(togglePause(id));
                  }}
                />
              )}
            </div>
            <div className="abort">
              <IoIosCloseCircle
                size={14}
                onClick={() => (window as any).abortDownload(id)}
              />
            </div>
          </div>
        )}
      </li>
    )
  );

  return (
    <div className="backlot-downloads">
      <h1 className="title">Downloads</h1>
      <Tabs className="tab"  defaultActiveKey="1" centered size="small" tabBarGutter = {8} >
        <TabPane tab="ALL" key="1">
          {downloads.length > 0 ? <ul>{renderDownloads(downloads)}</ul> : renderEmptyDownloads}
        </TabPane>
        <TabPane tab="User Downloads" key="2">
          {userDownloads.length > 0 ? <ul>{renderDownloads(userDownloads)}</ul> : renderEmptyDownloads}
        </TabPane>
        <TabPane tab="Downloaded Packs" key="3">
          {downloadedPacks.length > 0 ? <ul>{renderDownloads(downloadedPacks)}</ul> : renderEmptyDownloads}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default BacklotDownloads;
