import './OpenSceneModal.scss';
import Modal from 'antd/lib/modal/Modal';
import Loader from 'common/components/loader/Loader';
import React from 'react';

type propTypes = {
  onClose?: () => void;
  onEnter?: () => void;
  thumbnail?: string;
  title?: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
};

const OpenSceneModal = ({
  onClose = () => {},
  onEnter = () => {},
  thumbnail = '/empty-scene.jpg',
  title = '',
  loading = false,
  loadingText = '',
  disabled = false,
}: propTypes) => {
  const renderLoading = (
    <div className="loading">
      <Loader spinnerSize={16} spinnerColor="#949494" />
      <p>{loadingText}</p>
    </div>
  );

  const renderFooter = (
    <form
      className="footer"
      onSubmit={(e) => {
        e.preventDefault();
        onEnter();
      }}
    >
      <button className="cancel" type="button" onClick={onClose}>
        Back
      </button>
      <button
        className="enter"
        type="submit"
        // onClick={onEnter}
        disabled={disabled}
      >
        Open Scene
      </button>
    </form>
  );

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="open-scene-modal"
      visible
      onCancel={onClose}
      footer={null}
      title={null}
    >
      <div
        className="hero"
        // style={{
        //   backgroundImage: `url("${thumbnail}")`,
        // }}
      >
        <img src={thumbnail}></img>
        <h1>{title}</h1>
      </div>
      <div>{loading ? renderLoading : renderFooter}</div>
    </Modal>
  );
};

export default OpenSceneModal;
