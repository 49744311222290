import React, { useEffect, useRef, useState } from 'react';
import './Project.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableScenesSelector,
  inPersonalDriveSelector,
  projectSelector,
  projectSortableSelector,
  scenesOrderingSelector,
  selectedProjectIdSelector,
  selectedProjectNameSelector,
  selectedProjectStatusSelector,
  sharedProjectsSelector,
  themeSelector,
  userRoleInTeam,
  usersPublicSelector,
} from 'redux/slices/app/selectors';
import { Redirect } from 'react-router-dom';
import ScenesGrid from './scenes/grid/ScenesGrid';
import ScenesList from './scenes/list/ScenesList';
import { usePrevious } from 'common/hooks/usePrevious';
import { InvalidProjectOverlay } from './invalid-project-overlay/InvalidProjectSubModal';
import { TopBar } from './TopBar';
import _ from 'lodash';
import { reorderScenes, setSortableProject } from 'redux/slices/app/actions';

const Project = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const theme = useSelector(themeSelector);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  const userRole = useSelector(userRoleInTeam);
  const availableScenes = useSelector(availableScenesSelector) ?? [];
  const projectName = useSelector(selectedProjectNameSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const project = useSelector(projectSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const scenesOrdering = useSelector(scenesOrderingSelector);

  const [activeTab, setActiveTab] = useState(0);

  const [sceneOrderType, setSceneOrderType] = useState(0);

  const projectSubscriptionIsValid = useSelector(selectedProjectStatusSelector);
  const sortableProject = useSelector(projectSortableSelector);
  const sharedProjects = useSelector(sharedProjectsSelector);
  const [scenesOrderingState, setScenesOrderingState] = useState({});

  const inSharedProject = sharedProjects && projectId in sharedProjects;

  const usersPublicData = useSelector(usersPublicSelector) ?? {};
  // const scenes = useSelector(scenesSelector);
  const prevAvailableScenes =
    usePrevious<typeof availableScenes>(availableScenes);
  const [view, setView] = useState('grid');

  const projectMembers = Object.keys(project?.members ?? {}).map((id) => ({
    id,
    name: (project?.members ?? {})[id].name,
    thumbnail: (usersPublicData[id] ?? {}).thumbnail,
  }));

  useEffect(() => {
    let newAvailableScenes = false;
    availableScenes?.forEach(({ id }) => {
      const index = prevAvailableScenes?.findIndex(({ id: id1 }) => id === id1);
      if (index === -1) {
        newAvailableScenes = true;
      }
    });
    if (newAvailableScenes && containerRef.current) {
      (containerRef.current as any).scrollTop = (
        containerRef.current as any
      ).scrollHeight;
    }
  }, [containerRef, availableScenes, prevAvailableScenes]);

  useEffect(() => {
    if (sortableProject) {
      setSceneOrderType(1);
      setView('list');
    }
  }, [sortableProject]);

  useEffect(() => {
    if (sceneOrderType !== 1) {
      dispatch(setSortableProject(false));
    }
  }, [sceneOrderType]);

  // Reset scenes order
  useEffect(() => {
    setScenesOrderingState(_.cloneDeep(scenesOrdering ?? {}));
  }, [scenesOrdering]);

  if (userRole === 'Reviewer') return <Redirect to="/menu/not-available" />;

  let sortedScenes = availableScenes;

  switch (sceneOrderType) {
    // Sort by recently updated
    case 0: {
      sortedScenes = availableScenes.sort((scene1, scene2) => {
        return (scene2.lastModified ?? 0) - (scene1.lastModified ?? 0);
      });
      break;
    }

    // Sort by customized order
    case 1: {
      sortedScenes = availableScenes.sort(({ id: id1 }, { id: id2 }) => {
        return (scenesOrdering ?? {})[id1] - (scenesOrdering ?? {})[id2];
      });
      break;
    }

    // Sort by scene name
    case 2: {
      sortedScenes = availableScenes.sort((scene1, scene2) => {
        const a = ((scene1.name as string) ?? '').trim().substr(4).trim();
        const b = ((scene2.name as string) ?? '').trim().substr(4).trim();
        return a.localeCompare(b);
      });
      break;
    }
  }

  if (sortableProject)
    sortedScenes = [...availableScenes].sort(
      ({ id: id1, name: name1 = '' }, { id: id2, name: name2 = '' }) => {
        if (
          !scenesOrderingState ||
          (!scenesOrderingState[id1] && !scenesOrderingState[id2])
        )
          return name1.localeCompare(name2);
        if (scenesOrderingState[id1] === undefined) return 1;
        if (scenesOrderingState[id2] === undefined) return -1;
        return scenesOrderingState[id1] - scenesOrderingState[id2];
      }
    );

  const onSceneIndexUpdate = (currentSceneId, oldIndex, newIndex) => {
    const newOrdering = _.cloneDeep(scenesOrderingState);
    sortedScenes.forEach((scene, index) => {
      if (!newOrdering[scene.id]) {
        newOrdering[scene.id] = index + 1;
      }
    });

    let otherSceneId: any = null;
    for (const id in newOrdering) {
      if (newOrdering[id] === newIndex) otherSceneId = id;
    }

    if (otherSceneId)
      setScenesOrderingState({
        ...newOrdering,
        [currentSceneId]: newIndex,
        [otherSceneId]: oldIndex,
      });
  };

  const onSceneOrderSubmit = () => {
    dispatch(setSortableProject(false));
    dispatch(reorderScenes(projectId, scenesOrderingState));
  };

  return (
    <>
      <InvalidProjectOverlay
        visible={!projectSubscriptionIsValid && inSharedProject}
      >
        <div className={`screen ${theme}`} ref={containerRef}>
          <div
            className="screen-container"
            style={!project ? { display: 'none' } : {}}
          >
            {selectedProjectId && (
              <TopBar
                title={projectName}
                members={projectMembers}
                view={view}
                setView={setView}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                sceneOrderTypes={[
                  'Recently Updated',
                  'Scene Order',
                  'Scene Name',
                ]}
                sceneOrderType={sceneOrderType}
                onSceneOrderTypeChange={setSceneOrderType}
                showMembers={selectedProjectId && !inPersonalDrive}
              />
            )}
            {view === 'grid' ? (
              <ScenesGrid scenes={sortedScenes} />
            ) : (
              <ScenesList
                scenes={sortedScenes}
                onSceneIndexUpdate={onSceneIndexUpdate}
                onSceneOrderSubmit={onSceneOrderSubmit}
              />
            )}
          </div>
        </div>
      </InvalidProjectOverlay>
    </>
  );
};

export default Project;
