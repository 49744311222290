import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import {
  curveOffsetsSelector,
  curveScreenSelector,
  curveXRangeSelector,
  curveYRangeSelector,
} from 'redux/slices/curves/selectors';

// const maxWidth = 1200;
// const maxHeight = 900;

type TKeyFrameType = 'mirrored' | 'reverse-mirrored' | 'free';
type TCurveType = 'linear' | 'smooth' | 'constant' | 'weighted';

/**
 * 'l' for left handle points
 * 'r' for right handle points
 * 'k' for keyframe points
 */
type TDraggingType = 'l' | 'r' | 'k' | null;

type keyFrame = {
  position: { x: number; y: number };
  left?: { x: number; y: number };
  right?: { x: number; y: number };
  type: TKeyFrameType;
  curveType: TCurveType;
};

export const Curve = ({
  curveColor = 'black',
  curveIndex,
  isDragging,
  setIsDragging,
  mouseX,
  mouseY,
  initialKeyFrames = null as any,
  calcMousePos = ({ x, y }) => ({ x, y }),
}) => {
  const { min: minX, max: maxX } = useSelector(curveXRangeSelector);
  const { min: minY, max: maxY } = useSelector(curveYRangeSelector);
  const screen = useSelector(curveScreenSelector);

  const [draggingIndex, setDraggingIndex] = useState(-1);

  const [draggingType, setDraggingType] = useState<TDraggingType>(null);

  const [keyFrames, setKeyFrames] = useState<keyFrame[] | null>(
    initialKeyFrames
  );

  const reactToUnity = useCallback(
    (keyFrame: keyFrame) => {
      const transformedFrame = _.cloneDeep(keyFrame);

      const transformPoint = ({ x, y }) => {
        const ret = { x: 0, y: 0 };
        ret.x = x / (window.innerWidth / (maxX - minX)) + minX;
        ret.y = -y + window.innerHeight;
        ret.y = ret.y / (window.innerHeight / (maxY - minY)) + minY;
        return ret;
      };

      transformedFrame.position = transformPoint(transformedFrame.position);
      if (transformedFrame.left)
        transformedFrame.left = transformPoint(transformedFrame.left);
      if (transformedFrame.right)
        transformedFrame.right = transformPoint(transformedFrame.right);

      (window as any).onCurveEdit(curveIndex, draggingIndex, transformedFrame);
    },
    [curveIndex, draggingIndex, minX, maxX, minY, maxY, screen]
  );

  useEffect(() => {
    setKeyFrames(initialKeyFrames);
  }, [initialKeyFrames]);

  // validates the bounds of all keyframes + handles
  const validateKeyframes = (keyFrames: keyFrame[]) => {
    keyFrames.forEach((frame, index) => {
      const prevFrame = index - 1 >= 0 ? keyFrames[index - 1] : null;
      const nextFrame =
        index + 1 < keyFrames.length ? keyFrames[index + 1] : null;

      if (prevFrame && frame.position.x <= prevFrame.position.x) {
        frame.position.x = prevFrame.position.x + 1;
      }

      if (nextFrame && frame.position.x >= nextFrame.position.x) {
        frame.position.x = nextFrame.position.x - 1;
      }

      if (frame.left) {
        if (prevFrame && frame.left.x <= prevFrame?.position.x)
          frame.left.x = prevFrame.position.x + 1;
        else if (frame.left?.x >= frame.position.x) {
          frame.left.x = frame.position.x - 1;
        }
      }
      if (frame.right) {
        if (nextFrame && frame.right.x >= nextFrame.position.x)
          frame.right.x = nextFrame.position.x - 1;
        else if (frame.right.x <= frame.position.x)
          frame.right.x = frame.position.x + 1;
      }

      if (frame.type === 'reverse-mirrored' && frame.left && frame.right) {
        const dxl = frame.position.x - frame.left.x;
        const dxr = frame.right.x - frame.position.x;

        const dyl = frame.position.y - frame.left.y;
        const dyr = frame.right.y - frame.position.y;

        if (dxl < dxr) {
          frame.right.x = frame.position.x + dxl;
        } else if (dxr < dxl) {
          frame.left.x = frame.position.x - dxr;
        }

        if (dyl < dyr) {
          frame.right.y = frame.position.y + dyl;
        } else if (dyr < dyl) {
          frame.left.y = frame.position.y - dyr;
        }
      }

      if (frame.type === 'mirrored' && frame.left && frame.right) {
        const dxl = frame.position.x - frame.left.x;
        const dxr = frame.right.x - frame.position.x;

        const dyl = frame.position.y - frame.left.y;
        const dyr = frame.position.y - frame.right.y;

        if (dxl < dxr) {
          frame.right.x = frame.position.x + dxl;
        } else if (dxr < dxl) {
          frame.left.x = frame.position.x - dxr;
        }

        if (dyl < dyr) {
          frame.right.y = frame.position.y - dyl;
        } else if (dyr < dyl) {
          frame.left.y = frame.position.y - dyr;
        }
      }
    });
    setKeyFrames(keyFrames);
  };

  // Drag points
  useEffect(() => {
    if (isDragging && keyFrames) {
      let newKeyFrames = _.cloneDeep(keyFrames);
      const frame = keyFrames[draggingIndex];
      if (draggingType === 'l') {
        (newKeyFrames[draggingIndex].left as any).y = mouseY;
        (newKeyFrames[draggingIndex].left as any).x = mouseX;

        const dx = frame.position.x - mouseX;
        const dy = frame.position.y - mouseY;
        if (frame.type === 'reverse-mirrored' && frame.right) {
          (newKeyFrames[draggingIndex].right as any).y = frame.position.y + dy;
          (newKeyFrames[draggingIndex].right as any).x = frame.position.x + dx;
        } else if (frame.type === 'mirrored' && frame.right) {
          (newKeyFrames[draggingIndex].right as any).y = frame.position.y - dy;
          (newKeyFrames[draggingIndex].right as any).x = frame.position.x + dx;
        }
      } else if (draggingType === 'r') {
        (newKeyFrames[draggingIndex].right as any).y = mouseY;
        (newKeyFrames[draggingIndex].right as any).x = mouseX;

        const dx = frame.position.x - mouseX;
        const dy = frame.position.y - mouseY;
        if (frame.type === 'reverse-mirrored' && frame.left) {
          (newKeyFrames[draggingIndex].left as any).y = frame.position.y + dy;
          (newKeyFrames[draggingIndex].left as any).x = frame.position.x + dx;
        } else if (frame.type === 'mirrored' && frame.left) {
          (newKeyFrames[draggingIndex].left as any).y = frame.position.y - dy;
          (newKeyFrames[draggingIndex].left as any).x = frame.position.x + dx;
        }
      } else if (draggingType === 'k') {
        const dy = mouseY - frame.position.y;
        const dx = mouseX - frame.position.x;

        const cornerFrame = false;

        if (!cornerFrame) newKeyFrames[draggingIndex].position.x = mouseX;
        newKeyFrames[draggingIndex].position.y = mouseY;

        if (newKeyFrames[draggingIndex].left)
          (newKeyFrames[draggingIndex].left as any).y += dy;
        if (newKeyFrames[draggingIndex].right)
          (newKeyFrames[draggingIndex].right as any).y += dy;

        if (!cornerFrame) {
          if (newKeyFrames[draggingIndex].left)
            (newKeyFrames[draggingIndex].left as any).x += dx;
          if (newKeyFrames[draggingIndex].right)
            (newKeyFrames[draggingIndex].right as any).x += dx;
        }
      }
      validateKeyframes(newKeyFrames);
    }
  }, [mouseX, mouseY]);

  // Drop dragging
  useEffect(() => {
    if (!isDragging) {
      if (draggingIndex !== -1 && keyFrames) {
        reactToUnity(_.cloneDeep(keyFrames[draggingIndex]));
      }
      setDraggingIndex(-1);
      setDraggingType(null);
    }
  }, [isDragging]);

  const makeConstant = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].curveType = 'constant';
      validateKeyframes(newKeyFrames);
    }
  };

  const makeLinear = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].curveType = 'linear';
      validateKeyframes(newKeyFrames);
    }
  };

  const makeWeighted = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].curveType = 'weighted';
      validateKeyframes(newKeyFrames);
    }
  };

  const makeMirrored = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].type = 'mirrored';
      validateKeyframes(newKeyFrames);
    }
  };

  const makeReversMirrored = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].type = 'reverse-mirrored';
      validateKeyframes(newKeyFrames);
    }
  };

  const makeFree = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames[index].type = 'free';
      validateKeyframes(newKeyFrames);
    }
  };

  const deleteKeyFrame = (index) => {
    if (keyFrames) {
      const newKeyFrames = _.cloneDeep(keyFrames);
      newKeyFrames.splice(index, 1);
      validateKeyframes(newKeyFrames);
    }
  };

  const keyFrameMenu = (index) => (
    <Menu>
      <Menu.Item key="1" onClick={() => makeMirrored(index)}>
        Mirror
      </Menu.Item>
      <Menu.Item key="2" onClick={() => makeReversMirrored(index)}>
        Reverse Mirror
      </Menu.Item>
      <Menu.Item key="3" onClick={() => makeFree(index)}>
        Free
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => deleteKeyFrame(index)}
        style={{ color: 'red' }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const curveMenu = (index) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          makeConstant(index);
        }}
      >
        Constant
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          makeLinear(index);
        }}
      >
        Linear
      </Menu.Item>
      <Menu.Item key="3">Smooth</Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          makeWeighted(index);
        }}
      >
        Weighted
      </Menu.Item>
    </Menu>
  );

  const renderKeyFrames = keyFrames?.map((keyFrame, index) => (
    <Dropdown
      transitionName=""
      overlay={keyFrameMenu(index)}
      trigger={['contextMenu']}
    >
      <circle
        className="keyframe"
        cx={keyFrame.position.x}
        cy={keyFrame.position.y}
        r={5}
        onMouseDown={(e) => {
          e.stopPropagation();
          setIsDragging(true);
          setDraggingIndex(index);
          setDraggingType('k');
        }}
      />
    </Dropdown>
  ));

  const renderHandles = keyFrames?.map((keyFrame, index) => {
    let leftHandle: any = null;
    let rightHandle: any = null;

    if (keyFrame.left && keyFrame.curveType === 'weighted') {
      leftHandle = (
        <>
          <path
            className="handle-line"
            d={`M${keyFrame.position.x} ${keyFrame.position.y} L${keyFrame.left.x} ${keyFrame.left.y}`}
            stroke="black"
            fill="none"
            strokeWidth="2px"
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsDragging(true);
              setDraggingIndex(index);
              setDraggingType('l');
            }}
          />

          <circle
            className="handle"
            r={5}
            cx={keyFrame.left.x}
            cy={keyFrame.left.y}
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsDragging(true);
              setDraggingIndex(index);
              setDraggingType('l');
            }}
          />
        </>
      );
    }

    const renderRightHandle =
      index + 1 < keyFrames.length &&
      keyFrames[index + 1].curveType === 'weighted' &&
      keyFrame.right;
    if (renderRightHandle) {
      rightHandle = (
        <>
          <path
            className="handle-line"
            d={`M${keyFrame.position.x} ${keyFrame.position.y} L${keyFrame.right?.x} ${keyFrame.right?.y}`}
            stroke="black"
            fill="none"
            strokeWidth="2px"
          />
          <circle
            className="handle"
            r={5}
            cx={keyFrame.right?.x}
            cy={keyFrame.right?.y}
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsDragging(true);
              setDraggingIndex(index);
              setDraggingType('r');
            }}
          />
        </>
      );
    }
    return (
      <>
        {leftHandle}
        {rightHandle}
      </>
    );
  });

  const renderCurve = keyFrames?.map((keyFrame, index) => {
    if (index === 0) return null;
    const prevKeyFrame = keyFrames[index - 1];
    let path = `M ${prevKeyFrame.position.x} ${prevKeyFrame.position.y} `;

    if (keyFrame.curveType === 'constant') {
      path += `L${keyFrame.position.x} ${prevKeyFrame.position.y} L${keyFrame.position.x} ${keyFrame.position.y}`;
    } else if (keyFrame.curveType === 'linear') {
      path += `L${keyFrame.position.x} ${keyFrame.position.y}`;
    } else if (keyFrame.curveType === 'weighted') {
      path += `C ${prevKeyFrame.right?.x} ${prevKeyFrame.right?.y} `;
      path += `${keyFrame.left?.x} ${keyFrame.left?.y}, ${keyFrame.position.x} ${keyFrame.position.y} `;
    }

    return (
      <Dropdown
        transitionName=""
        overlay={curveMenu(index)}
        trigger={['contextMenu']}
      >
        <path
          className="segment"
          d={path}
          stroke={curveColor}
          fill="none"
          strokeWidth="2px"
          onClick={(e) => {
            const newKeyFrames = _.cloneDeep(keyFrames);
            const mousePos = calcMousePos({ x: e.clientX, y: e.clientY });
            const newKeyFrame: keyFrame = {
              position: {
                x: mousePos.x,
                y: mousePos.y,
              },
              left: { x: mousePos.x - 30, y: mousePos.y },
              right: { x: mousePos.x + 30, y: mousePos.y },
              type: 'free',
              curveType: 'weighted',
            };
            newKeyFrames.splice(index, 0, newKeyFrame as any);
            // setKeyFrames(newKeyFrames);

            validateKeyframes(newKeyFrames);
          }}
        />
      </Dropdown>
    );
  });

  return (
    <>
      {renderCurve}
      {renderKeyFrames}
      {renderHandles}
    </>
  );
};
