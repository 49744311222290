import './SubscriptionExpiredModal.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useSelector } from 'react-redux';
import { inPersonalDriveSelector, personalDriveIsActiveSelector, selectedTeamIdSelector, selectedTeamIsActiveSelector, teamsSelector } from 'redux/slices/app/selectors';
import { userNameSelector } from 'redux/slices/auth/authSelectors';
import { useHistory } from 'react-router-dom';




const SubscriptionExpiredModal = ({ onClose = () => {}, onSwitchTeamSelect = () => {}}) => {
    const history = useHistory();
    const username = useSelector(userNameSelector);
    const teamId = useSelector(selectedTeamIdSelector);
    const teams = useSelector(teamsSelector);
    const inPersonalDrive = useSelector(inPersonalDriveSelector);
    const personalDriveIsActive = useSelector(personalDriveIsActiveSelector);
    const selectedTeamIsActive = useSelector(selectedTeamIsActiveSelector);
    const isActive = inPersonalDrive
      ? personalDriveIsActive
      : selectedTeamIsActive;

    const onBillingSelect = () => {
    history.push(`/settings/${teamId}?tab=billing`);
    onClose();
    };
    return (
        <Modal
          transitionName=""
          maskTransitionName=""
          className="subscription-expired-modal"
          visible
          title="Subscription Expired"
          footer={null}
          onCancel={!isActive ? undefined : onClose}
          closable = {false}
        >
            <div className = "info">
                Hi {username}, your subscription has failed to renew. Please update your payment method to continue.
            </div>
            <div className="open-btn">
                <button
                    onClick={onBillingSelect}
                >
                    Update Payment
                </button>
            </div>
            {teams && teams.length > 0 && (
                <div className="switch-drive"
                    onClick = {onSwitchTeamSelect}
                >
                    Select another drive using this email {">"}
                </div>
            )}
        </Modal>
    );
};



export default SubscriptionExpiredModal;