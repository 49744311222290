import './Sidebar.scss';
import React, { useEffect, useState } from 'react';
import { setSelectedProjectId } from 'redux/slices/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import NewProject from '../project/newProject/NewProject';
import {
  inPersonalDriveSelector,
  selectedProjectIdSelector,
  selectedTeamNameSelector,
  availableSharedProjectsArraySelector,
  teamsSelector,
  availableProjectsSelector,
  selectedTeamIdSelector,
  themeSelector,
  selectedTeamIsActiveSelector,
  personalDriveIsActiveSelector,
} from 'redux/slices/app/selectors';
import { logout } from 'redux/slices/auth/actions/logout';
import SwitchAccountModal from 'backlot-menu/components/modals/switch-account-modal/SwitchAccountModal';
import SubscriptionExpiredModal from 'backlot-menu/components/modals/subscription-expired-modal/SubscriptionExpiredModal';
import ManageAddressablesModal from 'backlot-menu/components/modals/manage-addressables-modal/ManageAddressablesModal';
import SettingsModal from 'backlot-menu/components/modals/settings-modal/SettingsModal';
import { Dropdown } from 'antd';
import { FaCaretDown } from 'react-icons/fa';
import { userNameSelector } from 'redux/slices/auth/authSelectors';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useSelector(themeSelector);
  const userName = useSelector(userNameSelector);
  const selectedTeamId = useSelector(selectedTeamIdSelector);

  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const [showProjects, setShowProjects] = useState(true);
  const [showSharedProjects, setShowSharedProjects] = useState(true);
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
  const [showSubscriptionExpiredModal, setSubscriptionExpiredModal] =
    useState(false);
  const [showManageStorageModal, setShowManageStorageModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const teamId = useSelector(selectedTeamIdSelector);
  const teams = useSelector(teamsSelector);

  // const projects = useSelector(projectsSelector) ?? [];
  const sharedProjects =
    useSelector(availableSharedProjectsArraySelector) ?? [];
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const teamName = useSelector(selectedTeamNameSelector);
  // const teamMembers = useSelector(teamMembersSelector);
  const availableProjects = useSelector(availableProjectsSelector) ?? [];
  const personalDriveIsActive = useSelector(personalDriveIsActiveSelector);
  const selectedTeamIsActive = useSelector(selectedTeamIsActiveSelector);
  const isActive = inPersonalDrive
    ? personalDriveIsActive
    : selectedTeamIsActive;
  //  Team sidebar actions
  useEffect(() => {
    if (selectedTeamId !== null) {
      dispatch(setSelectedProjectId(null));
      if (isActive !== null && !isActive) {
        setSubscriptionExpiredModal(true);
      }
    }
  }, [selectedTeamId, isActive]);

  const onLogout = () => {
    dispatch(logout());
  };

  const onProjectSelect = (projectId) => {
    dispatch(setSelectedProjectId(projectId));
    // dispatch(fetchProjectMembers(projectId, teamMembers));
  };

  const location = useLocation();
  const pathname = location.pathname;

  const renderedProjects = (projects) =>
    projects.map(({ name, id }, index) => {
      const selected = selectedProjectId === id;
      return (
        <div
          key={id}
          className={`widebar-container-content-box-open-container${
            selected ? ' selected' : ''
          }`}
          onClick={() => {
            onProjectSelect(id);
            if (pathname !== '//project') history.push(`/menu/project`);
          }}
        >
          <div className="title">{name}</div>
          <img
            className="widebar-container-content-box-open-icon"
            alt="selectIcon"
            src={'/assets/images/menu/Vector(3).svg'}
          ></img>
        </div>
      );
    });

  return (
    <>
      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}
      {showSwitchAccountModal && (
        <SwitchAccountModal
          firstSelectedTeam={teamId}
          onClose={() => setShowSwitchAccountModal(false)}
        />
      )}
      {showManageStorageModal && (
        <ManageAddressablesModal
          onClose={() => setShowManageStorageModal(false)}
        />
      )}
      {showSubscriptionExpiredModal && (
        <SubscriptionExpiredModal
          onClose={() => setSubscriptionExpiredModal(false)}
          onSwitchTeamSelect={() => {
            setSubscriptionExpiredModal(false);
            setShowSwitchAccountModal(true);
          }}
        />
      )}
      <div className={`widebar ${theme}`}>
        <div className="widebar-container">
          <img
            className="logo"
            alt="backlot logo"
            src="/assets/images/menu/teamsBar-logo.svg"
          />
          <div className="widebar-container-title">
            <div className="widebar-container-title-content">{teamName}</div>
          </div>
          <div className="widebar-container-content">
            <div
              className="widebar-container-content-box-close"
              onClick={() => history.push(`/menu/recent`)}
            >
              <img src="/menu/sidebar/time.svg" alt="time" />
              <div className="title">Recents</div>
            </div>

            {!inPersonalDrive && (
              <div
                className="widebar-container-content-box-close"
                onClick={() => history.push(`/menu/team`)}
              >
                <img src="/assets/images/menu/team.svg" />
                <div className="title">Team</div>
              </div>
            )}

            {inPersonalDrive && (
              <div
                className="widebar-container-content-box-close"
                onClick={() => {
                  // if (pathname !== "//project") history.push("/menu/project");
                  history.push(`/menu/shared-scenes`);
                  dispatch(setSelectedProjectId(null));
                }}
              >
                <img src="/assets/images/menu/shared.svg" alt="share" />
                <div className="title">Shared Scenes</div>
              </div>
            )}

            {inPersonalDrive && (
              <>
                <div
                  className="widebar-container-content-box-close"
                  onClick={() => setShowSharedProjects(!showSharedProjects)}
                >
                  <img src="/assets/images/menu/shared.svg" alt="share" />
                  <div className="title">Shared Projects</div>
                </div>
                {showSharedProjects && (
                  <div className="widebar-container-content-box-open">
                    {renderedProjects(sharedProjects)}
                  </div>
                )}
              </>
            )}

            <div
              className="widebar-container-content-box-close"
              onClick={() => setShowProjects(!showProjects)}
            >
              <img src="/assets/images/menu/projects.svg" />
              <div className="title">Projects</div>
            </div>
            {showProjects === true ? (
              <React.Fragment>
                <div className="widebar-container-content-box-open">
                  {renderedProjects(availableProjects)}
                </div>
                <div
                  className="widebar-container-content-box-open-form"
                  // onSubmit={handleSubmit(SubmitNewProject)}
                >
                  <NewProject />
                </div>
              </React.Fragment>
            ) : null}
          </div>
          <div className="flex-grow-1" />
          <Dropdown
            transitionName=""
            className="settings-drop"
            overlayClassName="settings-drop-menu"
            trigger={['click']}
            overlay={
              <div>
                <div
                  className="drop-row"
                  onClick={() => history.push(`/settings/${teamId}`)}
                >
                  Account Management
                </div>
                <div
                  className="drop-row"
                  onClick={() => {
                    (window as any).onSettingsOpen();
                    setShowSettingsModal(true);
                  }}
                >
                  Resolution
                </div>
                <div
                  className="drop-row"
                  onClick={() => setShowManageStorageModal(true)}
                >
                  Manage Storage
                </div>
                {teams && teams.length > 0 && (
                  <div
                    className="drop-row"
                    onClick={() => setShowSwitchAccountModal(true)}
                  >
                    Switch Team
                  </div>
                )}
                <div className="drop-row" onClick={onLogout}>
                  Logout
                </div>
              </div>
            }
          >
            <div>
              {userName}
              <img
                className="arrow"
                src="/menu/sidebar/arrow-down.svg"
                alt="arrow"
              />
              <img
                className="gear-icon"
                src="/menu/sidebar/gear.svg"
                alt="gear"
              />
            </div>
          </Dropdown>
          {/* <div
            className="widebar-container-content-box-open-text my-3"
            onClick={() => history.push(`/settings/${teamId}`)}
          >
            Account Settings
          </div>

          <div
            className="widebar-container-content-box-open-text my-3"
            onClick={() => setShowSettingsModal(true)}
          >
            App Settings
          </div>
          <div
            className="widebar-container-content-box-open-text my-3"
            // onClick={hideTeamBar}
            onClick={() => setShowManageStorageModal(true)}
          >
            Manage Storage
          </div>
          {teams && teams.length > 0 && (
            <div
              className="widebar-container-content-box-open-text my-3"
              // onClick={hideTeamBar}
              onClick={() => setShowSwitchAccountModal(true)}
            >
              Switch Account
            </div>
          )}
          <div
            className="widebar-container-content-box-open-text mb-3 logout-btn"
            onClick={onLogout}
          >
            Logout
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
