import './BacklotShare.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedSceneNameSelector,
  selectedTeamIdSelector,
  teamMembersSelector,
} from 'redux/slices/app/selectors';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { useFetchUserData } from 'common/hooks/useFetchUserData';
import {
  sceneGuestsSelector,
  userAccessSceneSelector,
} from 'redux/slices/scenes/selector';
import {
  inviteGuestToScene,
  revokeInvite,
  subscribeSceneUsers,
  unSubscribeSceneUsers,
} from 'redux/slices/scenes/actions';

const roleNumToStr = ['Admin', 'Editor', 'Reviewer', 'Guest'];

const BacklotShare = ({ match }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const teamId = useSelector(selectedTeamIdSelector);
  const sceneId = match.params.sceneId;
  const sceneName = useSelector(selectedSceneNameSelector);

  useFetchUserData(sceneId);

  const guests = useSelector(sceneGuestsSelector);
  //   const guests: any[] = [];
  //   for (let i = 0; i < 20; i++)
  //     guests.push({ userId: 'id', name: 'name', roleName: 'Admin' });
  const members = useSelector(teamMembersSelector) ?? [];
  const userAccessScene = useSelector(userAccessSceneSelector);

  useEffect(() => {
    if (sceneId) dispatch(subscribeSceneUsers(sceneId));
    return () => {
      dispatch(unSubscribeSceneUsers());
    };
  }, [dispatch, sceneId]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(inviteGuestToScene(email, sceneId));
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(
      `https://share.backlot.studio/dashboard/${sceneId}/clips`
    );
  };

  const renderGuests = guests.map(({ userId, name, roleName }) => (
    <div key={userId} className="user guest">
      <div className="name">{name}</div>
      <div className="role">
        <DropdownButton id="dropdown-item-button" title={roleName}>
          <Dropdown.Item
            as="button"
            onClick={() => dispatch(revokeInvite(sceneId, userId))}
          >
            Revoke Access
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  ));

  const renderMembers = members.map(({ id, name, role }) => (
    <div key={id} className="user member">
      <div className="name">{name}</div>
      <div className="role">{roleNumToStr[role]}</div>
    </div>
  ));

  return (
    <div id="backlot-share">
      {!userAccessScene ? (
        <div className="no-access">No Access</div>
      ) : (
        <React.Fragment>
          <div className="share-header">
            <div>
              <b>{`Share ${sceneName}`}</b>
            </div>
          </div>

          <div className="share-form">
            <form onSubmit={onSubmit}>
              <input
                type="email"
                placeholder="Invite a Reviewer..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
              <button className="btn btn-primary" type="submit">
                Invite
              </button>
            </form>
            <p>
              Reviewers are external members (outside your team) who can view &
              comment on scene content exported to the Backlot site.
            </p>
          </div>

          <div className="team">
            <div className="title">
              <b>Scene Access</b>
            </div>
            <div className="users">
              {renderGuests}
              {renderMembers}
            </div>
          </div>

          <div className="share-footer">
            <a onClick={copyUrlToClipboard}>
              <FaLink size={14} />
              Copy Link
            </a>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default BacklotShare;
