import _ from 'lodash';
import { createSelector } from 'reselect';
import { camerasSelector } from '../blueprints/selectors';

interface ClipsSlice {
  clips: {
    [cameraId: string]: {
      [versionTimestamp: string]: {
        url: string;
        thumbnail?: string;
        metadata?: {
          timestamp: string;
          userID: string;
          focalLength?: string;
          apreture?: string;
          model?: string;
        };
      };
    };
  };
}
export interface IClip {
  id: string;
  name?: string;
  versions: {
    versionId: string;
    url: string;
    userId: string;
    thumbnail: string;
    cameraModel?: string;
    focalLength?: string;
    aperture?: string;
  }[];
  cameraModel?: string;
  focalLength?: number;
  aperture?: number;
}

// export const collapsedClipsSelector = createSelector(
//   [(store: ClipsSlice) => store.clips?.clips, camerasSelector],
//   (clips, cameras) => {
//     // console.log(cameras);

//     if (!clips) return null;

//     const versions = Object.keys(clips ?? {});
//     let collapsedClips: { [clipId: string]: Clip } = {};
//     versions.forEach((versionId) => {
//       Object.values((clips ?? {})[versionId].videos ?? {}).forEach((clip) => {
//         // const camera = cameras.find(({ id }) => id === clip.shotId);
//         // console.log(clip);

//         const newClip = { ...clip, versions: 0 };
//         versions.forEach((version) => {
//           if (clips && clips[version].videos[newClip.shotId])
//             newClip.versions++;
//         });
//         // if (collapsedClips[newClip.shotId]) newClip.versions++;
//         collapsedClips = { ...collapsedClips, [newClip.shotId]: newClip };
//       });
//     });
//     return Object.values(collapsedClips);
//   }
// );

// export const clipsSelector = (state: ClipsSlice) => state.clips?.clips;
export const clipsSelector = (state: ClipsSlice) => state.clips;

export const clipsArraySelector = createSelector(
  clipsSelector,
  camerasSelector,
  (clips = {}, cameras = []) => {
    if (!clips) return null;
    const ret: { [clipId: string]: IClip } = {};
    Object.keys(clips).forEach((clipId) => {
      const camera = cameras.find(({ id }) => id === clipId);
      const versions: any[] = [];
      Object.keys(clips[clipId]).forEach((version) => {
        versions.push({
          versionId: version,
          url: clips[clipId][version].url,
          userId: clips[clipId][version].metadata?.userID,
          thumbnail: clips[clipId][version].thumbnail,
          cameraModel: clips[clipId][version].metadata?.model,
          focalLength: clips[clipId][version].metadata?.focalLength,
          aperture: clips[clipId][version].metadata?.apreture,
        });
      });

      ret[clipId] = {
        id: clipId,
        versions,
        name: camera?.name,
        cameraModel: (camera ?? { metadata: {} }).metadata['Camera Model'],
        focalLength: (camera?.dynamicMetadata['Focal Length'] ?? {})[0],
        aperture: (camera?.dynamicMetadata['Aperture'] ?? {})[0],
      };
    });
    return ret;
  }
);

export const clipsVersionsSelector = createSelector(
  clipsSelector,
  (clips = {}) => (!_.isEmpty(clips) ? Object.keys(clips) : [])
);

export const clipSelector = (version, clipId) =>
  createSelector(clipsSelector, (clips) =>
    clips && clips[version] ? clips[version].videos[clipId] : null
  );

// export const clipPathSelector = (clipId) => {
//   return createSelector(clipsSelector, (clips) =>
//     clips ? (clips[clipId] as any).url : null
//   );
// };

export const clipsTableDataSelector = createSelector(
  clipsSelector,
  (clips = {}) => {
    // if (!_.isEmpty(clips)) {
    //   let versions = objectToArray(clips);
    //   versions.sort(
    //     (a, b) => (new Date(a.id) as any) - (new Date(b.id) as any)
    //   );
    //   let lastVersion = versions[versions.length - 1];
    //   return objectToTableData(lastVersion.videos);
    // }
    return {};
  }
);

export const clipsShotDisplaySelector = createSelector(
  clipsSelector,
  (clips) => {
    let result = {};
    // for (const date in clips) {
    //   for (const key in clips[date].videos) {
    //     result[(clips[date].videos[key] as any).url] =
    //       clips[date].videos[key].shotDisplay;
    //   }
    // }
    return result;
  }
);

export const lastVersionSelector = createSelector(
  clipsSelector,
  (clips = {}) => {
    // if (!_.isEmpty(clips)) {
    //   let versions = objectToArray(clips);
    //   versions.sort(
    //     (a, b) => (new Date(a.id) as any) - (new Date(b.id) as any)
    //   );
    //   let lastVersion = versions[versions.length - 1];
    //   return lastVersion.id;
    // }
    return [];
  }
);

export const lastVersionClipsSelector = createSelector(
  lastVersionSelector,
  clipsSelector,
  // (lastVersion, clips = {}) => (lastVersion ? clips[lastVersion]?.videos : {})
  (lastVersion, clips = {}) => ({})
);

const objectToArray = (object) => {
  const result: any[] = [];
  for (const id in object) {
    result.push({ id, ...object[id] });
  }
  return result;
};

const objectToTableData = (object) => {
  const result: any[] = [];
  for (const id in object) {
    result.push({
      id,
      key: id,
      shotVideo: object[id].url,
      shotDescription: object[id].metadata
        ? object[id].metadata.shotDescription
        : '',
      shotType: object[id].metadata ? object[id].metadata.shotType : '',
      shotSize: object[id].metadata ? object[id].metadata.shotSize : '',
      movement: object[id].metadata ? object[id].metadata.movement : '',
      shotNum: id,
      index: object[id].index,
    });
  }
  return result;
};
