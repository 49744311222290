import React, { useEffect, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSceneVersions } from 'redux/slices/project/actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteScenePendingSelector } from 'redux/slices/loaders/loadersSelectors';
import {
  inPersonalDriveSelector,
  selectedProjectIdSelector,
  selectedSceneSelector,
  sharedProjectsSelector,
  sharedScenesSelector,
} from 'redux/slices/app/selectors';
import { FaEllipsisV } from 'react-icons/fa';
import VersionsModal from 'backlot-menu/components/modals/versionsModal/versionsModal';
import { removeScene, setSelectedSceneId } from 'redux/slices/app/actions';
import confirm from 'antd/lib/modal/confirm';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import DuplicateSceneModal from 'backlot-menu/components/modals/duplicateSceneModal/DuplicateSceneModal';
import RenameSceneModal from 'backlot-menu/components/modals/renameSceneModal/RenameSceneModal';
import { SharePersonalSceneModal } from 'backlot-menu/components/share/personal-share/SharePersonalSceneModal';
import { ShareTeamSceneModal } from 'backlot-menu/components/share/team-share/ShareTeamSceneModal';

const SceneOptions = ({ view, sceneId, sceneName }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showRenameSceneModal, setShowRenameSceneModal] = useState(false);
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [showShareSceneModal, setShowShareSceneModal] = useState(false);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  const userId = useSelector(userIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const deleteScenePending = useSelector(deleteScenePendingSelector);
  const scene = useSelector(selectedSceneSelector);
  const sharedProjects = useSelector(sharedProjectsSelector);
  const sharedScenes = useSelector(sharedScenesSelector);

  const userIsOwner = scene && scene.owner_id === userId;

  const inSharedProject = sharedProjects && projectId in sharedProjects;
  const inSharedScene = sharedScenes && sceneId in sharedScenes;

  const openShareScene = () => {
    setShowShareSceneModal(true);
    setShowDropdown(false);
  };

  const openSceneMembers = () => {
    if (inPersonalDrive) setShowShareSceneModal(true);
    else setShowMembersModal(true);
    setShowDropdown(false);
  };

  const onDuplicateScene = () => {
    setShowDropdown(false);
    setShowDuplicateModal(true);
  };

  const openViewHistory = () => {
    dispatch(fetchSceneVersions(sceneId));
    dispatch(setSelectedSceneId(sceneId));
    setShowDropdown(false);
    setShowVersionsModal(true);
  };

  const closeShareSceneModal = () => {
    setShowShareSceneModal(false);
  };

  const closeSceneMembers = () => {
    setShowMembersModal(false);
    // dispatch(emptySceneMembers());
  };

  const closeDuplicateModal = () => {
    setShowDuplicateModal(false);
  };

  // For hiding the dropdown after clicking ok on the popconfirm
  useEffect(() => {
    if (deleteScenePending === true) {
      setShowDropdown(false);
    }
  }, [deleteScenePending]);

  useEffect(() => {
    if (showDropdown) dispatch(setSelectedSceneId(sceneId));
  }, [showDropdown]);

  const menu = (
    <Menu>
      {/* {!(inSharedProject || inSharedScene) && (
        <Menu.Item key="0" onClick={openShareScene}>
          {inPersonalDrive ? 'Share Scene' : 'View Permissions'}
        </Menu.Item>
      )} */}

      {!inSharedScene && (
        <Menu.Item key="1" onClick={onDuplicateScene}>
          Duplicate
        </Menu.Item>
      )}

      <Menu.Item
        key="2"
        onClick={() => {
          setShowRenameSceneModal(true);
          setShowDropdown(false);
        }}
      >
        Rename
      </Menu.Item>

      {userIsOwner && (
        <Menu.Item
          key="3"
          onClick={() => {
            setShowDropdown(false);
            showDeleteConfirm();
          }}
        >
          Delete
        </Menu.Item>
      )}

      <Menu.Item key="4" onClick={openViewHistory}>
        Version History
      </Menu.Item>
    </Menu>
  );

  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete this scene?`,
      icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
      content: `This operation is irreversible and ${sceneName} will be deleted forever!`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(removeScene(selectedProjectId, sceneId));
      },
      onCancel() {},
    });
  };

  return (
    <React.Fragment>
      {showShareSceneModal && !inPersonalDrive && (
        // <EditSceneModal
        //   onClose={closeSceneMembers}
        //   title={sceneName}
        //   // members={members}
        //   // nonMembers={nonSceneMembers}
        //   // addMember={addSceneMember}
        //   // removeMember={removeSceneMember}
        // />
        <ShareTeamSceneModal onClose={closeShareSceneModal} />
      )}

      {showShareSceneModal && inPersonalDrive && (
        // <SharePersonalSceneModal
        //   onClose={() => setShowShareSceneModal(false)}
        // />
        <SharePersonalSceneModal onClose={closeShareSceneModal} />
      )}

      {showDuplicateModal && (
        <DuplicateSceneModal onClose={closeDuplicateModal} />
      )}

      {showRenameSceneModal && (
        <RenameSceneModal onClose={() => setShowRenameSceneModal(false)} />
      )}

      <VersionsModal
        visible={showVersionsModal}
        onClose={() => {
          setShowVersionsModal(false);
        }}
        sceneId={sceneId}
        sceneName={sceneName}
      />
      <Dropdown
        transitionName=""
        visible={showDropdown}
        onVisibleChange={(visible) => setShowDropdown(visible)}
        overlay={menu}
        trigger={['click']}
      >
        {/* <img className="screen-content-card-icon" src='/assets/images/menu/scene-dots.svg' /> */}
        <img
          className="screen-content-card-icon"
          src="/menu/ellipses.svg"
          alt="options"
          // color={view === 'grid' ? 'white' : 'black'}
        />
      </Dropdown>
    </React.Fragment>
  );
};

export default SceneOptions;
