import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * A window function listener that redirects to the search items view once called
 */
export const useInstantSearch = () => {
  const history = useHistory();

  const instantSearch = () => {
    history.push(`/library/home`);
  };

  useEffect(() => {
    window.addEventListener('instantSearch', instantSearch);
    return () => {
      window.removeEventListener('instantSearch', instantSearch);
    };
  }, []);
};
