import React from 'react';
import '../CommentReplies.css';

const RepliesBtn = ({ repliesLength, setShowReplies }) => {
  return (
    <React.Fragment>
      {repliesLength > 0 ? (
        <div className="replies-btn mt-1" onClick={() => setShowReplies(true)}>
          <img
            src="/assets/images/curver_arrow.svg"
            alt=""
            width="12px"
            height="12px"
          />
          <span> Replies {repliesLength} </span>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default RepliesBtn;
