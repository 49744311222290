import { produce } from 'immer';
import { blueprintsActionTypes } from './actions';

export const blueprintsReducer = produce((state = initState, action) => {
  switch (action.type) {
    case blueprintsActionTypes.SET_ORIGINAL_BLUEPRINTS:
      return {
        ...state,
        originalData: action.payload,
      };

    case blueprintsActionTypes.SET_BLUEPRINT_SCENES:
      return {
        ...state,
        sceneData: { ...state.sceneData, objects: action.payload },
      };

    case blueprintsActionTypes.SET_BLUEPRINT_DIMENSIONS:
      return {
        ...state,
        dimensions: {
          min: action.payload.min,
          max: action.payload.max,
          offset: action.payload.offset,
        },
      };

    case blueprintsActionTypes.SET_ENVIRONMENT_SETTINGS: {
      state.environmentSettings = action.payload;
      return state;
    }

    case blueprintsActionTypes.SET_CENTER_POSITION: {
      state.centerPosition = action.payload;
      return state;
    }

    case blueprintsActionTypes.SET_BLUEPRINT_DURATION:
      return { ...state, duration: action.payload };

    case blueprintsActionTypes.SET_TIME_FRAME_BLUEPRINT:
      return { ...state, timeFrame: action.payload };

    case blueprintsActionTypes.INCREMENT_TIME_FRAME:
      const { timeFrame } = state;
      return { ...state, timeFrame: timeFrame + 1 };

    case blueprintsActionTypes.PLAY_BLUEPRINT:
      return { ...state, isPlaying: true };

    case blueprintsActionTypes.PAUSE_BLUEPRINT:
      return { ...state, isPlaying: false };

    case blueprintsActionTypes.HIDE_OBJECT:
      return {
        ...state,
        sceneData: {
          ...state.sceneData,
          objects: {
            ...state.sceneData.objects,
            [action.payload]: {
              ...state.sceneData.objects[action.payload],
              hidden: true,
            },
          },
        },
      };

    case blueprintsActionTypes.SHOW_OBJECT:
      return {
        ...state,
        sceneData: {
          ...state.sceneData,
          objects: {
            ...state.sceneData.objects,
            [action.payload]: {
              ...state.sceneData.objects[action.payload],
              hidden: false,
            },
          },
        },
      };

    case blueprintsActionTypes.SELECT_OBJECT:
      return {
        ...state,
        selectedObjectId: action.payload,
      };

    case blueprintsActionTypes.DESELECT_OBJECT:
      return {
        ...state,
        selectedObjectId: null,
      };

    case blueprintsActionTypes.HOVER_OBJECT:
      return {
        ...state,
        hoveredObjectId: action.payload,
      };

    case blueprintsActionTypes.UN_HOVER_OBJECT:
      return {
        ...state,
        hoveredObjectId: null,
      };

    case blueprintsActionTypes.SET_SCALE:
      return {
        ...state,
        scale: action.payload,
      };

    case blueprintsActionTypes.SET_POSITION:
      return {
        ...state,
        position: action.payload,
      };

    case blueprintsActionTypes.SET_MEASURING_OBJECTS:
      return addObjectToMeasureDistance(state, action.payload);

    case blueprintsActionTypes.DELETE_DISTANCE:
      return {
        ...state,
        distances: [
          ...state.distances.slice(0, action.payload),
          ...state.distances.slice(action.payload, 0),
        ],
        distancesIds: [
          ...state.distancesIds.slice(0, action.payload),
          ...state.distancesIds.slice(action.payload, 0),
        ],
      };

    case blueprintsActionTypes.CLEAR_BLUEPRINTS:
      return initState;

    case blueprintsActionTypes.SET_SVG_DIMENSIONS:
      return { ...state, svgDimensions: { ...action.payload } };

    case blueprintsActionTypes.SET_SELECTED_OBJECT_TAB:
      return { ...state, selectedObjectTab: action.payload };

    case blueprintsActionTypes.SET_THUMBNAILS:
      return { ...state, sceneObjectsThumbnails: action.payload };
    default:
      return state;
  }
});

const initState = {
  svgDimensions: {
    width: 500,
    height: 500,
  },
  environmentSettings: {},
  originalData: null,
  duration: 0,
  timeFrame: 0,
  isPlaying: false,
  selectedObjectId: null,
  selectedObjectTab: 'info',
  hoveredObjectId: null,
  scale: 1,
  position: {
    x: 2500,
    z: 2500,
  },
  centerPosition: { x: 0, z: 0 },
  distanceMeasuringObjects: {
    firstObj: null,
    secondObj: null,
  },
  distances: [],
  distancesIds: [],
  dimensions: {
    min: {
      x: -100,
      z: -100,
    },
    max: {
      x: 1000,
      z: 1000,
    },
    offset: {
      x: 0,
      z: 0,
    },
  },
  sceneData: {
    objects: {},
  },
  sceneObjectsThumbnails: {},
};

const addObjectToMeasureDistance = (state, object) => {
  if (state.distanceMeasuringObjects.firstObj === null) {
    return {
      ...state,
      distanceMeasuringObjects: {
        ...state.distanceMeasuringObjects,
        firstObj: object,
      },
    };
  } else if (state.distanceMeasuringObjects.secondObj === null) {
    if (state.distanceMeasuringObjects.firstObj !== object) {
      if (
        !state.distancesIds.includes(
          `${state.distanceMeasuringObjects.firstObj}_${object}`
        ) &&
        !state.distancesIds.includes(
          `${object}_${state.distanceMeasuringObjects.firstObj}`
        )
      ) {
        return {
          ...state,
          distanceMeasuringObjects: {
            ...state.distanceMeasuringObjects,
            secondObj: object,
          },
          distances: [
            ...state.distances,
            {
              ...state.distanceMeasuringObjects,
              secondObj: object,
            },
          ],
          distancesIds: [
            ...state.distancesIds,
            `${state.distanceMeasuringObjects.firstObj}_${object}`,
          ],
        };
      }
    }

    return state;
  } else {
    return {
      ...state,
      distanceMeasuringObjects: {
        firstObj: object,
        secondObj: null,
      },
    };
  }
};
