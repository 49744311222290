import React, { useRef, useState } from 'react';
import CommentCard from '../Comment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './CommentReplies.css';
import RepliesBtn from './RepliesBtn/RepliesBtn';
import defaultStyles from '../EditComment/defaultStyles';
import { commentType, createReply, IReply } from 'redux/slices/comments/types';
import { addReply } from 'redux/slices/comments/actions';
import Reply from '../../Reply/Reply';
import {
  userIdSelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';

type propTypes = {
  commentsType: commentType;
  showReplies: boolean;
  commentReplies: IReply;
  parentId: string;
  setShowReplies: (v: any) => void;
  containerRefId?: string;
};

const CommentReplies = ({
  commentsType,
  showReplies,
  commentReplies,
  parentId,
  setShowReplies,
  containerRefId,
}: propTypes) => {
  const dispatch = useDispatch();
  const [replyOnReply, setReplyOnReply] = useState(''); // This state is used for replying on a reply comment by mentioning the user of that comment

  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const userRole = useSelector(userRoleSelector);
  const sceneId = useSelector(selectedSceneIdSelector);
  const users = useSelector<any>((state) => state.app.users);
  const scrollToBottomRef = useRef(null as any);

  const onReplySubmit = (text, tags) => {
    const date = moment().toISOString();
    const comment: IReply = createReply(
      text,
      userId,
      userName,
      userRole,
      date,
      tags,
      parentId
    );

    dispatch(
      addReply(commentsType, comment, parentId, sceneId, containerRefId)
    );
    // dispatch(
    //   addCommentReply(
    //     commentId,
    //     comment,
    //     shotID,
    //     sceneId,
    //     userID,
    //     userName,
    //     userRole,
    //     date,
    //     tags
    //   )
    // );
  };

  // const scrollTo = (ref) => {
  //     if (ref /* + other conditions */) {
  //       ref.scrollIntoView({ behavior: 'smooth', block: 'end' })
  //     }
  //   }

  // useEffect(() => {
  //   if (scrollToBottomRef.current && showReplies === true) {
  //     scrollToBottomRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'end',
  //     });
  //   }
  // }, [showReplies]);

  let showRepliesBtn: any = null;
  let replies: any = commentReplies;
  if (replies && !_.isEmpty(replies)) {
    const repliesLength = Object.keys(replies).length;

    showRepliesBtn = (
      <RepliesBtn
        repliesLength={repliesLength}
        setShowReplies={setShowReplies}
      />
    );

    replies = Object.entries(commentReplies).map((comment) => {
      return (
        <CommentCard
          key={comment[0]}
          commentType={commentsType}
          commentId={comment[0]}
          commentorId={comment[1].commentorId}
          commentorName={comment[1].commentorName}
          role={comment[1].role}
          timeStamp={comment[1].date}
          text={comment[1].text}
          myComment={comment[1].myComment}
          likes={comment[1].likes}
          tags={comment[1].tags}
          parentId={comment[1].parentId}
          containerRefId={containerRefId}
          replyOnReply={() => {
            scrollToBottomRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
            setReplyOnReply(
              '@[(' +
                comment[1].commentorName +
                ',' +
                comment[1].commentorId +
                ')]'
            );
          }}
        ></CommentCard>
      );
    });
  }

  const repliesContainer = (
    <div className="ml-4">
      {replies}
      <Reply
        users={users}
        onCommentSubmit={onReplySubmit}
        disabled={sceneId ? false : true}
        tagForReply={replyOnReply}
        setReplyOnReply={setReplyOnReply}
        singleLine={false}
        className=""
        style={defaultStyles}
        submitBtn={true}
      />
      <div ref={scrollToBottomRef}></div>
    </div>
  );
  return <div>{showReplies ? repliesContainer : showRepliesBtn}</div>;
};

export default CommentReplies;
