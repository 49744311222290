export const convertItemsToObject = () => (data) => {
  return { ...data, items: arrayToObject(data.items) };
};

export const addDefaultSubcategory = () => (data) => {
  return [{ subcategory: 'All' }, ...data];
};

export const convertSubcategoriesToObject = () => (data) => {
  return arrayToObject(data);
};

export const extractItemId = () => (data) => {
  const itemsData = data.items;
  for (const key in itemsData) {
    const id = itemsData[key]?.itemID;
    if (id) {
      itemsData[id] = itemsData[key];
      delete itemsData[key];
    }
  }
  return data;
};

export const addCategory = (category) => (data) => {
  return { data, category };
};

export const addSubcategory = (subcategory) => (data) => {
  return { data, subcategory };
};

export const arrayToObject = (data) => {
  return Object.assign({}, data ?? []);
};
