import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import './CommentsPanel.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { commentsArraySelector } from 'redux/slices/comments/selectors';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import { addComment } from 'redux/slices/comments/actions';
import { clipsShotDisplaySelector } from 'redux/slices/clips/selectors';
import { useHistory, withRouter } from 'react-router-dom';
import Loader from 'common/components/loader/Loader';
import useScrollReachTop from 'common/hooks/useScrollReachTop';
import { createComment } from 'redux/slices/comments/types';
import CommentCard from '../comments/Comment/Comment';
import Reply from '../comments/Reply/Reply';

/**
 * Renders the scene comments and a textbox for adding new comments.
 */

type propTypes = {
  comments: any[];
  extraCommentsPending?: boolean;
  onReachTop?: () => void;
};

/**
 * Renders the scene comments and a textbox for adding new comments.
 */
const CommentsPanel = ({
  comments = [],
  extraCommentsPending = false,
  onReachTop: onReachTop = () => {},
}: propTypes) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const commentsEndRef = useRef(null as any);
  const commentsContainerRef = useRef(null as any);

  const users = useSelector<any>((state) => state.app.users);
  const sceneId = useSelector(selectedSceneIdSelector);
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const userRole = useSelector(userRoleSelector);

  const [scrollHeight, setScrollHeight] = useState(0);

  useScrollReachTop(commentsContainerRef, () => {
    updateScrollPosition();
    onReachTop();
  });

  const onCommentSubmit = (text, tags) => {
    const date = moment().toISOString();
    const comment = createComment(text, userId, userName, userRole, date, tags);
    dispatch(addComment('SCENE_COMMENT', comment, sceneId));
  };

  // const comments = useSelector(commentsArraySelector);
  // const comments = useSelector(sceneCommentsArraySelector);
  const selectedSceneId = useSelector(selectedSceneIdSelector);

  // Smooth scrolling to the last comment in the comments section.

  const scrollToBottom = () => {
    if (commentsEndRef.current && commentsEndRef.current.scrollIntoView) {
      commentsEndRef.current.scrollIntoView({
        // behavior: 'smooth',
      });
    }
  };

  /**
   *  Fixes the scroll position once the scroll height is changed
   * */
  const updateScrollPosition = () => {
    if (commentsContainerRef.current) {
      const newHeight: number = commentsContainerRef.current.scrollHeight;
      if (newHeight !== scrollHeight) {
        commentsContainerRef.current.scrollTop += newHeight - scrollHeight;
        setScrollHeight(newHeight);
      }
    }
  };

  const renderComments = comments.map((comment) => {
    const commentBody = (
      <CommentCard
        commentType="SCENE_COMMENT"
        key={comment.id}
        commentId={comment.id ?? ''}
        commentorId={comment.commentorId}
        commentorName={comment.commentorName}
        role={comment.role}
        timeStamp={comment.date}
        text={comment.text}
        likes={comment.likes}
        // myComment={comment.myComment}
        // videoPath={props.path}
        tags={comment.tags}
        replies={comment.replies}
        // parentId={comment.parentId}
      ></CommentCard>
    );
    return commentBody;
  });

  return (
    <div className="comments-panel">
      <Row>
        <div className="comments-header w-100 m-auto d-flex justify-content-center">
          <div className="sceneComments-header d-flex align-items-center justify-content-center mb-1">
            <span> Scene Comments</span>
          </div>
        </div>
      </Row>

      {/* <div> */}
      <div
        ref={commentsContainerRef}
        className="comments-section d-flex flex-column align-items-start no-gutters mt-0 pt-0"
      >
        <Loader show={extraCommentsPending} centerSpinner />
        {renderComments}
        <div ref={commentsEndRef}></div>
      </div>
      {/* </div> */}

      {/* <Row className="flex-grow-1" /> */}

      <div className="comments-reply">
        <Reply
          users={users}
          onCommentSubmit={onCommentSubmit}
          disabled={!selectedSceneId}
        />
      </div>
    </div>
  );
};

export default CommentsPanel;
