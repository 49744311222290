import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './UserPopover.css';
import { useSelector } from 'react-redux';
import { positionToColor } from '../../../config';

/**
 * This component renders these components:
 *  1- Like Button that toggle the like status of the comment.
 *  2- Text that shows how many people liked this comment. If they are less than three, then there names
 *      will be displayed.
 *  3- A popover that displays all the names of the people who liked this comment.
 * @param {*} props
 */
const UserPopover = (props) => {
  const users = useSelector((state) => state.app.users);
  const sideBar = useSelector((state) => state.showSidebar);

  // This state handles the z-index of the popover.
  // If the CommentsModal is open or the sidebar is open, then the z-index of the popover is set to be higher than
  //  there z-index. Else, the z-index of the popover is set to 0 to be lower than the z-index of the header.
  const [zIndex, setZIndex] = useState(4000);

  let user;
  if (users) {
    user = users.find((user) => user.id === props.userID);
    if (user) {
      user.role = user?.role.toUpperCase();
    }
  }

  const popover = (
    <Popover
      id="popover-basic"
      className="user-popover"
      style={{ position: 'relative', zIndex: zIndex }}
    >
      <Popover.Content>
        <div className="user-content d-flex flex-column">
          <img
            className="rounded-circle h-25 w-25"
            src={user?.avatar}
            alt="User avatar"
          />
          <p className="name"> {user?.name} </p>
          <p className="email"> {user?.email} </p>
          <p
            className="role mt-6"
            style={{
              color: positionToColor[user?.role]
                ? positionToColor[user?.role]
                : 'rgba(0, 0, 0)',
            }}
          >
            {user?.role}
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        className="over-lay"
        trigger={['focus', 'click']}
        delay={{ show: 300, hide: 300 }}
        placement="top"
        overlay={popover}
      >
        {props.children}
      </OverlayTrigger>
    </>
  );
};

export default UserPopover;
