import './PersonalSceneTab.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  projectMembersArraySelector,
  sceneMembersArraySelector,
  sceneNameSelector,
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  scenePendingInvitesArraySelector,
  shareScenePendingSelector,
  updateSceneMemberRolePendingSelector,
} from 'redux/slices/app/selectors';
import {
  sharePersonalScene,
  updateSceneMemberRole,
  unsharePersonalScene,
  deleteSceneInvitation,
} from 'redux/slices/app/actions';
import Loader from 'common/components/loader/Loader';

export const PersonalSceneTab = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState<'Reviewer' | 'Editor'>('Editor');

  const projectId = useSelector(selectedProjectIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  const sceneName = useSelector(sceneNameSelector);

  const projectMembers = useSelector(projectMembersArraySelector) ?? [];
  const sceneMembers = useSelector(sceneMembersArraySelector) ?? [];
  const pendingInvites = useSelector(scenePendingInvitesArraySelector) ?? [];

  const shareScenePending = useSelector(shareScenePendingSelector);

  const updateSceneMembersPending = useSelector(
    updateSceneMemberRolePendingSelector
  );

  const renderMembers = (members, disabled) =>
    members.map(({ id, role, name }) => (
      <div className={`member${disabled ? ' disabled' : ''}`} key={id}>
        <div className="name">{name}</div>
        <Loader
          show={!disabled && updateSceneMembersPending[id] === true}
          spinnerSize={20}
        >
          <select
            value={role}
            disabled={disabled}
            onChange={(e) => {
              console.log(e.target.value);
              const newRole = +e.target.value;
              if (newRole === 3) {
                dispatch(unsharePersonalScene(sceneId, id));
              } else if (role !== newRole)
                dispatch(
                  updateSceneMemberRole(
                    projectId,
                    sceneId,
                    id,
                    newRole === 1 ? 'Editor' : 'Reviewer'
                  )
                );
            }}
          >
            <option value="1">Editor</option>
            <option value="2">Reviewer</option>
            <option value="3" style={{ color: 'red' }}>
              Remove User
            </option>
          </select>
        </Loader>
      </div>
    ));

  const renderPendingInvites = (members) =>
    members.map(({ id, role, email }) => (
      <div className={`member`} key={id}>
        <div className="name">{`${email} (${
          role === 1 ? 'Editor' : 'Reviewer'
        })`}</div>
        <button
          className="remove-btn"
          onClick={() => dispatch(deleteSceneInvitation(sceneId, id))}
        >
          Delete Invitation
        </button>
      </div>
    ));

  return (
    <div className="personal-scene-tab">
      <div className="invite-member">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(sharePersonalScene(sceneId, email, role));
            setEmail('');
          }}
        >
          <input
            type="text"
            placeholder="user@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select value={role} onChange={(e) => setRole(e.target.value as any)}>
            <option value="Editor">Editor</option>
            <option value="Reviewer">Reviewer</option>
          </select>
          <button type="submit" disabled={shareScenePending}>
            <Loader
              show={shareScenePending}
              spinnerSize={12}
              spinnerColor="white"
            />
            Invite
          </button>
        </form>
        <p>
          Reviewers are external members who can view & comment on scene content
          exported to the Backlot site.
        </p>
      </div>

      <div className="members">
        <h2 className="title">{sceneName}</h2>
        {renderMembers(sceneMembers, false)}
        {pendingInvites.length > 0 && (
          <>
            <h2 className="title">Pending Invites</h2>
            {renderPendingInvites(pendingInvites)}
          </>
        )}
        {projectMembers.length > 0 && (
          <>
            <h2 className="title">Project Level Access</h2>
            {renderMembers(projectMembers, true)}
          </>
        )}
      </div>
    </div>
  );
};
