import moment from 'moment';
import Loader from 'common/components/loader/Loader';
import React, { useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { FaArrowDown, FaArrowUp, FaCopy } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchSceneStatus,
  setAppLoader,
  setScene,
  setSelectedSceneId,
} from 'redux/slices/app/actions';
import {
  inPersonalDriveSelector,
  sceneMembersSelector2,
  selectedProjectIdSelector,
  selectedSceneStatusPendingSelector,
  selectedProjectStatusSelector,
  selectedSceneSelector,
  selectedTeamIdSelector,
  usersPublicSelector,
  selectedSceneStatusSelector,
  projectSortableSelector,
  teamMembersArraySelector,
} from 'redux/slices/app/selectors';
import {
  fetchSceneVersions,
  openScene,
  updateRecentScenes,
} from 'redux/slices/project/actions';
import { XYCoord } from 'dnd-core';
import SceneOptions from '../scene/sceneOptions/SceneOptions';
import EditSceneModal from 'backlot-menu/components/modals/editMembersModal/EditSceneModal';
import OpenSceneModal from 'backlot-menu/components/modals/openSceneModal/OpenSceneModal';
import DuplicateSceneModal from 'backlot-menu/components/modals/duplicateSceneModal/DuplicateSceneModal';
import Members from '../../members/Members';
import firebase from 'firebase-main';
import {
  userIdSelector,
  userImageSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import VersionsModal from 'backlot-menu/components/modals/versionsModal/versionsModal';
import { dispatchEvents } from 'redux/middlewares/amplitude';

const SceneListItem = ({
  scene,
  index,
  displayIndex = true,
  onIndexUpdate,
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showOpenSceneModal, setShowOpenSceneModal] = useState(false);
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [showMembers, setShowMembers] = useState(false);

  const usersPublicData = useSelector(usersPublicSelector);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const userId = useSelector(userIdSelector);
  const username = useSelector(userNameSelector);
  const userThumbnail = useSelector(userImageSelector);

  const sortableProject = useSelector(projectSortableSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const teamMembers = useSelector(teamMembersArraySelector);
  const selectedScene = useSelector(selectedSceneSelector);
  const sceneName: string = scene.loading ? scene.name.name : scene.name;
  const lastModified = scene.lastModified
    ? moment(scene.lastModified).format('MM/DD/YYYY hh:mm')
    : null;
  // const sceneMembers = Object.values(useSelector(sceneMembersSelector2) ?? {});
  const projectId = scene.projectId ?? useSelector(selectedProjectIdSelector);

  const sceneIsValid = useSelector(selectedSceneStatusSelector);
  const sceneSubscriptionPending = useSelector(
    selectedSceneStatusPendingSelector
  );

  const sceneIsPublic = scene.isPublic;
  const sceneMembers = Object.keys(scene.members ?? {}).map((id) => ({
    id,
    name: scene.members[id].name,
    thumbnail: usersPublicData[id]?.thumbnail,
  }));

  if (!sceneMembers.find(({ id }) => id === userId))
    sceneMembers.unshift({
      id: userId,
      name: username,
      thumbnail: userThumbnail,
    });

  const inSharedScene = history.location.pathname === '/menu/shared-scenes';

  let members: any[] = [];

  if (!inPersonalDrive) {
    if (sceneIsPublic) {
      members = teamMembers ?? [];
    } else {
      members = sceneMembers;
    }
  } else {
    members = sceneMembers;
  }

  const openViewHistory = () => {
    dispatch(fetchSceneVersions(scene.id));
    dispatch(setSelectedSceneId(scene.id));
    setShowVersionsModal(true);
  };

  return (
    <>
      {showDuplicateModal && (
        <DuplicateSceneModal onClose={() => setShowDuplicateModal(false)} />
      )}

      <VersionsModal
        visible={showVersionsModal}
        onClose={() => {
          setShowVersionsModal(false);
        }}
        sceneId={scene.id}
        sceneName={sceneName}
      />

      {showOpenSceneModal && (
        <OpenSceneModal
          thumbnail={scene.thumbnail}
          title={
            sceneIsValid !== false
              ? `${displayIndex ? index + 1 + '. ' : ''}${sceneName}`
              : "This scene's subscription has ended"
          }
          disabled={sceneIsValid === false}
          onClose={() => {
            dispatch(setScene(null));
            setShowOpenSceneModal(false);
          }}
          loading={
            !selectedScene || (inSharedScene && sceneSubscriptionPending)
          }
          onEnter={() => {
            // dispatch(updateRecentScenes(teamId, projectId, scene.id));
            // dispatch(setAppLoader(true));
            // (window as any).selectScene(
            //   scene.id,
            //   scene.name,
            //   scene?.projectId ?? projectId ?? selectedScene?.project_id
            // );
            // dispatchEvents([{ event_type: 'open_scene', user_id: userId }]);
            // firebase.analytics().logEvent('open_scene', scene);
            dispatch(openScene(teamId, projectId, scene.id, scene.name));
            history.push(`/menu/project/scene/${scene.id}`);
          }}
        />
      )}

      {showMembers && (
        <EditSceneModal
          onClose={() => {
            setShowMembers(false);
          }}
          title={sceneName}
          // members={sceneMembers}
          // nonMembers={{}}
          // addMember={() => {}}
          // removeMember={() => {}}
        />
      )}
      <div
        className="scene-row"
        onClick={() => {
          // dispatch(subscribeScene(scene.id));

          dispatch(setSelectedSceneId(scene.id));
          if (inSharedScene) dispatch(fetchSceneStatus(scene.id));
          setShowOpenSceneModal(true);
        }}
        // style={{ position: 'relative' }}
      >
        {scene.loading === true ? (
          <div className="scene-loader">
            <Loader
              centerSpinner
              hideChildren
              backgroundStyle={{ height: '100%' }}
              spinnerColor="white"
              show={scene.loading}
            />
          </div>
        ) : null}

        <div
          // className="scene-image image1 scene-list-item-image"
          className="scene-image"
          style={{
            backgroundImage: `url("${scene.thumbnail ?? '/empty-scene.jpg'}")`,
          }}
        />

        <div className="scene-num">{displayIndex && `${index + 1}.`}</div>
        {sortableProject && (
          <div className="sort-arrows">
            <FaArrowUp
              onClick={(e) => {
                e.stopPropagation();
                onIndexUpdate(scene.id, index + 1, index);
              }}
            />
            <FaArrowDown
              onClick={(e) => {
                e.stopPropagation();
                onIndexUpdate(scene.id, index + 1, index + 2);
              }}
            />
          </div>
        )}
        <div className="scene-name">{sceneName?.toUpperCase()} </div>
        {/* <div className="scene-list-item-title"> */}
        {/* {displayIndex && <span className="scene-index"> {index + 1} </span>} */}
        {/* {sceneName?.toUpperCase()} */}
        {/* </div> */}

        {/* {!inPersonalDrive && (
            <div
              className={`public-tag ${
                sceneIsPublic
                  ? 'public'
                  : sceneIsPublic === false
                  ? 'private'
                  : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedSceneId(scene.id));
                setShowMembers(true);
              }}
            >
              {sceneIsPublic
                ? 'Entire Team'
                : sceneIsPublic === false
                ? 'Private'
                : ''}
            </div>
          )} */}
        {/* <div className="last-modified">{lastModified}</div> */}
        <div className="scene-date">
          <span className="name">{scene?.modifierName}</span>
          <span className="date">{lastModified}</span>
        </div>
        <div className="scene-creator">
          {scene.ownerId ? scene.ownerName : 'test'}
        </div>
        <div className="scene-members">
          {!inPersonalDrive && (
            <Members
              members={members}
              showAddMemberBtn={false}
              membersModalTitle="Scene Members"
            />
          )}
        </div>

        {/* <div className="members" onClick={(e) => e.stopPropagation()}>
          {!inPersonalDrive && (
            <Members members={sceneMembers} membersModalTitle="Scene Members" />
          )}
        </div> */}
        {/* <div className="scene-list-item-settings scene-options"> */}
        <div className="scene-options">
          <img
            src="/menu/sidebar/time.svg"
            alt="time"
            onClick={(e) => {
              e.stopPropagation();
              openViewHistory();
            }}
          />
          {!inSharedScene && (
            <img
              src="/menu/grid/duplicate.svg"
              alt="duplicate"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedSceneId(scene.id));
                setShowDuplicateModal(true);
              }}
              className="screen-content-card-icon"
            />
            // <FaCopy
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     dispatch(setSelectedSceneId(scene.id));
            //     setShowDuplicateModal(true);
            //   }}
            //   // className="screen-content-card-icon"
            // />
          )}
          <img
            src="/menu/share.svg"
            alt="share"
            className="screen-content-card-icon"
          />
          <div onClick={(e) => e.stopPropagation()}>
            <SceneOptions
              view="list"
              sceneName={sceneName}
              sceneId={scene.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SceneListItem;
