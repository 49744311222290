import { Action } from 'redux/common/types';
import axios from 'axios';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

export const amplitudeMiddleware = (store) => (next) => (action: Action) => {
  next(action);
  if (action.meta?.amplitude) {
    const { events } = action.meta.amplitude;

    const userId = userIdSelector(store.getState());

    if (userId) {
      events.forEach((event) => {
        if (!event.user_id) event.user_id = userId;
      });
    }

    dispatchEvents(events);
  }
};

export const dispatchEvents = (
  events: {
    event_type: string;
    user_id?: string;
    event_properties?: { [id: string]: any };
    [id: string]: any;
  }[]
) => {
  axios
    .post('https://api2.amplitude.com/2/httpapi', {
      api_key: process.env.REACT_APP_AMPLITUDE_API_KEY,
      events,
    })
    .catch((err) => {
      console.error(err);
    });
};
