import './Home.scss';
import React, { useEffect, useRef, useState } from 'react';
import InputWithImage from '../input/input-with-icon/InputWithImage';
import { navButtons } from './nav-buttons';
import { Route, Switch, useHistory } from 'react-router-dom';
import NavGrid from './nav-grid/NavGrid';
import SearchGrid from './search-grid/SearchGrid';
import { useSelector } from 'react-redux';
import { libraryVersionSelector } from 'redux/slices/items/selectors';

const Home = () => {
  const history = useHistory();
  const searchBarRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  // const [version, setVersion] = useState('');

  const libraryVersion = useSelector(libraryVersionSelector);

  // useEffect(() => {
  //   window.addEventListener('setLibraryVersion', (e: any) => {
  //     setVersion(e.params.version);
  //   });
  //   return () => {
  //     window.removeEventListener('setLibraryVersion', setVersion);
  //   };
  // }, []);

  // Focuses on searchBar when instantSearch window fn is called
  useEffect(() => {
    const focusSearchBar = () => {
      if (searchBarRef.current) {
        (searchBarRef.current as any).focus();
      }
    };
    window.addEventListener('instantSearch', focusSearchBar);
    // focusSearchBar();
    return () => {
      window.removeEventListener('instantSearch', focusSearchBar);
    };
  }, [searchBarRef.current]);

  const renderNavButtons = <NavGrid navButtons={navButtons} />;

  const renderSearchItems = (
    <SearchGrid query={searchText} showFilters={showFilters} />
  );

  return (
    <>
      <div className="library-version">{'Ver: ' + (libraryVersion ?? '')}</div>
      <div className="backlot-library-home">
        <div className="library-header">
          <Route path={`/library/home/search`}>
            <div
              className="back"
              onClick={() => {
                setSearchText('');
                history.push(`/library/home`);
              }}
            >
              {'< back'}
            </div>
          </Route>
          <div id="app-title">
            <h1>Library</h1>
          </div>
        </div>

        <div id="search-bar">
          <InputWithImage
            ref={searchBarRef}
            src="/backlot-library/home/search.svg"
            style={{
              borderRadius: '50px',
              backgroundColor: 'rgba(255, 251, 251, 0.2)',
              border: '0px',
              color: 'white',
              padding: '0rem',
              paddingLeft: '2rem',
            }}
            value={searchText}
            autofocus={false}
            onChange={setSearchText}
            onFocusIn={() => {
              if (!history.location.pathname.includes('search'))
                history.push(`/library/home/search`);
            }}
          />
          <Route path={`/library/home/search`}>
            <button onClick={() => setShowFilters(!showFilters)}>
              {showFilters ? `Hide Filters` : `Show Filters`}
            </button>
          </Route>
        </div>

        <Switch>
          <Route path={`/library/home/search`}>{renderSearchItems}</Route>
          <Route path={`/library/home`}>{renderNavButtons}</Route>
        </Switch>
      </div>
    </>
  );
};

export default Home;
