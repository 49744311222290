import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Login from '../login/Login';
import { isLoggedInSelector } from 'redux/slices/auth/authSelectors';

const PrivateRoute = ({ component: Component, extraProps = {}, ...rest }) => {
  const isLogin = useSelector(isLoggedInSelector);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <Component {...props} {...extraProps} />
        ) : (
          <Redirect to="/login" />
          // <Login />
        )
      }
    />
  );
};

export default PrivateRoute;
