import './BacklotAnimations.scss';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useRef, useState } from 'react';
import { FaPauseCircle, FaPlayCircle, FaPlusCircle } from 'react-icons/fa';
import useScrollReachBottom from 'common/hooks/useScrollReachBottom';
import useDebouncedState from 'common/hooks/useDebouncedState';
import { useDispatch, useSelector } from 'react-redux';
import {
  firebaseSearchItems2,
  setSearchTags,
  setSearchTerm,
  setSearchFilters,
  likeAnimation,
  unlikeAnimation,
} from 'redux/slices/items/actions';
import {
  searchItemsSelector,
  searchItemsSizeSelector,
  searchItemsFiltersSelector,
} from 'redux/slices/items/selectors';
import { searchItemsPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import { BiHeartCircle } from 'react-icons/bi';
import { RiHeartAddLine } from 'react-icons/ri';
import Loader from 'common/components/loader/Loader';
const tags = [
  { tagLabel: 'Featured', color: '#6495ED', tag: 'featured' },
  { tagLabel: 'All', color: 'Tomato', tag: 'all' },
  { tagLabel: 'Run', color: 'Orange', tag: 'run' },
  { tagLabel: 'Walk', color: 'DodgerBlue', tag: 'walk' },
  { tagLabel: 'Violence', color: 'MediumSeaGreen', tag: 'violence' },
  { tagLabel: 'Attack', color: '#663399', tag: 'attack' },
  { tagLabel: 'Sprint', color: 'SlateBlue', tag: 'sprint' },
  { tagLabel: 'Dance', color: 'Violet', tag: 'dance' },
  { tagLabel: 'Shoot', color: '#00BFFF', tag: 'shoot' },
  { tagLabel: 'Carry', color: '#DC143C', tag: 'carry' },
  { tagLabel: 'Move', color: '#D2691E', tag: 'move' },
  { tagLabel: 'Crouch', color: '#6495ED', tag: 'crouch' },
  { tagLabel: 'Turn', color: '#008B8B', tag: 'turn' },
  { tagLabel: 'Jump', color: '#556B2F', tag: 'jump' },
  { tagLabel: 'Gun', color: '#FF8C00', tag: 'gun' },
  { tagLabel: 'Battle', color: '#B22222', tag: 'battle' },
  { tagLabel: 'Idle', color: '#FF1493', tag: 'idle' },
  { tagLabel: 'Hit', color: '#FFD700', tag: 'hit' },
  { tagLabel: 'Fall', color: '#228B22', tag: 'fall' },
  { tagLabel: 'Stand', color: '#B8860B', tag: 'stand' },
  { tagLabel: 'Exercise', color: '#87CEEB', tag: 'exercise' },
  { tagLabel: 'Punch', color: '#000080', tag: 'punch' },
  { tagLabel: 'Death', color: '#6B8E23', tag: 'death' },
  { tagLabel: 'Rest', color: '#FF4500', tag: 'rest' },
  { tagLabel: 'Sneak', color: '#F4A460', tag: 'sneak' },
  { tagLabel: 'Weapon', color: '#4B0082', tag: 'weapon' },
  { tagLabel: 'Escape', color: '#800000', tag: 'escape' },
  { tagLabel: 'Boxing', color: 'Tomato', tag: 'boxing' },
  { tagLabel: 'Cheer', color: 'Orange', tag: 'cheer' },
  { tagLabel: 'Cover', color: 'DodgerBlue', tag: 'cover' },
  { tagLabel: 'Sport', color: 'MediumSeaGreen', tag: 'sport' },
  { tagLabel: 'Stab', color: '#663399', tag: 'stab' },
  { tagLabel: 'Strafe', color: 'SlateBlue', tag: 'strafe' },
  { tagLabel: 'Construction', color: '#FF4500', tag: 'construction' },
  { tagLabel: 'Archer', color: '#00BFFF', tag: 'archer' },
  { tagLabel: 'Swim', color: '#DC143C', tag: 'swim' },
  { tagLabel: 'Sit', color: '#D2691E', tag: 'sit' },
  { tagLabel: 'Magic', color: '#6495ED', tag: 'magic' },
  { tagLabel: 'Cook', color: '#008B8B', tag: 'cook' },
  { tagLabel: 'Social', color: '#556B2F', tag: 'social' },
  { tagLabel: 'Villager', color: '#FF8C00', tag: 'villager' },
  { tagLabel: 'Bazooka', color: '#4B0082', tag: 'bazooka' },
  { tagLabel: 'Grenade', color: '#B22222', tag: 'grenade' },
  { tagLabel: 'Climb', color: '#FFD700', tag: 'climb' },
  { tagLabel: 'Pick Up', color: '#228B22', tag: 'pickup' },
];
const searchDebounceDelay = 700;
const HITS_PER_PAGE = 30;

const BacklotAnimations = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const teamId = useSelector(selectedTeamIdSelector);
  const [searchText, setSearchText, searchTextDebounced] = useDebouncedState(
    '',
    searchDebounceDelay
  );
  const searchTextDebouncedLoading = searchTextDebounced !== searchText;
  const [curSearchPage, setCurSearchPage] = useState(0);

  const [activeAnimationIndex, setActiveAnimationIndex] = useState(-1);
  const [showTags, setShowTags] = useState(true);
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedTagLabel, setSelectedTagLabel] = useState('');
  const [selectedTagColor, setSelectedTagColor] = useState('');
  useEffect(() => {
    dispatch(setSearchTags('AnimationItems'));
  }, [dispatch]);

  const stopAnimations = () => {
    setActiveAnimationIndex(-1);
  };

  useEffect(() => {
    window.addEventListener('stopAnimations', stopAnimations);

    return () => {
      window.removeEventListener('stopAnimations', stopAnimations);
    };
  }, []);

  useScrollReachBottom(ref, () => {
    if (
      (selectedTag || searchTextDebounced) &&
      !extraSearching &&
      curSearchPage < searchItemsSize
    ) {
      let filters: any =
        selectedTag && selectedTag !== 'all' ? { tags: selectedTag } : {};
      if (selectedTag === 'Favorites') {
        delete filters.tags;
        filters.animation_favorites = true;
      }
      dispatch(
        firebaseSearchItems2(
          teamId,
          searchTextDebounced as string,
          curSearchPage,
          HITS_PER_PAGE,
          'AnimationItems',
          filters
        )
      );
      setCurSearchPage(curSearchPage + HITS_PER_PAGE);
    }
  });

  const searchedItems = useSelector(searchItemsSelector);
  const searchItemsFilters = useSelector(searchItemsFiltersSelector);
  const previousTag = searchItemsFilters.tags ? searchItemsFilters.tags : 'all';
  const loading = useSelector(searchItemsPendingSelector);
  const searchItemsSize = useSelector(searchItemsSizeSelector);
  const searchItemsPending = useSelector(searchItemsPendingSelector);

  const searching =
    searchText.length > 0 && (searchItemsPending || searchTextDebouncedLoading);

  const extraSearching = searchItemsPending;
  useEffect(() => {
    setCurSearchPage(0);
  }, [searchText]);

  useEffect(() => {
    if (selectedTag || searchTextDebounced) {
      let filters: any =
        selectedTag && selectedTag !== 'all' ? { tags: selectedTag } : {};
      if (selectedTag === 'Favorites') {
        delete filters.tags;
        filters.animation_favorites = true;
      }
      dispatch(setSearchTerm(searchTextDebounced));
      dispatch(setSearchFilters(filters));
      // if ((searchTextDebounced as string).length > 0) {
      dispatch(
        firebaseSearchItems2(
          teamId,
          searchTextDebounced as string,
          0,
          HITS_PER_PAGE,
          'AnimationItems',
          filters
        )
      );
      setCurSearchPage(HITS_PER_PAGE);
      // } else {
      //   dispatch(clearSearchItems());
      // }
    }
  }, [dispatch, searchTextDebounced, selectedTag]);

  useEffect(() => {
    (window as any).prevScroll = 0;
  }, []);

  useEffect(() => {
    const listener = (e) => {
      const { scrollTop } = ref.current as any;
      const prevScroll = (window as any).prevScroll;

      let newScroll = scrollTop;
      const delta = scrollTop - prevScroll;

      const speed = 0.5;

      let offset = Math.round(delta * speed);
      // if (delta * speed > 0) offset = Math.ceil(delta * speed);

      newScroll = prevScroll + offset;
      newScroll = Math.max(newScroll, 0);

      (ref.current as any).scrollTop = newScroll;
      (window as any).prevScroll = newScroll;
    };
    if (ref.current) {
      (ref.current as any).addEventListener('scroll', listener);
    }
    return () => {
      (ref.current as any).removeEventListener('scroll', listener);
    };
  }, [ref]);
  const onFavoritesClick = () => {
    if (selectedTag === 'Favorites') {
      setSelectedTag('');
      setSelectedTagColor('');
      setSelectedTagLabel('');
      setCurSearchPage(0);
      setSearchText('');
      setShowTags(true);
    } else {
      setSelectedTag('Favorites');
      setSelectedTagColor('#FA6161');
      setSelectedTagLabel('Favorites');
      setCurSearchPage(0);
      setSearchText('');
      setShowTags(false);
    }
  };
  return (
    <div className="animations">
      <div className="top-box">
        <Search
          className="search"
          placeholder="Search Animations..."
          enterButton={<div />}
          size="large"
          suffix={
            <img src="/backlot-library/home/search-black.svg" alt="search" />
          }
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => (window as any).onFocusIn()}
          onBlur={() => (window as any).onFocusOut()}
          // onSearch={onSearch}
        />
        <div className="favorites-button" onClick={onFavoritesClick}>
          <BiHeartCircle
            size={25}
            color={selectedTag === 'Favorites' ? '#FA6161' : 'white'}
          />
        </div>
      </div>
      <div className="animations-tags">
        {showTags && !searchText
          ? tags.map(({ tagLabel, color, tag }) => {
              return (
                <div
                  key={tag}
                  className="tag-box"
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    setSelectedTag(tag);
                    setSelectedTagLabel(tagLabel);
                    setSelectedTagColor(color);
                    setShowTags(false);
                  }}
                >
                  {tagLabel}
                </div>
              );
            })
          : selectedTag && (
              <div
                className="tag-selected"
                style={{ backgroundColor: selectedTagColor }}
                onClick={() => {
                  setSelectedTag('');
                  setSelectedTagColor('');
                  setSelectedTagLabel('');
                  setCurSearchPage(0);
                  setSearchText('');
                  setShowTags(true);
                }}
              >
                <span className="tag-back">{'<'}</span>
                <span className="tag-text">{selectedTagLabel}</span>
              </div>
            )}
      </div>

      <div className="animations-list" ref={ref}>
        {selectedTag || searchText ? (
          <>
            {searchedItems.map(({ name, itemID, favorite }) => (
              <div className="animation" key={itemID}>
                <div className="name">{name}</div>
                <div
                  className="favorite"
                  onClick={() => {
                    if (favorite) {
                      dispatch(unlikeAnimation(itemID, teamId));
                    } else {
                      dispatch(likeAnimation(itemID, teamId));
                    }
                  }}
                >
                  <RiHeartAddLine color={favorite ? '#FA6161' : 'white'} />
                </div>
                <div
                  className="play"
                  onClick={() => {
                    if (itemID === activeAnimationIndex) {
                      setActiveAnimationIndex(-1);
                      (window as any).onPauseAnimation(itemID);
                    } else {
                      setActiveAnimationIndex(itemID);
                      (window as any).onPlayAnimation(itemID);
                    }
                  }}
                >
                  {itemID === activeAnimationIndex ? (
                    <FaPauseCircle />
                  ) : (
                    <FaPlayCircle />
                  )}
                </div>
                <div
                  className="add"
                  onClick={() => {
                    (window as any).onAddAnimation(itemID);
                  }}
                >
                  <FaPlusCircle />
                </div>
              </div>
            ))}
            <Loader
              show={loading}
              centerSpinner
              spinnerSize={20}
              backgroundStyle={{ width: '100%', marginTop: '4vh' }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default BacklotAnimations;
