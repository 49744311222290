import Loader from 'common/components/loader/Loader';
import React from 'react';
import './PaymentSummary.css';

const PaymentSummary = ({
  credit,
  cost,
  loader = false, // For showing loader on calculating cost
}) => {
  const totalAmount = (cost - credit).toFixed(2);
  const fixedCredit = -Number(credit).toFixed(2);
  return (
    <div className="payment-summary">
      <span className="payment-summary-title my-3"> Payment summary </span>
      <Loader
        className="d-flex justify-content-center"
        hideChildren={true}
        spinnerSize={20}
        backgroundStyle={{ height: '100%' }}
        show={loader}
      >
        <div className="d-flex justify-content-between">
          <span> Licenses cost</span>
          <span>{'$' + cost?.toFixed(2)}</span>
        </div>
        {fixedCredit > 0 && (
          <div className="payment-summary-credit">
            <span>Free credit</span>
            <span> {'$' + fixedCredit}</span>
          </div>
        )}
        <div className="payment-summary-total-amount my-3">
          <span> Total amount</span>
          <span> {'$' + totalAmount} </span>
        </div>
      </Loader>
    </div>
  );
};

export default PaymentSummary;
