import firebase from 'firebase-main';
import { firebaseRealtimeRequest } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRealtimeApiFactory';
import { fireRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRequestTypes';
import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';
import { fetchScenesForProject } from 'redux/slices/app/actions';
import { projectsListenerForNewScene } from 'redux/slices/project/actions';

export const userDataActionTypes = {
  FETCH_USER_PROJECTS: 'FETCH_USER_PROJECTS',
  FETCH_USER_PROJECTS_SUCCESS: 'FETCH_USER_PROJECTS_SUCCESS',
  FETCH_USER_PROJECTS_EMPTY: 'FETCH_USER_PROJECTS_EMPTY',
  FETCH_USER_PROJECTS_FAIL: 'FETCH_USER_PROJECTS_FAIL',
  SET_USER_PROJECTS: 'SET_USER_PROJECTS',

  FETCH_USER_SCENES: 'FETCH_USER_SCENES',
  FETCH_USER_SCENES_SUCCESS: 'FETCH_USER_SCENES_SUCCESS',
  FETCH_USER_SCENES_EMPTY: 'FETCH_USER_SCENES_EMPTY',
  FETCH_USER_SCENES_FAIL: 'FETCH_USER_SCENES_FAIL',
  SET_USER_SCENES: 'SET_USER_SCENES',

  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_EMPTY: 'FETCH_USER_DATA_EMPTY',
  FETCH_USER_DATA_FAIL: 'FETCH_USER_DATA_EMPTY',
  SET_USER_DATA: 'SET_USER_DATA',

  FETCH_ROLE: 'FETCH_ROLE',
  FETCH_ROLE_EMPTY: 'FETCH_ROLE_EMPTY',
  FETCH_ROLE_FAIL: 'FETCH_ROLE_FAIL',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  SET_ROLE: 'SET_ROLE',
};

// --------------------------------- Fetch User's Projects ---------------------------------

export const fetchUserProjects = (userId) => {
  return {
    type: userDataActionTypes.FETCH_USER_PROJECTS,
    meta: {
      firebaseRealtimeApi: {
        url: `users/${userId}/projects`,
        req: fireRequestTypes.GET,
        onSuccessDispatches: [fetchUserProjectsSuccess, setUserProjects],
        onEmptyResponseDispatches: [fetchUserProjectsEmpty, setUserProjects],
        onFailDispatches: [fetchUserProjectsFail],
        dataPrepareFunctions: [],
      },
    },
  };
};

export const setUserProjects = (userProjects) => {
  return {
    type: userDataActionTypes.SET_USER_PROJECTS,
    payload: userProjects,
  };
};
export const fetchUserProjectsSuccess = () => {
  return {
    type: userDataActionTypes.FETCH_USER_PROJECTS_SUCCESS,
  };
};

export const fetchUserProjectsEmpty = () => {
  return {
    type: userDataActionTypes.FETCH_USER_PROJECTS_EMPTY,
  };
};

export const fetchUserProjectsFail = () => {
  return {
    type: userDataActionTypes.FETCH_USER_PROJECTS_FAIL,
  };
};

// --------------------------------- Fetch User's Scenes ---------------------------------

export const fetchUserScenes = (userId) => {
  return {
    type: userDataActionTypes.FETCH_USER_SCENES,
    meta: {
      firebaseRealtimeApi: {
        url: `users/${userId}/scenes`,
        req: fireRequestTypes.GET,
        onSuccessDispatches: [fetchUserScenesSuccess, setUserScenes],
        onEmptyResponseDispatches: [fetchUserScenesEmpty, setUserScenes],
        onFailDispatches: [fetchUserScenesFail],
        dataPrepareFunctions: [],
      },
    },
  };
};

export const setUserScenes = (userScenes) => {
  return {
    type: userDataActionTypes.SET_USER_SCENES,
    payload: userScenes,
  };
};
export const fetchUserScenesSuccess = () => {
  return {
    type: userDataActionTypes.FETCH_USER_SCENES_SUCCESS,
  };
};

export const fetchUserScenesEmpty = () => {
  return {
    type: userDataActionTypes.FETCH_USER_SCENES_EMPTY,
  };
};

export const fetchUserScenesFail = () => {
  return {
    type: userDataActionTypes.FETCH_USER_SCENES_FAIL,
  };
};

// --------------------------------- Fetch User's Data ---------------------------------

export const fetchData = (userId) => {
  return {
    type: userDataActionTypes.FETCH_USER_DATA,
    meta: {
      ...firebaseRealtimeRequest(
        fireRequestTypes.GET,
        `users/${userId}/public`,
        [setData],
        [fetchDataEmpty],
        [fetchDataFail]
      ),
    },
  };
};

export const fetchDataSuccess = () => {
  return {
    type: userDataActionTypes.FETCH_USER_DATA_SUCCESS,
    meta: {},
  };
};

export const fetchDataEmpty = () => {
  return {
    type: userDataActionTypes.FETCH_USER_DATA_EMPTY,
    meta: {
      ...toaster(toasterType.info, 'You currently have no data'),
    },
  };
};

export const fetchDataFail = (errorMessage) => {
  return {
    type: userDataActionTypes.FETCH_USER_DATA_FAIL,
    meta: {
      ...toaster(
        toasterType.warning,
        'An error occurred while fetching your data',
        errorMessage
      ),
    },
  };
};

export const setData = (data) => {
  data.avatar = data.avatar ? data.avatar : '/assets/images/menu/user.png';
  return {
    type: userDataActionTypes.SET_USER_DATA,
    payload: data,
  };
};

// --------------------------------- Fetch User's Role ---------------------------------

export const fetchRole = (userId) => {
  return {
    type: userDataActionTypes.FETCH_ROLE,
    meta: {
      ...firebaseRealtimeRequest(
        fireRequestTypes.GET,
        `users/${userId}/public/role`,
        [setRole],
        [fetchRoleEmpty],
        [fetchRoleFail]
      ),
    },
  };
};

export const fetchRoleSuccess = () => {
  return {
    type: userDataActionTypes.FETCH_ROLE_SUCCESS,
    meta: {},
  };
};

export const fetchRoleEmpty = () => {
  return {
    type: userDataActionTypes.FETCH_ROLE_EMPTY,
    meta: {
      ...toaster(toasterType.info, 'You currently have no role'),
    },
  };
};

export const fetchRoleFail = (errorMessage) => {
  return {
    type: userDataActionTypes.FETCH_ROLE_FAIL,
    meta: {
      ...toaster(
        toasterType.warning,
        'An error occurred while fetching your role',
        errorMessage
      ),
    },
  };
};

export const setRole = (role) => {
  return {
    type: userDataActionTypes.SET_ROLE,
    payload: role,
  };
};

// --------------------------- Listener for new scene created by the user -------------------------------
export const listenerForNewScene = (userId, newSceneId, projectId) => (
  dispatch
) => {
  const ref = firebase.database().ref(`users/${userId}/scenes/`);
  ref.on('value', (snapshot) => {
    const userScenes = snapshot.val();
    if (userScenes[newSceneId] != null) {
      dispatch(projectsListenerForNewScene(projectId, newSceneId, userScenes));
      ref.off();
    }
  });
};
