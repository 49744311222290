export const calculateRowSize = (viewType = 'list') => {
  const windowWidth = window.innerWidth;
  if (viewType === 'list') return 1;

  for (let i = 0; i < breakPoints.length; i++) {
    const breakPoint = breakPoints[i];
    if (windowWidth >= breakPoint) {
      return breakPointsToRowSize[i][viewType];
    }
  }

  return 1;
};

const breakPoints = [1600, 1200, 992, 768, 576, 400, 320, 0];

// Maps the breakPoint value to the number of items per row
const breakPointsToRowSize = [
  {
    // 1600
    medium: 9,
    large: 8,
  },
  {
    // 1200
    medium: 8,
    large: 7,
  },
  {
    // 992
    medium: 7,
    large: 6,
  },
  {
    // 768
    medium: 5,
    large: 4,
  },
  {
    // 576
    medium: 3,
    large: 3,
  },
  {
    // 400
    medium: 2,
    large: 1,
  },
  {
    // 320
    medium: 1,
    large: 1,
  },
  {
    // 0
    medium: 1,
    large: 1,
  },
];
