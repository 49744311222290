import algoliasearch from 'algoliasearch';
import _ from 'lodash';

const client = algoliasearch('PHMNSXEVLT', '60abb8e377de098d562c25f22e0cfbf2');
const index = client.initIndex('root');

export const algoliaItemsMiddleware = (store) => (next) => (action) => {
  next(action);

  if (action.meta?.algoliaItemSearch) {
    const { query = '' } = action.meta.algoliaItemSearch;
    const { itemIds } = action.meta.algoliaItemSearch;
    const { options = {} } = action.meta.algoliaItemSearch;

    const {
      allowEmptyQuery = true,
      page = 0, // page number starts from  0
      hitsPerPage = 150, // page size: default to be 10
      cacheable = true,
      filters = '', // example: 'deleted:false'
    } = options;

    const {
      onSuccessDispatches = [],
      onEmptyDispatches = [],
      onFailDispatches = [],
    } = action.meta.algoliaItemSearch;

    if (query.length === 0 && !allowEmptyQuery) {
      onSuccessDispatches.forEach((action) => {
        store.dispatch(action({}));
      });
      onEmptyDispatches.forEach((action) => {
        store.dispatch(action({}));
      });
      return;
    }

    const onError = (err) => {
      console.error(err);
      onFailDispatches.forEach((action) => {
        store.dispatch(action(err));
      });
    };

    if (itemIds) {
      // Search by IDs
      index
        .getObjects(itemIds)
        .then((res) => {
          let { results: hits } = res;
          hits = hits.filter((hit) => hit);

          hits.forEach((hit) => {
            hit.itemID = hit.objectID;
          });

          hits = { ...hits };
          for (const key in hits) {
            const { itemID } = hits[key];
            hits[itemID] = hits[key];
            delete hits[key];
          }

          onSuccessDispatches.forEach((action) => {
            store.dispatch(action(hits));
          });

          if (_.isEmpty(hits)) {
            onEmptyDispatches.forEach((action) => {
              store.dispatch(action(hits));
            });
          }
        })
        .catch(onError);
      return;
    }

    // Search by query
    index
      .search(query, { query, page, hitsPerPage, filters, cacheable })
      .then((res) => {
        const totalHitsSize = res.nbHits;
        let { hits } = res;
        hits = _.cloneDeep(hits);

        hits.forEach((hit) => {
          hit.itemID = hit.objectID;
        });

        hits = { ...hits };
        for (const key in hits) {
          const { itemID } = hits[key];
          hits[itemID] = hits[key];
          delete hits[key];
        }

        const payload = { totalHitsSize, hits };

        onSuccessDispatches.forEach((action) => {
          store.dispatch(action(payload));
        });

        if (totalHitsSize === 0 || _.isEmpty(hits)) {
          onEmptyDispatches.forEach((action) => {
            store.dispatch(action(payload));
          });
        }
      })
      .catch(onError);
  }
};
