import './UpdateNotification.scss';
import firebase from 'firebase-main';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Loader from 'common/components/loader/Loader';

export const UpdateNotification = () => {
  // const { description = '',  versionNumber } = notification ?? {};
  const [description, setDescription] = useState('');
  const [versionNumber, setVersionNumber] = useState('');

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    firebase
      .database()
      .ref(`settings/build_latest_version`)
      .once('value')
      .then((res) => {
        const data = res.val();
        console.log(data);
        setDescription(data.description);
        setVersionNumber(data.versionNumber);
      })
      .catch((err) => {
        console.error(err);
        setVisible(false);
      });
  }, []);

  const onCancel = () => {
    // readNotification();
    // dispatch(setUpdateNotification(null));
  };

  const onUpdate = () => {
    setVisible(false);
  };

  return (
    <Modal
      className="update-notification-modal"
      visible={visible}
      closable={false}
      title={null}
      footer={null}
      onCancel={onCancel}
    >
      <Loader
        show={versionNumber === ''}
        spinnerSize={32}
        spinnerColor="black"
        centerSpinner
      >
        <h1>Update Available</h1>
        <p style={{ marginBottom: '1.5vw' }}>
          New Version {versionNumber} is available.
        </p>

        {description.split(/\r?\n|\r/g).map((item) => (
          <p>{item}</p>
        ))}

        <p style={{ marginTop: '1vw' }}>
          Visit <span style={{ color: 'blue' }}>backlot.studio/download</span>{' '}
          to update
        </p>

        <footer>
          <button className="update-btn" onClick={onUpdate}>
            OK
          </button>
        </footer>
      </Loader>
    </Modal>
  );
};
