import { CharacterSlice } from './types';

type CharacterSelector = { character: CharacterSlice };

export const characterIdSelector = (store: CharacterSelector) =>
  store.character.characterId;

export const optionTypesSelector = (store: CharacterSelector) =>
  store.character.optionTypes;

export const optionsSelector = (store: CharacterSelector) =>
  store.character.options;

export const selectedOptionsSelector = (store: CharacterSelector) =>
  store.character.selectedOptions;

export const baseCharactersSelector = (store: CharacterSelector) =>
  store.character.baseCharacters;
