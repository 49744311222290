import './SearchAddress.scss';
import React, { useEffect, useState } from 'react';
import { AutoComplete, Spin } from 'antd';
import useDebounce from 'common/hooks/useDebounce';

type propTypes = {
  searchText: string;
  onChange: (v: string) => void;
  onSelect: (v: string) => void;
  debounce?: number;
};

const SearchAddress = ({
  searchText,
  onChange,
  debounce = 300,
  onSelect = () => {},
}: propTypes) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [debouncedSearchText, debounceLoading] = useDebounce(
    searchText,
    debounce
  );

  useEffect(() => {
    if (debouncedSearchText) {
      setLoading(true);
      setOptions([]);
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${debouncedSearchText}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
      )
        .then((res) => res.json())
        .then((data) => {
          const { features = [] } = data;
          const options = features.map((feature) => {
            return {
              label: feature.place_name,
              value: feature.place_name,
              key: feature.place_name,
            };
          });
          setOptions(options);
          setLoading(false);
        });
    }
  }, [debouncedSearchText]);

  return (
    <AutoComplete
      className="search-location-input"
      autoFocus
      value={searchText}
      options={debounceLoading ? [] : options}
      notFoundContent={loading || debounceLoading ? <Spin /> : 'No items found'}
      style={{ width: '100%' }}
      onSelect={onSelect}
      onSearch={onChange}
      onChange={(v) => onChange(v)}
      onFocus={(e) => {
        (e.target as any).select();
      }}
      placeholder="Search Address..."
    />
  );
};

export default SearchAddress;
