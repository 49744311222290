import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProjectForScene,
  fetchProjectsForTeam,
  fetchScenesForProject,
  fetchTeamForProject,
  fetchTeamsForUser,
  setSelectedSceneId,
  subscribeTeams,
  unsubscribeTeams,
} from 'redux/slices/app/actions';
import {
  projectsSelector,
  scenesSelector,
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  selectedTeamIdSelector,
  teamsSelector,
} from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userProjectsSelector,
  userScenesSelector,
} from 'redux/slices/auth/authSelectors';
import { subscribeToClips } from 'redux/slices/clips/actions';

export const useFetchUserData = (sceneIdFromUrl) => {
  if (sceneIdFromUrl === '#') {
    sceneIdFromUrl = undefined;
  }
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);

  const selectedTeamId = useSelector(selectedTeamIdSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const selectedSceneId = useSelector(selectedSceneIdSelector);

  const userTeams = useSelector(teamsSelector);
  const userProjects = useSelector(userProjectsSelector);
  const userScenes = useSelector(userScenesSelector);

  const availableTeams = useSelector(teamsSelector);
  const availableProjects = useSelector(projectsSelector);
  const availableScenes = useSelector(scenesSelector);

  // Set sceneId from url
  useEffect(() => {
    if (sceneIdFromUrl) {
      // dispatch(setSelectedSceneId(sceneIdFromUrl));
    }
  }, [dispatch, sceneIdFromUrl]);

  // Fetch projectId using sceneId
  useEffect(() => {
    if (sceneIdFromUrl && !selectedProjectId) {
      // dispatch(fetchProjectForScene(sceneIdFromUrl));
    }
  }, [dispatch, sceneIdFromUrl, selectedProjectId]);

  // Fetch teamId using projectId
  useEffect(() => {
    // if (selectedProjectId && !selectedTeamId)
      // dispatch(fetchTeamForProject(selectedProjectId));
  }, [dispatch, selectedProjectId, selectedTeamId]);

  // Fetch teams list for user
  useEffect(() => {
    if (userId && _.isEmpty(availableTeams)) {
      // dispatch(fetchTeamsForUser(userId));
      dispatch(subscribeTeams(userId));
    }

    return () => {
      dispatch(unsubscribeTeams());
    };
  }, [dispatch, userId]);

  // Fetch projects list for team
  useEffect(() => {
    if (
      selectedTeamId &&
      !_.isEmpty(userProjects) &&
      _.isEmpty(availableProjects)
    ) {
      console.log(selectedTeamId, availableProjects);
      // dispatch(fetchProjectsForTeam(selectedTeamId, userProjects));
    }
  }, [dispatch, selectedTeamId, userProjects]);

  // Fetch scenes list for project
  useEffect(() => {
    if (
      selectedProjectId &&
      !_.isEmpty(userScenes) &&
      _.isEmpty(availableScenes)
    ) {
      // dispatch(fetchScenesForProject(selectedProjectId, userScenes));
    }
  }, [dispatch, selectedProjectId, userScenes]);

  // Fetch clips for using there data in referencing
  useEffect(() => {
    dispatch(subscribeToClips(sceneIdFromUrl));
  }, [sceneIdFromUrl]);
};
