import { createSelector } from 'reselect';
import { ItemImage } from './types';

type gallerySlice = {
  itemsGallery: {
    images: { [key: string]: ItemImage };
    pendingUpload: null | string;
  };
};

export const galleryImagesSelector = createSelector(
  (state: gallerySlice) => state.itemsGallery.images,
  (images) => Object.values(images ?? {})
);

export const pendingImageSelector = (state: gallerySlice) =>
  state.itemsGallery.pendingUpload;
