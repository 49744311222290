import DuplicateSceneModal from 'backlot-menu/components/modals/duplicateSceneModal/DuplicateSceneModal';
import OpenSceneModal from 'backlot-menu/components/modals/openSceneModal/OpenSceneModal';
import Loader from 'common/components/loader/Loader';
import firebase from 'firebase-main';
import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dispatchEvents } from 'redux/middlewares/amplitude';
import {
  setAppLoader,
  setScene,
  setSelectedSceneId,
  subscribeScene,
} from 'redux/slices/app/actions';
import {
  selectedProjectIdSelector,
  selectedSceneSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { openScene, updateRecentScenes } from 'redux/slices/project/actions';
import SceneOptions from '../scene/sceneOptions/SceneOptions';

const SceneGridCard = ({ scene, index, displayIndex = true }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(userIdSelector);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showOpenSceneModal, setShowOpenSceneModal] = useState(false);
  const projectId = scene.projectId ?? useSelector(selectedProjectIdSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const sceneName: string = scene.loading ? scene.name.name : scene.name;
  const selectedScene = useSelector(selectedSceneSelector);

  return (
    <>
      {showDuplicateModal && (
        <DuplicateSceneModal onClose={() => setShowDuplicateModal(false)} />
      )}

      {showOpenSceneModal && (
        <OpenSceneModal
          thumbnail={scene.thumbnail}
          title={`${displayIndex ? index + 1 + '. ' : ''}${sceneName}`}
          onClose={() => {
            dispatch(setScene(null));
            setShowOpenSceneModal(false);
          }}
          loading={!selectedScene}
          onEnter={() => {
            // dispatch(updateRecentScenes(teamId, projectId, scene.id));
            // dispatch(setAppLoader(true));
            // (window as any).selectScene(
            //   scene.id,
            //   scene.name,
            //   scene?.projectId ?? projectId ?? selectedScene?.project_id
            // );
            // firebase.analytics().logEvent('open_scene', scene);
            // dispatchEvents([{ event_type: 'open_scene', user_id: userId }]);
            dispatch(openScene(teamId, projectId, scene.id, scene.name));
            history.push(`/menu/project/scene/${scene.id}`);
          }}
        />
      )}
      <div
        className="scene-container"
        onClick={() => {
          // dispatch(subscribeScene(scene.id));
          dispatch(setSelectedSceneId(scene.id));
          setShowOpenSceneModal(true);
        }}
        style={{ position: 'relative' }}
      >
        {scene.loading === true ? (
          <div className="scene-loader">
            <Loader
              centerSpinner
              hideChildren
              backgroundStyle={{ height: '100%' }}
              spinnerColor="white"
              show={scene.loading}
            />
          </div>
        ) : null}

        <div
          className="image1 screen-content-card"
          style={{
            backgroundImage: `url("${scene.thumbnail ?? '/empty-scene.jpg'}")`,
          }}
        >
          <div
            className="screen-content-card-icon-container"
            onClick={(e) => e.stopPropagation()}
          >
            {/* <img
            className="screen-content-card-icon"
            alt="save"
            src={'/assets/images/menu/save.svg'}
          /> */}
            <img
              src="/menu/grid/duplicate.svg"
              alt="duplicate"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedSceneId(scene.id));
                setShowDuplicateModal(true);
              }}
              className="screen-content-card-icon"
            />

            <img
              src="/menu/share.svg"
              alt="share"
              className="screen-content-card-icon"
            />
            <SceneOptions
              view="grid"
              sceneName={sceneName}
              sceneId={scene.id}
            />
          </div>
          <div className="screen-content-card-title">
            {/* {displayIndex && <span className="scene-index"> {index + 1} </span>} */}
            {sceneName?.toUpperCase()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SceneGridCard;
