import './CreateSceneButton.scss';
import React, { useState } from 'react';
import CreateSceneModal from '../../newScene/create-scene-modal/CreateSceneModal';

export const CreateSceneButton = ({ className = '' }) => {
  const [showNewSceneModal, setShowNewSceneModal] = useState(false);
  return (
    <>
      {showNewSceneModal && (
        <CreateSceneModal onCancel={() => setShowNewSceneModal(false)} />
      )}
      <div
        className={`create-scene-btn ${className}`}
        onClick={() => setShowNewSceneModal(true)}
      >
        <div className="content">
          <div className="plus-btn">
            <span>+</span>
          </div>
          <div className="title">New Scene</div>
        </div>
      </div>
    </>
  );
};
