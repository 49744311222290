import { mainActionTypes } from './../../common/types';

export interface ItemImage {
  url: string;
  userName: string;
  timestamp: string;
}

export type galleryActionType = mainActionTypes | 'UPLOAD';

export type galleryResourceType = 'IMAGE';

export type galleryActionStatus = 'SUCCESS' | 'FAIL';

export const galleryNamespace = 'gallery';

export const galleryAction = (
  actionType: galleryActionType,
  resource?: galleryResourceType,
  status?: galleryActionStatus
) => {
  let action = `${galleryNamespace}/${actionType}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;

  return action;
};
