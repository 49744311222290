import { useEffect, useState } from 'react';

const useDebouncedState = (initState, delay = 500) => {
  const [state, setState] = useState(initState);
  const [debouncedState, setDebouncedState] = useState(initState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (debouncedState !== state) {
        setDebouncedState(state);
      }
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  return [state, setState, debouncedState];
};

export default useDebouncedState;
