import './PurchaseNoticeModal.scss';
import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Modal from 'antd/lib/modal/Modal';

export const PurchaseNoticeModal = ({ onOk = () => {}, item }) => {
  const { thumbnail } = item;
  const images = thumbnail.split(',');

  const [carousalIndex, setCarousalIndex] = useState(0);

  const onSubmit = async () => {
    onOk();
  };

  const renderThumbnail =
    !images || images.length <= 1 ? (
      <div
        className="img-container"
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      />
    ) : (
      <div className="carousel-container">
        <Carousel
          indicators={false}
          activeIndex={carousalIndex}
          onSelect={setCarousalIndex}
          interval={null}
          prevIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === 0) {
                  setCarousalIndex(images.length - 1);
                } else {
                  setCarousalIndex(carousalIndex - 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-prev-icon"
            />
          }
          nextIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === images.length - 1) {
                  setCarousalIndex(0);
                } else {
                  setCarousalIndex(carousalIndex + 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-next-icon"
            />
          }
        >
          {images.map((src) => (
            <Carousel.Item key={src}>
              <img className="carousel-img" src={src} alt="environment" />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="purchase-item-notice-modal"
      visible
      onCancel={() => {
        onOk();
      }}
      footer={null}
      closable={false}
    >
      <div className="item-desc">
        {renderThumbnail}
        <div className="desc">
          <div className="price">Purchased</div>
          <div className="name">
            Item has begun downloading and will import upon completion
          </div>
        </div>
      </div>

      <div className="footer">
        <button className="submit" type="button" onClick={onSubmit}>
          OK
        </button>
      </div>
    </Modal>
  );
};
