import { Action } from 'redux/common/types';
import { settingsAction } from './types';

export const setSettings = (settings): Action => ({
  type: settingsAction('SET', 'SETTINGS'),
  payload: settings,
});

export const setResOptions = (options): Action => ({
  type: settingsAction('SET', 'RES_OPTIONS'),
  payload: options,
});
