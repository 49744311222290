import { galleryAction } from './types';
import produce from 'immer';

const initState = {
  images: {},
  pendingUpload: null,
};

export const itemGalleryReducer = produce((state = initState, action) => {
  switch (action.type) {
    case galleryAction('UPLOAD', 'IMAGE'): {
      state.pendingUpload = action.payload;
      return state;
    }

    case galleryAction('SET', 'IMAGE', 'SUCCESS'):
    case galleryAction('SET', 'IMAGE', 'FAIL'): {
      state.pendingUpload = null;
      return state;
    }

    case galleryAction('SET', 'IMAGE'): {
      state.images = action.payload;
      return state;
    }

    default:
      return state;
  }
});
