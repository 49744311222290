import { combineReducers } from 'redux';
import { appLoadersReducer } from './reducers/appLoaders';
import { authLoadersReducer } from './reducers/authLoaders';
import { blueprintsLoadersReducer } from './reducers/blueprintsLoader';
import { sceneCommentsLoadersReducer } from './reducers/commentsLoaders';
// import { sceneCommentsLoadersReducer } from './reducers/sceneComments';
import { libraryItemsReducer } from './reducers/libraryItems';
import { projectLoadersReducer } from './reducers/projectLoaders';

export const loadersReducer = combineReducers({
  authLoaders: authLoadersReducer,
  blueprints: blueprintsLoadersReducer,
  appLoaders: appLoadersReducer,
  sceneCommentsLoaders: sceneCommentsLoadersReducer,
  libraryItemsLoaders: libraryItemsReducer,
  projectLoaders: projectLoadersReducer,
});
