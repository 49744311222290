import React, { useState } from 'react';
import '../../Reply/Reply.css';
import { useSelector } from 'react-redux';
import { Form, Button } from 'antd';
import 'antd/dist/antd.css';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from './defaultStyles';
import './EditComment.css';

/**
 * Renders the input element for adding a new comment
 */

const EditComment = ({
  text = '',
  tags = {},
  commentID = '',
  onEditComment = () => {},
  toggleEdit = () => {},
}: {
  [key: string]: any;
}) => {
  const [form] = Form.useForm();
  const [comment, setComment] = useState(text);
  const [mentions, setMentions] = useState(tags ? tags : {});

  let users = useSelector<any>((state) => state.app.users);

  const addMentions = (id, display) => {
    if (mentions[id] == null) {
      setMentions({
        ...mentions,
        [id]: display,
      });
    }
  };

  const onSubmit = (e) => {
    let mentionsIds = Object.keys(mentions);
    let tags = {};
    mentionsIds.forEach((id) => {
      if (comment.includes(id)) {
        tags[id] = mentions[id];
      }
    });

    if (onEditComment) {
      onEditComment(comment, tags);
    }
    toggleEdit();
  };

  const renderSuggestion = React.useCallback(
    (entry, search, highlightedDisplay, index, focused) => {
      return (
        <div className="d-flex justify-content-start">
          <img
            src={entry.avatar}
            style={{ width: '25px', height: '25px' }}
            alt="Suggested user's avatar"
          />
          <span className="mx-3"> {entry.display} </span>
        </div>
      );
    },
    []
  );

  return (
    <React.Fragment>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onSubmit}
        style={{ maxWidth: '100%', padding: '0 10px', minWidth: '100%' }}
      >
        <Form.Item className="mb-0">
          <div>
            <MentionsInput
              allowSpaceInQuery
              value={comment}
              singleLine={false}
              style={defaultStyle}
              onChange={(e) => setComment(e.target.value)}
              id="edit-box"
            >
              <Mention
                type="user"
                trigger="@"
                data={users ? users : null}
                markup="@[(__display__,__id__)]"
                renderSuggestion={renderSuggestion}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@${display}`}
                onAdd={addMentions}
              />
            </MentionsInput>
          </div>
        </Form.Item>
        <Form.Item className="mb-0">
          <div className="text-right">
            <Button className="editComment-btn" onClick={toggleEdit}>
              Cancel
            </Button>
            <Button
              className="editComment-btn"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default EditComment;
