import './ResumeSubModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import firebase from 'firebase-main';
import { toasterAction } from 'redux/common/actions';
import PaymentSummary from '../license-modal/paymentSummary/PaymentSummary';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      fontFamily: 'Open Sans, sans-serif',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement
      options={CARD_OPTIONS as any}
      onChange={onChange}
      className="StripeElement"
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`Paybutton SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <Button type="primary" className="ResetButton" onClick={onClick}>
    Back
  </Button>
);

export const ResumeSubModal = ({ teamId, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [token, setToken] = useState<boolean | null>(null);
  const [form] = Form.useForm();

  const [fetchPricePending, setFetchPricePending] = useState(false);
  const [formSubmitPending, setFormSubmitPending] = useState(false);
  const [price, setPrice] = useState(null);

  // Fetch resume subscription cost
  useEffect(() => {
    setFetchPricePending(true);
    firebase
      .functions()
      .httpsCallable('resumeSubscriptionPrice')({ teamId })
      .then((res) => {
        setFetchPricePending(false);
        setPrice(res.data.price);
      })
      .catch((err) => {
        dispatch(toasterAction('error', 'Something went wrong', err));
        onClose();
      });
  }, []);

  const handleSubmit = async (formValues) => {
    // event.preventDefault();
    setFormSubmitPending(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card')?.focus();
      return;
    }

    if (cardComplete) {
      // setProcessing(true);
    }

    // Use your card Element with other Stripe.js APIs
    const { error: paymentMethodError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as any,
      });

    try {
      if (paymentMethodError) {
        setError(error);
      } else {
        if (paymentMethod) {
          // onSubmit(paymentMethod.id);
          const res = await firebase
            .functions()
            .httpsCallable('resumeSubscription')({
            teamId,
            paymentMethodId: paymentMethod.id,
          });

          if (res.data.message === 'requires_action') {
            try {
              await stripe?.confirmCardPayment(res.data.client_secret, {
                payment_method: res.data.paymentMethodId,
              });
            } catch (err: any) {
              await firebase
                .functions()
                .httpsCallable('resumeSubscriptionConfirmationResponse')({
                responseToken: res.data.responseToken,
              });

              dispatch(toasterAction('error', 'Failed to resume subscription'));
              setFormSubmitPending(false);
              return;
            }

            await firebase
              .functions()
              .httpsCallable('resumeSubscriptionConfirmationResponse')({
              responseToken: res.data.responseToken,
            });
          }

          dispatch(
            toasterAction('success', 'Subscription resumed successfully')
          );
          onClose();
        } else {
          // dispatch(onFail('Payment method is needed!'));
          throw 'No payment method id found';
        }
      }
    } catch (err: any) {
      dispatch(toasterAction('error', 'Failed to create payment', err));
      onClose();
    }
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title="Resume Subscription"
      visible
      onCancel={onClose}
      footer={null}
    >
      <Form
        name="React_Stripe"
        layout={'vertical'}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item>
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </Form.Item>
        {error && <ErrorMessage>{error?.message}</ErrorMessage>}

        <PaymentSummary loader={fetchPricePending} cost={price} credit={0} />

        <div className="d-flex justify-content-center">
          <SubmitButton
            processing={formSubmitPending}
            error={error}
            disabled={!stripe}
          >
            Resume Subscription
          </SubmitButton>
        </div>
      </Form>
    </Modal>
  );
};
