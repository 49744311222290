import './App.css';
import Login from 'common/components/login/Login';
import PrivateRoute from 'common/components/route-types/PrivateRoute';
import PublicRoute from 'common/components/route-types/PublicRoute';
import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { BacklotComments } from 'backlot-comments/BacklotComments';
import { BacklotLibrary } from 'backlot-library/BacklotLibrary';
// import RoutesMenu from 'RoutesMenu';
import { BacklotMenu } from 'backlot-menu/BacklotMenu';
import SceneSettings from 'backlot-scene-settings/SceneSettings';
import BacklotShare from 'backlot-share/BacklotShare';
import { useInstantSearch } from 'common/hooks/useInstantSearch';
import BacklotDownloads from 'backlot-downloads/BacklotDownloads';
import BacklotExport from 'backlot-export/BacklotExport';
import Loader from 'common/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  appLoadingSelector,
  personalDriveIdSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import NotFoundPage from 'common/components/not-found-page/NotFoundPage';
import BacklotScenes from 'backlot-scenes/BacklotScenes';
import BacklotBugReport from 'backlot-bug-report/BacklotBugReport';
import BacklotAnimations from 'backlot-animations/BacklotAnimations';
import { BacklotCurveEditor } from 'backlot-curve-editor/BacklotCurveEditor';
import BacklotPropAnimations from 'backlot-prop-animations/BacklotPropAnimations';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import firebase from 'firebase-main';
import { BacklotCharacterWizard } from 'backlot-character-wizard/BacklotCharacterWizard';
import { BacklotSettings } from 'backlot-settings/BacklotSettings';
import {
  subscribePersonalDriveCustomerId,
  subscribePersonalDriveIsCanceled,
  subscribePersonalDriveStatus,
  subscribeTeamDriveStatus,
  unSubscribePersonalDriveCustomerId,
  unsubscribePersonalDriveIsCanceled,
  unsubscribePersonalDriveStatus,
  unsubscribeTeamDriveStatus,
} from 'redux/slices/app/actions';
import Blueprint from 'backlot-blueprint/Blueprint';
// import SunEffect from 'backlot-scene-settings/components/sun-effect/SunEffect';

function App() {
  useInstantSearch();
  const dispatch = useDispatch();
  const appIsLoading = useSelector(appLoadingSelector);

  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const personalDriveId = useSelector(personalDriveIdSelector);

  useEffect(() => {
    if (userId) {
      firebase.analytics().setUserId(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userName) firebase.analytics().setUserProperties({ userName });
  }, [userName]);

  useEffect(() => {
    if (personalDriveId) {
      dispatch(subscribePersonalDriveStatus(personalDriveId));
      dispatch(subscribePersonalDriveIsCanceled(personalDriveId));
      dispatch(subscribePersonalDriveCustomerId(personalDriveId));
    }
    if (personalDriveId && teamId && teamId !== personalDriveId) {
      dispatch(subscribeTeamDriveStatus(teamId));
    }
    return () => {
      dispatch(unsubscribePersonalDriveStatus());
      dispatch(unsubscribePersonalDriveIsCanceled());
      dispatch(unSubscribePersonalDriveCustomerId());
      dispatch(unsubscribeTeamDriveStatus());
    };
  }, [dispatch, personalDriveId, teamId]);

  return (
    <Loader show={appIsLoading} fullscreen hideChildren>
      <Switch>
        <PublicRoute
          path="/not-found"
          component={NotFoundPage}
          redirect="/login"
        />

        <PublicRoute path={`/login`} component={Login} restricted />

        <PrivateRoute path={'/settings/:teamId'} component={BacklotSettings} />

        <PrivateRoute path={`/comments`} component={BacklotComments} />

        <PrivateRoute path={`/blueprint/:sceneId`} component={Blueprint} />

        <PrivateRoute path={`/library`} component={BacklotLibrary} />

        <PrivateRoute path={`/menu`} component={BacklotMenu} />

        <PrivateRoute path={`/downloads`} component={BacklotDownloads} />

        <PrivateRoute path={`/share/:sceneId`} component={BacklotShare} />

        <PrivateRoute path={`/scenesettings`} component={SceneSettings} />

        <PrivateRoute path={`/export`} component={BacklotExport} />

        <PrivateRoute path={`/bug-report`} component={BacklotBugReport} />

        <PrivateRoute path={`/animations`} component={BacklotAnimations} />

        <PublicRoute path={'/curve-editor'} component={BacklotCurveEditor} />

        <PublicRoute
          path={`/character-wizard`}
          component={BacklotCharacterWizard}
        />

        <PrivateRoute
          path={`/prop-animations`}
          component={BacklotPropAnimations}
        />

        <PrivateRoute
          path={`/scene-switcher/:projectId`}
          component={BacklotScenes}
        />
        {/* <PrivateRoute path="/tmp" component={SunEffect} /> */}
        {/* <PrivateRoute path="/" component={RoutesMenu} exact /> */}

        {/* <PublicRoute path="*">
        <Redirect to="/login" />
      </PublicRoute> */}

        <PrivateRoute path="*">
          <Redirect to={`/not-found`} />
        </PrivateRoute>
      </Switch>
    </Loader>
  );
}

export default App;
