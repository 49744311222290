import './TeamSharePanel.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { TeamProjectTab } from './team-project-tab/TeamProjectTab';
import { useSelector } from 'react-redux';
import { selectedProjectNameSelector } from 'redux/slices/app/selectors';

export const ShareTeamProjectModal = ({ onClose = () => {} }) => {
  const projectName = useSelector(selectedProjectNameSelector);
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="personal-share-modal"
      title={`Share ${projectName}`}
      visible
      onCancel={onClose}
      footer={null}
    >
      <TeamProjectTab />
    </Modal>
  );
};
