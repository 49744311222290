import { BLUEPRINT_FPS } from 'common/config/blueprints';
import _ from 'lodash';
import {
  blueprintsActionTypes,
  fetchBlueprintsFail,
  fetchBlueprintsSuccess,
  setBlueprintEnvironmentSettings,
  setCenterPosition,
  setOriginalBlueprints,
} from 'redux/slices/blueprints/actions';
import {
  incrementTimeFrame,
  setDuration,
  pauseBlueprint,
  setBluePrintScenes,
  setBlueprintDimensions,
} from 'redux/slices/blueprints/actions';

// const api = 'http://share.backlot.studio/api/blueprint/-MGsUJ_GMPbleEJOIYR5';

let playerInterval;

export const blueprintPlayerMiddleware = (store) => (next) => (action) => {
  next(action);
  const { type } = action;
  if (type === blueprintsActionTypes.FETCH_BLUEPRINTS) {
    fetch(action.payload.link)
      .then((response) => {
        response
          .json()
          .then((res) => {
            // Fetching data
            const sceneData = res;
            const objects = sceneData.objects;

            // Calculate scene bounds

            sceneData.offset = { x: 0, z: 0 };
            sceneData.min.x = Infinity;
            sceneData.min.z = Infinity;
            sceneData.max.x = -Infinity;
            sceneData.max.z = -Infinity;

            for (const objectId in objects) {
              const curObject = objects[objectId];
              const width = curObject.boundingBox
                ? curObject.boundingBox.x + 0
                : 10;
              const height = curObject.boundingBox
                ? curObject.boundingBox.z + 0
                : 10;
              for (const curFrame in curObject.posKeyframes) {
                const curTransform = curObject.posKeyframes[curFrame];
                const x = curTransform.posX;
                const z = curTransform.posZ;
                sceneData.min.x = Math.min(x - width / 2, sceneData.min.x);
                sceneData.min.z = Math.min(z - height / 2, sceneData.min.z);
                sceneData.max.x = Math.max(x + width / 2, sceneData.max.x);
                sceneData.max.z = Math.max(z + height / 2, sceneData.max.z);

                if (x < 0)
                  sceneData.offset.x = Math.max(sceneData.offset.x, -x);
                if (z < 0)
                  sceneData.offset.z = Math.max(sceneData.offset.z, -z);
              }
            }

            if (sceneData.min.x < 0) {
              sceneData.offset.x = Math.max(
                sceneData.offset.x,
                -sceneData.min.x
              );
            }
            if (sceneData.min.z < 0) {
              sceneData.offset.z = Math.max(
                sceneData.offset.z,
                -sceneData.min.z
              );
            }
            // scenesData.min.x = Math.min(scenesData.min.x, scenesData.min.z);
            // scenesData.min.z = Math.min(scenesData.min.x, scenesData.min.z);
            // scenesData.max.z = Math.max(scenesData.max.x, scenesData.max.z);
            // scenesData.max.x = Math.max(scenesData.max.x, scenesData.max.z);

            // Push keys inside objects

            for (const objectId in objects) {
              objects[objectId].id = objectId;
            }

            store.dispatch(setOriginalBlueprints(_.cloneDeep(res)));

            const sceneDimensions = {
              xMin: Math.floor(sceneData.min.x),
              xMax: Math.ceil(sceneData.max.x),
              zMin: Math.floor(sceneData.min.z),
              zMax: Math.ceil(sceneData.max.z),
              // xOffset: Math.ceil(sceneData.offset.x / 100) * 100,
              // zOffset: Math.ceil(sceneData.offset.z / 100) * 100,
              xOffset: Math.ceil(sceneData.offset.x),
              zOffset: Math.ceil(sceneData.offset.z),
            };

            const toNearestTens = (n) => Math.ceil((n + 1) / 10) * 10;

            store.dispatch(
              setBlueprintDimensions(
                {
                  x: toNearestTens(sceneDimensions.xMin),
                  z: toNearestTens(sceneDimensions.zMin),
                },
                {
                  x: toNearestTens(sceneDimensions.xMax),
                  z: toNearestTens(sceneDimensions.zMax),
                },
                {
                  x: toNearestTens(sceneDimensions.xOffset),
                  z: toNearestTens(sceneDimensions.zOffset),
                }
              )
            );

            store.dispatch(
              setBlueprintEnvironmentSettings(sceneData.environmentSettings)
            );

            // Normalizing co-ordinates

            // const rangeX = sceneDimensions.xMax - sceneDimensions.xMin;
            // const rangeZ = sceneDimensions.zMax - sceneDimensions.zMin;

            // for (const objectId in objects) {
            //   const curObject = objects[objectId];
            //   for (const curFrame in curObject.posKeyframes) {
            //     const curTransform = curObject.posKeyframes[curFrame];
            //     const percentileX =
            //       (curTransform.posX - sceneDimensions.xMin) / rangeX;
            //     const percentileZ =
            //       (curTransform.posZ - sceneDimensions.zMin) / rangeZ;

            //     const newPosX = BLUEPRINT_CONTAINER_WIDTH * percentileX;
            //     const newPosZ = BLUEPRINT_CONTAINER_HEIGHT * percentileZ;

            //     curTransform.posX = newPosX;
            //     curTransform.posZ = BLUEPRINT_CONTAINER_HEIGHT - newPosZ;
            //   }
            // }

            // Transforming bounding boxes

            // for (const objectId in objects) {
            //   const curObject = objects[objectId];
            //   if (curObject.boundingBox) {
            //     const width = curObject.boundingBox.x;
            //     const height = curObject.boundingBox.z;

            //     const newWidth = (width * BLUEPRINT_CONTAINER_WIDTH) / rangeX;
            //     const newHeight =
            //       (height * BLUEPRINT_CONTAINER_HEIGHT) / rangeZ;

            //     curObject.boundingBox.x = newWidth;
            //     curObject.boundingBox.z = newHeight;
            //   }
            // }

            // Normalize rotations

            for (const objectId in objects) {
              const curObject = objects[objectId];
              for (const curFrame in curObject.rotKeyframes) {
                const rotation = -curObject.rotKeyframes[curFrame];
                curObject.rotKeyframes[curFrame] = { rotation };
              }

              if (curObject.yawKeyframes)
                for (const curFrame in curObject.yawKeyframes) {
                  const rotation = curObject.yawKeyframes[curFrame];
                  curObject.yawKeyframes[curFrame] = { rotation };
                }
            }

            // Normalize dimensions

            // for (const objectId in objects) {
            //   const curObject = objects[objectId];
            //   if (curObject.dimensions) {
            //     const objectDimensions = curObject.dimensions;
            //     const rangeX = sceneDimensions.xMax - sceneDimensions.xMin;
            //     const rangeZ = sceneDimensions.zMax - sceneDimensions.zMin;
            //     objectDimensions.width =
            //       (objectDimensions.width / rangeX) * BLUEPRINT_CONTAINER_WIDTH;
            //     objectDimensions.height =
            //       (objectDimensions.height / rangeZ) *
            //       BLUEPRINT_CONTAINER_HEIGHT;
            //   }
            // }

            // Capitalize objects' types

            for (const objectId in objects) {
              const curObject = objects[objectId];
              curObject.Type = curObject.Type.toUpperCase();
            }

            // Add default metaData

            for (const objectId in objects) {
              const curObject = objects[objectId];
              if (!curObject.metadata) {
                curObject.metadata = {};
              }
              if (!curObject.name) {
                curObject.name = curObject.Type;
              }
              curObject.metadata.name = curObject.name;

              if (curObject.Equipment)
                curObject.equipment = curObject.Equipment;
            }

            // Add default dynamic metadata

            for (const objectId in objects) {
              const curObject = objects[objectId];
              if (!curObject.dynamicMetadata) curObject.dynamicMetadata = {};
            }

            // Remove faulty dynamic metadata

            for (const objectId in objects) {
              const curObject = objects[objectId];
              for (const meta in curObject.dynamicMetadata) {
                if (_.isEmpty(curObject.dynamicMetadata[meta])) {
                  curObject.metadata[meta] = '-';
                  delete curObject.dynamicMetadata[meta];
                }
              }
            }

            store.dispatch(setBluePrintScenes(objects));

            // Calculate center
            let centerX = 0;
            let centerZ = 0;
            let objCnt = 0;
            for (const objectId in objects) {
              const curObject = objects[objectId];
              ++objCnt;
              centerX += curObject.posKeyframes[0].posX;
              centerZ += curObject.posKeyframes[0].posZ;
            }

            if (objCnt !== 0) {
              centerX /= objCnt;
              centerZ /= objCnt;
            }

            store.dispatch(setCenterPosition(centerX, centerZ));

            // Calculate & set duration of the scene
            let max = 0;
            for (const objectKey in objects) {
              for (const keyFrame in objects[objectKey].posKeyframes) {
                max = Math.max(max, +keyFrame);
              }
              for (const keyFrame in objects[objectKey].rotKeyframes) {
                max = Math.max(max, +keyFrame);
              }
            }
            store.dispatch(setDuration(max));
            store.dispatch(fetchBlueprintsSuccess());
          })
          .catch((err) => {
            store.dispatch(fetchBlueprintsFail(err));
            console.error(err);
          });
      })
      .catch((err) => {
        store.dispatch(fetchBlueprintsFail(err));
        console.error(err);
      });
  } else if (type === blueprintsActionTypes.PLAY_BLUEPRINT) {
    const { duration } = store.getState().blueprint;
    clearPlayerInterval();
    playerInterval = setInterval(() => {
      const { timeFrame } = store.getState().blueprint;
      if (timeFrame < duration) {
        store.dispatch(incrementTimeFrame());
      } else {
        clearPlayerInterval();
        store.dispatch(pauseBlueprint());
      }
    }, 1000 / BLUEPRINT_FPS);
  } else if (type === blueprintsActionTypes.PAUSE_BLUEPRINT) {
    clearPlayerInterval();
  }
};

const clearPlayerInterval = () => {
  if (playerInterval) {
    clearInterval(playerInterval);
  }
};
