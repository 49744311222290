import { svgTypes } from './constants';
/**
 * Maps commenters' position to a color with which it will be displayed
 */
export const positionToColor = {
  GAFFER: 'rgba(255, 212, 23, 0.6)',
  DIRECTOR: 'rgba(136, 88, 234, 0.6)',
  DP: 'rgba(0, 255, 87, 0.69)',
};

export const svgToHref = {
  [svgTypes.CAMERA]: '/blueprints/camera2.svg',
  [svgTypes.LIGHT]: '/blueprints/light2.svg',
};
