import { Action } from './../../common/types';
import Axios from 'axios';
import { fireRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRequestTypes';

export const blueprintsActionTypes = {
  SUBSCRIBE_BLUEPRINTS_LINK: 'SUBSCRIBE_BLUEPRINTS_LINK',
  UNSUBSCRIBE_BLUEPRINTS_LINK: 'UNSUBSCRIBE_BLUEPRINTS_LINK',
  FETCH_BLUEPRINTS_LINK: 'FETCH_BLUEPRINTS_LINK',
  FETCH_BLUEPRINTS: 'FETCH_BLUEPRINTS',
  FETCH_BLUEPRINTS_EMPTY: 'FETCH_BLUEPRINTS_EMPTY',
  FETCH_BLUEPRINTS_FAIL: 'FETCH_BLUEPRINTS_FAIL',
  FETCH_BLUEPRINTS_SUCCESS: 'FETCH_BLUEPRINTS_SUCCESS',

  SET_ENVIRONMENT_SETTINGS: 'SET_ENVIRONMENT_SETTINGS',

  SET_ORIGINAL_BLUEPRINTS: 'SET_ORIGINAL_BLUEPRINTS',

  SET_CENTER_POSITION: 'SET_CENTER_POSITION',

  SET_BLUEPRINT_SCENES: 'SET_BLUEPRINT_SCENES',
  SET_BLUEPRINT_DIMENSIONS: 'SET_BLUEPRINT_DIMENSIONS',
  SET_BLUEPRINT_DURATION: 'SET_BLUEPRINT_DURATION',

  PLAY_BLUEPRINT: 'PLAY_BLUEPRINT',
  PAUSE_BLUEPRINT: 'PAUSE_BLUEPRINT',

  SET_HIGHLIGHTED_OBJECT: 'SET_HIGHLIGHTED_OBJECT',
  RESET_HIGHLIGHTED_OBJECT: 'RESET_HIGHLIGHTED_OBJECT',
  SET_TIME_FRAME_BLUEPRINT: 'SET_TIME_FRAME_BLUEPRINT',
  INCREMENT_TIME_FRAME: 'INCREMENT_TIME_FRAME',

  SHOW_OBJECT: 'SHOW_OBJECT',
  HIDE_OBJECT: 'HIDE_OBJECT',

  SELECT_OBJECT: 'SELECT_OBJECT',
  DESELECT_OBJECT: 'DESELECT_OBJECT',
  SET_SELECTED_OBJECT_TAB: 'SET_SELECTED_OBJECT_TAB',

  HOVER_OBJECT: 'HOVER_OBJECT',
  UN_HOVER_OBJECT: 'UN_HOVER_OBJECT',

  SET_SCALE: 'SET_SCALE',
  SET_POSITION: 'SET_POSITION',

  SET_MEASURING_OBJECTS: 'SET_MEASURING_OBJECTS',
  DELETE_DISTANCE: 'DELETE_DISTANCE',

  CLEAR_BLUEPRINTS: 'CLEAR_BLUEPRINTS',

  SET_SVG_DIMENSIONS: 'SET_SVG_DIMENSIONS',

  FETCH_OBJECTS_THUMBNAILS: 'FETCH_OBJECTS_THUMBNAILS',
  FETCH_OBJECTS_THUMBNAILS_SUCCESS: 'FETCH_OBJECTS_THUMBNAILS_SUCCESS',
  FETCH_OBJECTS_THUMBNAILS_FAIL: 'FETCH_OBJECTS_THUMBNAILS_FAIL',

  SET_THUMBNAILS: 'SET_THUMBNAILS',
};

export const subscribeBlueprintsLink = (sceneId) => {
  return {
    type: blueprintsActionTypes.SUBSCRIBE_BLUEPRINTS_LINK,
    meta: {
      firebaseRealtimeApi: {
        id: 'blueprint',
        req: fireRequestTypes.SUBSCRIBE,
        url: `blueprints/${sceneId}`,
        onSuccessDispatches: [fetchBlueprints],
        onEmptyResponseDispatches: [fetchBlueprintsEmpty],
        onFailDispatches: [fetchBlueprintsFail],
      },
    },
  };
};

export const unsubscribeBlueprintsLink = () => {
  return {
    type: blueprintsActionTypes.UNSUBSCRIBE_BLUEPRINTS_LINK,
    meta: {
      firebaseRealtimeApi: {
        id: 'blueprint',
        req: fireRequestTypes.UNSUBSCRIBE,
      },
    },
  };
};

export const fetchBlueprintsLink = (sceneId) => {
  return {
    type: blueprintsActionTypes.FETCH_BLUEPRINTS_LINK,
    meta: {
      firebaseRealtimeApi: {
        req: fireRequestTypes.GET,
        url: `blueprints/${sceneId}`,
        onSuccessDispatches: [fetchBlueprints],
        onEmptyResponseDispatches: [fetchBlueprintsEmpty],
        onFailDispatches: [fetchBlueprintsFail],
      },
    },
  };
};

export const fetchBlueprints = (link) => {
  return {
    type: blueprintsActionTypes.FETCH_BLUEPRINTS,
    payload: link,
  };
};

export const fetchBlueprintsSuccess = (blueprintsUrl?) => {
  return {
    type: blueprintsActionTypes.FETCH_BLUEPRINTS_SUCCESS,
    payload: { url: blueprintsUrl },
  };
};

export const fetchBlueprintsEmpty = () => {
  return {
    type: blueprintsActionTypes.FETCH_BLUEPRINTS_EMPTY,
  };
};

export const fetchBlueprintsFail = (errorMessage) => {
  return {
    type: blueprintsActionTypes.FETCH_BLUEPRINTS_FAIL,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch blueprints', errorMessage),
      // ...toaster(toasterType.warning, 'Data not available'),
    },
  };
};

export const setOriginalBlueprints = (data) => {
  return {
    type: blueprintsActionTypes.SET_ORIGINAL_BLUEPRINTS,
    payload: data,
  };
};

export const setCenterPosition = (x, z): Action => ({
  type: blueprintsActionTypes.SET_CENTER_POSITION,
  payload: { x, z },
});

export const setBluePrintScenes = (data) => {
  return {
    type: blueprintsActionTypes.SET_BLUEPRINT_SCENES,
    payload: data,
  };
};

export const setBlueprintDimensions = (min, max, offset) => {
  return {
    type: blueprintsActionTypes.SET_BLUEPRINT_DIMENSIONS,
    payload: {
      min,
      max,
      offset,
    },
  };
};

export const setBlueprintEnvironmentSettings = (settings) => ({
  type: blueprintsActionTypes.SET_ENVIRONMENT_SETTINGS,
  payload: settings,
});

export const setTimeFrame = (timeFrame) => {
  return {
    type: blueprintsActionTypes.SET_TIME_FRAME_BLUEPRINT,
    payload: timeFrame,
  };
};

export const setDuration = (duration) => {
  return {
    type: blueprintsActionTypes.SET_BLUEPRINT_DURATION,
    payload: duration,
  };
};

export const incrementTimeFrame = () => {
  return {
    type: blueprintsActionTypes.INCREMENT_TIME_FRAME,
  };
};

export const playBlueprint = () => {
  return {
    type: blueprintsActionTypes.PLAY_BLUEPRINT,
  };
};

export const pauseBlueprint = () => {
  return {
    type: blueprintsActionTypes.PAUSE_BLUEPRINT,
  };
};

export const showObject = (objectId) => {
  return { type: blueprintsActionTypes.SHOW_OBJECT, payload: objectId };
};

export const hideObject = (objectId) => {
  return { type: blueprintsActionTypes.HIDE_OBJECT, payload: objectId };
};

export const selectObject = (objectId) => {
  return { type: blueprintsActionTypes.SELECT_OBJECT, payload: objectId };
};

export const deselectObject = () => {
  return { type: blueprintsActionTypes.DESELECT_OBJECT };
};

export const hoverObject = (objectId) => {
  return { type: blueprintsActionTypes.HOVER_OBJECT, payload: objectId };
};

export const unHoverObject = (objectId) => {
  return { type: blueprintsActionTypes.UN_HOVER_OBJECT, payload: objectId };
};

export const setSelectedOBjectTab = (tab) => {
  return {
    type: blueprintsActionTypes.SET_SELECTED_OBJECT_TAB,
    payload: tab,
  };
};

export const setScale = (scale) => {
  return {
    type: blueprintsActionTypes.SET_SCALE,
    payload: scale,
  };
};

export const setPosition = (position) => {
  return {
    type: blueprintsActionTypes.SET_POSITION,
    payload: position,
  };
};

export const setMeasuringObjects = (objectId) => {
  return {
    type: blueprintsActionTypes.SET_MEASURING_OBJECTS,
    payload: objectId,
  };
};

export const deleteDistance = (index) => {
  return {
    type: blueprintsActionTypes.DELETE_DISTANCE,
    payload: index,
  };
};

export const clearBlueprints = () => {
  return {
    type: blueprintsActionTypes.CLEAR_BLUEPRINTS,
  };
};

export const setSvgDimensions = (width, height) => {
  return {
    type: blueprintsActionTypes.SET_SVG_DIMENSIONS,
    payload: {
      width,
      height,
    },
  };
};

//--------------------------------------Fetch objects' thumbnails----------------------------------------
export const fetchThumbnails = (sceneId) => async (dispatch, getState) => {
  const body = {
    sceneId,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const api = process.env.REACT_APP_CLOUD_FN_URL;
  const res = await Axios.post(`${api}/getScenesItemsThumbnails`, body, config);

  dispatch(setThumbnails(res.data));
};

export const setThumbnails = (thumbnails) => {
  return {
    type: blueprintsActionTypes.SET_THUMBNAILS,
    payload: thumbnails,
  };
};
