import './BlueprintTree.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  camerasSelector,
  charactersSelector,
  hoveredObjectIdSelector,
  lightsSelector,
  propsSelector,
  selectedObjectIdSelector,
} from 'redux/slices/blueprints/selectors';
import {
  selectObject,
  deselectObject,
  hideObject,
  showObject,
  hoverObject,
  unHoverObject,
} from 'redux/slices/blueprints/actions';

const BlueprintTree = () => {
  const dispatch = useDispatch();

  const [camerasHidden, setCamerasHidden] = useState(null);
  const [propsHidden, setPropsHidden] = useState(null);
  const [charactersHidden, setCharactersHidden] = useState(null);
  const [lightsHidden, setLightsHidden] = useState(null);

  const cameras = useSelector(camerasSelector);
  const lights = useSelector(lightsSelector);
  const characters = useSelector(charactersSelector);
  const propsObjects = useSelector(propsSelector);

  const selectedObjectId = useSelector(selectedObjectIdSelector);
  const hoveredObjectId = useSelector(hoveredObjectIdSelector);

  const hideObjects = (objects) => {
    objects.forEach((object) => {
      if (object.id) {
        dispatch(hideObject(object.id));
        if (selectedObjectId === object.id) dispatch(deselectObject());
        if (hoveredObjectId === object.id) dispatch(unHoverObject(object.id));
      }
    });
  };

  const showObjects = (objects) => {
    objects.forEach((object) => {
      if (object.id) {
        dispatch(showObject(object.id));
      }
    });
  };

  const renderTitle = (title, iconUrl, objects, isHidden, setHidden) => (
    <h1 className={`blueprint-tree-title ${isHidden ? 'hidden' : ''}`}>
      <span className="title-icon">
        <img src={iconUrl} alt={`${title} icon`} />
      </span>
      <span className="name">{title}</span>
      <span className="eye-icon">
        <img
          src={`/blueprints/${isHidden ? 'eye-invisible' : 'eye'}.svg`}
          alt="visibility eye"
          onClick={(e) => {
            e.stopPropagation();
            !isHidden ? hideObjects(objects) : showObjects(objects);
            setHidden(!isHidden);
          }}
        />
      </span>
    </h1>
  );

  const renderList = (objects) =>
    objects.map((object, index) => (
      <li
        key={object.id}
        className={`blueprint-tree-elem ${object.hidden ? 'hidden' : ''} ${
          selectedObjectId === object.id ? 'selected' : ''
        }  ${object.id === hoveredObjectId ? 'hovered' : ''}`}
        onClick={(e) => {
          if (object.hidden) return;
          dispatch(
            selectedObjectId === object.id
              ? deselectObject()
              : selectObject(object.id)
          );
        }}
        onMouseEnter={() => {
          if (object.hidden) return;
          dispatch(hoverObject(object.id));
        }}
        onMouseLeave={() => {
          if (object.hidden) return;
          dispatch(unHoverObject(object.id));
        }}
      >
        <span className="name">{object.name}</span>
        <span className="eye-icon">
          <img
            src={`/blueprints/${object.hidden ? 'eye-invisible' : 'eye'}.svg`}
            alt="visibility eye"
            onClick={(e) => {
              e.stopPropagation();
              if (!object.hidden && selectedObjectId === object.id)
                dispatch(deselectObject());
              if (!object.hidden && object.id === hoveredObjectId)
                dispatch(unHoverObject(object.id));
              dispatch(
                !object.hidden ? hideObject(object.id) : showObject(object.id)
              );
            }}
          />
        </span>
      </li>
    ));

  return (
    <div className="blueprint-tree px-0 py-1">
      <ul>
        <li>
          {renderTitle(
            'CAMERAS',
            '/blueprints/camera-small.svg',
            cameras,
            camerasHidden,
            setCamerasHidden
          )}
          <ul>{renderList(cameras)}</ul>
        </li>

        <li>
          {renderTitle(
            'LIGHTS',
            '/blueprints/light-small.svg',
            lights,
            lightsHidden,
            setLightsHidden
          )}
          <ul>{renderList(lights)}</ul>
        </li>

        <li>
          {renderTitle(
            'CHARACTERS',
            '/blueprints/character-small.svg',
            characters,
            charactersHidden,
            setCharactersHidden
          )}
          <ul>{renderList(characters)}</ul>
        </li>

        <li>
          {renderTitle(
            'PROPS',
            '/blueprints/prop-small.svg',
            propsObjects,
            propsHidden,
            setPropsHidden
          )}
          <ul>{renderList(propsObjects)}</ul>
        </li>
      </ul>
    </div>
  );
};

export default BlueprintTree;
