import { appAction } from './types';
import { produce } from 'immer';
import { appActionTypes } from './actions';
import _ from 'lodash';

export const appReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case appAction('CLEAR', 'APP'): {
      return {
        ...initialState,
        sceneTemplates: state.sceneTemplates,
        uuid: state.uuid,
      };
    }

    case appAction('SET', 'UUID'): {
      state.uuid = action.payload;
      return state;
    }

    case appAction('SET', 'APP_LOADING'): {
      state.appLoading = action.payload;
      return state;
    }

    case appAction('SET', 'TEAMS'): {
      state.teams = { ...state.teams, ...(action.payload ?? {}) };
      return state;
    }

    case appAction('SET', 'TEAM'): {
      const oldTeamMembers = state.selectedTeam?.members ?? {};
      state.selectedTeam = action.payload ?? {};
      if (state.selectedTeam.members)
        for (const userId in state.selectedTeam.members) {
          state.selectedTeam.members[userId].id = userId;
          if (oldTeamMembers[userId]) {
            state.selectedTeam.members[userId] = oldTeamMembers[userId];
          }
        }
      return state;
    }

    case appAction('SET', 'PROJECT'): {
      if (action.payload?.id === state.selectedProjectId)
        state.selectedProject = action.payload ?? {};
      // const scenes = state.selectedProject.scenes;
      // if (scenes) {
      //   if (!state.selectedProject.scenesOrder) {
      //     const scenesOrder = {};
      //     Object.keys(scenes).forEach((sceneId, index) => {
      //       scenesOrder[sceneId] = index;
      //     });
      //     state.selectedProject.scenesOrder = scenesOrder;
      //   }
      // }
      return state;
    }

    case appAction('UNSUBSCRIBE', 'PROJECT'): {
      const project = action.payload ?? { id: '' };
      if (state.projectsData && state.projectsData[project.id]) {
        delete state.projectsData[project.id];
      }
      return state;
    }

    case appAction('CLEAR', 'PROJECTS'): {
      state.projectsData = null;
      return state;
    }

    case appAction('ADD', 'PROJECT_DATA'): {
      if (!state.projectsData) state.projectsData = {};
      const project = action.payload ?? { id: 'null' };
      state.projectsData = { ...state.projectsData, [project.id]: project };
      return state;
    }

    case appAction('SET', 'SCENE'): {
      if (action.payload?.id !== state.selectedSceneId) return state;
      state.selectedScene = action.payload ?? {};
      if (state.selectedScene.members)
        for (const userId in state.selectedScene.members) {
          state.selectedScene.members[userId].id = userId;
        }
      return state;
    }

    case appActionTypes.SET_SCENE_THUMBNAIL: {
      const { sceneId = 'faulty', thumbnail } = action.payload;
      return {
        ...state,
        scenesThumbnails: { ...state.scenesThumbnails, [sceneId]: thumbnail },
      };
    }

    case appActionTypes.UNSUBSCRIBE_SCENE_THUMBNAIL: {
      const sceneId = action.payload;
      if (!sceneId) return state;

      const scenesThumbnails = _.cloneDeep(state.scenesThumbnails);
      delete scenesThumbnails[sceneId];
      return {
        ...state,
        scenesThumbnails,
      };
    }

    case appActionTypes.SET_SELECTED_TEAM_ID: {
      if (state.selectedTeamId !== action.payload) {
        state.selectedTeamId = action.payload;
        state.recentScenes = null;
        state.selectedTeam = null;
        state.sceneAccess = null;
        state.selectedTeamIsActive = null;
        state.selectedProject = null;
        state.selectedProjectId = null;
        state.selectedScene = null;
        state.selectedSceneId = null;
      }
      return state;
    }

    case appActionTypes.SET_SELECTED_PROJECT_ID: {
      const newProjectId = action.payload;
      if (state.selectedProjectId !== newProjectId) {
        state.sceneAccess = {};
        state.selectedProject = null;
        state.selectedProjectId = newProjectId;
        state.selectedProjectIsActive = null;
        state.selectedScene = null;
        state.selectedSceneId = null;
      }
      return state;
    }

    case appAction('SET', 'PROJECT_STATUS'): {
      state.selectedProjectIsActive = action.payload;
      return state;
    }

    case appActionTypes.SET_SELECTED_SCENE_ID: {
      const newSceneId = action.payload;
      if (state.selectedSceneId !== newSceneId) {
        state.selectedScene = null;
        state.selectedSceneIsActive = null;
        state.selectedSceneId = newSceneId;
      }
      return state;
    }

    case appAction('SET', 'SCENE_STATUS'): {
      state.selectedSceneIsActive = action.payload;
      return state;
    }

    case appActionTypes.SET_MODE:
      return { ...state, mode: action.payload };

    case appAction('SET', 'RECENT_SCENES'): {
      state.recentScenes = action.payload ?? {};
      for (const sceneId in action.payload) {
        state.recentScenes[sceneId].id = sceneId;
      }
      return state;
    }

    case appAction('FETCH', 'RECENT_SCENES', 'FAIL'): {
      state.recentScenes = {};
      return state;
    }

    case appAction('SET', 'TEAM_MEMBERS_PUBLIC'): {
      const { key: teamId, members = {} } = action.payload;
      // if (teamId && teamId === state.selectedTeamId) {
      //   if (!state.selectedTeam?.members) {
      //     state.selectedTeam.members = {};
      //   }
      //   Object.values(members).forEach((member: any) => {
      //     const userId = member.id;
      //     const { role, ...userData } = member.public;

      //     if (!state.selectedTeam.members[userId]) {
      //       state.selectedTeam.members[userId] = {};
      //     }

      //     if (!state.selectedTeam.members[userId].thumbnail)
      //       state.selectedTeam.members[userId].thumbnail =
      //         '/default-profile-image.jpg';

      //     state.selectedTeam.members[userId] = {
      //       ...state.selectedTeam?.members[userId],
      //       ...userData,
      //     };
      //   });
      // }
      for (const memberId in members) {
        state.usersPublicData[memberId] = {
          id: memberId,
          title: members[memberId].public?.role ?? '',
          ...members[memberId].public,
        };

        delete state.usersPublicData[memberId].role;

        if ((state.selectedTeam?.members ?? {})[memberId]) {
          state.usersPublicData[memberId].role =
            state.selectedTeam?.members[memberId].role;
          if (!state.usersPublicData[memberId].thumbnail) {
            state.usersPublicData[memberId].thumbnail =
              '/default-profile-image.jpg';
          }
        }
      }
      return state;
    }

    case appAction('SET', 'SCENE_MEMBERS_PUBLIC'): {
      const { key: sceneId, members = {} } = action.payload;
      if (sceneId && sceneId === state.selectedSceneId) {
        if (!state.selectedScene?.members) {
          state.selectedScene.members = {};
        }
        Object.values(members).forEach((member: any) => {
          const userId = member.id;
          const { ...userData } = member.public;
          if (!state.selectedScene.members[userId]) {
            state.selectedScene.members[userId] = {};
          }

          state.selectedScene.members[userId] = {
            ...state.selectedScene?.members[userId],
            ...userData,
          };

          const role = ((state.selectedTeam.members ?? {})[userId] ?? {}).role;
          if (!state.selectedScene.members[userId].thumbnail)
            state.selectedScene.members[userId].thumbnail =
              '/default-profile-image.jpg';

          state.selectedScene.members[userId].role = role;
        });
      }
      return state;
    }

    case appAction('CHECK', 'SCENE_ACCESS', 'SUCCESS'): {
      if (!state.sceneAccess) {
        state.sceneAccess = {};
      }
      state.sceneAccess = { ...state.sceneAccess, [action.payload]: true };
      return state;
    }

    case appAction('CHECK', 'SCENE_ACCESS', 'FAIL'): {
      if (!state.sceneAccess) {
        state.sceneAccess = {};
      }
      state.sceneAccess = { ...state.sceneAccess, [action.payload]: false };
      return state;
    }

    case appAction('SET', 'SCENE_ACCESS'): {
      state.sceneAccess = action.payload;
      return state;
    }

    case appAction('SET', 'SCENE_LAST_MODIFIED'): {
      if (!state.lastModifiedScenes) {
        state.lastModifiedScenes = {};
      }
      const { sceneId, lastModified } = action.payload;
      state.lastModifiedScenes[sceneId] = lastModified;
      return state;
    }

    case appAction('ADD', 'SCENE_IS_PUBLIC'): {
      const { sceneId, sceneIsPublic } = action.payload;
      if (sceneId) {
        state.scenesIsPublic[sceneId] = sceneIsPublic;
      }
      return state;
    }

    case appAction('SET', 'SCENE_TEMPLATES'): {
      state.sceneTemplates = action.payload ?? {};
      return state;
    }

    case appAction('CREATE', 'SCENE'): {
      state.pendingScene = action.payload ?? {};
      return state;
    }

    case appAction('UPDATE', 'PENDING_SCENE'): {
      state.pendingScene = { ...(state.pendingScene ?? {}), ...action.payload };
      return state;
    }

    case appAction('CLEAR', 'PENDING_SCENE'): {
      state.pendingScene = null;
      return state;
    }

    // ---------------------------------------------------------- Sharing

    case appAction('SHARE', 'PROJECT'): {
      state.loaders.shareProjectPending = true;
      return state;
    }

    case appAction('SHARE', 'PROJECT', 'SUCCESS'):
    case appAction('SHARE', 'PROJECT', 'FAIL'): {
      state.loaders.shareProjectPending = false;
      return state;
    }

    case appAction('UPDATE', 'PROJECT_MEMBER_ROLE'):
    case appAction('UNSHARE', 'PROJECT'): {
      const { userId } = action.payload;
      state.loaders.updateProjectMemberRolePending[userId] = true;
      return state;
    }

    case appAction('UPDATE', 'PROJECT_MEMBER_ROLE', 'SUCCESS'):
    case appAction('UPDATE', 'PROJECT_MEMBER_ROLE', 'FAIL'):
    case appAction('UNSHARE', 'PROJECT', 'SUCCESS'):
    case appAction('UNSHARE', 'PROJECT', 'FAIL'): {
      const { userId } = action.payload;
      state.loaders.updateProjectMemberRolePending[userId] = false;
      return state;
    }

    case appAction('SHARE', 'SCENE'): {
      state.loaders.shareScenePending = true;
      return state;
    }

    case appAction('SHARE', 'SCENE', 'SUCCESS'):
    case appAction('SHARE', 'SCENE', 'FAIL'): {
      state.loaders.shareScenePending = false;
      return state;
    }

    case appAction('UPDATE', 'SCENE_MEMBERS_ROLE'):
    case appAction('UNSHARE', 'SCENE'): {
      const { userId } = action.payload;
      state.loaders.updateSceneMemberRolePending[userId] = true;
      return state;
    }

    case appAction('UPDATE', 'SCENE_MEMBERS_ROLE', 'SUCCESS'):
    case appAction('UPDATE', 'SCENE_MEMBERS_ROLE', 'FAIL'):
    case appAction('UNSHARE', 'SCENE', 'SUCCESS'):
    case appAction('UNSHARE', 'SCENE', 'FAIL'): {
      const { userId } = action.payload;
      state.loaders.updateSceneMemberRolePending[userId] = false;
      return state;
    }

    case appAction('SET', 'PAYMENT_INFO'): {
      const { teamId = '', data } = action.payload;
      state.paymentInfo[teamId] = data;
      return state;
    }

    case appAction('SET', 'SORTABLE_PROJECT'): {
      state.sortableProject = action.payload;
      return state;
    }

    case appAction('SET', 'DRIVE_STATUS'): {
      state.selectedTeamIsActive = action.payload;
      return state;
    }

    case appAction('SET', 'DRIVE_IS_CANCELED'): {
      state.selectedTeamIsCanceled = action.payload;
      return state;
    }

    case appAction('SET', 'PERSONAL_DRIVE_STATUS'): {
      state.personalDriveIsActive = action.payload;
      return state;
    }

    case appAction('SET', 'PERSONAL_DRIVE_IS_CANCELED'): {
      state.personalDriveIsCanceled = action.payload;
      return state;
    }

    case appAction('SET', 'PERSONAL_DRIVE_CUSTOMER_ID'): {
      state.personalDriveCustomerId = action.payload;
      return state;
    }

    case appAction('SET', 'THEME'): {
      state.theme = action.payload;
      return state;
    }

    // case appAction('UPDATE', 'SCENES_ORDERING'): {
    //   const newOrdering = action.payload;
    //   state.selectedProject.scenesOrder = newOrdering;
    //   return state;
    // }

    default:
      return state;
  }
});

const initialState = {
  appLoading: false,

  uuid: null,

  personalDrive: null,

  teams: {},
  projectsData: {},
  scenesThumbnails: {},
  scenesIsPublic: {},
  sceneAccess: null,
  recentScenes: null,
  lastModifiedScenes: {},
  sceneTemplates: {},

  usersPublicData: {},

  selectedTeamId: null,
  selectedTeamIsActive: null,
  selectedTeam: null,

  selectedProjectId: null,
  selectedProjectIsActive: null,
  selectedProject: null,

  selectedSceneId: null,
  selectedSceneIsActive: null,
  selectedScene: null,

  pendingScene: null,

  paymentInfo: {},

  personalDriveIsActive: null,
  personalDriveIsCanceled: null,
  personalDriveCustomerId: null,

  sortableProject: false,

  theme: 'original',

  loaders: {
    shareScenePending: null,
    shareProjectPending: null,
    updateProjectMemberRolePending: {},
    updateSceneMemberRolePending: {},
  },

  mode: '',
};
