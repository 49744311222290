import { mainActionTypes } from 'redux/common/types';

export type bundlesSlice = {
  data?:
    | { itemType: string; category: string; key: string; size?: number }[]
    | null;
};

export interface BundlesSelector {
  bundles: bundlesSlice;
}

const namespace = 'bundles';

type bundlesActionsTypes = mainActionTypes;

type bundlesResource = 'BUNDLES' | 'BUNDLE' | 'SIZE';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const bundlesAction = (
  type: bundlesActionsTypes,
  resource?: bundlesResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
