import { appAction } from 'redux/slices/app/types';

const initState = {
  teamsFetchPending: false,
  projectsFetchPending: false,
  scenesFetchPending: false,
  createScenePending: false,
  duplicateScenePending: false,
  renameScenePending: false,
  bugReportPending: false,
};
export const appLoadersReducer = (state = initState, action) => {
  switch (action.type) {
    case appAction('CREATE', 'SCENE'): {
      state.createScenePending = true;
      return state;
    }

    case appAction('CREATE', 'SCENE', 'FAIL'):
    case appAction('CREATE', 'SCENE', 'SUCCESS'): {
      state.createScenePending = false;
      return state;
    }

    case appAction('DUPLICATE', 'SCENE'): {
      state.duplicateScenePending = true;
      return state;
    }

    case appAction('DUPLICATE', 'SCENE', 'SUCCESS'):
    case appAction('DUPLICATE', 'SCENE', 'FAIL'): {
      state.duplicateScenePending = false;
      return state;
    }

    case appAction('UPDATE', 'SCENE_NAME'): {
      state.renameScenePending = true;
      return state;
    }

    case appAction('UPDATE', 'SCENE_NAME', 'SUCCESS'):
    case appAction('UPDATE', 'SCENE_NAME', 'FAIL'): {
      state.renameScenePending = false;
      return state;
    }

    case appAction('REPORT', 'BUG'): {
      state.bugReportPending = true;
      return state;
    }

    case appAction('REPORT', 'BUG', 'FAIL'):
    case appAction('REPORT', 'BUG', 'SUCCESS'): {
      state.bugReportPending = false;
      return state;
    }

    default:
      return state;
  }
};
