import React, { useState } from 'react';
import { Modal } from 'antd';
import './versionsModal.css';
import Loader from 'common/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  sceneVersionsPendingSelector,
  revertSceneVersionPendingSelector,
} from 'redux/slices/loaders/loadersSelectors';
import { sceneVersionsSelector } from 'redux/slices/project/selectors';
import moment from 'moment';
import { revertSceneVersion } from 'redux/slices/project/actions';
const VersionsModal = ({ visible, onClose, sceneId, sceneName }) => {
  const dispatch = useDispatch();
  const sceneVersionsPending = useSelector(sceneVersionsPendingSelector);
  const versions = useSelector(sceneVersionsSelector);
  const [selectedVersion, setSelectedVersion] = useState(-1);
  const revertSceneVersionPending = useSelector(
    revertSceneVersionPendingSelector
  );
  //const versions = ["2021-01-08 11:35:00","2021-01-03 18:20:43","2020-12-30 14:30:02","2021-01-08 11:35:00","2021-01-03 18:20:43","2020-12-30 14:30:02","2021-01-08 11:35:00","2021-01-03 18:20:43","2020-12-30 14:30:02","2021-01-08 11:35:00","2021-01-03 18:20:43","2020-12-30 14:30:02"]
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="versions"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Loader
        show={sceneVersionsPending}
        spinnerColor={'#0081b4'}
        centerSpinner
        backgroundStyle={{ height: '100%', width: '100%', padding: '10vw' }}
      >
        <div className="screen-top-title" style={{ padding: '30px' }}>
          {sceneName?.toUpperCase()} Version History
        </div>
        <div style={{ marginTop: '30px', height: '400px', overflow: 'auto' }}>
          {Object.keys(versions)
            .reverse()
            .map((version, index) => {
              return (
                <div className="version-list-item-container">
                  <div className="version-list-item-content">
                    <div
                      className="image1 version-list-item-image"
                      style={{
                        backgroundImage: `url("${
                          versions[version] !== ''
                            ? versions[version]
                            : '/empty-scene.jpg'
                        }")`,
                      }}
                    ></div>
                    <div className="version-list-item-title">
                      {moment(+version).format('dddd MMMM Do YYYY, h:mm:ss a')}
                    </div>
                  </div>
                  <Loader
                    show={
                      revertSceneVersionPending && index === selectedVersion
                    }
                    spinnerSize={20}
                    spinnerColor={'#0081b4'}
                    centerSpinner
                  >
                    <button
                      disabled={revertSceneVersionPending}
                      className="modal-revert-button"
                      onClick={() => {
                        setSelectedVersion(index);
                        dispatch(revertSceneVersion(sceneId, version));
                      }}
                    >
                      Revert Version
                    </button>
                  </Loader>
                </div>
              );
            })}
        </div>
      </Loader>
    </Modal>
  );
};

export default VersionsModal;
