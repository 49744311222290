import './SettingsModal.scss';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  resOptionsSelector,
  settingsSelector,
} from 'redux/slices/settings/selectors';
import { setSettings } from 'redux/slices/settings/actions';

const SettingsModal = ({ onClose = () => {} }) => {
  const dispatch = useDispatch();
  const defaultSettings = useSelector(settingsSelector);
  const resOptions = useSelector(resOptionsSelector);
  const [autoSave, setAutoSave] = useState(5);
  const [res, setRes] = useState('');
  const [resValue, setResValue] = useState(100);
  const [fps, setFps] = useState('30');

  useEffect(() => {
    setAutoSave(defaultSettings.autoSave);
    setRes(defaultSettings.res);
    setFps(defaultSettings.fps);
    setResValue(defaultSettings.resValue);
  }, [defaultSettings]);

  const onSubmit = () => {
    (window as any).onSaveSettings(autoSave, res, fps);
    dispatch(setSettings({ autoSave, res, fps, resValue }));
    onClose();
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="main-settings-modal"
      visible
      title="Settings"
      // maskClosable={false}
      onCancel={onClose}
      onOk={onSubmit}
      okText="Save"
    >
      <form>
        <div>
          <label>Auto save frequency</label>
          <div className="icon-wrapper">
            <span>1 min</span>
            <Slider
              min={1}
              max={15}
              tipFormatter={(v) => `${v} min${v !== 1 ? 's' : ''}`}
              value={autoSave}
              onChange={(v) => {
                setAutoSave(v);
              }}
            />
            <span>15 min</span>
          </div>
        </div>

        <div>
          <label>Resolution</label>
          <div className="res-btn-group">
            {resOptions.map(({ name, value }) => {
              return (
                <div
                  key={name}
                  className={`res-btn ${res === name ? 'selected' : ''} ${
                    value === 0 ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    if (value > 0) setRes(name);
                  }}
                >
                  {name}
                </div>
              );
            })}
          </div>
          {/* <div className="res-btn-group">
            {resOptions.map((option) => (
              <button
                className="res-btn"
                key={option.name}
                onClick={(e) => {
                  e.preventDefault();
                  setRes(option.value + '');
                  // const newRes = resOptions[option.value];
                  setResValue(option.value);
                  // console.log(newRes);
                }}
              >
                {option.name}
              </button>
            ))}
          </div> */}
          {/* <select
            value={res}
            onChange={(e) => {
              setRes(e.target.value);
              const newRes = resOptions[e.target.value];
              setResValue(newRes.value);
              console.log(newRes);
            }}
          >
             <option value="0">Full</option>
            <option value="1">Three-Quarter</option>
            <option value="2">Half</option>
            <option value="3">Quarter</option>
            <option value="4">Adaptive</option> 
            {resOptions.map((option, index) => (
              <option key={option.value} value={index}>
                {option.name}
              </option>
            ))}
          </select> */}
          {/* <div className="icon-wrapper">
            <span>{defaultSettings.resMin}%</span>
            <Slider
              min={defaultSettings.resMin}
              max={100}
              tipFormatter={(v) => ` ${v}% `}
              value={resValue}
              onChange={(v) => {
                setResValue(v);
                setRes('');
              }}
            />
            <span>100%</span>
          </div> */}
        </div>

        {/* <div>
          <label>FPS</label>
          <select
            value={fps}
            onChange={(e) => {
              setFps(e.target.value);
            }}
          >
            <option value="30">30 FPS</option>
            <option value="60">60 FPS</option>
          </select>
        </div> */}
      </form>
    </Modal>
  );
};

export default SettingsModal;
