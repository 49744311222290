import { createSelector } from 'reselect';
import _ from 'lodash';
import { userIdSelector } from '../auth/authSelectors';

interface AppSlice {
  app: {
    appLoading: boolean;

    uuid: string;

    sceneTemplates: {
      totalSize: number;
      items: {
        [templateId: string]: {
          itemID: string;
          name: string;
          originalName: string;
          images: string;
          thumbnail: string;
        };
      };
    };

    paymentInfo: {
      [teamId: string]: {
        balance?: number;
        wallet?: number;
        number_of_editor_licenses?: number;
        number_of_reviewer_licenses?: number;
        number_of_active_editors?: number;
        number_of_active_reviewers?: number;
        card?: {
          brand?: string;
          country?: string;
          funding?: string;
          last4?: string;
        };
        end_date?: number;
        is_active?: boolean;
        number_of_active_users?: number;
      };
    };

    personalDriveIsCanceled: null | boolean;
    personalDriveIsActive: null | boolean;
    personalDriveCustomerId: null | string;

    teams: null | { [teamId: string]: { name?: string; color?: string } };

    teamMembers: {
      id: string;
      // avatar?: string;
      name: string;
      role: string;
      // thumbnail?: string;
    }[];

    usersPublicData: {
      [userId: string]: {
        id: string;
        name: string;
        email?: string;
        title: string;
        thumbnail?: string;
        role?: number;
      };
    };

    projectsData: { [key: string]: any };

    selectedTeamId: string | null;
    selectedTeamIsActive: boolean | null;
    selectedTeamIsCanceled: null | boolean;
    selectedTeam: null | {
      is_enterprise: boolean;
      name: string;
      owner_id: string;
      sharedProjects?: {
        [projectId: string]: {
          name: string;
          role: number;
        };
      };

      sharedScenes?: {
        [sceneId: string]: {
          name: string;
          role: number;
          thumbnail?: string;
        };
      };

      members?: {
        [userId: string]: {
          id: string;
          name: string;
          role: number;
          thumbnail?: string;
          isAdmin: boolean;
          isBillingAdmin: boolean;
        };
      };
      projects?: {
        [projectId: string]: {
          name: string;
          is_public: boolean;
          members?: { [userId: string]: { name: string; role: number } };
        };
      };
      public?: { thumbnail: string };
    };

    selectedProjectIsActive: null | boolean;
    selectedProject: null | {
      name: string;
      is_public: boolean;
      owner_id: string;
      team_id: string;
      scenes: {
        [sceneId: string]: {
          name: string;
          is_public: boolean;
          members?: {
            [userId: string]: { role: number; name: string; id: string };
          };
          thumbnail?: string;
          last_modified: number;
          modifierId?: string;
          modifierName?: string;
          owner_id?: string;
          owner_name?: string;
        };
      };
      scenesOrder: { [sceneId: string]: number };
      members?: {
        [userId: string]: { role: number; name: string; id: string };
      };
      pending_invites: {
        [id: string]: { date: number; email: string; role: number };
      };
    };

    selectedSceneIsActive: null | boolean;
    selectedScene: null | {
      is_public: boolean;
      name: string;
      owner_id: string;
      project_id: string;
      public: { thumbnail: string };
      members: { [userId: string]: { id: string; name: string; role: number } };
      pending_invites: {
        [id: string]: { date: number; email: string; role: number };
      };
    };

    scenesMembers: null | {
      [key: string]: {
        members?: {
          [key: string]: { name: string; role: number; userId: string };
        };
        is_public?: boolean;
      };
    };

    faultyScene: boolean | null;

    recentScenes: null | {
      [sceneId: string]: {
        id: string;
        name: string;
        date: number;
        projectId: string;
        projectName: string;
        thumbnail?: string;
      };
    };

    scenesIsPublic: { [sceneId: string]: boolean };

    sortableProject: boolean;

    theme: string;

    pendingScene: null | {
      id?: string;
      name: string;
      order: number;
      isPublic: boolean;
      projectId: string;
      userId: string;
      templateName?: string;
      thumbnail?: string;
    };

    loaders: {
      shareScenePending?: boolean | null;
      shareProjectPending?: boolean | null;

      updateProjectMemberRolePending: { [userId: string]: boolean };
      updateSceneMemberRolePending: { [userId: string]: boolean };
    };
  };
}

export const themeSelector = (store: AppSlice) => store.app.theme;

export const uuidSelector = (store: AppSlice) => store.app.uuid;

export const paymentInfoSelector = (store: AppSlice) => store.app.paymentInfo;

export const sharedProjectsSelector = (store: AppSlice) =>
  store.app.selectedTeam?.sharedProjects;

export const availableSharedProjectsArraySelector = createSelector(
  sharedProjectsSelector,
  (projects) => {
    if (!projects) return projects;
    return Object.keys(projects)
      .map((id) => ({
        id,
        name: projects[id].name,
        role: projects[id].role,
      }))
      .filter((project) => project.role !== 2);
  }
);

export const sharedScenesSelector = (store: AppSlice) =>
  store.app.selectedTeam?.sharedScenes;

export const personalDriveIdSelector = (store) =>
  store.firebase.profile?.personal_drive?.id;

export const personalDriveNameSelector = (store) =>
  store.firebase.profile?.personal_drive?.name;

export const sceneTemplatesSelector = createSelector(
  (store: AppSlice) => store.app.sceneTemplates.items,
  (templates) => {
    const blankTemplate = {
      itemID: 'blank',
      name: 'Blank',
      originalName: 'Blank',
      images: '/empty-scene.jpg',
      thumbnail: '/empty-scene.jpg',
    };
    return { blankTemplate, ...templates };
  }
);

export const selectedTeamSelector = (store: AppSlice) => store.app.selectedTeam;

export const projectsSelector2 = (store: AppSlice) =>
  store.app.selectedTeam?.projects;

export const projectsArraySelector = createSelector(
  projectsSelector2,
  (projects) => {
    if (!projects) return projects;
    return Object.keys(projects).map((id) => ({ id, ...projects[id] }));
  }
);

export const projectsStatusSelector = (store: AppSlice) =>
  store.app.projectsData;

export const selectedProjectStatusSelector = (store: AppSlice) =>
  store.app.selectedProjectIsActive;

export const selectedSceneStatusSelector = (store: AppSlice) =>
  store.app.selectedSceneIsActive;

export const selectedSceneStatusPendingSelector = (store: AppSlice) =>
  store.app.selectedScene !== null && store.app.selectedSceneIsActive === null;

export const selectedProjectSelector = (store: AppSlice) =>
  store.app.selectedProject;

export const selectedSceneSelector = (store: AppSlice) =>
  store.app.selectedScene;

export const personalDriveIsActiveSelector = (store: AppSlice) =>
  store.app.personalDriveIsActive;

export const personalDriveCustomerIdSelector = (store: AppSlice) =>
  store.app.personalDriveCustomerId;

export const personalDriveIsCanceledSelector = (store: AppSlice) =>
  store.app.personalDriveIsCanceled;

export const appLoadingSelector = (store: AppSlice) => store.app.appLoading;
export const selectedTeamIdSelector = (store) => store.app.selectedTeamId;
export const selectedProjectIdSelector = (store) => store.app.selectedProjectId;
export const selectedSceneIdSelector = (store) => store.app.selectedSceneId;
export const selectedTeamIsActiveSelector = (store: AppSlice) =>
  store.app.selectedTeamIsActive;
export const selectedTeamIsActivePendingSelector = (store: AppSlice) =>
  store.app.selectedTeamIsActive === null && store.app.selectedTeamId !== null;

export const sceneNameSelector = (store: AppSlice) =>
  store.app.selectedScene?.name;

export const isAdminSelector = createSelector(
  [selectedTeamSelector, userIdSelector],
  (team, userId) => {
    return (team?.members ?? {})[userId]?.isAdmin;
  }
);

export const isBillingAdminSelector = createSelector(
  [selectedTeamSelector, userIdSelector],
  (team, userId) => {
    return (team?.members ?? {})[userId]?.isBillingAdmin;
  }
);

export const modeSelector = (store) => store.app.mode;
export const sceneMembersSelector = (store) => store.app.sceneMembers;
// export const teamMembersSelector = (store) => store.app.teamMembers;
export const teamMembersSelector = (store: AppSlice) => store.app.teamMembers;

export const teamMembersSelector3 = (store: AppSlice) =>
  store.app.selectedTeam?.members;

export const selectedTeamIsCanceledSelector = createSelector(
  [
    selectedTeamIdSelector,
    personalDriveIdSelector,
    personalDriveIsActiveSelector,
    (store: AppSlice) => store.app.selectedTeamIsCanceled,
  ],
  (teamId, personalId, personalDriveIsActive, selectedTeamIsCanceled) => {
    if (teamId === personalId) return personalDriveIsActive;
    return selectedTeamIsCanceled;
  }
);

export const userProfileSelector = (userId) => {
  return createSelector(
    (store: any) => store.app.users,
    (users) =>
      users.length > 0 ? users.find((user) => user.id === userId) : null
  );
};

export const inPersonalDriveSelector = createSelector(
  [personalDriveIdSelector, selectedTeamIdSelector],
  (personalDriveId, teamId) =>
    personalDriveId === undefined ? null : personalDriveId === teamId
);

export const selectedSceneNameSelector = (store: AppSlice) =>
  store.app.selectedScene?.name;

export const selectedProjectNameSelector = (store: AppSlice) =>
  store.app.selectedProject?.name;

// export const teamsSelector = createSelector(
//   (store: AppSlice) => store.app.teams,
//   (teams) => {
//     if (!teams) return null;
//     return Object.keys(teams).map((teamId) => ({
//       id: teamId,
//       ...teams[teamId],
//     }));
//   }
// );

export const teamsSelector = createSelector(
  (store: any) =>
    store.firebase.profile?.teams as
      | { [teamId: string]: { name: string; thumbnail: string } }
      | undefined,
  (teams) => {
    if (!teams) return teams;
    return Object.keys(teams).map((teamId) => ({
      id: teamId,
      name: teams[teamId].name,
    }));
  }
);

export const selectedTeamNameSelector = (store: AppSlice) =>
  store.app.selectedTeam?.name;

export const projectsSelector = createSelector(
  (store: AppSlice) => store.app.selectedTeam?.projects,
  (projects) => {
    if (!projects) return projects;
    const ret = Object.entries(projects).map((entry) => {
      return { id: entry[0], name: entry[1].name };
    });
    return ret;
  }
);

export const deletedProjectsSelector = createSelector(
  (store: any) => store.app.projectsData,
  (projects) =>
    (Object.values(projects ?? {}) as any).filter(({ removed }) => removed)
);

// export const scenesSelector = createSelector(
//   (store: any) => store.app.scenes,
//   (scenes) => objectToArray(scenes)
// );
export const teamSelector = (store: AppSlice) => store.app.selectedTeam;

export const scenesOrderingSelector = (store: AppSlice) =>
  store.app.selectedProject?.scenesOrder;

export const scenesSelector = createSelector(
  (store: AppSlice) => store.app.selectedProject?.scenes,
  scenesOrderingSelector,
  (scenes, scenesOrder = {}) => {
    if (!scenes) return null;
    const result: {
      id;
      name;
      isPublic: boolean;
      members?: {
        [userId: string]: { role: number; name: string; id: string };
      };
      loading?;
      order: number;
      lastModified?: number;
      thumbnail?: string;
      modifierName?: string;
      modifierId?: string;
      ownerId?: string;
      ownerName?: string;
    }[] = [];
    for (const id in scenes) {
      result.push({
        id,
        name: scenes[id].name,
        order: scenesOrder[id],
        isPublic: scenes[id].is_public,
        members: scenes[id].members,
        lastModified: scenes[id].last_modified,
        thumbnail: scenes[id].thumbnail,
        modifierName: scenes[id].modifierName,
        modifierId: scenes[id].modifierId,
        ownerId: scenes[id].owner_id,
        ownerName: scenes[id].owner_name,
      });
    }
    result.sort(({ order: o1 = 0 }, { order: o2 = 0 }) => {
      return o1 - o2;
    });
    return result;
  }
);

export const scenesArraySelector = createSelector(scenesSelector, (scenes) =>
  !scenes
    ? null
    : Object.keys(scenes).map((sceneId) => {
        return {
          id: sceneId,
          name: scenes[sceneId].name,
          members: scenes[sceneId].members,
          isPublic: scenes[sceneId].is_public,
          thumbnail: scenes[sceneId].thumbnail,
        };
      })
);

export const availableScenesSelector = createSelector(
  [
    inPersonalDriveSelector,
    userIdSelector,
    teamSelector,
    selectedProjectSelector,
    scenesSelector,
  ],
  (inPersonalDrive, userId, team, project, scenes) => {
    if (!scenes) return null;
    if (inPersonalDrive) {
      if ((project?.members ?? {})[userId] || project?.owner_id === userId)
        return scenes;
      return scenes.filter(
        (scene) => (scene.members ?? {})[userId]?.role === 1
      );
    } else {
      const isAdmin = (team?.members ?? {})[userId].isAdmin;
      return scenes.filter((scene) => {
        if (isAdmin) return true;
        if (scene.isPublic === false) return (scene.members ?? {})[userId];
        if (project?.is_public === false)
          return (project.members ?? {})[userId];
        return (team?.members ?? {})[userId];
      });
    }
  }
);

export const sharedScenesArraySelector = createSelector(
  sharedScenesSelector,
  (scenes) => {
    if (!scenes) return null;
    return Object.keys(scenes).map((id) => ({
      id,
      name: scenes[id].name,
      role: scenes[id].role,
      thumbnail: scenes[id].thumbnail,
    }));
  }
);

export const sceneSelector = (store: AppSlice) => store.app.selectedScene;
export const projectSelector = (store: AppSlice) => store.app.selectedProject;

export const availableSharedScenesArraySelector = createSelector(
  sharedScenesArraySelector,
  (scenes) => {
    if (!scenes) return scenes;
    return scenes.filter((scene) => scene.role !== 2);
  }
);

export const availableProjectsSelector = createSelector(
  inPersonalDriveSelector,
  userIdSelector,
  teamSelector,
  (inPersonalDrive, userId, team) => {
    if (!team) return null;
    const projects = team.projects;
    if (!projects) return null;

    if (inPersonalDrive) {
      return Object.keys(projects).map((id) => ({ ...projects[id], id }));
    }

    const isAdmin = (team.members ?? {})[userId]?.isAdmin;

    return Object.keys(projects)
      .map((id) => ({ ...projects[id], id }))
      .filter(
        (project) =>
          isAdmin || project.is_public || (project.members ?? {})[userId]
      );
    // Object.values(projects ?? ({} as any)) as any
    // ).filter(({ removed }) => !removed);
  }
);

export const projectMembersSelector = (store: AppSlice) =>
  store.app.selectedProject?.members ?? teamMembersSelector2(store);

export const projectMembersArraySelector = createSelector(
  projectMembersSelector,
  (members) =>
    !members
      ? null
      : Object.keys(members ?? {}).map((id) => ({ ...members[id], id }))
);

export const usersPublicSelector = (store: AppSlice) =>
  store.app.usersPublicData;

// export const sceneMembersSelector2 = (store: AppSlice) =>
//   store.app.selectedScene?.members;

export const scenePendingInvitesSelector = (store: AppSlice) =>
  store.app.selectedScene?.pending_invites;

export const projectPendingInvitesSelector = (store: AppSlice) =>
  store.app.selectedProject?.pending_invites;

export const scenePendingInvitesArraySelector = createSelector(
  [scenePendingInvitesSelector],
  (invites) => {
    if (!invites) return invites;
    return Object.keys(invites).map((id) => ({ id, ...invites[id] }));
  }
);

export const projectPendingInvitesArraySelector = createSelector(
  [projectPendingInvitesSelector],
  (invites) => {
    if (!invites) return invites;
    return Object.keys(invites).map((id) => ({ id, ...invites[id] }));
  }
);

export const sceneMembersSelector2 = createSelector(
  [usersPublicSelector, (store: AppSlice) => store.app.selectedScene?.members],
  (usersPublic, sceneMembers) => {
    if (!sceneMembers) return null;
    const members: typeof usersPublic = {};
    for (const userId in sceneMembers) {
      members[userId] = {
        ...sceneMembers[userId],
        ...(usersPublic[userId] ?? {}),
      };
    }
    return members;
  }
);

export const sceneMembersArraySelector = createSelector(
  sceneMembersSelector2,
  (members) => {
    return !members
      ? null
      : Object.keys(members).map((userId) => ({
          ...members[userId],
        }));
  }
);

export const teamMembersSelector2 = createSelector(
  [usersPublicSelector, (store: AppSlice) => store.app.selectedTeam?.members],
  (usersPublic, teamMembers) => {
    if (!teamMembers) return null;
    const members: typeof usersPublic = {};
    for (const userId in teamMembers) {
      members[userId] = {
        ...teamMembers[userId],
        ...(usersPublic[userId] ?? {}),
      };
    }
    return members;
  }
);

export const teamMembersArraySelector = createSelector(
  teamMembersSelector2,
  (members) => {
    if (!members) return members;
    return Object.values(members);
  }
);

export const userRoleInTeam = createSelector(
  [teamMembersSelector2, userIdSelector],
  (members, userId) => {
    if (!members) return null;
    const role = members[userId]?.role;
    switch (role) {
      case 0:
        return 'Admin';
      case 1:
        return 'Editor';
      case 2:
        return 'Reviewer';
      default:
        return null;
    }
  }
);

export const sceneIsPublicSelector = (store: AppSlice) =>
  store.app.selectedScene?.is_public;

export const sceneOwnerIdSelector = (store: AppSlice) =>
  store.app.selectedScene?.owner_id;

export const recentScenesSelector = createSelector(
  (store: AppSlice) => store.app.recentScenes,
  (recentScenes) => {
    if (!recentScenes) return null;
    let result = Object.values(recentScenes);
    result.sort(
      (a, b) =>
        (new Date(b.date * 1000) as any) - (new Date(a.date * 1000) as any)
    );
    result = result.map((scene) => ({
      ...scene,
    }));
    return result;
  }
);

export const projectSortableSelector = (store: AppSlice) =>
  store.app.sortableProject;

export const pendingSceneSelector = (store: AppSlice) => store.app.pendingScene;

export const shareScenePendingSelector = (store: AppSlice) =>
  !!store.app.loaders.shareScenePending;

export const shareProjectPendingSelector = (store: AppSlice) =>
  !!store.app.loaders.shareProjectPending;

export const updateProjectMemberRolePendingSelector = (store: AppSlice) =>
  store.app.loaders.updateProjectMemberRolePending;

export const updateSceneMemberRolePendingSelector = (store: AppSlice) =>
  store.app.loaders.updateSceneMemberRolePending;
