import { blueprintsActionTypes } from 'redux/slices/blueprints/actions';

const initState = {
  blueprintsPending: false,
};
export const blueprintsLoadersReducer = (state = initState, action) => {
  switch (action.type) {
    case blueprintsActionTypes.FETCH_BLUEPRINTS_LINK:
    case blueprintsActionTypes.SUBSCRIBE_BLUEPRINTS_LINK:
      return { ...state, blueprintsPending: true };
    case blueprintsActionTypes.FETCH_BLUEPRINTS_EMPTY:
    case blueprintsActionTypes.FETCH_BLUEPRINTS_FAIL:
    case blueprintsActionTypes.FETCH_BLUEPRINTS_SUCCESS:
      return { ...state, blueprintsPending: false };

    default:
      return state;
  }
};
