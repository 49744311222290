export type UserPublicInfo = {
  name?: string;
  email?: string;
  role?: string;
  thumbnail?: string;
};

const namespace = 'auth';

type authActionsTypes =
  | 'SUBSCRIBE'
  | 'UNSUBSCRIBE'
  | 'FETCH'
  | 'SET'
  | 'UPDATE'
  | 'DELETE';
type authResource = 'REGISTRATION_PENDING' | 'REGISTRATION_ERROR';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const authAction = (
  type: authActionsTypes,
  resource?: authResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
