import { appActionTypes } from '../app/actions';
import { projectActionTypes } from './actions';

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectActionTypes.FETCH_PROJECT_MEMBERS_SUCCESS:
      return { ...state, ...action.payload };
    case projectActionTypes.FETCH_SCENE_MEMBERS_SUCCESS:
      return { ...state, ...action.payload };
    case appActionTypes.SET_SELECTED_TEAM_ID:
      return initialState;
    case projectActionTypes.FETCH_SCENE_VERSIONS:
      return { ...state, sceneVersions: {} };
    case projectActionTypes.FETCH_SCENE_VERSIONS_SUCCESS:
      return { ...state, sceneVersions: action.payload };
    default:
      return state;
  }
};

const initialState = {
  projectMembers: [],
  nonProjectMembers: [],
  sceneMembers: [],
  nonSceneMembers: [],
  sceneVersions: {},
};
