import firebase from 'firebase-main';
import _ from 'lodash';
import { Action } from 'redux/common/types';
import { logoutActionTypes } from 'redux/slices/auth/actions/logout';
import { fireRequestTypes } from './firebaseRequestTypes';

const listeners: {
  [key: string]: firebase.database.Query | firebase.database.Reference | null;
} = {};

export const firebaseRealtimeApiMiddleware = (store) => (next) => (
  action: Action
) => {
  if (action.type === logoutActionTypes.LOGOUT) {
    unsubscribeListeners();
  }
  next(action);
  if (action.meta && action.meta.firebaseRealtimeApi) {
    const {
      url = '/',
      req = '',
      onSuccessDispatches = [],
      onFailDispatches = [],
      onEmptyResponseDispatches = [],
      dataPrepareFunctions = [],
      id,
      orderByChild,
      limitToFirst,
      limitToLast,
    } = action.meta.firebaseRealtimeApi;

    const applyPreparers = (snapshot) =>
      prepareData(snapshot.val(), dataPrepareFunctions);

    const dispatchActions = (data) => {
      let dispatchesCollection;

      if (!data || _.isEmpty(data)) {
        dispatchesCollection = onEmptyResponseDispatches;
      } else {
        dispatchesCollection = onSuccessDispatches;
      }

      if (req === fireRequestTypes.UPDATE) {
        dispatchesCollection = onSuccessDispatches;
      }

      dispatchesCollection.forEach((action) => {
        store.dispatch(action(data));
      });
    };

    const successCallback = (snapshot) => {
      const data = applyPreparers(snapshot);
      dispatchActions(data);
    };

    const failCallback = (err) => {
      onFailDispatches.forEach((action) => {
        store.dispatch(action(err.message));
      });
    };

    let ref = firebase.database().ref(url);

    let queryRef: firebase.database.Query | null = null;

    if (orderByChild) {
      queryRef = ref.orderByChild(orderByChild);
    }
    if (limitToFirst) {
      queryRef = ref.limitToFirst(limitToFirst);
    } else if (limitToLast) {
      queryRef = ref.limitToLast(limitToLast);
    }

    switch (req) {
      case fireRequestTypes.GET:
        if (queryRef) {
          queryRef.once('value').then(successCallback).catch(failCallback);
        } else {
          ref.once('value').then(successCallback).catch(failCallback);
        }
        break;
      case fireRequestTypes.PUSH:
        ref.push(action.payload).then(dispatchActions).catch(failCallback);
        break;
      case fireRequestTypes.UPDATE:
        ref.update(action.payload).then(dispatchActions).catch(failCallback);
        break;
      case fireRequestTypes.SET:
        ref.set(action.payload).then(dispatchActions).catch(failCallback);
        break;
      case fireRequestTypes.REMOVE:
        ref.remove().then(dispatchActions).catch(failCallback);
        break;
      case fireRequestTypes.SUBSCRIBE:
        if (!id) {
          console.error('Firebase subscribe events must have an id key');
          return;
        }
        if (listeners[id]) {
          listeners[id]?.off('value');
        }
        if (queryRef) {
          queryRef.on('value', successCallback, failCallback);
          listeners[id] = queryRef;
        } else {
          ref.on('value', successCallback, failCallback);
          listeners[id] = ref;
        }
        break;
      case fireRequestTypes.UNSUBSCRIBE:
        if (!id) {
          console.error('Firebase unsubscribe events must have an id key');
          break;
        }
        if (listeners[id]) {
          listeners[id]?.off('value');
        }
        break;

      default:
        console.error('Invalid firebase action type');
    }
  }
};

const prepareData = (data, dataPrepareFunctions) => {
  let preparedData = data;
  dataPrepareFunctions.forEach((prepFn) => {
    try {
      preparedData = prepFn(preparedData);
    } catch (err: any) {
      console.error(err);
    }
  });
  return preparedData;
};

const unsubscribeListeners = () => {
  for (const key in listeners) {
    if (listeners[key]) {
      listeners[key]?.off('value');
      listeners[key] = null;
    }
  }
};
