import React, { useEffect, useState } from 'react';
import './Reply.css';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import 'antd/dist/antd.css';
import { MentionsInput, Mention } from 'react-mentions';
import { useSelector } from 'react-redux';
import {
  sceneMembersSelector2,
  sceneSelector,
  teamMembersSelector2,
} from 'redux/slices/app/selectors';

/**
 * Input textbox with tagging functionality
 *
 * To use the tagger, type @ and a popup window will appear with available users for tagging
 */
const Reply = ({
  className = 'mentions reply-box form-control',
  style = {},
  placeholder = 'Reply',
  // users = [],
  disabled = false,
  tagForReply = '', // Used for replying on a reply on a comment.
  setReplyOnReply = () => {}, // Used for replying on a reply on a comment.
  onCommentSubmit,
  singleLine = true,
  submitBtn = true,
}: {
  [key: string]: any;
  setReplyOnReply?: (...args: any) => void;
  onCommentSubmit: (text: string, tags: any) => void;
}) => {
  const [form] = Form.useForm();
  const [comment, setComment] = useState('');
  const [mentions, setMentions] = useState([] as any);
  const scene = useSelector(sceneSelector);
  const users = Object.values(
    useSelector(
      scene?.members ? sceneMembersSelector2 : teamMembersSelector2
    ) ?? {}
  ).map(({ name, ...rest }) => ({ ...rest, name, display: name }));
  // const enterKeyPressed = useKeyPress("Enter", true) // Second argument for prevent default
  // const shiftKeyPressed = useKeyPress("Shift")

  // Tag user on replying on his reply on a comment
  useEffect(() => {
    if (!comment.startsWith(tagForReply)) {
      // Check if we had add already add the tag of the user
      setComment(tagForReply + ' ' + comment);
    }

    setReplyOnReply();
  }, [tagForReply]);

  const addMentions = (id, display) => {
    console.log(id, display);

    if (mentions[id] == null) {
      setMentions({
        ...mentions,
        [id]: display,
      });
    }
  };

  const onSubmit = (e) => {
    if (comment.trim() !== '') {
      let mentionsIds = Object.keys(mentions);
      let tags = {};
      mentionsIds.forEach((id) => {
        if (comment.includes(id)) {
          tags[id] = mentions[id];
        }
      });
      setComment('');
      setMentions([]);
      onCommentSubmit(comment, tags);
    }
  };

  const renderSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div className="d-flex justify-content-start">
        <img
          src={entry.thumbnail ?? '/default-profile-image.jpg'}
          style={{ width: '25px', height: '25px' }}
          alt="avatar"
        />
        <span className="mx-3"> {entry.name} </span>
      </div>
    );
  };

  // useEffect(() => {
  //   if (enterKeyPressed === true && !shiftKeyPressed) {
  //     onSubmit()
  //   }
  // }, [enterKeyPressed])

  return (
    <React.Fragment>
      <Form form={form} layout="horizontal" onFinish={onSubmit}>
        <Form.Item className="mb-0 mt-3">
          <MentionsInput
            className={className}
            allowSpaceInQuery
            value={comment}
            singleLine={singleLine}
            onChange={(e) => setComment(e.target.value)}
            placeholder={placeholder}
            disabled={disabled}
            style={style}
          >
            <Mention
              type="user"
              trigger="@"
              data={users ? users : null}
              className=""
              markup="@[(__display__,__id__)]"
              renderSuggestion={renderSuggestion}
              appendSpaceOnAdd={true}
              displayTransform={(id, display) => {
                return `@${display}`;
              }}
              onAdd={addMentions}
            />
          </MentionsInput>
          {submitBtn ? (
            <i className="fas fa-paper-plane send-btn" onClick={onSubmit}></i>
          ) : null}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

Reply.propTypes = {
  /**
   * ClassName for the container
   */
  className: PropTypes.string,
  /**
   * Style for the container
   */
  style: PropTypes.object,
  /**
   * Placeholder for the input box
   */
  placeholder: PropTypes.string,
  /**
   * Array of users that can be tagged.
   */
  users: PropTypes.array,
  /**
   * Disables the input field if true.
   */
  disabled: PropTypes.bool,
  /**
   * Function that is called with the inputted comment and tags when user submits.
   */
  onCommentSubmit: PropTypes.func,
};

export default Reply;
