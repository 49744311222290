import './InvalidProjectOverlay.scss';
import React from 'react';

export const InvalidProjectOverlay = ({ visible, children }) => {
  if (!visible) return children;
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div className="project-sub-modal">
        <div className="body">
          <p>
            The owner of this project subscription has ended. Please contact the
            owner of this project or select another one
          </p>
        </div>
      </div>
      {children}
    </div>
  );
};
