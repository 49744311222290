import './ItemCard.scss';
import React, { forwardRef, useState } from 'react';
import { Carousel, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { likeItem, unlikeItem } from 'redux/slices/items/actions';
import {
  selectedProjectIdSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import { toasterAction } from 'redux/common/actions';
import DownloadModal from '../download-modal/DownloadModal';
import { useHistory } from 'react-router-dom';
import FullscreenIcon from './FullscreenIcon';
import FullscreenImage from 'common/components/fullscreen-image/FullscreenImage';
import firebase from 'firebase-main';
import { PurchaseModal2 } from '../purchase-modal2/PurchaseModal2';
import { PurchaseNoticeModal } from '../purchase-notice-modal/PurchaseNoticeModal';
import { FaDollarSign } from 'react-icons/fa';
import { dispatchEvents } from 'redux/middlewares/amplitude';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

const ItemCard = (
  {
    className = '',
    item,
    showSubcategory = true,
    onItemClick = () => {},
    onPackPurchase = (packId) => {},
    ...props
  },
  ref
) => {
  const dispatch = useDispatch(null);
  const history = useHistory();
  const userId = useSelector(userIdSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showPurchaseNoticeModal, setShowPurchaseNoticeModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [carousalIndex, setCarousalIndex] = useState(0);
  const [showFullscreenModal, setShowFullscreenModal] = useState(false);

  const inEnvironments = history.location.pathname.includes(
    'library/store/EnvironmentItems'
  );

  const {
    itemID,
    name,
    itemType,
    isPurchased,
    progress,
    isDownloaded = false,
    isFavorite = false,
    thumbnail,
    packIDs = [],
    driveID = null,
    price = 0,
  } = item;

  // const images = item.images ? item.images.split(',') : null;
  const images = thumbnail.split(',');

  const purchased = isPurchased || (driveID && driveID === teamId);
  const packs = packIDs.map(({ packID }) => packID);
  // const purchasable = packs && packs.length > 0;

  const myPack = packIDs.find(({ packSize }) => packSize === 1);

  // let price = 'Free';
  // if (purchased) price = null;
  // else {
  //   packIDs.forEach(({ packPrice, packSize }) => {
  //     if (packSize === 1) price = `$ ${packPrice.toFixed(2)}`;
  //   });
  // }

  const like = (e) => {
    if (projectId) {
      dispatch(likeItem(itemID, projectId));
    } else {
      dispatch(
        toasterAction(
          'info',
          'Invalid Project',
          'Please select a valid project from the main menu'
        )
      );
    }
    e.stopPropagation();
  };

  const unlike = (e) => {
    if (projectId) {
      dispatch(unlikeItem(itemID, projectId));
    } else {
      dispatch(
        toasterAction(
          'info',
          'Invalid Project',
          'Please select a valid project from the main menu'
        )
      );
    }
    e.stopPropagation();
  };

  const renderProgressBar = (
    <div className="item-progress">
      <ProgressBar now={progress} />
    </div>
  );

  const renderCloudIcon = (
    <img
      className="cloud-icon"
      src={`/backlot-library/library/${
        isDownloaded ? 'cloud-mark.svg' : 'cloud-download.svg'
      }`}
      alt={`cloud icon ${
        isDownloaded ? '(item is already downloaded)' : 'to download item'
      }`}
    />
  );

  const renderThumbnail =
    !inEnvironments && (!images || images.length <= 1) ? (
      <div
        className="img-container"
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      >
        {typeof progress === 'number' && progress < 100
          ? renderProgressBar
          : null}
      </div>
    ) : (
      <div className="carousel-container">
        <Carousel
          indicators={false}
          activeIndex={carousalIndex}
          onSelect={setCarousalIndex}
          interval={null}
          prevIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === 0) {
                  setCarousalIndex(images.length - 1);
                } else {
                  setCarousalIndex(carousalIndex - 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-prev-icon"
            />
          }
          nextIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === images.length - 1) {
                  setCarousalIndex(0);
                } else {
                  setCarousalIndex(carousalIndex + 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-next-icon"
            />
          }
        >
          {images.map((src) => (
            <Carousel.Item key={src}>
              <img className="carousel-img" src={src} alt="environment" />
            </Carousel.Item>
          ))}
        </Carousel>

        {typeof progress === 'number' && progress < 100
          ? renderProgressBar
          : null}
      </div>
    );

  return (
    <>
      {showFullscreenModal && (
        <FullscreenImage
          src={thumbnail}
          alt={thumbnail}
          onClose={() => setShowFullscreenModal(false)}
        />
      )}
      <DownloadModal
        item={item}
        show={showDownloadModal}
        handleClose={() => {
          setShowDownloadModal(false);
        }}
        handleOk={() => {
          onItemClick(item);
          setShowDownloadModal(false);
          dispatchEvents([
            {
              event_type: 'download_bundle',
              user_id: userId,
              bundle_name: item.subcategory,
            },
          ]);
          firebase.analytics().logEvent('download_bundle', {
            ...item,
            bundle_name: item.subcategory,
            item_name: item.name,
          });
        }}
      />

      {showPurchaseNoticeModal && (
        <PurchaseNoticeModal
          onOk={() => {
            setShowPurchaseNoticeModal(false);
          }}
          item={item}
        />
      )}

      {showPurchaseModal && (
        <PurchaseModal2
          onOk={(packId) => {
            onPackPurchase(packId);
            onItemClick(item);
            dispatchEvents([
              {
                event_type: 'download_bundle',
                user_id: userId,
                bundle_name: item.subcategory,
              },
            ]);
            if (price > 0) {
              setShowPurchaseNoticeModal(true);
            }
            setShowPurchaseModal(false);
          }}
          onCancel={() => {
            setShowPurchaseModal(false);
          }}
          item={item}
        />
      )}

      <div
        className={`item ${className}`}
        key={itemID}
        onClick={() => {
          if (!purchased) {
            setShowPurchaseModal(true);
          } else if (!isDownloaded && itemType === 'PropItems')
            setShowDownloadModal(true);
          else {
            if (onItemClick) onItemClick(item);
            firebase
              .analytics()
              .logEvent('select_item', { ...item, item_name: item.name });
            dispatchEvents([
              {
                event_type: 'select_item',
                user_id: userId,
                event_properties: { ...item, item_name: item.name },
              },
            ]);
          }
        }}
        ref={ref}
        {...props}
      >
        <div className="item-card">
          <div className="item-top-row">
            <FontAwesomeIcon
              className={`love-icon ${isFavorite ? 'fill' : 'no-fill'}`}
              icon={faHeart}
              size="xs"
              onClick={isFavorite ? unlike : like}
            />

            {thumbnail && thumbnail.length > 0 && (
              <div
                className="fullscreen-icon"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowFullscreenModal(true);
                }}
              >
                <FullscreenIcon size={14} />
              </div>
            )}
          </div>

          {/* <div
            className="img-container"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          >
            {typeof progress === 'number' && progress < 100
              ? renderProgressBar
              : null}
          </div> */}
          {renderThumbnail}

          <div className="item-bottom-row">
            {/* {purchasable && !purchased && (
              <FaDollarSign size={12} className="bottom-left" />
            )} */}
            {purchased && renderCloudIcon}
            {!purchased && (
              <div className="price-badge">
                {price === 0 ? 'FREE' : '$ ' + (price / 100).toFixed(2)}
              </div>
            )}
          </div>
        </div>

        <div className="name">
          <h4>{name}</h4>
          {item.subcategoryDisplayName &&
            showSubcategory &&
            item.itemType === 'PropItems' && (
              <div
                className="subcategory-label"
                onClick={() => {
                  if (item.subcategoryDisplayName && item.category) {
                    history.push(
                      `/library/store/PropItems?category=${item.category}&subcategory=${item.subcategoryDisplayName}`
                    );
                  } else {
                    history.push(
                      `/library/store/PropItems?category=${item.category}`
                    );
                  }
                }}
              >
                {item.subcategoryDisplayName}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default forwardRef(ItemCard);
