import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';

const PublicRoute = ({
  component: Component,
  restricted = false,
  redirect = '/',
  match = { url: '' },
  ...rest
}) => {
  const auth = useSelector((state) => state.firebase.auth);
  const isLogin = !isEmpty(auth);

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route (visible only to unAuthenticated users)
    <Route
      {...rest}
      render={(props) =>
        isLogin && restricted ? (
          // <Redirect to={`${match.url}/${redirect}`} />
          <Redirect to={`menu/recent`} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
