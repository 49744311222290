import './BacklotCurveEditor.scss';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Editor } from './Editor';
import { useDispatch, useSelector } from 'react-redux';
import { setCurves } from 'redux/slices/curves/actions';

export const BacklotCurveEditor = () => {
  const dispatch = useDispatch();
  // const [curves, setCurves] = useState<any[]>([]);

  return (
    <div className="curve-editor">
      <Editor />
    </div>
  );
};
