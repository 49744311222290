import './NavGrid.scss';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { itemsProgressSelector } from 'redux/slices/items/selectors';
import { userNameSelector } from 'redux/slices/auth/authSelectors';

const NavGrid = ({ navButtons = [] as any[] }) => {
  const history = useHistory();
  const userName = useSelector(userNameSelector);
  const itemsProgress = useSelector(itemsProgressSelector);
  const pendingItemsCount = Object.values(itemsProgress).filter(
    (progress) => progress < 100
  ).length;

  return (
    <div id="nav-container">
      {navButtons.map(({ title, className, src, redirectPath }, index) => (
        <div
          className={`nav`}
          key={index}
          onClick={() => {
            if (redirectPath) {
              history.push(`/library/${redirectPath}`);
            }
          }}
        >
          {pendingItemsCount > 0 && title == 'Team Library' && (
            <div className="badge">{pendingItemsCount}</div>
          )}
          <div className={`nav-icon ${className}`}>
            <img src={src} alt={title} />
          </div>
          <div>
            <h4>
              {title === 'Team Library' && userName
                ? `${userName}'s Assets`
                : title}
            </h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NavGrid;
