import './SharedScenes.scss';
import React, { useState } from 'react';
import ScenesGrid from '../project/scenes/grid/ScenesGrid';
import ScenesList from '../project/scenes/list/ScenesList';
import { useSelector } from 'react-redux';
import {
  availableSharedScenesArraySelector,
  themeSelector,
} from 'redux/slices/app/selectors';
import { TopBar } from '../project/TopBar';

const SharedScenes = () => {
  const [view, setView] = useState('grid');
  const theme = useSelector(themeSelector);
  const [activeTab, setActiveTab] = useState(0);
  const sharedScenes = useSelector(availableSharedScenesArraySelector) ?? [];
  const [sceneOrderType, setSceneOrderType] = useState(0);

  let sortedScenes = sharedScenes;

  switch (sceneOrderType) {
    // Sort by recently updated
    // case 0: {
    //   sortedScenes = sharedScenes.sort((scene1, scene2) => {
    //     return (scene2.date ?? 0) - (scene1.date ?? 0);
    //   });
    //   break;
    // }

    // // Sort by scene name
    case 0: {
      sortedScenes = sharedScenes.sort((scene1, scene2) => {
        const a = ((scene1.name as string) ?? '').trim().substr(4).trim();
        const b = ((scene2.name as string) ?? '').trim().substr(4).trim();
        return a.localeCompare(b);
      });
      break;
    }
  }
  return (
    <div className={`screen shared-scenes ${theme}`}>
      <div className="screen-container">
        <TopBar
          title="Shared Scenes"
          members={null}
          view={view}
          setView={setView}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sceneOrderType={sceneOrderType}
          sceneOrderTypes={['Scene Name']}
          onSceneOrderTypeChange={setSceneOrderType}
          showMembers={false}
          showNewSceneBtn={false}
          showShareBtn={false}
          showProjectSettings={false}
        />

        {view === 'grid' ? (
          <ScenesGrid
            scenes={sharedScenes}
            sortScenes={false}
            showAddSceneBtn={false}
          />
        ) : (
          <ScenesList scenes={sharedScenes} showAddSceneBtn={false} />
        )}
      </div>
    </div>
  );
};

export default SharedScenes;
