export const prepareUsers = () => (users) => {
  if (!users) {
    return users;
  }
  for (const key in users) {
    if (users[key]) {
      if (users[key].public) {
        users[key].public.id = key;
        users[key].public.key = key;
        users[key].public.display = users[key].public.name
          ? users[key].public.name
          : 'No Name';
        users[key].public.avatar = users[key].public.thumbnail
          ? users[key].public.thumbnail
          : 'https://s3.amazonaws.com/uifaces/faces/twitter/bluefx_/128.jpg';
      }
    }
  }

  let allUsers = [];
  for (const userId in users) {
    if (users[userId]) {
      let user = users[userId].public;
      allUsers.push({ ...user });
    }
  }
  return allUsers;
};

export const getIntersection = (obj1 = {}) => (obj2 = {}) => {
  const intersection = {};
  for (const key in obj1) {
    if (obj2 && obj2[key]) intersection[key] = obj2[key];
  }
  return intersection;
};

export const extractProjectId = () => (data) => {
  if (!data || !data.project_id) return data;
  return data.project_id;
};

export const extractTeamId = () => (data) => {
  if (!data || !data.team_id) return data;
  return data.team_id;
};

export const prepareTeams = () => teams => {
  if (!teams) return teams;

  const colors = ["yellow", "green", "purple"];

  Object.keys(teams).forEach((key, index) => {
    teams[key] = {
      name: teams[key],
      color: colors[index % colors.length]
    }
  })

  return teams
}