import './SearchGrid.scss';
import queryString from 'query-string';
import ItemsGrid from 'backlot-library/components/item/items-grid/ItemsGrid';
import React, { useEffect, useRef, useState } from 'react';
import useDebounce from 'common/hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import {
  itemsSelector,
  itemsSizeSelector,
  searchColorsSelector,
  searchErasSelector,
  searchItemsSelector,
  searchItemsSizeSelector,
  searchMaterialTypesSelector,
  subcategoryFiltersSelector,
} from 'redux/slices/items/selectors';
import {
  itemsPendingSelector,
  searchItemsPendingSelector,
} from 'redux/slices/loaders/loadersSelectors';
import { useHistory } from 'react-router-dom';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import {
  clearSearchItems,
  firebaseSearchItems2,
  setSearchTerm,
  setSearchTags,
  setSearchFilters,
  fetchItems,
  selectSubcategory,
} from 'redux/slices/items/actions';
import { Select } from 'antd';
import { searchFilters } from 'redux/slices/items/types';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import useScrollReachBottom from 'common/hooks/useScrollReachBottom';
import { dispatchEvents } from 'redux/middlewares/amplitude';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

const searchDebounceDelay = 300;
const HITS_PER_PAGE = 10;

const tags = [
  { tag: 'camera', color: '#26d474', itemType: 'CameraItems' },
  { tag: 'light', color: '#ffd417', itemType: 'LightItems' },
  { tag: 'character', color: '#ff6f1c', itemType: 'CharacterItems' },
  { tag: 'prop', color: '#a84e9b', itemType: 'PropItems' },
  { tag: 'environment', color: '#eb2d40', itemType: 'EnvironmentItems' },
  { tag: 'template', color: '#2dbdeb', itemType: 'Templates' },
];

const options = [
  { label: 'Building', color: '#ffffff', value: 'isBuilding' },
  { label: 'Can-Carry', color: '#ffffff', value: 'canCarry' },
  { label: 'Environmental', color: '#ffffff', value: 'isEnvironmentalItem' },
];

const SearchGrid = ({ query, showFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const userId = useSelector(userIdSelector);

  const itemsContainerRef = useRef<any>();

  const teamId = useSelector(selectedTeamIdSelector);

  const [selectedTags, setSelectedTags] = useState<boolean[]>(
    tags.map(() => false)
  );

  const featuredItems = useSelector(itemsSelector);
  const featuredItemsPending = useSelector(itemsPendingSelector);
  const featuredItemsSize = useSelector(itemsSizeSelector);

  // const [selectedOptions, setSelectedOptions] = useState<{
  //   isBuilding?: boolean;
  //   canCarry?: boolean;
  //   isEnvironmentalItem?: boolean;
  // }>({});

  // const [selectedColor, setSelectedColor] = useState<string | undefined>(
  //   undefined
  // );
  // const [selectedMaterial, setSelectedMaterial] = useState<string | undefined>(
  //   undefined
  // );
  // const [selectedEra, setSelectedEra] = useState<string | undefined>(undefined);

  const [filters, setFilters] = useState<searchFilters>({});

  const colors = useSelector(searchColorsSelector);
  const materials = useSelector(searchMaterialTypesSelector);
  const eras = useSelector(searchErasSelector);
  const subcategories = useSelector(subcategoryFiltersSelector);

  // const filters: searchFilters = {
  //   color: selectedColor,
  //   materialType: selectedMaterial,
  //   era: selectedEra,
  //   ...selectedOptions,
  // };

  useEffect(() => {
    const newSelectedTags = tags.map(() => false);
    if (params.tags) {
      const queryTags = (params?.tags as string).split(',');
      tags.forEach(({ tag }, index) => {
        if (queryTags.includes(tag)) {
          newSelectedTags[index] = true;
        }
      });
    }
    setSelectedTags(newSelectedTags);
  }, [params.tags]);

  const ref = useRef(null);
  const [searchTextDebounced, searchTextDebouncedLoading] = useDebounce(
    query,
    searchDebounceDelay
  );

  const [curSearchPage, setCurSearchPage] = useState(1);

  const searchedItems = useSelector(searchItemsSelector);
  const searchItemsSize = useSelector(searchItemsSizeSelector);
  const searchItemsPending = useSelector(searchItemsPendingSelector);

  const searching =
    query.length > 0 && (searchItemsPending || searchTextDebouncedLoading);

  const showFeatured = searchTextDebounced.length === 0;
  const extraSearching = searchItemsPending;

  useEffect(() => {
    dispatch(selectSubcategory('Featured'));
  }, []);

  useEffect(() => {
    setCurSearchPage(0);
  }, [query]);

  const queryTags = tags
    .filter((_, index) => selectedTags[index])
    .map(({ itemType }) => itemType)
    .toString();

  // Searches for the debounced text
  useEffect(() => {
    dispatch(setSearchTerm(searchTextDebounced));
    dispatch(setSearchTags(queryTags));
    dispatch(setSearchFilters(filters));
    if ((searchTextDebounced as string).length > 0) {
      dispatch(
        firebaseSearchItems2(
          teamId,
          searchTextDebounced as string,
          0,
          HITS_PER_PAGE,
          queryTags,
          showFilters ? filters : {}
        )
      );
      setCurSearchPage(HITS_PER_PAGE);
    } else {
      dispatch(clearSearchItems());
      dispatch(fetchItems(teamId, 'PropItems', 'Featured', 0, HITS_PER_PAGE));
    }
  }, [
    dispatch,
    teamId,
    searchTextDebounced,
    selectedTags,
    filters,
    showFilters,
  ]);

  // Log search event
  useEffect(() => {
    if (searchTextDebounced.length > 0)
      dispatchEvents([
        {
          user_id: userId,
          event_type: 'search_items',
          event_properties: {
            query: searchTextDebounced,
            tags: queryTags,
            filters,
          },
        },
      ]);
  }, [searchTextDebounced, userId, queryTags, filters]);

  const onItemClick = (item) => {
    const { _highlightResult, ...itemData } = item;
    (window as any).selectObject(itemData);
    (window as any).addInstantiatedItems(item.itemID);
  };

  const onPackPurchase = (packId) => {
    if ((searchTextDebounced as string).length > 0) {
      dispatch(
        firebaseSearchItems2(
          teamId,
          searchTextDebounced as string,
          0,
          searchedItems.length,
          queryTags,
          showFilters ? filters : {}
        )
      );
      setCurSearchPage(HITS_PER_PAGE);
    }
  };

  const onPageReachBottom = () => {
    if (
      searchTextDebounced.length > 0 &&
      !extraSearching &&
      curSearchPage < searchItemsSize
    ) {
      dispatch(
        firebaseSearchItems2(
          teamId,
          searchTextDebounced as string,
          curSearchPage,
          HITS_PER_PAGE,
          queryTags,
          filters
        )
      );
      setCurSearchPage(curSearchPage + HITS_PER_PAGE);
    } else if (showFeatured && featuredItems.length < featuredItemsSize) {
      dispatch(
        fetchItems(
          teamId,
          'PropItems',
          'Featured',
          featuredItems.length,
          HITS_PER_PAGE
        )
      );
    }
  };

  useScrollReachBottom(itemsContainerRef, onPageReachBottom, [
    extraSearching,
    curSearchPage,
    searchItemsSize,
    featuredItemsSize,
  ]);

  const renderSelections = (
    <>
      <Select
        className="select"
        placeholder="Color"
        dropdownClassName="color-select"
        value={filters.color}
        onChange={(color) => setFilters({ ...filters, color })}
        allowClear
        // mode="multiple"
      >
        {colors.map((color) => (
          <Select.Option
            key={color}
            className="search-library-select"
            value={color}
          >
            {color}
          </Select.Option>
        ))}
      </Select>

      <Select
        className="select"
        placeholder="Material"
        dropdownClassName="color-select"
        value={filters.materialType}
        onChange={(materialType) => setFilters({ ...filters, materialType })}
        allowClear
      >
        {materials.map((material) => (
          <Select.Option
            key={material}
            className="search-library-select"
            value={material}
          >
            {material}
          </Select.Option>
        ))}
      </Select>

      <Select
        className="select"
        placeholder="Era"
        dropdownClassName="color-select"
        value={filters.era}
        onChange={(era) => setFilters({ ...filters, era })}
        allowClear
      >
        {eras.map((era) => (
          <Select.Option
            key={era}
            className="search-library-select"
            value={era}
          >
            {era}
          </Select.Option>
        ))}
      </Select>

      {/* <Select
        className="select"
        placeholder="Subcategory"
        dropdownClassName="color-select"
        value={filters.subcategory}
        onChange={(subcategory) => setFilters({ ...filters, subcategory })}
        allowClear
      >
        {subcategories?.map((subcategory) => (
          <Select.Option
            key={subcategory}
            className="search-library-select"
            value={subcategory}
          >
            {subcategory}
          </Select.Option>
        ))}
      </Select> */}
    </>
  );

  const renderTags = tags.map(({ tag, color }, index) => (
    <button
      key={tag}
      className={`check-tag ${selectedTags[index] ? 'checked' : ''}`}
      // checked={selectedTags[index]}
      onClick={() => {
        const checked = !selectedTags[index];
        let newTags = '';
        if (checked) {
          newTags = tags
            .filter((_, i) => i === index || selectedTags[i])
            .map(({ tag }) => tag)
            .toString();
        } else {
          newTags = tags
            .filter((_, i) => i !== index && selectedTags[i])
            .map(({ tag }) => tag)
            .toString();
        }

        history.push({
          pathname: history.location.pathname,
          search: newTags ? `?tags=${newTags}` : '',
        });
      }}
      style={{
        backgroundColor: selectedTags[index] ? color : 'inherit',
        borderColor: color,
      }}
    >
      {tag}
    </button>
  ));

  const renderOptions = options.map(({ label, value, color }) => (
    <button
      key={value}
      className={`check-tag ${filters[value] ? 'checked' : ''}`}
      // checked={filters[value]}
      onClick={() => {
        const checked = !filters[value];
        const newFilters = { ...filters, [value]: checked };
        if (!checked) delete newFilters[value];
        setFilters(newFilters);
      }}
      style={{
        backgroundColor: filters[value] ? color : 'inherit',
        borderColor: color,
        color: filters[value] ? 'black' : 'white',
      }}
    >
      {label}
    </button>
  ));

  const displayItems = showFeatured ? featuredItems : searchedItems;

  const searchPending = searchedItems.length === 0 && (searching as boolean);
  const extraSearchPending = extraSearching;

  const featuredPending = featuredItemsPending && featuredItems.length === 0;
  const extraFeaturedPending = featuredItemsPending && featuredItems.length > 0;

  const pending = showFeatured ? featuredPending : searchPending;
  const extraPending = showFeatured ? extraFeaturedPending : extraSearchPending;

  return (
    <div id="home-search-results">
      {showFilters && (
        <>
          <div className="select-boxes">{renderSelections}</div>
          <div className="options-container">{renderOptions}</div>
        </>
      )}
      <div className="tags-container">{renderTags}</div>

      <div className="items-container" ref={itemsContainerRef}>
        <ItemsGrid
          items={displayItems}
          // itemsPending={searchedItems.length === 0 && (searching as boolean)}
          itemsPending={pending}
          extraItemsPending={extraPending}
          // extraItemsPending={extraSearching}
          onItemClick={onItemClick}
          onPackPurchase={onPackPurchase}
          // onPageReachBottom={onPageReachBottom}
          showSubcategory
          // ref={ref}
        />
      </div>
    </div>
  );
};

export default SearchGrid;
