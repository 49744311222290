import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';
import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import Axios from 'axios';
import { fireRealtimeDBType } from './../../middlewares/firebaseRealtimeApiMiddleware/types';
import { Action } from 'redux/common/types';
import { sceneActionType, sceneResource, sceneActionStatus } from './types';

const namespace = 'scenes';

export const scenesActionTypeFactory = (
  actionType: sceneActionType,
  resource?: sceneResource,
  actionStatus?: sceneActionStatus
) => {
  let action = `${namespace}/`;
  if (actionType) action += actionType;
  if (resource) action += `_${resource}`;
  if (actionStatus) action += `_${actionStatus}`;
  return action;
};

export const subscribeSceneUsers = (sceneId: string): Action => {
  const fireRealtimeDbReq: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'scenes_users',
      url: `scenes/${sceneId}/members`,
      onSuccessDispatches: [setSceneUsers],
      onEmptyResponseDispatches: [setSceneUsers],
    },
  };

  return {
    type: scenesActionTypeFactory('SUBSCRIBE', 'USERS'),
    meta: fireRealtimeDbReq,
  };
};

export const setSceneUsers = (users): Action => {
  return {
    type: scenesActionTypeFactory('SET', 'USERS'),
    payload: users,
  };
};

export const unSubscribeSceneUsers = (): Action => {
  const fireRealtimeDbReq: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'scenes_users',
    },
  };
  return {
    type: scenesActionTypeFactory('UNSUBSCRIBE', 'USERS'),
    meta: fireRealtimeDbReq,
  };
};

export const inviteGuestToScene = (email: string, sceneId: string) => async (
  dispatch,
  getState
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const body = {
    email,
    sceneId,
  };

  Axios.post(
    `${process.env.REACT_APP_REALTIME_URL}/inviteUserToScene`,
    body,
    config
  )
    .then((res) => {
      dispatch(inviteGuestToSceneSuccess(res.data.message));
    })
    .catch((error) => {
      dispatch(inviteGuestToSceneFail(error?.response?.data?.message));
    });
};

export const inviteGuestToSceneSuccess = (msg): Action => {
  return {
    type: scenesActionTypeFactory('INVITE', 'USERS', 'SUCCESS'),
    meta: {
      toaster: {
        type: 'success',
        message: 'Invitation created successfully',
        description: msg,
      },
    },
  };
};

export const inviteGuestToSceneFail = (error): Action => {
  return {
    type: scenesActionTypeFactory('INVITE', 'USERS', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to invite user',
        description: error,
      },
    },
  };
};

export const revokeInvite = (sceneId: string, userId: string): Action => {
  const fireRealtimeDbReq: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      req: 'REMOVE',
      url: `scenes/${sceneId}/members/${userId}`,
      onSuccessDispatches: [setSceneUsers],
      onEmptyResponseDispatches: [setSceneUsers],
    },
  };

  return {
    type: scenesActionTypeFactory('REVOKE', 'USERS'),
    meta: fireRealtimeDbReq,
  };
};

export const revokeInviteSuccess = (): Action => {
  return {
    type: scenesActionTypeFactory('REVOKE', 'USERS', 'SUCCESS'),
  };
};

export const revokeInviteFail = (error): Action => {
  return { type: scenesActionTypeFactory('REVOKE', 'USERS', 'FAIL') };
};
