import { createSelector } from 'reselect';
import { CommentsCollection } from './types';

interface CommentsSlice {
  comments: {
    users: any;
    comments: any;
    commentsModalId: string;
    showCommentsMenu: boolean;
    scrollingComment: null;
    tagsNotifications: any[];

    sceneComments: CommentsCollection;
    shotsComments: { [key: string]: CommentsCollection };
    snapshotComments: { [key: string]: CommentsCollection };
    objectComments: { [key: string]: CommentsCollection };
  };
}

const objectToArray = (obj) => {
  const array: any[] = [];
  for (const id in obj) {
    array.push({ id, ...obj[id] });
  }
  return array;
};

export const showCommentsMenuSelector = (store: CommentsSlice) =>
  store.comments.showCommentsMenu;

export const showCommentsModalSelector = (store: CommentsSlice) =>
  store.comments.commentsModalId;

export const commentsSelector = (store: CommentsSlice) =>
  store.comments.comments;

export const sceneCommentsSelector = (store: CommentsSlice) =>
  store.comments.sceneComments ?? {};

export const shotCommentsSelector = (store: CommentsSlice) =>
  store.comments.shotsComments ?? {};

export const snapshotCommentsSelector = (store: CommentsSlice) =>
  store.comments.snapshotComments ?? {};

export const objectCommentsSelector = (store: CommentsSlice) =>
  store.comments.objectComments ?? {};

export const clipsCommentsSelector = createSelector(
  (store: CommentsSlice) => store.comments.comments,
  (comments = {}) => {
    const filteredObject = {};
    let predicate = (comment) => comment.shotID != null;

    for (const key in comments) {
      if (predicate(comments[key])) filteredObject[key] = comments[key];
    }

    return filteredObject;
  }
);

export const blueprintObjectCommentsSelector = (objectId) =>
  createSelector(
    (store: any) => store.comments.comments,
    (comments) => {
      const filteredObject = {};
      let predicate = (comment) => comment.blueprintObjectId === objectId;

      for (const key in comments) {
        if (predicate(comments[key])) filteredObject[key] = comments[key];
      }

      return filteredObject;
    }
  );

export const commentsArraySelector = createSelector(
  commentsSelector,
  objectToArray
);

export const sceneCommentsArraySelector = createSelector(
  sceneCommentsSelector,
  objectToArray
);

export const clipsCommentsArraySelector = createSelector(
  clipsCommentsSelector,
  objectToArray
);

export const blueprintObjectCommentsArraySelector = (objectId) =>
  createSelector(blueprintObjectCommentsSelector(objectId), objectToArray);

export const commentSelector = (commentId, shotId) => {
  if (shotId != null) {
    return createSelector(clipsCommentsArraySelector, (comments) =>
      comments.length > 0
        ? comments.find((comment) => comment.id === commentId)
        : null
    );
  } else {
    return createSelector(sceneCommentsArraySelector, (comments) =>
      comments.length > 0
        ? comments.find((comment) => comment.id === commentId)
        : null
    );
  }
};

// export const userCommentOwnershipSelector = (commentID, shotID) =>
//   createSelector(
//     (store: any) =>
//       shotID > 0
//         ? store.comments.clipComments.data[commentID]
//         : store.comments.sceneComments.data[commentID],
//     (store: any) => store.firebase.auth.uid,
//     (comment, userId) => (comment ? comment.commentor === userId : true)
//   );
