import produce from 'immer';
import { Action } from 'redux/common/types';
import { bundlesAction, bundlesSlice } from './types';

export const bundlesReducer = produce(
  (state: bundlesSlice = initState, action: Action) => {
    switch (action.type) {
      case bundlesAction('SET', 'BUNDLES'): {
        state.data = action.payload;
        return state;
      }

      case bundlesAction('REMOVE', 'BUNDLE'): {
        const bundleId = action.payload;
        if (state.data) {
          state.data = state.data.filter((bundle) => bundle.key !== bundleId);
        }
        return state;
      }

      default:
        return state;
    }
  }
);

const initState: bundlesSlice = { data: null };
