import { invoice } from './types';
import { createSelector } from 'reselect';
import { userIdSelector } from '../auth/authSelectors';
import { mapRolesToString } from './util';
import moment from 'moment';
import _ from 'lodash';
import { teamMembersSelector3 } from '../app/selectors';

interface TeamSlice {
  team: {
    renewalDate: number;
    invoices: { invoices: invoice[]; packs: invoice[] };
    licensesForm: {
      newNumOfLicenses: number;
      newSubscriptionCost: number;
    };
  };
}

export const teamImageSelector = (state) => state.team.teamImage;
// export const teamMembersSelector = (state) => state.team.teamMembers;
export const userExistedSelector = (state) => state.team.userExisted;
// export const cardDataSelector = (state) => state.team.cardData;
export const creditSelector = (state) => state.team.credit;
export const numOfLicensesSelector = (state) => state.team.numOfLicenses;
export const numOfActiveUsersSelector = (state) => state.team.numOfActiveUsers;
export const newNumOfLicensesSelector = (state) => state.team.newNumOfLicenses;
export const newSubscriptionCostSelector = (state: TeamSlice) =>
  state.team.licensesForm.newSubscriptionCost;
export const newSubscriptionDateSelector = (state: TeamSlice) =>
  state.team.renewalDate;
export const requiresActionSelector = (state) => state.team.requiresAction;
export const paymentMethodIdSelector = (state) => state.team.paymentMethodId;

export const isAdminSelector = createSelector(
  userIdSelector,
  teamMembersSelector3,
  (userId, teamMembers = {}) =>
    Object.values(teamMembers ?? {}).find((member) => member.id === userId)
      ?.isAdmin
);

export const userRoleInTeamSelector = createSelector(
  userIdSelector,
  teamMembersSelector3,
  (userId, teamMembers) =>
    mapRolesToString(
      Object.values(teamMembers ?? {}).find((member) => member.id === userId)
        ?.role
    )
);

export const isAdminOrEditorSelector = createSelector(
  [userIdSelector, teamMembersSelector3],
  (userId, teamMembers = {}) => {
    // console.log(
    //   Object.values(teamMembers ?? {}).find((member) => member.id === userId)
    //     ?.role ?? 3
    // );

    return (
      (Object.values(teamMembers ?? {}).find((member) => member.id === userId)
        ?.role ?? 3) < 2
    );
  }
);

export const adminsAndEditorsLengthSelector = createSelector(
  teamMembersSelector3,
  (teamMembers) =>
    Object.values(teamMembers ?? {}).filter(
      (member) => member.role === 0 || member.role === 1
    ).length
);

export const newMemberDataSelector = createSelector(
  (state: any) => state.team.userData,
  (user) => {
    let result: any[] = [];
    for (const id in user) {
      const resultUser = { ...user[id] };
      resultUser.avatar = user[id].avatar
        ? user[id].avatar
        : '/assets/images/user.png';
      result.push({ id, ...resultUser });
    }
    return result;
  }
);

export const pendingInvitesSelector = createSelector(
  (state: any) => state.team.pendingInvites,
  (invites = {}) => {
    let result: any[] = [];
    if (!_.isEmpty(invites)) {
      for (const id in invites) {
        result.push({ id, email: invites[id].email, role: invites[id].role });
      }
    }
    return result;
  }
);

export const renewalDateSelector = createSelector(
  (state: any) => state.team.renewalDate,
  (date) => {
    if (date) {
      const momentObj = moment(new Date(date * 1000));
      const momentString = momentObj.format('MMM DD YYYY');
      return momentString;
    }
  }
);

export const invoicePendingSelector = (store: TeamSlice) =>
  !store.team.invoices;

export const invoicesSelector = createSelector(
  [(state: TeamSlice) => state.team.invoices?.invoices],
  (invoices) => {
    return invoices ? [...invoices].sort((a, b) => b?.date - a?.date) : null;
  }
);

export const inAppPurchaseHistorySelector = createSelector(
  [(state: TeamSlice) => state.team.invoices?.packs],
  (invoices) => {
    return invoices ? [...invoices].sort((a, b) => b?.date - a?.date) : null;
  }
);
