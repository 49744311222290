import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { objectCommentsSelector } from 'redux/slices/comments/selectors';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import { animateScroll } from 'react-scroll';
import { commentType, createComment } from 'redux/slices/comments/types';
import { addComment } from 'redux/slices/comments/actions';
import CommentCard from 'backlot-comments/components/comments/Comment/Comment';
import Reply from 'backlot-comments/components/comments/Reply/Reply';

type propTypes = {
  commentType: commentType;
  objectId: any;
  cardTitleRef: any;
};

const ElementComments = ({
  commentType,
  objectId,
  cardTitleRef,
}: propTypes) => {
  const dispatch = useDispatch();

  const commentsSectionRef = useRef(null as any);

  // const comments = useSelector(blueprintObjectCommentsArraySelector(objectId));
  const comments = useSelector(objectCommentsSelector)[objectId] ?? {};

  const commentsIds = Object.keys(comments);
  const commentsArray = Object.values(comments);

  const users = useSelector<any>((state) => state.app.users);
  const sceneId = useSelector(selectedSceneIdSelector);
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const userRole = useSelector(userRoleSelector);
  const selectedSceneId = useSelector(selectedSceneIdSelector);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'blueprint-comments',
    });
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [comments.length]);

  useEffect(() => {
    // Adjust the height of the comments sections to be the height of 100vh - all of the other elements
    if (cardTitleRef) {
      const cardTitleHeight = cardTitleRef.current.offsetHeight;
      let height = window.innerHeight - 216 - cardTitleHeight;
      commentsSectionRef.current.style.maxHeight = height + 'px';
      commentsSectionRef.current.style.height = height + 'px';
    }
  }, [cardTitleRef]);

  const onCommentSubmit = (text, tags) => {
    const date = moment().toISOString();
    const comment = createComment(text, userId, userName, userRole, date, tags);
    dispatch(addComment('OBJECT_COMMENT', comment, sceneId, objectId));
  };

  const elementComments = commentsArray.map((comment, index) => {
    return (
      <CommentCard
        key={comment.id}
        commentType={commentType}
        commentId={comment.id ?? commentsIds[index]}
        commentorId={comment.commentorId}
        commentorName={comment.commentorName}
        role={comment.role}
        timeStamp={comment.date}
        containerRefId={objectId}
        text={comment.text}
        likes={comment.likes}
        // myComment={comment.myComment}
        tags={comment.tags}
        replies={comment.replies}
        // parentId={comment.parentId}
      ></CommentCard>
    );
  });

  return (
    <div className="d-flex flex-column">
      <div
        ref={commentsSectionRef}
        className="comments"
        id="blueprint-comments"
      >
        {elementComments}
      </div>

      <div>
        <div>
          <Reply
            users={users}
            onCommentSubmit={onCommentSubmit}
            disabled={selectedSceneId ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default ElementComments;
