import React from 'react';
import { useSelector } from 'react-redux';
import { projectSortableSelector } from 'redux/slices/app/selectors';
import _ from 'lodash';
import SceneListItem from './SceneListItem';
import { CreateSceneButton } from '../create-scene-btn/CreateSceneButton';

type propTypes = {
  scenes?: any[];
  displayIndex?: boolean;
  showAddSceneBtn?: boolean;
  onSceneIndexUpdate?: (currentSceneId, oldIndex, newIndex) => void;
  onSceneOrderSubmit?: () => void;
  addSceneBtnPosition?: 'top' | 'bottom';
};

const ScenesList = ({
  scenes = [],
  displayIndex = true,
  showAddSceneBtn = true,
  onSceneIndexUpdate = (a, b, c) => {},
  onSceneOrderSubmit = () => {},
  addSceneBtnPosition = 'bottom',
}: propTypes) => {
  const sortableProject = useSelector(projectSortableSelector);

  return (
    <>
      {sortableProject && (
        <div className="save-sort-btn">
          <button onClick={onSceneOrderSubmit}>Save Ordering</button>
        </div>
      )}
      <div className="scene-list-container">
        <div className="scene-row header">
          <div className="scene-image"></div>
          <div className="scene-num">#</div>
          <div className="sort-arrows"></div>
          <div className="scene-name">Name</div>
          <div className="scene-date">Last Modified</div>
          <div className="scene-creator">Creator</div>
          <div className="scene-members">Collaborators</div>
          <div className="scene-options"></div>
        </div>

        {showAddSceneBtn && addSceneBtnPosition === 'top' && (
          <div className="scene-row">
            <CreateSceneButton className="scene-image" />
          </div>
        )}

        {scenes.map((scene, index) => {
          return (
            <SceneListItem
              key={scene.id}
              scene={scene}
              index={index}
              displayIndex={displayIndex}
              onIndexUpdate={onSceneIndexUpdate}
            />
          );
        })}

        {showAddSceneBtn && addSceneBtnPosition === 'bottom' && (
          <div className="scene-row">
            <CreateSceneButton className="scene-image" />
          </div>
        )}
      </div>
    </>
  );
};

export default ScenesList;
