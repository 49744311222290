export const addShotDisplay = () => (clips) => {
  let version = 1;
  let shotNum;
  for (const date in clips) {
    shotNum = 1
    for (const key in clips[date].videos) {
      clips[date].videos[key].shotDisplay = version + "." + shotNum
      shotNum++
    }
    version++
  }
  return clips
}

/**
 * Pushes keys inside objects as id
 *
 * ex: { key1 : {} }  =>  { key1 : {id : key1} }
 */
export const idPreparer = () => (clips) => {
  if (!clips) return clips;
  for (const key in clips) {
    clips[key].id = key;
  }
  return clips;
};

export const addTimeStamp = () => clips => {
  console.log(clips)
  let result = {}
  let i = 0
  for (const key in clips.videos) {
    console.log(key)
    if (i < 2) {
      result["2020-07-29T11:45:17+0000"] = {
        videos: {
          [key]: {
            ...clips.videos[key]
          }
        }
      }
    }
    else {
      result["2020-09-29T11:45:17+0000"] = {
        videos: {
          [key]: {
            ...clips.videos[key]
          }
        }
      }
    }
    i++
  }

  return result
}
