import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';

export const loginActionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
};

export const login = (email, password) => {
  return {
    type: loginActionTypes.LOGIN,
    payload: {
      email,
      password,
    },
  };
};

export const loginSuccess = (userID) => {
  return {
    type: loginActionTypes.LOGIN_SUCCESS,
    payload: userID,
    meta: {
      // ...toaster(toasterType.success, 'Logged in successfully!'),
    },
  };
};

export const loginFail = (errorMessage) => {
  return {
    type: loginActionTypes.LOGIN_FAIL,
    payload: errorMessage,
    meta: {
      ...toaster(toasterType.error, 'Failed to login', errorMessage),
    },
  };
};
