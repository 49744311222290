import { itemsAction, searchFilters } from './types';
import _ from 'lodash';
import produce from 'immer';
import { itemsActionTypes, searchItems } from './actions';

export const itemsReducer = produce((state = initState, action) => {
  const actionType = action.type;

  switch (actionType) {
    case itemsActionTypes.RESET:
      return {
        ...initState,
        libraryVersion: state.libraryVersion,
        downloadedItemsIds: state.downloadedItemsIds,
        downloadedItems: state.downloadedItems,
        favoriteItems: state.favoriteItems,
        subcategoriesProgress: state.subcategoriesProgress,
        itemsProgress: state.itemsProgress,
        instantiatedItems: state.instantiatedItems,
        pendingItems: state.pendingItems,
        purchasedItems: state.purchasedItems,
        filters: state.filters,
      };

    case itemsAction('SET', 'LIBRARY_VERSION'): {
      state.libraryVersion = action.payload;
      return state;
    }

    case itemsActionTypes.SET_ITEMS: {
      const { items = {} } = action.payload;
      state.items.data = items;
      break;
    }

    case itemsActionTypes.ADD_ITEMS: {
      const { items = {}, totalSize = 0 } = action.payload.data;
      const { subcategory = null } = action.payload;
      const selectedSubCategory = state.subcategories.selectedSubCategory;
      const oldItems = state.items.data;

      // if there are items to be added and they belong to the selected subcategory then add items
      if (subcategory === selectedSubCategory) {
        if (!_.isEmpty(items)) {
          state.items.data = { ...state.items.data, ...items };
        }
        state.items.totalSize = totalSize;
      }
      break;
    }

    case itemsActionTypes.FETCH_ITEMS_FAIL:
      state.items.totalSize = 0;
      break;

    case itemsActionTypes.CLEAR_SEARCH_ITEMS:
      state.searchItems.totalSize = 0;
      state.searchItems.data = {};
      break;

    case itemsActionTypes.SET_SEARCH_TERM: {
      if (action.payload === state.searchItems.searchTerm) break;
      state.searchItems.searchTerm = action.payload;
      state.searchItems.data = {};
      break;
    }

    case itemsActionTypes.SET_SEARCH_TAGS: {
      const tags = action.payload;
      if (tags === state.searchItems.tags) break;
      state.searchItems.tags = tags;
      state.searchItems.data = {};
      break;
    }

    case itemsAction('SET', 'SEARCH_FILTERS'): {
      const oldFilters = state.searchItems.filters;
      const newFilters = action.payload;
      if (_.isEqual(oldFilters, newFilters)) return state;
      state.searchItems.filters = newFilters;
      state.searchItems.data = {};
      return state;
    }

    case itemsActionTypes.ADD_SEARCH_ITEMS: {
      // const { items, totalHitsSize } = action.payload;
      // state.searchItems.totalSize = totalHitsSize;
      // state.searchItems.data = { ...state.searchItems.data, ...items };
      const {
        items,
        searchTerm,
        totalSize,
        tags = '',
        filters,
      } = action.payload;

      if (
        searchTerm !== state.searchItems.searchTerm ||
        tags !== state.searchItems.tags ||
        _.isEqual(filters, state.filters)
      )
        break;
      const itemsObj = {};
      items.forEach((item) => {
        itemsObj[item.itemID] = item;
      });
      state.searchItems.totalSize = totalSize;
      state.searchItems.data = { ...state.searchItems.data, ...itemsObj };
      break;
    }

    case itemsActionTypes.SEARCH_ITEMS_FAIL: {
      state.searchItems.totalSize = 0;
      return state;
    }

    case itemsActionTypes.SET_SEARCH_ITEMS:
      state.searchItems.data = action.payload;
      break;

    case itemsActionTypes.SET_SUB_CATEGORIES: {
      const { data, category } = action.payload;
      if (state.selectedCategory === category) {
        if (category === 'CharacterItems') {
          state.subcategories.types = {
            0: { subcategory: 'Character Templates' },
          };
        } else {
          state.subcategories.types = data;
        }
      }
      break;
    }

    case itemsActionTypes.SELECT_SUBCATEGORY:
      if (state.subcategories.selectedSubCategory !== action.payload) {
        state.items.data = {};
        state.subcategories.selectedSubCategory = action.payload;
      }
      break;

    case itemsActionTypes.SELECT_CATEGORY:
      state.selectedCategory = action.payload;
      break;

    // ------------------------------------------------------- Favorite Items

    case itemsActionTypes.LIKE_ITEM: {
      const itemId = action.payload;
      state.favoriteItems.favoriteItemsIds[itemId] = itemId;
      if (state.searchItems.data[itemId])
        state.searchItems.data[itemId].favorite = true;
      break;
    }

    case itemsActionTypes.LIKE_ITEM_FAIL: {
      const itemId = action.payload;
      delete state.favoriteItems.favoriteItemsIds[itemId];
      if (state.searchItems.data[itemId])
        delete state.searchItems.data[itemId].favorite;
      return state;
    }

    case itemsActionTypes.UNLIKE_ITEM: {
      const itemId = action.payload;
      delete state.favoriteItems.favoriteItemsIds[itemId];
      if (state.searchItems.data[itemId])
        delete state.searchItems.data[itemId].favorite;
      return state;
    }

    case itemsActionTypes.UNLIKE_ITEM_FAIL: {
      const itemId = action.payload;
      state.favoriteItems.favoriteItemsIds[itemId] = itemId;
      if (state.searchItems.data[itemId])
        state.searchItems.data[itemId].favorite = true;
      return state;
    }

    case itemsActionTypes.UNLIKE_ITEM_SUCCESS: {
      const itemId = action.payload;
      delete state.favoriteItems.data[itemId];
      break;
    }

    case itemsActionTypes.SET_LIKED_ITEMS_IDS: {
      state.favoriteItems.favoriteItemsIds = action.payload;
      break;
    }

    case itemsActionTypes.SET_LIKED_ITEMS: {
      const items = {};

      (action.payload.items ?? [])?.forEach((item) => {
        items[item?.itemID] = item;
      });
      state.favoriteItems.data = items;
      break;
    }

    case itemsActionTypes.SET_ITEM_PROGRESS: {
      let { itemId, progress } = action.payload;
      if (state.items.data[itemId])
        state.items.data[itemId].progress = progress;
      state.itemsProgress[itemId] = progress;
      break;
    }

    case itemsActionTypes.SET_ITEM_DOWNLOADED_LOCALLY: {
      const { items } = action.payload;
      state.downloadedItemsIds = { ...state.downloadedItemsIds, ...items };
      break;
    }

    case itemsAction('FETCH', 'DOWNLOADED_ITEMS'): {
      state.downloadedItemsPending = true;
      break;
    }

    case itemsAction('ADD', 'DOWNLOADED_ITEMS'): {
      let items = action.payload.items;
      const itemsObj = {};
      items.forEach((item) => {
        if (item?.itemID) itemsObj[item.itemID] = item;
      });
      state.downloadedItems = { ...state.downloadedItems, ...itemsObj };
      state.downloadedItemsPending = false;
      break;
    }

    case itemsAction('FETCH', 'DOWNLOADED_ITEMS', 'FAIL'): {
      state.downloadedItemsPending = false;
      break;
    }

    case itemsAction('ADD', 'INSTANTIATED_ITEMS'): {
      let items = action.payload.items;
      const itemsObj = {};

      items?.forEach((item) => {
        if (item?.itemID)
          itemsObj[item.itemID] = { ...item, isPurchased: true };
      });
      state.instantiatedItems = { ...state.instantiatedItems, ...itemsObj };
      break;
    }

    case itemsAction('ADD', 'PURCHASED_ITEMS'): {
      let items = action.payload.items;
      const itemsObj = {};

      items.forEach((item) => {
        if (item?.itemID)
          itemsObj[item.itemID] = { ...item, isPurchased: true };
      });
      state.purchasedItems = { ...state.purchasedItems, ...itemsObj };
      break;
    }

    case itemsAction('FETCH', 'PENDING_ITEMS'): {
      state.pendingItemsLoading = true;
      break;
    }

    case itemsAction('ADD', 'PENDING_ITEMS'): {
      let items = action.payload.items;
      const itemsObj = {};

      items?.forEach((item) => {
        if (item?.itemID) itemsObj[item.itemID] = item;
      });
      state.pendingItems = { ...state.pendingItems, ...itemsObj };
      state.pendingItemsLoading = false;
      break;
    }

    case itemsAction('ADD', 'PENDING_ITEMS', 'FAIL'): {
      state.pendingItemsLoading = false;
      break;
    }

    case itemsAction('SET', 'SUBCATEGORY_PROGRESS'): {
      const { subcategory, progress, progressText } = action.payload;
      state.subcategoriesProgress = {
        ...state.subcategoriesProgress,
        [subcategory]: { progress, progressText },
      };
      break;
    }

    case itemsAction('SET', 'SEARCH_OPTIONS'): {
      state.filters = action.payload;
      return state;
    }
    case itemsActionTypes.SET_PROP_ANIMATIONS: {
      state.propAnimations = action.payload;
      return state;
    }
    case itemsActionTypes.SET_PROP_SEARCH_ANIMATIONS: {
      state.propSearchAnimations = action.payload;
      return state;
    }
    default:
      break;
  }
  return state;
});

const initState = {
  libraryVersion: null,
  propAnimations: [],
  propSearchAnimations: [],
  items: { data: {}, totalSize: 0 },
  searchItems: {
    data: {},
    searchTerm: '',
    tags: '',
    filters: {},
    totalSize: 0,
  },
  favoriteItems: { data: {}, favoriteItemsIds: {} },
  downloadedItemsIds: {},
  downloadedItems: {},

  instantiatedItems: {},

  purchasedItems: {},

  itemsProgress: {},

  pendingItems: {},

  downloadedItemsPending: false,
  pendingItemsLoading: false,

  selectedCategory: null,
  subcategories: { types: {}, selectedSubCategory: null },
  subcategoriesProgress: {},
  filters: { colors: [], materialTypes: [], eras: [] },
};
