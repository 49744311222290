import { userIdSelector } from "redux/slices/auth/authSelectors";
import { createSelector } from "reselect";
import { SceneUser } from "./types";

interface ScenesSlice {
  scenes: {
    users: { [key: string]: SceneUser };
  };
}

const roleNumToStr = ["Admin", "Editor", "Reviewer", "Guest"];

export const sceneUsersSelector = (store: ScenesSlice) => store.scenes.users;

export const sceneMembersSelector = createSelector(
  sceneUsersSelector,
  (users) => {
    return Object.values(users)
      .filter((user) => user.role !== 3)
      .map(
        (user): SceneUser => {
          return { ...user, roleName: roleNumToStr[user.role] };
        }
      );
  }
);

export const sceneGuestsSelector = createSelector(
  sceneUsersSelector,
  (users) => {
    return Object.values(users)
      .filter((user) => user.role === 3)
      .map(
        (user): SceneUser => {
          return { ...user, roleName: roleNumToStr[user.role] };
        }
      );
  }
);
export const userAccessSceneSelector = createSelector(
  sceneUsersSelector,
  userIdSelector,
  (users, userId) => {
    return Object.keys(users).includes(userId) && users[userId].role !== 3;
  }
);
export const isGuestSelector = createSelector(
  userIdSelector,
  sceneGuestsSelector,
  (myId, guests) => {
    const isGuest = guests.some(({ userId }) => userId === myId);
    console.log(isGuest);

    return isGuest;
  }
);
