import { Action } from 'redux/common/types';
import { StripeSlice, stripeAction } from './types';
import { produce } from 'immer';
import { appAction } from '../app/types';

export const stripeReducer = produce(
  (state: StripeSlice = initialState, action: Action) => {
    switch (action.type) {
      case stripeAction('SET', 'PAYMENT_INFO'):
      case appAction('SET', 'PAYMENT_INFO'): {
        const { teamId = '', data } = action.payload;
        state.paymentInfo[teamId] = data;
        return state;
      }

      case stripeAction('CLEAR', 'PAYMENT_ACTION'): {
        state.paymentAction = null;
        return state;
      }

      case stripeAction('SET', 'PAYMENT_ACTION'): {
        state.paymentAction = action.payload;
        return state;
      }

      case stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE'): {
        state.loaders.purchaseTeamEditorLicensePending = true;
        return state;
      }

      case stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE', 'SUCCESS'):
      case stripeAction('PURCHASE', 'TEAM_EDITOR_LICENSE', 'FAIL'): {
        state.loaders.purchaseTeamEditorLicensePending = false;
        return state;
      }

      case stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE'): {
        state.loaders.purchaseTeamReviewerLicensePending = true;
        return state;
      }

      case stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE', 'SUCCESS'):
      case stripeAction('PURCHASE', 'TEAM_REVIEWER_LICENSE', 'FAIL'): {
        state.loaders.purchaseTeamReviewerLicensePending = false;
        return state;
      }

      case stripeAction('ADD', 'TEAM_MEMBER'): {
        state.loaders.addTeamMemberPending = true;
        return state;
      }

      case stripeAction('ADD', 'TEAM_MEMBER', 'SUCCESS'):
      case stripeAction('ADD', 'TEAM_MEMBER', 'FAIL'): {
        state.loaders.addTeamMemberPending = false;
        return state;
      }

      case stripeAction('SET', 'DRIVES_PRICES'): {
        const { personalDrivePrice, teamDrivePrice } = action.payload;
        state.personalDrivePrice = personalDrivePrice;
        state.teamDrivePrice = teamDrivePrice;
        return state;
      }

      case stripeAction('FETCH', 'LICENSE_PRICES'): {
        state.licensePrices = { ...state.licensePrices, ...action.payload };
        state.loaders.licensePricePending = true;
        return state;
      }

      case stripeAction('FETCH', 'LICENSE_PRICES', 'FAIL'): {
        const { teamId, licenses, type } = action.payload;
        if (
          teamId === state.licensePrices.teamId &&
          licenses === state.licensePrices.licenses &&
          type === state.licensePrices.type
        ) {
          state.loaders.licensePricePending = false;
          state.licensePrices.cost = NaN;
        }
        return state;
      }

      case stripeAction('SET', 'LICENSE_PRICES'): {
        const { teamId, licenses, type } = action.payload;
        if (
          teamId === state.licensePrices.teamId &&
          licenses === state.licensePrices.licenses &&
          type === state.licensePrices.type
        ) {
          state.licensePrices = action.payload;
        }
        state.loaders.licensePricePending = false;
        return state;
      }

      default:
        return state;
    }
  }
);

const initialState: StripeSlice = {
  paymentAction: null,
  paymentInfo: {},
  teamDrivePrice: 12,
  personalDrivePrice: 14,
  licensePrices: { teamId: '', cost: NaN, licenses: NaN, type: 'Editor' },
  loaders: {
    purchaseTeamEditorLicensePending: false,
    purchaseTeamReviewerLicensePending: false,
    addTeamMemberPending: false,
    licensePricePending: false,
  },
};
