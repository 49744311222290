import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';

export const SaveCharacterModal = ({ close }) => {
  const [name, setName] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (name) {
      (window as any).onSaveCharacter(name);
      close();
    }
  };
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      visible
      footer={null}
      title="Save Character"
      onCancel={close}
    >
      <form className="rename-proj-form" onSubmit={onSubmit}>
        <div>
          <label>Character Name</label>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="button-container">
          <button className="btn btn-primary">Create</button>
        </div>
      </form>
    </Modal>
  );
};
