import queryString from 'query-string';
import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { DeleteUserModal } from './delete-user-modal/DeleteUserModal';

export const AccountSettings = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  return (
    <Tab.Pane active = {params?.tab === "account"} id="account">
      {showDeleteModal && (
        <DeleteUserModal
          onCancel={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      <h6>ACCOUNT SETTINGS</h6>
      <hr />
      <form>
        <div className="form-group">
          <label className="d-block text-danger">Delete Account</label>
          <p className="text-muted font-size-sm">
            Once you delete your account, there is no going back. Please be
            certain.
          </p>
        </div>
        <button
          className="btn btn-danger"
          type="button"
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          Delete Account
        </button>
      </form>
    </Tab.Pane>
  );
};
