import './BacklotSettings.scss';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Nav, Row } from 'react-bootstrap';
import { AccountSettings } from './account-settings/AccountSettings';
import { Dropdown, Menu } from 'antd';
import {
  inPersonalDriveSelector,
  isAdminSelector,
  isBillingAdminSelector,
  personalDriveIdSelector,
  personalDriveNameSelector,
  selectedTeamNameSelector,
  teamMembersSelector3,
  teamsSelector,
} from 'redux/slices/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { IoMdArrowDropdown, IoMdLogOut } from 'react-icons/io';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { setSelectedTeamId } from 'redux/slices/app/actions';
import { FaDownload, FaList } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { logout } from 'redux/slices/auth/actions/logout';
import {
  fetchDrivesPrices,
  fetchPaymentInfo,
} from 'redux/slices/stripe/actions';
import { useFetchUserData } from 'common/hooks/useFetchUserData2';
import { useFetchTeamInfo } from 'common/hooks/useFetchTeamInfo';
import ProfileInfo from './profile-info/ProfileInfo';
import { Support } from './support/Support';
import { NotificationsSettings } from './notifications-settings/NotificationsSettings';
import { Billing } from './billing/Billing';
import { DriveSettings } from './drive-settings/DriveSettings';
import TeamMembers from './team-members/TeamMembers';
import { fetchInvoices } from 'redux/slices/team/actions';

export const BacklotSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch() as any;
  const userId = useSelector(userIdSelector);
  const personalDriveId = useSelector(personalDriveIdSelector);
  const personalDriveName = useSelector(personalDriveNameSelector);
  const teams = useSelector(teamsSelector);
  const teamName = useSelector(selectedTeamNameSelector);
  const teamMembers = useSelector(teamMembersSelector3);
  const licenses = [...(teams ?? [])];
  if (personalDriveId)
    licenses.push({ id: personalDriveId, name: personalDriveName });
  const roleInTeam = (teamMembers ?? {})[userId]?.role;
  const teamId = match.params.teamId;
  const params = queryString.parse(history.location.search);

  useFetchUserData();
  useFetchTeamInfo();

  useEffect(() => {
    dispatch(setSelectedTeamId(teamId));
    dispatch(fetchInvoices(teamId));
    dispatch(fetchPaymentInfo(teamId));
  }, [teamId]);

  useEffect(() => {
    dispatch(fetchDrivesPrices());
  }, [dispatch, userId]);

  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const isAdmin = useSelector(isAdminSelector);
  const isBillingAdmin = useSelector(isBillingAdminSelector);

  const teamsMenu = (
    <Menu className="teams-drop-menu">
      {licenses.map(({ id, name }) => (
        <Menu.Item
          key={id}
          onClick={() => {
            history.push(`/settings/${id}`);
            dispatch(setSelectedTeamId(teamId));
          }}
        >
          <div>{name}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="settings-menu">
      <Row className="gutters-sm">
        <div className="sidebar">
          <Nav
            defaultActiveKey={params?.tab ? `#${params.tab}` : '#profile'}
            className="flex-column nav-pills "
          >
            <div className="selected-license">
              <Dropdown
                transitionName=""
                overlay={teamsMenu}
                trigger={['click']}
              >
                <div>
                  <div className="title">
                    <p
                      className={`license-name ${
                        !teamName ? 'no-selection' : ''
                      }`}
                    >
                      {teamName ?? 'Select a team'}
                    </p>
                    <p className="role">
                      {!inPersonalDrive ? (
                        <>
                          {roleInTeam <= 1 ? 'Editor' : 'Reviewer'}
                          {' License'}
                        </>
                      ) : (
                        'Personal Drive'
                      )}

                      {(isAdmin || inPersonalDrive) && (
                        <span className="admin-tag">ADMIN</span>
                      )}
                      {isBillingAdmin && (
                        <span className="admin-tag">BILLING ADMIN</span>
                      )}
                    </p>
                  </div>
                  <div className="arrow">
                    <IoMdArrowDropdown size={22} />
                  </div>
                </div>
              </Dropdown>
            </div>

            <Nav.Link
              href="#profile"
              data-toggle="tab"
              className="nav-item nav-link has-icon nav-link-faded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-user mr-2"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              Profile Information
            </Nav.Link>
            <Nav.Link
              href="#account"
              data-toggle="tab"
              className="nav-item nav-link has-icon nav-link-faded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-settings mr-2"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
              </svg>
              Account Settings
            </Nav.Link>
            {/* <a
                  href="#security"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-shield mr-2"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                  Security
                </a> */}
            {/* <a
                  href="#notification"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-bell mr-2"
                  >
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                  </svg>
                  Notification
                </a> */}
            <Nav.Link
              href="#billing"
              data-toggle="tab"
              className="nav-item nav-link has-icon nav-link-faded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-credit-card mr-2"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                <line x1="1" y1="10" x2="23" y2="10"></line>
              </svg>
              Billing
            </Nav.Link>

            {!inPersonalDrive && (
              <a
                href="#driveSettings"
                data-toggle="tab"
                className={`nav-item nav-link has-icon nav-link-faded${
                  !isAdmin && !inPersonalDrive ? ' disabled' : ''
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-settings mr-2"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                Drive Settings
              </a>
            )}

            {!inPersonalDrive && (
              <a
                href="#team"
                data-toggle="tab"
                className={`nav-item nav-link has-icon nav-link-faded`}
              >
                <img
                  alt="team"
                  src="/menu/team.svg"
                  style={{
                    width: '2.8vw',
                    marginLeft: '-0.5vw',
                    opacity: '0.7',
                  }}
                />
                Team Members
              </a>
            )}
          </Nav>

          <hr />
          <Link
            to={`/menu/project`}
            className="link nav-item nav-link has-icon nav-link-faded "
          >
            <FaList />
            Menu
          </Link>

          <a
            href="#"
            className="link nav-item nav-link has-icon nav-link-faded"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <IoMdLogOut />
            Logout
          </a>
        </div>

        <div className="main-content tab-content">
          <ProfileInfo />

          <AccountSettings />

          <Support />

          <NotificationsSettings />

          <Billing />

          <DriveSettings />

          <TeamMembers />
        </div>
      </Row>
    </div>
  );
};
