import './InvoiceHistory.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import { fetchInvoices } from 'redux/slices/team/actions';
import {
  invoicePendingSelector,
  invoicesSelector,
} from 'redux/slices/team/selectors';
import { Table, Tag, Space } from 'antd';
import moment from 'moment';

const statusToColor = {
  paid: '#d6ecff',
  uncollectible: '#fee3c0',
  void: '#e6e6fc',
  open: '#cbf4c9',
  draft: '#e3e8ee',
  deleted: '#fbb5b2',
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (timestamp) => (
      <a>{moment.unix(+timestamp).format('MM/DD/YYYY hh:mm a')}</a>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <div>{`$ ${+amount.toFixed(2)}`}</div>,
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    // render: (amount: number) => <div>{`$ ${+amount.toFixed(2)}`}</div>,
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) => (
      <div
        className="status"
        style={{ backgroundColor: statusToColor[status] }}
      >
        {status}
      </div>
    ),
  },
];

const InvoiceHistory = ({ teamId }) => {
  const dispatch = useDispatch();

  const invoices = useSelector(invoicesSelector);
  const loading = useSelector(invoicePendingSelector);

  const dataSource = (invoices ?? []).map(
    ({ id, amount, date, status, reason }) => {
      return { key: date, amount, date, status, reason };
    }
  );

  return (
    <div className="invoices-table">
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default InvoiceHistory;
