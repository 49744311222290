import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button, Form, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  userIdSelector,
  userImageSelector,
  userLastNameSelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import firebase from 'firebase-main';
import { toasterAction } from 'redux/common/actions';
import Loader from 'common/components/loader/Loader';
import { ImageCrop } from 'common/components/ImageCrop/ImageCrop';
import { jobTitles } from 'common/config/jobTitles';
import { useHistory } from 'react-router-dom';

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const userLastName = useSelector(userLastNameSelector);
  const userRole = useSelector(userRoleSelector);
  const userImage = useSelector(userImageSelector);
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [pending, setPending] = useState(false);

  const [name, setName] = useState(userName ?? '');
  const [lastName, setLastName] = useState(userLastName ?? '');
  const [role, setRole] = useState(userRole ?? '');
  const [imgBlob, setImgBlob] = useState<null | Blob>();
  const [imgUrl, setImgUrl] = useState<string>('');

  useEffect(() => {
    setName(userName ?? '');
  }, [userName]);

  useEffect(() => {
    setLastName(userLastName ?? '');
  }, [userLastName]);

  useEffect(() => {
    setRole(userRole ?? '');
  }, [userRole]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    try {
      const userInfoUpdatePromise = firebase
        .database()
        .ref(`users/${userId}/public`)
        .update({ name, last_name: lastName, role });

      let updateImgPromise: any = null;

      if (imgBlob) {
        updateImgPromise = firebase
          .storage()
          .ref(`users/${userId}/${userId}.jpg`)
          .put(imgBlob);

        // updateImgPromise = firebase
        //   .database()
        //   .ref(`users/${userId}/public`)
        //   .update({ thumbnail: url });
      }

      const promises = [userInfoUpdatePromise];
      if (updateImgPromise) promises.push(updateImgPromise);

      await Promise.all(promises);

      dispatch(toasterAction('success', 'Info Updated Successfully!'));
      setPending(false);
    } catch (err: any) {
      dispatch(toasterAction('error', 'Failed to update info', err));
      console.error(err);
      setPending(false);
    }
  };

  const onReset = (e) => {
    e.preventDefault();
    setName(userName ?? '');
    setRole(userRole ?? '');
    setImgUrl(userImage ?? '');
    setImgBlob(null);
  };

  return (
    <Tab.Pane active = {params?.tab === "profile" || !params?.tab} id="profile">
      <h6>YOUR PROFILE INFORMATION</h6>
      <hr />
      <Form onSubmit={onSubmit} onReset={onReset}>
        <Form.Group>
          <Form.Label>Profile Picture</Form.Label>
          <div>
            <div className="uploader">
              <ImageCrop
                onImageLoad={(url, blob) => {
                  setImgBlob(blob);
                  setImgUrl(url);
                }}
                backgroundImgSrc={imgUrl.length > 0 ? imgUrl : userImage}
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            id="firstName"
            placeholder="Enter your first name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Text id="fullNameHelp" className="form-text text-muted">
            Your name may appear around here where you are mentioned. You can
            change or remove it at any time.
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            id="lastName"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            required
            as="select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {/* {Object.keys(positionToColor).map((key) => ( */}
            {jobTitles.map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button type="submit" disabled={pending}>
          <Loader
            show={pending}
            spinnerSize={16}
            spinnerColor="white"
            backgroundStyle={{ display: 'inline', marginRight: '1rem' }}
          ></Loader>
          Update Profile
        </Button>
        <Button type="reset" className="btn btn-light">
          Reset Changes
        </Button>
      </Form>
    </Tab.Pane>
  );
};

export default ProfileInfo;
