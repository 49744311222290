import './Time.scss';
import React, { useEffect, useState } from 'react';
import { Slider } from 'antd';
import SearchAddress from '../common/search-address/SearchAddress';
import firebase from 'firebase-main';
import moment from 'moment';
import { TimePicker } from 'antd';
import useDebounce from 'common/hooks/useDebounce';

// const apiLimiter = JSON.parse(localStorage.getItem('apiLimiter') ?? '{}');

const Time = () => {
  const [time, setTime] = useState<any>('06:00 AM');
  // const [hr, setHr] = useState('06');
  // const [minute, setMinute] = useState('00');
  // const [day, setDay] = useState('AM');

  const [timeSlider, setTimeSlider] = useState(360);
  const [timeSliderDebounced] = useDebounce(timeSlider, 1500);
  const [timeSliderUpdated, setTimeSliderUpdated] = useState(false);
  const [date, setDate] = useState('2021-06-01');
  const [address, setAddress] = useState('Los Angeles, CA');
  const [savedAddress, setSavedAddress] = useState('Los Angeles, CA');

  const [editingTime, setEditingTime] = useState(false);
  const [editingDate, setEditingDate] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);

  // const callGoogleMap = useLimiter('google');

  // const googleKey = envMode === 'dev' ? googleDevApiKey : googleProdApiKey;

  /** Loads the google places api */
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [googleKey]);

  // Time slider debounced
  useEffect(() => {
    if (timeSliderDebounced && timeSliderUpdated) {
      (window as any).onTimeChangeDebounced(timeSliderDebounced);
    }
  }, [timeSliderDebounced]);

  useEffect(() => {
    const onClick = () => {
      setAddress(savedAddress);
      if (editingTime) toggleEditingTime();
      if (editingDate) toggleEditingDate();
      if (editingAddress) toggleEditingAddress(savedAddress);
    };
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [savedAddress, editingAddress, editingTime, editingDate]);

  const windowSetTime = ({ params }) => {
    const { minutes } = params;
    setTimeSlider(minutes);
    setTime(timeFormatter(minutes));
  };

  const windowSetDate = ({ params }) => {
    setDate(params.date);
  };

  // const windowSetLocation = ({ params }) => {
  //   setLongitude(params.lng);
  //   setLatitude(params.lat);
  // };

  const windowSetAddress = ({ params }) => {
    setAddress(params.address);
    setSavedAddress(params.address);
  };

  useEffect(() => {
    window.addEventListener('setTime', windowSetTime as any);
    window.addEventListener('setDate', windowSetDate as any);
    window.addEventListener('setAddress', windowSetAddress as any);
    // window.addEventListener('setLocation', windowSetLocation as any);

    return () => {
      window.removeEventListener('setTime', windowSetTime as any);
      window.removeEventListener('setDate', windowSetDate as any);
      window.removeEventListener('setAddress', windowSetAddress as any);
      // window.removeEventListener('setLocation', windowSetLocation as any);
    };
  }, []);

  const toggleEditingTime = () => {
    setEditingTime(!editingTime);
    if (editingTime) {
      (window as any).onTimeSet();
      firebase.analytics().logEvent('scene_settings', { time });
    }
  };

  const toggleEditingDate = () => {
    setEditingDate(!editingDate);
    if (editingDate) {
      // calculateLocation(address).then(async (lngLat) => {
      //   const utc = await calculateUtc(date, lngLat.lng, lngLat.lat, googleKey);
      //   (window as any).onDateChange(date, utc);
      // });

      // (window as any).onDateChange(date, {
      //   dstOffset: 0,
      //   rawOffset: 7200,
      //   status: 'OK',
      //   timeZoneId: 'Africa/Cairo',
      //   timeZoneName: 'Eastern European Standard Time',
      // });

      // (window as any).onDateChange(date, '0', '0');

      calculateLocation(address).then(async (lngLat) => {
        const utc = await calculateUtc(date, lngLat.lng, lngLat.lat);
        (window as any).onDateChange(date, utc.gmtOffset, utc.dst);
        firebase.analytics().logEvent('scene_settings', {
          date,
          gmtOffset: utc.gmtOffset,
          dst: utc.dst,
        });
      });
    }
  };

  const toggleEditingAddress = (address) => {
    setEditingAddress(!editingAddress);

    if (editingAddress) {
      setSavedAddress(address);
      // (window as any).onAddressChange(address, 0, 0, {
      //   status: 'OK',
      //   message: '',
      //   countryCode: 'EG',
      //   countryName: 'Egypt',
      //   zoneName: 'Africa/Cairo',
      //   abbreviation: 'EET',
      //   gmtOffset: 7200,
      //   dst: '0',
      //   zoneStart: 1411678800,
      //   zoneEnd: null,
      //   nextAbbreviation: null,
      //   timestamp: 1605146400,
      //   formatted: '2020-11-12 02:00:00',
      // });
      // (window as any).onAddressChange(address, '0', '0', '0', '0');

      calculateLocation(address).then(async (lngLat) => {
        const utc = await calculateUtc(date, lngLat.lng, lngLat.lat);
        (window as any).onAddressChange(
          address,
          lngLat.lng,
          lngLat.lat,
          utc.gmtOffset,
          utc.dst
        );

        firebase.analytics().logEvent('scene_settings', {
          address,
          lngLat,
          date,
          gmtOffset: utc.gmtOffset,
          dst: utc.dst,
        });
      });

      // (window as any).onAddressChange(address, 29.9772962, 31.1324955, {
      //   dstOffset: 0,
      //   rawOffset: 7200,
      //   status: 'OK',
      //   timeZoneId: 'Africa/Cairo',
      //   timeZoneName: 'Eastern European Standard Time',
      // });
    }
  };

  const timeFormatter = (minutes = 0) => {
    let hr = Math.floor(minutes / 60);
    const mn = minutes - hr * 60;
    const dayTime = hr < 12 ? 'AM' : 'PM';

    if (hr >= 12) hr -= 12;
    if (hr === 0) hr = 12;

    const hrPadded = (hr + '').length === 1 ? `0${hr}` : hr + '';
    const mnPadded = (mn + '').length === 1 ? `0${mn}` : mn + '';

    const timeFormatted = `${hrPadded}:${mnPadded} ${dayTime}`;
    return timeFormatted;
  };

  const renderTime = (
    <div
      className="clickable"
      onClick={(e) => {
        e.stopPropagation();
        toggleEditingTime();
      }}
    >
      {time}
    </div>
  );

  let [renderTimeInputText, dayTime] = time.split(' ');
  if (dayTime === 'PM') {
    let [hr, min] = renderTimeInputText.split(':');
    hr = +hr + 12 + '';
    if (+hr === 24) hr = '12';
    renderTimeInputText = `${hr}:${min}`;
  } else {
    let [hr, min] = renderTimeInputText.split(':');
    if (hr === '12') hr = '00';
    renderTimeInputText = `${hr}:${min}`;
  }

  const renderTimeInput = (
    <div
      className="time-input"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="time-input-text">
        <TimePicker
          className="time-picker"
          showSecond={false}
          value={moment(time, 'HH:mm a')}
          onChange={(time, timeString) => {
            console.log(time?.hours(), time?.minutes());
            const hr = time?.hours() ?? 0;
            const min = time?.minutes() ?? 0;

            const totalMinutes = +hr * 60 + +min;

            setTime(timeString);

            setTimeSlider(totalMinutes);
            setTimeSliderUpdated(true);
            (window as any).onTimeChange(totalMinutes);
          }}
          format="h:mm a"
          use12Hours
          allowClear={false}
          showNow={false}
        />
        {/* <input
          type="time"
          value={renderTimeInputText}
          onChange={(e) => {
            const value = e.target.value;
            console.log(value);

            let [hr, min] = value.split(':');
            const totalMinutes = +hr * 60 + +min;
            const day = +hr >= 12 ? 'PM' : 'AM';
            hr = +hr > 12 ? +hr - 12 + '' : hr;
            hr = +hr === 0 ? '12' : hr;
            if (hr.length === 1) hr = '0' + hr;
            const newTime = `${hr}:${min} ${day}`;

            setTime(newTime);
            setTimeSlider(totalMinutes);
            (window as any).onTimeChange(totalMinutes);
          }}
        /> */}
      </div>
      <div className="slider">
        <span className="slider-left">12:00 AM</span>
        <Slider
          min={0}
          max={1439}
          step={1}
          value={timeSlider}
          tipFormatter={timeFormatter}
          onChange={(newValue: number) => {
            setTimeSlider(newValue);
            setTime(timeFormatter(newValue));
            setTimeSliderUpdated(true);
            (window as any).onTimeChange(newValue);
          }}
          defaultValue={1}
        />
        <span className="slider-right">11:59 PM</span>
      </div>

      <button onClick={toggleEditingTime}>Ok</button>
    </div>
  );

  const renderDate = (
    <div
      className="clickable"
      onClick={(e) => {
        e.stopPropagation();
        toggleEditingDate();
      }}
    >
      {date}
    </div>
  );

  const renderDateInput = (
    <>
      <input
        type="date"
        value={date}
        onChange={(e) => {
          setDate(e.target.value);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <button
        onClick={(e) => {
          e.stopPropagation();
          toggleEditingDate();
        }}
      >
        Ok
      </button>
    </>
  );

  const renderAddress = (
    <div
      className="clickable"
      onClick={(e) => {
        e.stopPropagation();
        setEditingAddress(true);
      }}
    >
      {savedAddress}
    </div>
  );

  const renderAddressInput = (
    <div className="address-input" onClick={(e) => e.stopPropagation()}>
      {/* <SearchLocationInput
        address={address}
        onChange={setAddress}
        onSelectAddress={setAddress}
        // onSelectLatLng={(lat, lng) => {}}
      /> */}
      <SearchAddress
        searchText={address}
        onChange={setAddress}
        onSelect={(v) => {
          setAddress(v);
          toggleEditingAddress(v);
        }}
        debounce={500}
      />
      {/* <button onClick={toggleEditingAddress}>Ok</button> */}
    </div>
  );

  return (
    <div className="time">
      <div className="title">
        <b>TIME OF DAY</b>
      </div>

      <div id="body">
        {/* <div className="card"></div> */}

        <div className="info">
          <div className="day-time">
            <div className="time-text">
              <img
                className="clickable"
                src={'/backlot-library/scene-settings/time/time.svg'}
                alt="clock"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingTime();
                }}
              />
              {renderTime}
            </div>
            {editingTime && renderTimeInput}
          </div>

          <div className="recalculate-lights">
            <button
              onClick={(e) => {
                e.preventDefault();
                (window as any).onRecalculateLights();
                firebase
                  .analytics()
                  .logEvent('scene_settings', { recalculate_lights: true });
              }}
            >
              Recalculate Lights
            </button>
          </div>

          <div className="date">
            <img
              className="clickable"
              src={'/backlot-library/scene-settings/time/calendar.svg'}
              alt="calendar"
              onClick={(e) => {
                e.stopPropagation();
                toggleEditingDate();
              }}
            />
            {editingDate ? renderDateInput : renderDate}
          </div>

          <div className="location">
            <img
              className="clickable"
              src={'/backlot-library/scene-settings/time/pin.svg'}
              alt="pin"
              onClick={(e) => {
                e.stopPropagation();
                setEditingAddress(true);
              }}
            />
            {editingAddress ? renderAddressInput : renderAddress}
          </div>
        </div>

        {/* <div className="map"> */}
        {/* <img src={mapSrc} alt="map" /> */}
        {/* <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAQnKHO-w6kWct7hWhzMOvJL4cNjTdnWMk',
            }}
            // defaultCenter={{ lat: 34.052235, lng: -118.243683 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            center={{ lat: latitude, lng: longitude }}
            onChange={(value) => {
              setLatitude(value.center.lat);
              setLongitude(value.center.lng);
            }}
          ></GoogleMapReact> */}
        {/* </div> */}
      </div>
    </div>
  );
};

const calculateLocation = async (address) => {
  // const location = await getLatLng((await geocodeByAddress(address))[0]);
  const geocode = await (
    await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
    )
  ).json();

  const lngLat = geocode?.features?.length > 0 && geocode?.features[0]?.center;

  // console.log(lngLat, location);
  const location = { lng: lngLat[0], lat: lngLat[1] };

  return location;
};

const calculateUtc = async (date, lng, lat) => {
  const timestamp = new Date(date).getTime() / 1000;
  // const newUtc = await (
  //   await fetch(
  //     `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${apiKey}`
  //   )
  // ).json();

  const utcTimeDb = await (
    await fetch(
      `https://api.timezonedb.com/v2.1/get-time-zone?key=${process.env.REACT_APP_TIMEZONE_DB_API_KEY}&format=json&by=position&lat=${lat}&lng=${lng}&time=${timestamp}`
    )
  ).json();

  // console.log(newUtc, utcTimeDb);

  return utcTimeDb;
};

export default Time;
