import './Gallery.scss';
import React, { useEffect } from 'react';
import GalleryHeader from './header/GalleryHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeImages,
  unSubscribeImages,
} from 'redux/slices/itemGallery/actions';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import GalleryBody from './body/GalleryBody';

const Gallery = () => {
  const dispatch = useDispatch();

  const teamId = useSelector(selectedTeamIdSelector);

  useEffect(() => {
    dispatch(subscribeImages(teamId));
    return () => {
      dispatch(unSubscribeImages());
    };
  }, []);

  return (
    <div id="item-gallery">
      <GalleryHeader />
      <GalleryBody />
    </div>
  );
};

export default Gallery;
