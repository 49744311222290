export const svgTypes = {
  CAMERA: 'CAMERA',
  LIGHT: 'LIGHTS',
  CHARACTER: 'CHARACTER',
  PROP: 'PROP',
};

export const BLUEPRINT_FPS = 24;
export const BLUEPRINT_CONTAINER_WIDTH = 800;
export const BLUEPRINT_CONTAINER_HEIGHT = 500;
