export const firebaseRealtimeRequest = (
  req = '',
  url = '',
  onSuccessDispatches = [],
  onEmptyResponseDispatches = [],
  onFailDispatches = [],
  dataPrepareFunctions = [],
  id = ''
) => {
  return {
    firebaseRealtimeApi: {
      req,
      url,
      onSuccessDispatches,
      onEmptyResponseDispatches,
      onFailDispatches,
      dataPrepareFunctions,
      id,
    },
  };
};
