import './BacklotComments.css';
import React from 'react';
import Main from './components/main/Main';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'common/components/route-types/PrivateRoute';

export function BacklotComments(props) {
  const { match = {} } = props;

  return (
    <Switch>
      <PrivateRoute path={`${match.url}`} component={Main} exact />
      <PrivateRoute path={`*`}>
        <Redirect to={`not-found`} />
      </PrivateRoute>
    </Switch>
  );
}
