import './PersonalSharePanel.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { PersonalProjectTab } from './personal-project-tab/PersonalProjectTab';
import { useSelector } from 'react-redux';
import { selectedProjectNameSelector } from 'redux/slices/app/selectors';

export const SharePersonalProjectModal = ({ onClose = () => {} }) => {
  const projectName = useSelector(selectedProjectNameSelector);
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="personal-share-modal"
      title={`Share ${projectName}`}
      visible
      onCancel={onClose}
      footer={null}
    >
      <PersonalProjectTab />
    </Modal>
  );
};
