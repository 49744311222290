import Loader from 'common/components/loader/Loader';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  availableScenesSelector,
  scenesOrderingSelector,
} from 'redux/slices/app/selectors';
import _ from 'lodash';
import SceneGridCard from './SceneGridCard';
import { CreateSceneButton } from '../create-scene-btn/CreateSceneButton';

type propTypes = {
  scenes?: any[];
  sortScenes?: boolean;
  displayIndex?: boolean;
  showAddSceneBtn?: boolean;
  addSceneBtnPosition?: 'top' | 'bottom';
};

const ScenesGrid = ({
  scenes = [],
  sortScenes = false,
  displayIndex = true,
  showAddSceneBtn = true,
  addSceneBtnPosition = 'bottom',
}: propTypes) => {
  // const availableScenes = useSelector(availableScenesSelector) ?? [];

  // const scenesAccessPending = useSelector(scenesAccessPendingSelector);
  const scenesOrdering = useSelector(scenesOrderingSelector) ?? {};

  let sortedScenes = [...scenes].sort(
    ({ id: id1, name: name1 }, { id: id2, name: name2 }) => {
      if (!scenesOrdering[id1] && !scenesOrdering[id2])
        return name1.localeCompare(name2);
      if (scenesOrdering[id1] === undefined) return 1;
      if (scenesOrdering[id2] === undefined) return -1;
      return scenesOrdering[id1] - scenesOrdering[id2];
    }
  );

  return (
    // <Loader
    //   show={scenesAccessPending}
    //   backgroundStyle={{ height: '100%' }}
    //   centerSpinner
    //   hideChildren
    // >
    <div className="grid-scene-container">
      {showAddSceneBtn && addSceneBtnPosition === 'top' && (
        <CreateSceneButton />
      )}

      {(sortScenes ? sortedScenes : scenes).map((scene, index) => {
        return (
          <SceneGridCard
            key={scene.id}
            scene={scene}
            index={index}
            displayIndex={displayIndex}
          />
        );
      })}

      {showAddSceneBtn && addSceneBtnPosition === 'bottom' && (
        <CreateSceneButton />
      )}
    </div>
    // </Loader>
  );
};

export default ScenesGrid;
