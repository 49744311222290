import { svgTypes } from 'common/config/blueprints';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { BlueprintSlice } from './types';

// ---------------------------------------- Normal Selectors ----------------------------------------
export const svgDimensionsSelector = (store) => store.blueprint.svgDimensions;

export const blueprintDimensionsSelector = (store) =>
  store.blueprint.dimensions;

export const blueprintEnvironmentSettingsSelector = (store) =>
  store.blueprint.environmentSettings;

export const timeFrameSelector = (store) => store.blueprint.timeFrame;

export const sceneObjectsSelector = (store: BlueprintSlice) =>
  store.blueprint.sceneData.objects;

export const originalSceneObjects = (store) =>
  store.blueprint?.originalData?.objects;

export const centerPointSelector = (store: BlueprintSlice) =>
  store.blueprint.centerPosition;

export const durationSelector = (store) => store.blueprint.duration;

export const isPlayingSelector = (store) => store.blueprint.isPlaying;

export const scaleAndxPositionsSelector = (store) => {
  return {
    scale: store.blueprint.scale,
    position: store.blueprint.position.x,
  };
};

export const scaleAndzPositionsSelector = (store) => {
  return {
    scale: store.blueprint.scale,
    position: store.blueprint.position.z,
  };
};

export const selectedObjectIdSelector = (store) =>
  store.blueprint.selectedObjectId;

export const hoveredObjectIdSelector = (store) =>
  store.blueprint.hoveredObjectId;

export const minAndmaxSelector = (store) => {
  return {
    x: {
      min: store.blueprint.dimensions.min.x,
      max: store.blueprint.dimensions.max.x,
    },
    y: {
      min: store.blueprint.dimensions.min.z,
      max: store.blueprint.dimensions.max.z,
    },
  };
};

export const rangesSelector = (store) => {
  return {
    rangeX: store.blueprint.dimensions.max.x - store.blueprint.dimensions.min.x,
    rangeY: store.blueprint.dimensions.max.z - store.blueprint.dimensions.min.z,
  };
};

export const selectedObjectTabSelector = (store) =>
  store.blueprint.selectedObjectTab;

export const sceneObjectsThumbnailsSelector = (store) =>
  store.blueprint.sceneObjectsThumbnails;

// ---------------------------------------- Re-selectors ----------------------------------------

export const selectedObjectSelector = createSelector(
  [selectedObjectIdSelector, sceneObjectsSelector],
  (selectedObjectId, sceneObjects) => {
    if (sceneObjects) {
      return sceneObjects[selectedObjectId];
    }
    return null;
  }
);

export const hoveredObjectSelector = createSelector(
  [hoveredObjectIdSelector, sceneObjectsSelector],
  (hoveredObjectId, sceneObjects) => {
    if (sceneObjects) {
      return sceneObjects[hoveredObjectId];
    }
    return null;
  }
);

export const camerasSelector = createSelector(
  sceneObjectsSelector,
  (sceneObjects) => {
    const cameras = filterObject(
      sceneObjects,
      (object) => object.Type === svgTypes.CAMERA
    );
    return cameras;
  }
);

export const lightsSelector = createSelector(
  sceneObjectsSelector,
  (sceneObjects) => {
    const cameras = filterObject(
      sceneObjects,
      (object) => object.Type === svgTypes.LIGHT
    );
    return cameras;
  }
);

export const charactersSelector = createSelector(
  sceneObjectsSelector,
  (sceneObjects) => {
    const cameras = filterObject(
      sceneObjects,
      (object) => object.Type === svgTypes.CHARACTER
    );
    return cameras;
  }
);

export const propsSelector = createSelector(
  sceneObjectsSelector,
  (sceneObjects) => {
    const cameras = filterObject(
      sceneObjects,
      (object) => object.Type === svgTypes.PROP
    );
    return cameras;
  }
);

export const selectedObjectDataSelector = createSelector(
  [timeFrameSelector, selectedObjectSelector],
  (selectedTimeFrame, selectedObject) => {
    return calculateMetadata(selectedTimeFrame, selectedObject);
  }
);

export const hoveredObjectDataSelector = createSelector(
  [timeFrameSelector, hoveredObjectSelector],
  (selectedTimeFrame, hoveredObject) => {
    return calculateMetadata(selectedTimeFrame, hoveredObject);
  }
);

export const interpolatedSceneObjectsSelector = createSelector(
  sceneObjectsSelector,
  timeFrameSelector,
  (objects, timeFrame) => interpolateSceneObjects(objects, timeFrame)
);

export const interpolatedOriginalSceneObjectsSelector = createSelector(
  originalSceneObjects,
  timeFrameSelector,
  (objects, timeFrame) => interpolateSceneObjects(objects, timeFrame)
);

export const objectsPathsSelector = createSelector(
  sceneObjectsSelector,
  (objects) => {
    const paths: any[] = [];
    for (const objectKey in objects) {
      const object = objects[objectKey];
      const curPath: { x: any; y: any }[] = [];
      let lastPosition = { posX: -1, posZ: -1 };

      for (const keyFrame in object.posKeyframes) {
        const curPosition = _.cloneDeep(object.posKeyframes[keyFrame]);
        if (
          curPosition.posX !== lastPosition.posX ||
          curPosition.posZ !== lastPosition.posZ
        ) {
          curPath.push({
            x: curPosition.posX,
            y: curPosition.posZ,
          });
        }
        lastPosition = curPosition;
      }
      if (curPath.length > 1) {
        paths.push(curPath);
      }
    }
    return paths;
  }
);

export const distancesSelector = createSelector(
  hoveredObjectIdSelector,
  selectedObjectIdSelector,
  interpolatedSceneObjectsSelector,
  interpolatedOriginalSceneObjectsSelector,
  (hoveredObjetId, selectedObjectId, transformedObjects, originalObjects) => {
    if (
      !hoveredObjetId ||
      !selectedObjectId ||
      hoveredObjetId === selectedObjectId
    )
      return [];

    const firstTransformedObject = transformedObjects.find(
      (obj) => obj.id === hoveredObjetId
    );

    const firstOriginalObject = originalObjects.find(
      (obj) => obj.id === hoveredObjetId
    );

    const secondTransformedObject = transformedObjects.find(
      (obj) => obj.id === selectedObjectId
    );

    const secondOriginalObject = originalObjects.find(
      (obj) => obj.id === selectedObjectId
    );

    if (!firstTransformedObject || !secondTransformedObject) return [];

    let bool =
      firstTransformedObject.position.posX <
      secondTransformedObject.position.posX;
    const result = {
      start: {
        x: bool
          ? firstTransformedObject.position.posX
          : secondTransformedObject.position.posX,
        z: bool
          ? firstTransformedObject.position.posZ
          : secondTransformedObject.position.posZ,
      },

      end: {
        x: bool
          ? secondTransformedObject.position.posX
          : firstTransformedObject.position.posX,
        z: bool
          ? secondTransformedObject.position.posZ
          : firstTransformedObject.position.posZ,
      },

      distance: getEculiDistance(
        firstOriginalObject.position,
        secondOriginalObject.position
      ),
    };

    return [result];
  }
);
// ---------------------------------------- Util functions ----------------------------------------

const interpolateSceneObjects = (objects, timeFrame): any[] => {
  const interpolatedObjects: any[] = [];

  for (const objectKey in objects) {
    const object = objects[objectKey];

    const interpolatedPosition = interpolateTransform(
      object.posKeyframes,
      timeFrame
    );
    const interpolatedRotation = interpolateTransform(
      object.rotKeyframes,
      timeFrame
    );

    const interpolatedYaw = interpolateTransform(
      object.yawKeyframes,
      timeFrame
    );

    const interpolatedMetadata = {};
    for (const meta in object.dynamicMetadata) {
      interpolatedMetadata[meta] = interpolateTransform(
        object.dynamicMetadata[meta],
        timeFrame
      );
    }

    if (interpolatedYaw?.rotation) {
      if (object.Type === 'CAMERA')
        interpolatedRotation.rotation -= interpolatedYaw.rotation;
      else if (object.Type === 'LIGHTS') {
        interpolatedRotation.rotation -= interpolatedYaw.rotation;
      }
    }

    const interpolatedObject = {
      type: object.Type,
      id: object.id,
      key: objectKey,
      name: object.name,
      boundingBox: object.boundingBox,
      position: interpolatedPosition,
      rotation: interpolatedRotation,
      metadata: object.metadata,
      dynamicMetadata: interpolatedMetadata,
      dimensions: object.dimensions,
      hidden: object.hidden,
      equipment: object.equipment,
    };

    interpolatedObjects.push(interpolatedObject);
  }
  return interpolatedObjects;
};

const interpolateTransform = (transforms, timeFrame) => {
  const positionBoundaries = findBoundaryTransforms(transforms, timeFrame);
  const {
    floorTransform,
    ceilTransform,
    floorTimestamp,
    ceilTimestamp,
  } = positionBoundaries;

  const interpolatedTransform = interpolateObject(
    floorTransform,
    ceilTransform,
    floorTimestamp,
    ceilTimestamp,
    timeFrame
  );

  return interpolatedTransform;
};

const findBoundaryTransforms = (transforms, timeFrame) => {
  const result = {
    floorTransform: {},
    ceilTransform: {},
    floorTimestamp: 0,
    ceilTimestamp: Infinity,
  };
  for (let key in transforms) {
    const timestamp = parseInt(key);
    const transform = transforms[timestamp];

    if (
      timestamp <= parseInt(timeFrame) &&
      timestamp >= result.floorTimestamp
    ) {
      result.floorTransform = transform;
      result.floorTimestamp = timestamp;
    }
    if (timestamp >= parseInt(timeFrame) && timestamp <= result.ceilTimestamp) {
      result.ceilTransform = transform;
      result.ceilTimestamp = timestamp;
    }
  }
  return result;
};

const interpolateObject = (floorObject, ceilObject, tfMin, tfMax, tfMid) => {
  if (typeof floorObject === 'number') {
    const minValue = floorObject;
    const maxValue = ceilObject;
    const interpolatedValue = interpolateValue(
      minValue,
      maxValue,
      tfMin,
      tfMax,
      tfMid
    );
    return interpolatedValue;
  }
  const interpolatedObject = { ...floorObject };
  for (const key in interpolatedObject) {
    const minValue = floorObject[key];
    const maxValue = ceilObject[key];
    const interpolatedValue = interpolateValue(
      minValue,
      maxValue,
      tfMin,
      tfMax,
      tfMid
    );

    interpolatedObject[key] = interpolatedValue;
  }
  return interpolatedObject;
};

const interpolateValue = (minValue, maxValue, tfMin, tfMax, tfMid) => {
  const tfRange = tfMax - tfMin;
  if (tfRange === 0) {
    return minValue;
  }
  const percentile = (tfMid - tfMin) / tfRange;

  const valueRange = maxValue - minValue;

  const interpolatedValue = minValue + percentile * valueRange;

  return interpolatedValue;
};

const filterObject = (object, predicate) => {
  const array: any[] = [];
  for (const key in object) {
    if (predicate(object[key])) array.push(object[key]);
  }
  return array;
};

const getEculiDistance = (obj1, obj2) => {
  let a = obj1.posX - obj2.posX;
  let b = obj1.posZ - obj2.posZ;

  let c = Math.sqrt(a * a + b * b).toFixed(2);

  return c;
};

const calculateMetadata = (selectedTimeFrame, selectedObject) => {
  if (!selectedObject) return {};
  const { name, ...metadata } = selectedObject.metadata;
  const selectedDynamicMetadata = selectedObject.dynamicMetadata;
  const selectedHoverType = selectedObject.Type;
  const interpolatedDynamicData = {};

  for (const metadata in selectedDynamicMetadata) {
    const metadataFrames = selectedDynamicMetadata[metadata];

    const bounds = findBoundaryTransforms(metadataFrames, selectedTimeFrame);

    // Special case for interpolating camera's props
    if (selectedHoverType === svgTypes.CAMERA) {
      interpolatedDynamicData[metadata] = bounds.floorTransform;
      continue;
    }

    let interpolatedValue = interpolateValue(
      bounds.floorTransform,
      bounds.ceilTransform,
      bounds.floorTimestamp,
      bounds.ceilTimestamp,
      selectedTimeFrame
    );
    if (
      typeof interpolatedValue === 'number' &&
      !Number.isInteger(interpolatedValue)
    ) {
      interpolatedValue = Number(interpolatedValue.toFixed(2));
    }

    interpolatedDynamicData[metadata] = interpolatedValue;
  }
  const dataProps: any[] = [];
  if (metadata) {
    dataProps.push(...Object.entries(metadata));
  }
  if (interpolatedDynamicData) {
    dataProps.push(...Object.entries(interpolatedDynamicData));
  }

  return {
    id: selectedObject.id,
    name,
    type: selectedHoverType,
    props: dataProps,
    itemLibraryID: selectedObject.itemLibraryID,
    instanceId: selectedObject.instanceID,
    equipment: selectedObject.equipment,
  };
};
