import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type propTypes = {
  hideChildren?: boolean;
  show?: boolean;
  fullscreen?: boolean;
  className?: string;
  backgroundOpacity?: number;
  backgroundStyle?: React.CSSProperties;
  centerSpinner?: boolean;
  spinnerStyle?: React.CSSProperties;
  spinnerColor?: string;
  spinnerSize?: number;
  children?: any;
};

const Loader = ({
  hideChildren = true,

  show = true,
  fullscreen = false,
  className = '',
  backgroundOpacity = 1,
  backgroundStyle = {},

  centerSpinner = false,
  spinnerStyle = {},
  spinnerColor = 'white',
  spinnerSize = 48,
  children,
}: propTypes) => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: spinnerSize, color: spinnerColor }}
      color={'rgb(100,100,100)'}
      spin
    />
  );

  if (!show) {
    return <>{children}</>;
  }

  let spinnerContainerClasses = 'loader';
  if (fullscreen) spinnerContainerClasses += ' loader-fullscreen';

  let spinnerClasses = '';
  if (centerSpinner || fullscreen) spinnerClasses += ' center-spinner';

  let renderChildren = hideChildren ? null : children;
  return (
    <>
      <div
        className={`${spinnerContainerClasses} ${className}`}
        style={{ ...backgroundStyle, opacity: backgroundOpacity }}
      >
        <Spin
          indicator={antIcon}
          className={spinnerClasses}
          style={spinnerStyle}
        />
      </div>

      {renderChildren}
    </>
  );
};

Loader.propTypes = {
  hideChildren: PropTypes.bool,

  show: PropTypes.bool,
  fullscreen: PropTypes.bool,
  className: PropTypes.string,
  backgroundOpacity: PropTypes.number,
  backgroundStyle: PropTypes.object,

  centerSpinner: PropTypes.bool,
  spinnerStyle: PropTypes.object,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number,
};

export default Loader;
