import './Team.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setProject, setSelectedProjectId } from 'redux/slices/app/actions';
import {
  teamMembersSelector2,
  themeSelector,
  userRoleInTeam,
} from 'redux/slices/app/selectors';

const Team = () => {
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector);
  const teamMembers = Object.values(useSelector(teamMembersSelector2) ?? {});
  const userRole = useSelector(userRoleInTeam);

  useEffect(() => {
    dispatch(setSelectedProjectId(null));
    dispatch(setProject(null));
  }, [dispatch]);

  if (userRole === 'Reviewer') return <Redirect to="/menu/not-available" />;

  const renderedTeam = teamMembers.map(
    ({ name, role, title, thumbnail, id }) => {
      return (
        <div className="team-card" key={id}>
          <div style={{ position: 'relative' }}>
            <img className="team-member-img" src={thumbnail} alt="Avatar" />
            {role === 0 ? <div className="admin-badge"> ADMIN </div> : null}
          </div>
          <div className="team-member-information-container">
            <div className="team-member-name">{name}</div>
            <div className="team-member-job">
              {/* {typeof title === 'number' ? roleNumToStr[title] : title} */}
              {title}
            </div>
          </div>
        </div>
      );
    }
  );
  return (
    <div className={`screen ${theme}`}>
      <div className="screen-container screen-container-padding">
        <div className="team-title"> Team</div>
        <div className="team-cards p-0 ">{renderedTeam}</div>
      </div>
    </div>
  );
};

export default Team;
