import './CreateSceneModal.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  inPersonalDriveSelector,
  pendingSceneSelector,
  projectsArraySelector,
  scenesSelector,
  sceneTemplatesSelector,
  selectedProjectIdSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import {
  clearPendingScene,
  createScene,
  setAppLoader,
} from 'redux/slices/app/actions';
import AddMembersModal from './AddMembersModal';
import { createNewScenePendingSelector } from 'redux/slices/loaders/loadersSelectors';
import Loader from 'common/components/loader/Loader';
import OpenSceneModal from 'backlot-menu/components/modals/openSceneModal/OpenSceneModal';
import { openScene, updateRecentScenes } from 'redux/slices/project/actions';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase-main';
import { dispatchEvents } from 'redux/middlewares/amplitude';

const CreateSceneModal = ({ onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const currentProjectId = useSelector(selectedProjectIdSelector);

  const projects = useSelector(projectsArraySelector);

  const showProjectSelector = history.location.pathname.includes('menu/recent');

  const inPersonalDrive = useSelector(inPersonalDriveSelector);

  const createNewScenePending = useSelector(createNewScenePendingSelector);

  const sceneTemplates = Object.values(
    useSelector(sceneTemplatesSelector) ?? {}
  );

  const pendingScene = useSelector(pendingSceneSelector);
  const sceneOrder = (useSelector(scenesSelector)?.length ?? 0) + 1;
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [showCreateSceneModal, setShowCreateSceneModal] = useState(true);

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [sceneName, setSceneName] = useState('');
  const [sceneType, setSceneType] = useState('EXT.');
  const [sceneAccess, setSceneAccess] = useState('public');

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const formReady =
    selectedTemplateIndex !== -1 &&
    sceneName.length > 0 &&
    sceneType.length > 0 &&
    sceneAccess.length > 0 &&
    (!showProjectSelector || selectedProjectId.length > 0);

  const showOpenSceneModal = createNewScenePending || pendingScene;

  useEffect(() => {
    if (formSubmitted && !createNewScenePending) {
      // onCancel();
    }
  }, [formSubmitted, createNewScenePending]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        event.stopPropagation();
        if (showOpenSceneModal) {
          onSceneEnter();
        } else {
          onSubmit();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  const onSubmit = () => {
    // e.preventDefault();

    if (formReady) {
      if (sceneAccess !== 'public') {
        setShowAddMembersModal(true);
      } else {
        onFinish();
      }
    }
  };

  const onSceneEnter = () => {
    if (createNewScenePending) return;

    const projectId = showProjectSelector
      ? selectedProjectId
      : currentProjectId;

    // dispatch(updateRecentScenes(teamId, projectId, pendingScene?.id));
    dispatch(setAppLoader(true));
    // (window as any).selectScene(
    //   pendingScene?.id,
    //   pendingScene?.name,
    //   pendingScene?.projectId ?? projectId
    // );
    // firebase.analytics().logEvent('open_scene', pendingScene ?? {});
    // dispatchEvents([{ event_type: 'open_scene', user_id: userId }]);
    dispatch(
      openScene(teamId, projectId, pendingScene?.id, pendingScene?.name)
    );
    history.push(`/menu/project/scene/${pendingScene?.id}`);
  };

  const onFinish = (teamMembers?) => {
    let newMembers;
    if (teamMembers) {
      newMembers = { [userId]: { userId, name: userName } };
      teamMembers.forEach(({ id, name }) => {
        newMembers = { ...newMembers, [id]: { userId: id, name } };
      });
    }

    const projectId = showProjectSelector
      ? selectedProjectId
      : currentProjectId;

    setShowCreateSceneModal(false);
    dispatch(
      createScene(
        sceneType + ' ' + sceneName,
        sceneAccess === 'public',
        projectId,
        userId,
        sceneOrder,
        selectedTemplateIndex >= 0
          ? sceneTemplates[selectedTemplateIndex].itemID
          : undefined,
        selectedTemplateIndex >= 0
          ? (sceneTemplates[selectedTemplateIndex].thumbnail ?? '').split(
              ','
            )[0]
          : undefined,
        newMembers
      )
    );
    // onCancel();
    setFormSubmitted(true);
  };

  const renderInput = (
    <input
      type="text"
      value={sceneName}
      placeholder="NEW SCENE"
      onChange={(e) => setSceneName(e.target.value.toUpperCase())}
      autoFocus
      required
    />
  );

  const renderCheckmark = (
    <div className="checkmark">
      <img src="/menu/check-mark.svg" alt="checkmark" />
    </div>
  );

  const renderTemplates = sceneTemplates.map(
    ({ itemID, name, thumbnail }, index) => {
      const isSelected = selectedTemplateIndex === index;
      return (
        <div
          key={itemID}
          className={`template-card ${isSelected ? 'selected' : ''}`}
          onClick={() => {
            setSelectedTemplateIndex(isSelected ? -1 : index);
          }}
        >
          <div
            className="template-img"
            style={{ background: `url("${(thumbnail ?? '').split(',')[0]}")` }}
          >
            {isSelected && renderCheckmark}
          </div>
          <div className="template-title">{name}</div>
        </div>
      );
    }
  );

  return (
    <>
      {showOpenSceneModal && (
        <OpenSceneModal
          thumbnail={pendingScene?.thumbnail}
          title={`${pendingScene?.order}. ${sceneType} ${sceneName}`}
          loading={createNewScenePending}
          loadingText={'Creating new scene...'}
          onEnter={() => {
            onSceneEnter();
          }}
          onClose={() => {
            if (!createNewScenePending) {
              dispatch(clearPendingScene());
              onCancel();
            }
          }}
        />
      )}
      {
        <Modal
          transitionName=""
          maskTransitionName=""
          className="create-scene"
          title={null}
          // maskClosable={false}
          visible={showCreateSceneModal}
          onCancel={() => {
            if (!createNewScenePending) onCancel();
          }}
          footer={null}
          centered
        >
          {showAddMembersModal && (
            <AddMembersModal
              onCancel={() => {
                setShowAddMembersModal(false);
              }}
              onSubmit={(members) => {
                onFinish(members);
                setShowAddMembersModal(false);
              }}
            />
          )}
          <form
            className="scene-name-form"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="create-scene-header">Create a Scene</div>
            {showProjectSelector && (
              <div className="project-selector">
                <label>Project</label>
                <Select
                  value={selectedProjectId}
                  onChange={(v) => {
                    setSelectedProjectId(v);
                  }}
                  options={projects?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                />
              </div>
            )}
            <div className="scene-info">
              <Select
                value={sceneType}
                onChange={(v) => {
                  setSceneType(v);
                }}
                options={[
                  { label: 'INT.', value: 'INT.' },
                  { label: 'EXT.', value: 'EXT.' },
                ]}
              />
              {/* {editingSceneName ? renderInput : renderSceneName} */}
              {renderInput}
            </div>
            <div className="templates">
              <div className="templates-title">Film Sets</div>
              <div className="templates-grid">{renderTemplates}</div>
            </div>

            <div className="create-scene-footer">
              {/* {!inPersonalDrive && (
                <>
                  <div>Access</div>
                  <div>
                    <Select
                      value={sceneAccess}
                      onChange={(v) => {
                        setSceneAccess(v);
                      }}
                      options={[
                        { label: 'Full Team', value: 'public' },
                        { label: 'Private', value: 'private' },
                      ]}
                    />
                  </div>
                </>
              )} */}
              <div className="cancel">
                <button type="button" onClick={onCancel}>
                  Cancel
                </button>
              </div>
              <div className="create">
                <button
                  type="submit"
                  // onClick={onSubmit}
                  disabled={!formReady || createNewScenePending}
                >
                  <Loader show={createNewScenePending} spinnerSize={12} />
                  {createNewScenePending ? 'Creating...' : 'Create'}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      }
    </>
  );
};

export default CreateSceneModal;
