interface IBaseComment {
  id?: string;
  text: string;
  commentorId: string;
  commentorName: string;
  role: string;
  date: string;
  tags?: object;
  likes?: { [key: string]: string }[];
}
export interface IComment extends IBaseComment {
  replies?: {
    [key: string]: IReply;
  };
}

export interface IReply extends IBaseComment {
  parentId: string;
}

export type CommentsCollection = {
  [key: string]: IComment;
};

export const createComment = (
  text: string,
  userId: string,
  userName: string,
  userRole: string,
  date: string,
  tags = {}
): IBaseComment => {
  return {
    text,
    commentorId: userId,
    commentorName: userName,
    role: userRole,
    date,
    tags,
  };
};

export const createReply = (
  text: string,
  userId: string,
  userName: string,
  userRole: string,
  date: string,
  tags = {},
  parentId: string
): IReply => {
  return {
    ...createComment(text, userId, userName, userRole, date, tags),
    parentId,
  };
};

export type commentActionType =
  | 'SET'
  | 'ADD'
  | 'EDIT'
  | 'DELETE'
  | 'SUBSCRIBE'
  | 'UNSUBSCRIBE'
  | 'LIKE'
  | 'UNLIKE'
  | 'CLEAR'
  | 'OPEN_COMMENTS_MODAL'
  | 'CLOSE_COMMENTS_MODAL'
  | 'TOGGLE_COMMENTS_MENU'
  | 'CLOSE_COMMENTS_MENU';

export type commentType =
  | 'SCENE_COMMENT'
  | 'SNAPSHOT_COMMENT'
  | 'OBJECT_COMMENT'
  | 'SHOT_COMMENT';

export type commentActionStatus = 'SUCCESS' | 'FAIL';
