import './SceneSettings.scss';
import React from 'react';
import Time from './components/time/Time';
import Weather from './components/weather/Weather';
import Sky from './components/sky/Sky';
// import AdvancedOptions from './components/advanced/AdvancedOptions';

const SceneSettings = () => {
  return (
    <div className="scene-settings">
      <div className="scene-title">
        <b>ENVIRONMENT</b>
      </div>
      <Time />
      <Weather />
      <Sky />
      {/* <AdvancedOptions /> */}
      <div className="advanced"></div>
    </div>
  );
};

export default SceneSettings;
