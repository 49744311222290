export const resizeImage = (
  originalImageUrl: string,
  onResizeComplete: (blob: Blob, url: string) => void,
  maxWidth = 200,
  maxHeight = 200,
  mimeType = 'image/png'
) => {
  const originalImage = document.createElement('img');
  originalImage.src = originalImageUrl;
  originalImage.crossOrigin = 'Anonymous';

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = maxWidth;
  canvas.height = maxHeight;

  if (!context) {
    console.error('Error while resizing the image, context value was null');
  }

  originalImage.onload = () => {
    context?.clearRect(0, 0, maxWidth, maxHeight);

    const imgWidth = originalImage.width;
    const imgHeight = originalImage.height;

    context?.drawImage(
      originalImage,
      0,
      0,
      imgWidth,
      imgHeight,
      0,
      0,
      maxWidth,
      maxHeight
    );

    const dataUri = canvas.toDataURL(mimeType);

    const blob = fetch(dataUri)
      .then((res) => res.blob())
      .then((blob) => {
        const newUrl = URL.createObjectURL(blob);
        if (onResizeComplete) onResizeComplete(blob, newUrl);
      });

    cleanup(originalImage, canvas);
  };
};

const cleanup = (...elements) => {
  elements.forEach((element) => {
    element.remove();
  });
};
