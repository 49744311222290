import './BacklotPropAnimations.scss';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useRef, useState } from 'react';
import { FaPauseCircle, FaPlayCircle, FaPlusCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPropAnimations,
  setPropSearchAnimations,
  setSearchTags,
} from 'redux/slices/items/actions';
import {
  propAnimationsSearchSelector,
  propAnimationsSelector,
} from 'redux/slices/items/selectors';

const BacklotPropAnimations = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [activeAnimationIndex, setActiveAnimationIndex] = useState<any>(-1);
  const defaultPropAnimations = useSelector(propAnimationsSelector);
  const propAnimations = useSelector(propAnimationsSearchSelector);
  useEffect(() => {
    dispatch(setSearchTags('AnimationItems'));
  }, [dispatch]);

  const stopAnimations = () => {
    setActiveAnimationIndex(-1);
  };
  const search = (term) => {
    if (term === '') dispatch(setPropSearchAnimations(defaultPropAnimations));
    else {
      const regexStr = '(?=.*' + term.split(/\,|\s/).join(')(?=.*') + ')';
      const regex = new RegExp(regexStr, 'gi');
      console.log(regex);
      const res = defaultPropAnimations.filter(
        ({ name }) => name.match(regex)?.length > 0
      );
      dispatch(setPropSearchAnimations(res));
    }
  };
  useEffect(() => {
    window.addEventListener('stopAnimations', stopAnimations);
    return () => {
      window.removeEventListener('stopAnimations', stopAnimations);
    };
  }, []);

  useEffect(() => {
    search(searchText);
  }, [searchText]);

  return (
    <div className="animations-props">
      <Search
        className="search"
        placeholder="Search Animations..."
        enterButton={<div />}
        size="large"
        suffix={
          <img src="/backlot-library/home/search-black.svg" alt="search" />
        }
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onFocus={() => (window as any).onFocusIn()}
        onBlur={() => (window as any).onFocusOut()}
        // onSearch={onSearch}
      />

      <div className="animations-list scroll-bar" ref={ref}>
        {propAnimations.map(({ name, itemID }) => (
          <div className="animation" key={itemID}>
            <div className="name">{name}</div>
            <div
              className="play"
              onClick={() => {
                if (itemID === activeAnimationIndex) {
                  setActiveAnimationIndex(-1);
                  (window as any).onPauseAnimation(itemID);
                } else {
                  setActiveAnimationIndex(itemID);
                  (window as any).onPlayAnimation(itemID);
                }
              }}
            >
              {itemID === activeAnimationIndex ? (
                <FaPauseCircle />
              ) : (
                <FaPlayCircle />
              )}
            </div>
            <div
              className="add"
              onClick={() => {
                (window as any).onAddAnimation(itemID);
              }}
            >
              <FaPlusCircle />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BacklotPropAnimations;
