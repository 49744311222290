import './RenameProjectModal.scss';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedProjectIdSelector,
  selectedProjectNameSelector,
  selectedTeamIdSelector,
  sharedProjectsSelector,
} from 'redux/slices/app/selectors';
import { renameProject, renameSharedProject } from 'redux/slices/app/actions';

const RenameProjectModal = ({ visible = true, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const projectName = useSelector(selectedProjectNameSelector);
  const inSharedProject = (useSelector(sharedProjectsSelector) ?? {})[
    projectId
  ];
  const [name, setName] = useState(projectName ?? '');
  const onSubmit = (e) => {
    e.preventDefault();
    if (name?.length > 0) {
      if (inSharedProject) dispatch(renameSharedProject(projectId, name));
      else dispatch(renameProject(teamId, projectId, name));
      onClose();
    }
  };
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      footer={null}
      visible={visible}
      onCancel={onClose}
    >
      <form className="rename-proj-form" onSubmit={onSubmit}>
        <div className="modal-title"> Rename Project</div>
        <div>
          <label>Project Name</label>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="button-container">
          <button className="btn btn-primary">Rename</button>
        </div>
      </form>
    </Modal>
  );
};

export default RenameProjectModal;
