import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './BlueprintEditor.scss';
// import 'rc-ruler-slider/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDistance,
  setPosition,
  setScale,
  setSvgDimensions,
} from 'redux/slices/blueprints/actions';
import kelvinToRGB from 'kelvin-to-rgb';
import {
  centerPointSelector,
  svgDimensionsSelector,
} from 'redux/slices/blueprints/selectors';
import { metersToPixels } from '../blueprint.config';
import ControlButtons from './controlBtns/ControlBtns';
import InfiniteViewer from 'react-infinite-viewer';
import Guides from '@scena/react-guides';
import { svgTypes } from 'common/config/blueprints';
import useKeyPress from 'common/hooks/useKeyPress';

type propTypes = {
  containerWidth?: number;
  containerHeight?: number;
  containerPadding?: number;
  xOffset?: number;
  yOffset?: number;
  sceneObjects?: any[];
  paths?: any[];
  distances;
  selectedObjectId?: string;
  hoveredObjectId?: string;
  onEmptyClick?: () => void;
  onObjectClick?: (objId: string) => void;
  onObjectHover?: (objId: string) => void;
  onObjectLeave?: (objId: string) => void;
};

const minZoom = 0.4;
const maxZoom = 5;

const zoomToScale = (zoom) => {
  if (zoom <= 0.71) {
    return 'x200';
  }
  if (zoom <= 1) {
    return 'x100';
  }
  if (zoom <= 1.6) {
    return 'x50';
  }
  if (zoom <= 2.8) {
    return 'x20';
  }
  return 'x10';
};

const BlueprintEditor = ({
  containerWidth = 500,
  containerHeight = 500,
  containerPadding = metersToPixels(100),
  xOffset = 0,
  yOffset = 0,
  sceneObjects = [],
  paths = [],
  distances,
  selectedObjectId,
  hoveredObjectId,
  onEmptyClick = () => {},
  onObjectClick = () => {},
  onObjectHover = () => {},
  onObjectLeave = () => {},
}: propTypes) => {
  const dispatch = useDispatch();
  const svgRef: any = useRef(null);
  const deleteKey = useKeyPress('Delete');
  const [mouseOnPath, setMouseOnPath] = useState(false);
  const { width, height } = useSelector(svgDimensionsSelector);
  const centerPosition = useSelector(centerPointSelector);
  const [renderSvg, setRenderSvg] = useState<any>(null);
  const [renderFOV, setRenderFOV] = useState<any>(null);

  const svgWidth = containerWidth + 2 * containerPadding;
  const svgHeight = containerHeight + 2 * containerPadding;

  const transformXCoordinate = (x) => metersToPixels(x + xOffset);
  const transformYCoordinate = (y) => metersToPixels(y + yOffset);
  // containerHeight + containerPadding / 2 - metersToPixels(y + yOffset);

  // useEffect(() => {
  //   if (svgRef.current) {
  //     if (
  //       svgRef.current.clientWidth !== width ||
  //       svgRef.current.clientHeight !== height
  //     ) {
  //       dispatch(
  //         setSvgDimensions(
  //           svgRef.current.clientWidth,
  //           svgRef.current.clientHeight
  //         )
  //       );
  //     }
  //   }
  // });

  useEffect(() => {
    if (mouseOnPath !== false) {
      if (deleteKey) {
        dispatch(deleteDistance(mouseOnPath));
      }
    }
  }, [deleteKey]);

  const renderPath = (points, props) => {
    let d = 'M ';
    points.forEach((point) => {
      d += point.x + ' ' + point.y + ' ';
    });

    return <path d={d} {...props} />;
  };

  useEffect(() => {
    const newSvg = sceneObjects.map((obj) => {
      if (obj.hidden) return null;
      const { key } = obj;
      let x =
        typeof obj.position.posX === 'number'
          ? transformXCoordinate(obj.position.posX)
          : 0;
      let y =
        typeof obj.position.posZ === 'number'
          ? transformYCoordinate(obj.position.posZ)
          : 0;
      const { rotation = 0 } = obj.rotation;

      const isHovered = hoveredObjectId === obj.id;
      const isSelected = selectedObjectId === obj.id;
      const isColored = isHovered || isSelected;

      const onClick = (e) => {
        e.stopPropagation();
        onObjectClick(obj.id);
      };

      const onMouseOver = () => {
        onObjectHover(obj.id);
      };

      const onMouseLeave = () => {
        onObjectLeave(obj.id);
      };

      if (obj.type === svgTypes.CHARACTER) {
        const r = metersToPixels(0.5);
        return (
          <React.Fragment key={obj.id}>
            <circle
              key={key}
              cx={x}
              cy={containerHeight - y}
              r={r}
              stroke={isColored ? '#0094ff' : 'white'}
              fill="#0094ff"
              fillOpacity={isSelected ? 0.4 : 0}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            />

            {isColored ? (
              <text
                className="svg-text"
                textAnchor="middle"
                x={x}
                y={containerHeight - y + r * 2 + metersToPixels(0.75)}
                fontSize={metersToPixels(1)}
                fill="white"
              >
                {obj.name}
              </text>
            ) : null}
          </React.Fragment>
        );
      } else if (obj.type === svgTypes.PROP) {
        const { boundingBox = { x: 1, z: 1 } } = obj;
        let width = metersToPixels(boundingBox?.x);
        let height = metersToPixels(boundingBox?.z);

        const transform = `rotate(${rotation + 180} ${x} ${
          containerHeight - y
        })`;

        const textInBox = obj.name.length / 2 < boundingBox?.x - 4;

        return (
          <React.Fragment key={obj.id}>
            <rect
              key={key}
              x={x - width / 2}
              y={containerHeight - y - height / 2}
              width={width}
              height={height}
              transform={transform}
              stroke={isColored ? '#0094ff' : 'white'}
              fill="#0094ff"
              fillOpacity={isSelected ? 0.4 : 0}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            />
            {isColored || textInBox ? (
              <text
                className="svg-text"
                textAnchor="middle"
                x={x}
                // y={y + height / 2 + metersToPixels(0.6)}
                y={
                  textInBox
                    ? containerHeight - y
                    : containerHeight - y + height / 2 + metersToPixels(1.5)
                }
                fontSize={metersToPixels(1)}
                fill="white"
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              >
                {obj.name}
              </text>
            ) : null}
          </React.Fragment>
        );
      } else if (obj.type === svgTypes.CAMERA) {
        const w = metersToPixels(1) * 1.5;
        const h = metersToPixels(1) * 1.5;

        let href = '/blueprints/cam.svg';
        if (isHovered) href = '/blueprints/cam-outlined.svg';
        if (isSelected) href = '/blueprints/cam-filled.svg';

        const rotationOffset = -90;
        const transform = `rotate(${-rotation + rotationOffset} ${x} ${
          containerHeight - y
        })`;

        return (
          <Fragment key={obj.id}>
            <image
              key={key}
              x={x - w / 2}
              y={containerHeight - y - h / 2}
              width={w}
              height={h}
              href={href}
              transform={transform}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            />
            {isColored ? (
              <text
                textAnchor="middle"
                x={x}
                y={containerHeight - y + h / 2 + metersToPixels(1)}
                fill="white"
                fontSize={metersToPixels(1)}
              >
                {obj.name}
              </text>
            ) : null}
          </Fragment>
        );
      } else if (obj.type === svgTypes.LIGHT) {
        const w = metersToPixels(1) * 1.5;
        const h = metersToPixels(1) * 1.5;
        let href = '/blueprints/light.svg';
        if (isHovered) href = '/blueprints/light-outlined.svg';
        if (isSelected) href = '/blueprints/light-filled.svg';

        const rotationOffset = -90;
        const transform = `rotate(${-rotation + rotationOffset} ${x} ${
          containerHeight - y
        })`;

        return (
          <Fragment key={obj.id}>
            <image
              key={key}
              x={x - w / 2}
              y={containerHeight - y - h / 2}
              width={w}
              height={h}
              href={href}
              transform={transform}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            />
            {isColored ? (
              <text
                textAnchor="middle"
                x={x}
                y={containerHeight - y + h / 2 + metersToPixels(1)}
                fontSize={metersToPixels(1)}
                fill="white"
              >
                {obj.name}
              </text>
            ) : null}
          </Fragment>
        );
      }
    });

    const newFOV = sceneObjects.map((obj) => {
      if (obj.hidden) return null;
      const { key } = obj;
      let x =
        typeof obj.position.posX === 'number'
          ? transformXCoordinate(obj.position.posX)
          : 0;
      let y =
        typeof obj.position.posZ === 'number'
          ? transformYCoordinate(obj.position.posZ)
          : 0;
      const { rotation = 0 } = obj.rotation;

      const isHovered = hoveredObjectId === obj.id;
      const isSelected = selectedObjectId === obj.id;
      const isColored = isHovered || isSelected;

      if (obj.type === svgTypes.CAMERA) {
        const { dynamicMetadata = {} } = obj;
        const w = metersToPixels(1) * 1.5;
        const h = metersToPixels(1) * 1.5;
        const fovAngle = dynamicMetadata['Field of View'];

        const rayRotationOffset = 0;
        const rayTransform = `rotate(${-rotation + rayRotationOffset} ${x} ${
          containerHeight - y
        })`;

        // let FOV: Element = null;
        let FOV: any;

        if (fovAngle) {
          const range = 200;
          const theta = (90 - fovAngle / 2) * (Math.PI / 180);
          const scopeWidth = 2 * (range / Math.tan(theta));

          const path1 = [
            { x: x + 3, y: containerHeight - y - h / 2 },
            { x: x + scopeWidth / 2, y: containerHeight - y - range },
          ];

          const path2 = [
            { x: x - scopeWidth / 2, y: containerHeight - y - range },
            { x: x - 3, y: containerHeight - y - h / 2 },
          ];

          FOV = (
            <React.Fragment key={obj.id}>
              {renderPath(path1, {
                transform: rayTransform,
                strokeWidth: '0.5',
                stroke: `url(#transparent-line1)`,
                fill: `url(#transparent-line1)`,
              })}

              {renderPath(path2, {
                transform: rayTransform,
                strokeWidth: '0.5',
                stroke: `url(#transparent-line2)`,
                fill: `url(#transparent-line2)`,
              })}
            </React.Fragment>
          );
          return FOV;
        }
      } else if (obj.type === svgTypes.LIGHT) {
        const { dynamicMetadata = {} } = obj;
        const beamAngle = dynamicMetadata['Beam Angle'];
        const range = metersToPixels(dynamicMetadata['Range'] ?? 10);
        const temperature = dynamicMetadata['Color Temperature'] ?? 1000;
        const w = metersToPixels(1) * 1.5;
        const h = metersToPixels(1) * 1.5;

        let FOV: any;

        if (beamAngle) {
          // const range = metersToPixels(10);
          const theta = (90 - beamAngle / 2) * (Math.PI / 180);
          const scopeWidth = 2 * (range / Math.tan(theta));
          const points = [
            { x: x + w / 2 - 3, y: containerHeight - y - h / 2 },
            { x: x + scopeWidth / 2, y: containerHeight - y - range },
            { x: x - scopeWidth / 2, y: containerHeight - y - range },
            { x: x - w / 2 + 3, y: containerHeight - y - h / 2 },
          ];

          // const temperature = 1000;
          let temperatureColor = [0, 0, 0];
          if (obj.type === svgTypes.LIGHT) {
            temperatureColor = kelvinToRGB(temperature);
            // const colors = colorTemperatureToRGB(temperature);
            // temperatureColor = [colors.r, colors.g, colors.b];
          }

          const rayRotationOffset = 0;
          const rayTransform = `rotate(${-rotation + rayRotationOffset} ${x} ${
            containerHeight - y
          })`;

          FOV = (
            <React.Fragment key={obj.id}>
              <defs>
                <linearGradient
                  id={`grad${temperature}`}
                  x1="0%"
                  y1="100%"
                  x2="0%"
                  y2="0%"
                >
                  <stop
                    offset="0%"
                    style={{
                      stopColor: `rgba(${temperatureColor[0]} , ${temperatureColor[1]} , ${temperatureColor[2]} , 1)`,
                      stopOpacity: 1,
                    }}
                  />
                  <stop
                    offset="100%"
                    style={{
                      stopColor: `rgba(${temperatureColor[0]} , ${temperatureColor[1]} , ${temperatureColor[2]} , 0)`,
                      stopOpacity: 1,
                    }}
                  />
                </linearGradient>
              </defs>
              {renderPath(points, {
                transform: rayTransform,
                strokeWidth: '0.5',
                stroke: temperatureColor ? 'none' : 'rgba(255,255,255,0.4)',
                fill: temperatureColor ? `url(#grad${temperature})` : 'none',
              })}
            </React.Fragment>
          );

          return FOV;
        }
      } else if (obj.type === svgTypes.CHARACTER) {
        const r = metersToPixels(0.5);
        const rayRotationOffset = 0;
        const rayTransform = `rotate(${-rotation + rayRotationOffset} ${x} ${
          containerHeight - y
        })`;

        let FOV: any;

        if (typeof rotation === 'number') {
          const range = metersToPixels(20);
          const scopeWidth = metersToPixels(20);
          const path1 = [
            { x: x + scopeWidth / 2, y: containerHeight - y - range },
            { x: x + r / 2, y: containerHeight - y - r },
          ];

          const path2 = [
            { x: x - scopeWidth / 2, y: containerHeight - y - range },
            { x: x - r / 2, y: containerHeight - y - r },
          ];

          FOV = (
            <React.Fragment key={obj.id}>
              {renderPath(path1, {
                transform: rayTransform,
                strokeWidth: '0.5',
                stroke: `url(#transparent-line1)`,
                fill: `url(#transparent-line1)`,
              })}

              {renderPath(path2, {
                transform: rayTransform,
                strokeWidth: '0.5',
                stroke: `url(#transparent-line2)`,
                fill: `url(#transparent-line2)`,
              })}
            </React.Fragment>
          );
          return FOV;
        }
      }
      return null;
    });

    setRenderSvg(newSvg);
    setRenderFOV(newFOV);
  }, [
    sceneObjects,
    containerWidth,
    containerHeight,
    containerPadding,
    xOffset,
    yOffset,
    selectedObjectId,
    hoveredObjectId,
  ]);

  const renderPaths = paths
    .map((path) =>
      path.map((coordinate) => {
        return {
          x: transformXCoordinate(coordinate.x),
          y: containerHeight - transformYCoordinate(coordinate.y),
        };
      })
    )
    .map((path, index) => {
      return renderPath(path, {
        key: index,
        stroke: 'yellow',
        strokeOpacity: '0.25',
        strokeWidth: '0.5',
        fill: 'none',
      });
    });

  let renderDistance = null;
  if (distances.length > 0) {
    renderDistance = distances.map((distance, index) => {
      let x1 = transformXCoordinate(distance.start.x);
      let y1 = transformYCoordinate(distance.start.z);
      let x2 = transformXCoordinate(distance.end.x);
      let y2 = transformYCoordinate(distance.end.z);

      let r = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      let x_delta = (-10 / r) * (y1 - y2);
      let y_delta = (-10 / r) * (x2 - x1);
      let x3 = x1 + x_delta;
      let y3 = y1 + y_delta;
      let x4 = x2 + x_delta;
      let y4 = y2 + y_delta;

      let text_x_shift = x3;
      let text_y_shift = y3;

      r = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      x_delta = (-15 / r) * (y1 - y2);
      y_delta = (-15 / r) * (x2 - x1);
      x3 = x1 + x_delta;
      y3 = y1 + y_delta;
      x4 = x2 + x_delta;
      y4 = y2 + y_delta;

      let theta = Math.atan((y4 - y3) / (x4 - x3));
      let x_mid = x3 + (r / 2) * Math.cos(theta);
      let y_mid = y3 + (r / 2) * Math.sin(theta);

      let x5 = -((50 / 2) * Math.cos(theta)) + x_mid;
      let y5 = -((50 / 2) * Math.sin(theta)) + y_mid;
      let x6 = (50 / 2) * Math.cos(theta) + x_mid;
      let y6 = (50 / 2) * Math.sin(theta) + y_mid;
      const key = `${x1},${y1},${x2},${y2},${x3},${y3},${x4},${y4},${x5},${y5},${x6},${y6}`;
      return (
        <React.Fragment key={key}>
          <g fill="none" stroke="#FF8617" strokeWidth="1.2em">
            {/* <path
              strokeLinecap="round"
              d={`M ${x5} ${containerHeight - y5} L ${x6} ${
                containerHeight - y6
              }`}
            /> */}
          </g>
          <path
            id={`text-path${index}`}
            d={`M ${x1} ${containerHeight - y1} L ${x2} ${
              containerHeight - y2
            }`}
            stroke="#FF8617"
            strokeWidth={2}
          />

          <g
          // transform={`translate(${100},${100})`}
          // transform={`translate(${text_x_shift - x1 + x6 - x5},${
          //   -(text_y_shift - y1) + y6 - y5
          // })`}
          // transform={`translate(${text_x_shift - x1}, ${text_y_shift - y1})`}
          >
            <text>
              <textPath
                href={`#text-path${index}`}
                startOffset="50%"
                // alignmentBaseline="top"
                textAnchor="middle"
                fill="white"
                style={{ fontWeight: 'bold', fontSize: '12px' }}
                // filter="url(#goo)"
              >
                {distance.distance + 'M'}
              </textPath>
            </text>
          </g>

          {/* <text
            transform={`translate(${-containerPadding},${-containerPadding}) rotate(90deg) translate(${containerPadding},${containerPadding}) translate(${
              (x2 + x1) / 2
            },${(containerHeight - y6 + containerHeight - y5) / 2 + 5})`}
            textAnchor="middle"
            fill="white"
            style={{ fontWeight: 'bold', fontSize: '12px' }}
          >
            {distance.distance + 'M'}
          </text> */}
        </React.Fragment>
      );
    });
  }

  // console.log(containerWidth, containerHeight, containerPadding);

  // useEffect(() => {
  //   const x = 350 - metersToPixels(centerPosition.x + xOffset);
  //   const y = 400 - metersToPixels(centerPosition.z + yOffset);
  //   dispatch(
  //     setPosition({
  //       x: -x + containerPadding - metersToPixels(xOffset) - 60,
  //       z: -y + containerPadding - metersToPixels(yOffset) - 75,
  //     })
  //   );
  // }, [centerPosition]);

  const guides1 = useRef(null);
  const guides2 = useRef(null);
  const blueprintRef = useRef(null);
  const [zoom, setZoom] = useState(0.6);

  const [centerXPos, setCenterXPos] = useState(
    metersToPixels(centerPosition.x + xOffset) +
      containerPadding -
      (blueprintRef.current as any)?.wrapperElement?.clientWidth / zoom / 2
  );

  const [centerYPos, setCenterYPos] = useState(
    metersToPixels(centerPosition.z + yOffset) +
      containerPadding -
      (blueprintRef.current as any)?.wrapperElement?.clientHeight / zoom / 2
  );

  const [initialRender, setInitialRender] = useState(true);

  const [x, setX] = useState(centerXPos);
  const [y, setY] = useState(centerYPos);

  const [isDragging, setIsDragging] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  // Update rulers on positionChange
  useEffect(() => {
    if (guides1.current) {
      (guides1.current as any)?.scrollGuides(
        y - containerPadding - containerHeight + metersToPixels(yOffset)
      );
      (guides1.current as any)?.scroll(
        x - containerPadding - metersToPixels(xOffset)
      );
    }

    if (guides2.current) {
      (guides2.current as any)?.scrollGuides(
        x - containerPadding - metersToPixels(xOffset)
      );

      (guides2.current as any)?.scroll(
        y - containerPadding - containerHeight + metersToPixels(yOffset)
      );
    }
  }, [guides1.current, guides2.current, x, y]);

  // Initialize the center position
  useEffect(() => {
    if (blueprintRef.current) {
      setCenterXPos(
        metersToPixels(centerPosition.x + xOffset) +
          containerPadding -
          (blueprintRef.current as any)?.wrapperElement?.clientWidth / zoom / 2
      );

      setCenterYPos(
        metersToPixels(centerPosition.z + yOffset) +
          containerPadding -
          (blueprintRef.current as any)?.wrapperElement?.clientHeight / zoom / 2
      );
    }
  }, [blueprintRef.current, zoom]);

  // Scroll to initial position
  useEffect(() => {
    if (blueprintRef.current && initialRender && centerXPos && centerYPos) {
      (blueprintRef.current as any)?.scrollTo(centerXPos, centerYPos);
      setX(centerXPos);
      setY(centerYPos);
      setInitialRender(false);
    }
  }, [blueprintRef.current, initialRender, centerXPos, centerYPos]);

  const zoomIn = () => {
    let newZoom = zoom + (zoom > 1 ? 0.5 : 0.2);
    newZoom = Math.min(maxZoom, newZoom);
    setZoom(newZoom);
  };

  const zoomOut = () => {
    let newZoom = zoom - (zoom > 1 ? 0.5 : 0.2);
    newZoom = Math.max(minZoom, newZoom);
    setZoom(newZoom);
  };

  const gotoCenter = () => {
    (blueprintRef.current as any)?.scrollTo(centerXPos, centerYPos);
  };

  return (
    <div
      className="blueprint-container"
      onContextMenu={(e) => {
        // If the user right clicked inside the container
        // if (e.type === 'contextmenu') {
        //   e.preventDefault();
        //   onEmptyClick();
        // }
      }}
    >
      <div
        className="ruler horizontal guides"
        style={{ backgroundColor: '#09141d' }}
      >
        <Guides
          ref={guides1}
          type="horizontal"
          zoom={zoom}
          unit={100}
          backgroundColor="#09141d"
          textFormat={(v) => `${v / metersToPixels(1)}`}
          dragPosFormat={(v) => (-v / metersToPixels(1)).toFixed(2) + 'm'}
          // snaps={[1, 2, 3]}
          digit={1}
          style={{ height: '30px' }}
          rulerStyle={{
            left: '30px',
            width: 'calc(100% - 30px)',
            height: '100%',
          }}
          displayDragPos={true}
          onChangeGuides={({ guides }) => {
            // console.log('horizontal', guides);
          }}
          onDragStart={(e) => {
            // console.log('dragStart', e);
          }}
          onDrag={(e) => {
            // console.log('drag', e);
          }}
          onDragEnd={(e) => {
            // console.log('dragEnd', e);
          }}
        />
      </div>

      <div className="blue-body">
        <div className="ruler vertical guides">
          <Guides
            ref={guides2}
            type="vertical"
            backgroundColor="#09141d"
            zoom={zoom}
            unit={100}
            textFormat={(v) => `${-v / metersToPixels(1)}`}
            dragPosFormat={(v) => (v / metersToPixels(1)).toFixed(2) + 'm'}
            // snaps={[100, 200, 400]}
            rulerStyle={{
              // top: '30px',
              height: '100%',
              width: '30px',
            }}
            displayDragPos={true}
            onChangeGuides={({ guides }) => {
              // console.log('vertical', guides);
            }}
          />
        </div>
        <div
          className="infinite-container"
          onMouseDown={(e) => {
            setMousePos({ x: e.pageX, y: e.pageY });
            setIsDragging(true);
          }}
          onMouseMove={(e) => {
            if (isDragging) {
              const newX = x - (e.pageX - mousePos.x) / zoom;
              const newY = y - (e.pageY - mousePos.y) / zoom;
              setX(newX);
              setY(newY);

              setMousePos({ x: e.pageX, y: e.pageY });
              if (blueprintRef.current) {
                (blueprintRef.current as any).scrollTo(newX, newY);
              }
            }
          }}
          onMouseUp={() => {
            setIsDragging(false);
          }}
        >
          <ControlButtons
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            setTransform={() => {}}
            goToCenter={gotoCenter}
          />
          <InfiniteViewer
            ref={blueprintRef}
            className="viewer"
            useForceWheel
            // margin={0}
            // threshold={0}
            rangeX={[-1000, 1000]}
            rangeY={[-1000, 1000]}
            zoom={zoom}
            onScroll={(e) => {
              setX(e.scrollLeft);
              setY(e.scrollTop);
            }}
            onPinch={(e) => {
              let newZoom = zoom;
              if (Math.abs(e.distance) == 100) {
                newZoom += e.distance / 200;
              } else {
                newZoom += e.distance / 100;
              }
              newZoom = Math.max(0.5, newZoom);
              newZoom = Math.min(4, newZoom);
              setZoom(newZoom);
            }}
            displayHorizontalScroll
            displayVerticalScroll
            onDragEnd={(e) => {
              console.log('drag end', e);
            }}
          >
            {/* <div
              className="viewport"
              style={{
                width: '1200px',
                height: '800px',
                backgroundColor: 'green',
              }}
            >
              Target
            </div> */}
            <svg
              ref={svgRef}
              className={`${zoomToScale(zoom)}`}
              width={svgWidth}
              height={svgHeight}
            >
              <g
                transform={`translate(${containerPadding},${containerPadding})`}
              >
                <defs>
                  <linearGradient id="transparent-line1">
                    <stop offset="0%" stopColor="grey" stopOpacity="1" />
                    <stop offset="100%" stopColor="grey" stopOpacity="0" />
                  </linearGradient>
                </defs>
                <defs>
                  <linearGradient id="transparent-line2">
                    <stop offset="0%" stopColor="grey" stopOpacity="0" />
                    <stop offset="100%" stopColor="grey" stopOpacity="1" />
                  </linearGradient>
                </defs>
                {renderFOV}
                {renderPaths}
                {renderSvg}
                {renderDistance}
              </g>
            </svg>
          </InfiniteViewer>
        </div>
      </div>
    </div>
  );
};

export default BlueprintEditor;
