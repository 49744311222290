import React from 'react';
import './ControlBtns.css';

const ControlBtns = ({
  zoomIn,
  zoomOut,
  setTransform,
  goToCenter = () => {},
}) => {
  return (
    <div className="control-btns">
      <div onClick={zoomIn}>
        <img src="/blueprints/zoom-in.png" alt="zoom in" />
      </div>

      <div onClick={zoomOut}>
        <img src="/blueprints/zoom-out.png" alt="zoom out" />
      </div>

      {/* <div onClick={() => setTransform(-2500, -2500, 1)}> */}
      {/* <div onClick={() => setTransform(0, 0, 1)}> */}
      <div onClick={goToCenter}>
        <img src="/blueprints/center.png" alt="center" />
      </div>
    </div>
  );
};

export default ControlBtns;
