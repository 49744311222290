import './BacklotLibrary.css';
import Home from './components/home/Home';
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSearchFilters,
  setLibraryVersion,
  subscribeFavoriteItemIds,
  unsubscribeFavoriteItemIds,
} from 'redux/slices/items/actions';
import CategoryLibrary from './components/category-library/CategoryLibrary';
import TeamLibrary from './components/team-library/TeamLibrary';
import {
  selectedProjectIdSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import Gallery from './components/gallery/Gallery';
import { libraryVersionSelector } from 'redux/slices/items/selectors';
import { fetchPaymentInfo } from 'redux/slices/app/actions';

export function BacklotLibrary({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const driveId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const params = queryString.parse(history.location.search);
  const libVersion = useSelector(libraryVersionSelector);

  useEffect(() => {
    if (driveId) {
      dispatch(fetchPaymentInfo(driveId));
    }
  }, [dispatch, driveId]);

  useEffect(() => {
    if (projectId) dispatch(subscribeFavoriteItemIds(projectId));
    return () => {
      if (projectId) dispatch(unsubscribeFavoriteItemIds(projectId));
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    if (libVersion) {
      dispatch(fetchSearchFilters(libVersion));
    }
  }, [dispatch, libVersion]);
  useEffect(() => {
    if (params?.libVersion) {
      dispatch(setLibraryVersion(params?.libVersion as string));
    }
  }, [dispatch, params?.libVersion]);

  return (
    <div id="backlot-library">
      <Switch>
        <Route path={`${match.url}/home`}>
          <Home />
        </Route>

        <Route path={`${match.url}/store/:itemType`}>
          <CategoryLibrary />
        </Route>

        <Route path={`${match.url}/store`}>
          <CategoryLibrary />
        </Route>

        <Route path={`${match.url}/team-library`}>
          <TeamLibrary />
        </Route>

        <Route path={`${match.url}/gallery`}>
          <Gallery />
        </Route>

        <Route path={`${match.url}`}>
          <Redirect to={`${match.url}/home`} />
        </Route>

        <Route path={`*`}>
          <Redirect to={`/not-found`} />
        </Route>
      </Switch>
    </div>
  );
}
