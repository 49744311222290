import { Action } from 'redux/common/types';
import { characterAction } from './types';

export const setCharacterId = (id): Action => ({
  type: characterAction('SET', 'CHARACTER_ID'),
  payload: id,
});

export const fetchOptionTypes = (libVersion, characterId): Action => ({
  type: characterAction('FETCH', 'OPTIONS_TYPES'),
  payload: { libVersion, characterId },
  meta: {
    cloudRequest: {
      name: 'getClothTypes',
      body: { library_version: libVersion, base_character: characterId },
      onSuccessDispatches: [setOptionTypes],
      onFailDispatches: [fetchOptionTypesFail],
    },
  },
});

export const setOptionTypes = (options): Action => ({
  type: characterAction('SET', 'OPTIONS_TYPES'),
  payload: options,
});

export const fetchOptionTypesFail = (err): Action => ({
  type: characterAction('FETCH', 'OPTIONS_TYPES'),
  payload: err,
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to fetch character options',
      description: err,
    },
  },
});

export const fetchOptions = (
  libVersion,
  characterId,
  optionType,
  teamId
): Action => ({
  type: characterAction('FETCH', 'OPTIONS'),
  payload: { libVersion, characterId, clothType: optionType, teamId },
  meta: {
    cloudRequest: {
      name: 'getAllItemsLibrary',
      body: {
        itemType: 'ClothingItems',
        library_version: libVersion,
        base_character: characterId,
        clothing_type: optionType,
        teamId,
      },
      onSuccessDispatches: [addOptions(optionType)],
    },
  },
});

export const addOptions =
  (optionType) =>
  (options): Action => ({
    type: characterAction('ADD', 'OPTIONS'),
    payload: { optionType, options },
  });

export const setSelectedOption = (optionType, value): Action => ({
  type: characterAction('SET', 'SELECTED_OPTION'),
  payload: { optionType, value },
});

export const fetchBaseCharacters = (libVersion): Action => ({
  type: characterAction('FETCH', 'BASE_CHARACTERS'),
  payload: libVersion,
  meta: {
    cloudRequest: {
      name: 'getAllItemsLibrary',
      body: {
        library_version: libVersion,
        itemType: 'BaseCharacterItems',
      },
      onSuccessDispatches: [addBaseCharacters],
      onFailDispatches: [fetchBaseCharactersFail],
    },
  },
});

export const addBaseCharacters = (characters): Action => ({
  type: characterAction('SET', 'BASE_CHARACTERS'),
  payload: characters,
});

export const fetchBaseCharactersFail = (err): Action => ({
  type: characterAction('FETCH', 'BASE_CHARACTERS', 'FAIL'),
  payload: err,
});
