import React from 'react';
import { Tooltip } from 'antd';
import { ChromePicker } from 'react-color';

export const ColorPicker = ({ color, onChange, disableAlpha = false }) => {
  return (
    <div>
      <Tooltip
        className="color-picker"
        overlay={
          <ChromePicker
            disableAlpha={disableAlpha}
            color={color ?? { r: 255, g: 255, b: 255, a: 1 }}
            onChange={(color) => onChange(color.rgb)}
          />
        }
        style={{ padding: '0px' }}
        color="white"
        trigger="click"
      >
        <div
          className="color-picker-box"
          style={{
            backgroundColor: `rgba(${color?.r ?? 255},${color?.g ?? 255},${
              color?.b ?? 255
            },${color?.a ?? 1})`,
          }}
        ></div>
      </Tooltip>
    </div>
  );
};
