import './BacklotExport.scss';
import React, { useEffect, useState } from 'react';
import {
  IoIosCheckmarkCircle,
  IoMdArrowDropdown,
  IoMdArrowDropleft,
} from 'react-icons/io';
import { FaFile, FaFolder, FaLink, FaRegCircle } from 'react-icons/fa';
import CheckMark from 'backlot-scene-settings/components/common/check-mark/CheckMark';
import { useDispatch, useSelector } from 'react-redux';
import { toasterAction } from 'redux/common/actions';
import firebase from 'firebase-main';
import { dispatchEvents } from 'redux/middlewares/amplitude';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import Loader from 'common/components/loader/Loader';

interface IExport {
  id: string;
  name: string;
  isExported: boolean;
  link?: string;
  runtime?: string;
  camera?: string;
  lens?: string;
  rig?: string;
  aperture?: string;
  shutter?: string;
  iso?: string;
  temperature?: string;
}

const BacklotExport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userId = useSelector(userIdSelector);
  const [collapsed, setCollapsed] = useState({});
  const [resolution, setResolution] = useState('1080p');
  const [fps, setFps] = useState('24fps');
  const [exports, setExports] = useState<IExport[]>([]);
  const [showCamEquipment, setShowCamEquipment] = useState(false);
  const [headerLink, setHeaderLink] = useState(
    // 'www.share.backlot.studio/jh12uhja7'
    ''
  );

  const [uploadProgress, setUploadProgress] = useState<{
    [id: string]: number;
  }>({});

  const [isCompleted, setIsCompleted] = useState(false);

  const [completionDate, setCompletionDate] = useState('');

  const readyExports = exports.filter(({ isExported }) => isExported);

  const addExport = (e) => {
    if (isCompleted) return;
    const exports = (JSON.parse(e.params.exports) as IExport[]) ?? [];
    // const newExports = [...exports];
    // const index = exports.findIndex(({ id }) => id === exp.id);
    // // if the id is already available
    // if (index !== -1) {
    //   newExports[index] = exp;
    // } else {
    //   newExports.push(exp);
    // }
    setExports(exports);
    setLoading(false);
  };

  const removeExport = (e) => {
    const removedId = e.params.id;
    const newExports = [...exports];
    const index = exports.findIndex(({ id }) => id === removedId);
    if (index !== -1) {
      newExports.splice(index, 1);
      setExports(newExports);
    }
  };

  const setCompleted = (e) => {
    const { value } = e.params;
    setIsCompleted(value);
  };

  const onHeaderLinkChange = (e) => {
    setHeaderLink(e.params.link);
  };

  const setExportUpload = (e) => {
    const { id, progress } = e.params;
    setUploadProgress({ ...uploadProgress, [id]: progress });
  };

  useEffect(() => {
    window.addEventListener('addExport', addExport);
    // window.addEventListener('setCompleteExports', setCompleted);
    window.addEventListener('removeExport', removeExport);
    window.addEventListener('setExportLink', onHeaderLinkChange);
    window.addEventListener('setExportProgress', setExportUpload);

    return () => {
      window.removeEventListener('addExport', addExport);
      window.removeEventListener('setCompleteExports', setCompleted);
      window.removeEventListener('removeExport', removeExport);
      window.removeEventListener('setExportLink', onHeaderLinkChange);
      window.removeEventListener('setExportProgress', setExportUpload);
    };
  }, [exports, uploadProgress, isCompleted]);

  // Prevent form submission on pressing space
  useEffect(() => {
    window.onkeydown = (e) => {
      if (e.keyCode === 32) {
        e.preventDefault();
      }
    };
    return () => {
      window.onkeydown = () => {};
    };
  }, []);

  const toggleIsExported = (exportId) => {
    const newExports = [...exports];
    const index = exports.findIndex(({ id }) => id === exportId);
    if (index !== -1) {
      const updatedExport = { ...exports[index] };
      updatedExport.isExported = !updatedExport.isExported;
      newExports[index] = updatedExport;
      setExports(newExports);
    }
  };

  const onExport = () => {
    const exportIds = readyExports.map(({ id }) => id).join();
    (window as any).onStartExport(resolution, fps, exportIds, showCamEquipment);
    firebase.analytics().logEvent('export_clips', {
      resolution,
      fps,
      exportIds,
      showCamEquipment,
    });
    dispatchEvents([
      {
        event_type: 'export_clip',
        user_id: userId,
        event_properties: {
          resolution,
          fps,
          exportIds,
          showCamEquipment: showCamEquipment + '',
        },
      },
    ]);
  };

  const onNewExport = () => {
    (window as any).onNewExport();
    setIsCompleted(false);
  };

  const copyStrToClipboard = (str) => {
    navigator.clipboard.writeText(str);
  };

  const renderCompleteSign = (
    <>
      <img src="/exports/complete.png" />
      <h1>Export Complete</h1>
      <h2>{completionDate}</h2>
    </>
  );

  const renderExportProgress = (id) => {
    if (isCompleted && uploadProgress[id]) {
      if (uploadProgress[id] === -1)
        return (
          <button
            className="retry"
            onClick={(e) => {
              e.stopPropagation();
              (window as any).onExportRetry(id);
            }}
          >
            Retry
          </button>
        );
      if (uploadProgress[id] < 100)
        return ` (Uploading ${uploadProgress[id]}%)`;
      if (uploadProgress[id] === 100) return ' (Upload Complete)';
    }
  };

  const renderExports = (isCompleted ? readyExports : exports).map(
    ({
      id,
      name,
      isExported,
      link,
      runtime = '-',
      camera = '-',
      lens = '-',
      rig = '-',
      aperture = '-',
      shutter = '-',
      iso = '-',
      temperature = '-',
    }) => (
      <div
        key={id}
        className={`export ${isExported ? 'checked' : ''} ${
          collapsed[id] ? 'collapsed' : ''
        } ${isCompleted ? 'completed' : ''}`}
        onClick={() => {
          setCollapsed({ ...collapsed, [id]: !collapsed[id] });
        }}
      >
        <div className="export-header">
          <div
            className="check-mark"
            onClick={(e) => {
              e.stopPropagation();
              if (!isCompleted) toggleIsExported(id);
            }}
          >
            {isExported ? <IoIosCheckmarkCircle /> : <FaRegCircle />}
          </div>
          <div className="name">
            {name}
            <span className="upload-progress">{renderExportProgress(id)}</span>
          </div>
          {isCompleted && (
            <FaLink
              className="link-icon"
              onClick={(e) => {
                e.stopPropagation();
                if (link) copyStrToClipboard(link);
              }}
            />
          )}
          <div className="drop-arrow">
            {!collapsed[id] ? (
              <IoMdArrowDropdown size={22} />
            ) : (
              <IoMdArrowDropleft size={22} />
            )}
          </div>
        </div>

        <div className="export-body">
          <div>
            <p>
              <span>Runtime</span>
              {` ${runtime}`}
            </p>
            <p>
              <span>Camera</span>
              {` ${camera}`}
            </p>
            <p>
              <span>Lens</span>
              {` ${lens}`}
            </p>
            <p>
              <span>Rig</span>
              {` ${rig}`}
            </p>
          </div>

          <div>
            <p>
              <span>Aperture</span>
              {` ${aperture}`}
            </p>
            <p>
              <span>Shutter</span>
              {` ${`1 / ${Math.round(1 / +shutter)}`} `}
            </p>
            <p>
              <span>ISO</span>
              {` ${iso}`}
            </p>
            <p>
              <span>Temperature</span>
              {` ${temperature}`}
            </p>
          </div>
        </div>
      </div>
    )
  );

  const renderIncompleteFooter = (
    <>
      <div>
        <CheckMark
          label="Show camera & light equipment?"
          checked={showCamEquipment}
          onChange={setShowCamEquipment}
        />
      </div>
      <div>
        <div className="resolution">
          <select
            className="res-select"
            onChange={(e) => {
              setResolution(e.target.value);
            }}
            value={resolution}
          >
            <option value="480p">480p</option>
            <option value="720p">720p</option>
            <option value="1080p">1080p</option>
            <option value="2K">2K</option>
            <option value="4K">4K</option>
          </select>
        </div>
        <div className="fps">
          <select
            className="fps-select"
            onChange={(e) => {
              setFps(e.target.value);
            }}
            value={fps}
          >
            <option value="24fps">24fps</option>
            {/* <option value="30fps">30fps</option> */}
            {/* <option value="60fps">60fps</option> */}
          </select>
        </div>
        <div className="export-btn">
          {/* <button
            onClick={() => {
              (window as any).onOpenBehindTheScenesCamera();
            }}
            style={{ marginRight: '1rem' }}
          >{`Open 'Behind the scenes' camera`}</button> */}

          <button
            disabled={readyExports.length <= 0}
            onClick={(e) => {
              e.preventDefault();
              onExport();
            }}
          >{`Export ${readyExports.length} Shot${
            readyExports.length !== 1 ? 's' : ''
          }`}</button>
        </div>
      </div>
    </>
  );

  const renderCompleteFooter = (
    <div className="export-btn">
      <button onClick={onNewExport}>New Export</button>
    </div>
  );

  const renderFooter = isCompleted
    ? renderCompleteFooter
    : renderIncompleteFooter;

  return (
    <Loader show={loading} fullscreen>
      <div className="exports">
        <div className="save-btn" onClick={() => (window as any).onOpenFile()}>
          <FaFolder size={10} />
        </div>
        <div className="exports-header">
          <h1>Export</h1>
          {/* <a
          href="#"
          onClick={() => {
            if (headerLink) copyStrToClipboard(headerLink);
          }}
        >
          {headerLink}
        </a> */}
          <div className="copy-link" hidden={!headerLink || !isCompleted}>
            <img src="/exports/link.svg" alt="link" />
            <div className="url-link">{headerLink}</div>
            <button
              className={`copy-btn`}
              onClick={() => {
                copyStrToClipboard(headerLink);
                (window as any).onLinkClicked(headerLink);
              }}
            >
              Open
            </button>
          </div>
        </div>

        {isCompleted && (
          <div className="complete-sign">{renderCompleteSign}</div>
        )}

        <div className="exports-body scroll-bar">{renderExports}</div>

        <div className={`exports-footer ${isCompleted ? 'completed' : ''}`}>
          {renderFooter}
        </div>
      </div>
    </Loader>
  );
};

export default BacklotExport;
