import { realtimeDbActionTypes } from 'redux/common/types';

const namespace = 'stripe';

type stripeActionsTypes =
  | realtimeDbActionTypes
  | 'ADD'
  | 'REMOVE'
  | 'CLEAR'
  | 'CHECK'
  | 'PURCHASE'
  | 'CONFIRM';

type stripeResource =
  | 'PAYMENT'
  | 'PAYMENT_METHOD'
  | 'PAYMENT_ACTION'
  | 'PAYMENT_INFO'
  | 'LICENSE_PRICES'
  | 'PERSONAL_DRIVE'
  | 'TEAM_DRIVE'
  | 'DRIVES_PRICES'
  | 'TEAM_EDITOR_LICENSE'
  | 'TEAM_REVIEWER_LICENSE'
  | 'TEAM_MEMBER';

type actionStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export interface StripeSlice {
  paymentAction: paymentAction | null;
  paymentInfo: {
    [teamId: string]: paymentInfo;
  };
  teamDrivePrice: number;
  personalDrivePrice: number;
  licensePrices: {
    teamId: string;
    cost: number;
    licenses: number;
    type: 'Editor' | 'Reviewer';
  };
  loaders: {
    purchaseTeamEditorLicensePending: boolean;
    purchaseTeamReviewerLicensePending: boolean;
    addTeamMemberPending: boolean;
    licensePricePending: boolean;
  };
}

export type paymentAction = {
  message?: string;
  client_secret?: string;
  paymentMethodId?: string;
  responseToken?: string;
};

export type paymentInfo = {
  balance?: number;
  wallet?: number;
  number_of_editor_licenses?: number;
  number_of_reviewer_licenses?: number;
  number_of_active_editors?: number;
  number_of_active_reviewers?: number;
  card?: {
    brand?: string;
    country?: string;
    funding?: string;
    last4?: string;
  };
  end_date?: number;
  is_active?: boolean;
  number_of_active_users?: number;
};

export const stripeAction = (
  type: stripeActionsTypes,
  resource?: stripeResource,
  status?: actionStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
