import './AddCreditModal.scss';
import { Button, InputNumber, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import firebase from 'firebase-main';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterAction } from 'redux/common/actions';
import { fetchPaymentInfo } from 'redux/slices/stripe/actions';
import { fetchInvoices } from 'redux/slices/team/actions';
import { useStripe } from '@stripe/react-stripe-js';

const AddCreditModal = ({ onCancel = () => {}, teamId }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [value, setValue] = useState(10);
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    console.log(value * 100);

    setLoading(true);

    try {
      const { data } = await firebase
        .functions()
        .httpsCallable('chargeBalance')({
        teamId,
        amount: value * 100,
      });
      // if (paymentAction && paymentAction.client_secret) {
      //   stripe
      //     ?.confirmCardPayment(paymentAction?.client_secret, {
      //       payment_method: paymentAction.paymentMethodId,
      //     })
      //     .then(() => {
      //       dispatch(confirmTeamDrivePayment(paymentAction.responseToken));
      //     });
      // }
      if (data?.message === 'requires_action') {
        if (
          data?.client_secret &&
          data?.paymentMethodId &&
          data?.responseToken
        ) {
          await stripe?.confirmCardPayment(data.client_secret, {
            payment_method: data.paymentMethodId,
          });

          await firebase
            .functions()
            .httpsCallable('chargeBalanceConfirmationResponse')({
            responseToken: data.responseToken,
          });

          // .then(() => {
          //   dispatch(confirmTeamDrivePayment(paymentAction.responseToken));
          // });
        } else {
          dispatch(toasterAction('error', 'Failed to confirm payment'));
          setLoading(false);
          dispatch(fetchInvoices(teamId));
          return;
        }
      }

      setLoading(false);
      dispatch(fetchPaymentInfo(teamId));
      dispatch(fetchInvoices(teamId));
      onCancel();
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      dispatch(toasterAction('error', 'Failed to charge', err));
    }
  };
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="add-credit-modal"
      visible
      onCancel={onCancel}
      title="Add Credit"
      footer={null}
    >
      <div>
        <p>Your current payment method will be used for adding credit</p>

        <InputNumber
          value={value}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => +(value?.replace(/\$\s?|(,*)/g, '') ?? '0')}
          min={1}
          onChange={(e) => {
            let newValue = e ? +e : 0;
            setValue(newValue);
          }}
        />

        <Popconfirm
          title={`Are you sure you want to add $ ${value} to your wallet?`}
          onConfirm={onSubmit}
          okText="Yes"
          placement="bottom"
        >
          <Button
            className="pay-btn"
            type="primary"
            loading={loading}
            // onClick={onSubmit}
          >
            Pay
          </Button>
        </Popconfirm>
      </div>
    </Modal>
  );
};

export default AddCreditModal;
