import { Action } from 'redux/common/types';
import { curvesAction } from './types';

export const setCurves = (curves): Action => ({
  type: curvesAction('SET', 'CURVES'),
  payload: curves,
});

export const setXRange = (min, max): Action => ({
  type: curvesAction('SET', 'X_RANGE'),
  payload: { min, max },
});

export const setYRange = (min, max): Action => ({
  type: curvesAction('SET', 'Y_RANGE'),
  payload: { min, max },
});

export const setWidth = (width): Action => ({
  type: curvesAction('SET', 'SCREEN_WIDTH'),
  payload: width,
});

export const setHeight = (height): Action => ({
  type: curvesAction('SET', 'SCREEN_HEIGHT'),
  payload: height,
});
