import firebase from 'firebase-main';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {
  updatePaymentMethodSuccess,
  updatePaymentMethodFail,
} from 'redux/slices/stripe/actions';
import ReactStripe from '../ReactStripe/ReactStripe';

const EditPaymentModal = ({ teamId }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [updatePaymentMethodPending, setUpdatePaymentMethodPending] =
    useState(false);
  // const updatePaymentMethodPending = useSelector(
  //   updatePaymentMethodPendingSelector
  // );

  const onSubmit = (paymentMethodId) => {
    const body = {
      teamId,
      paymentMethodId,
    };
    setUpdatePaymentMethodPending(true);
    firebase
      .functions()
      .httpsCallable('updatePaymentMethod')(body)
      .then((res) => {
        dispatch(updatePaymentMethodSuccess(res.data));
        setUpdatePaymentMethodPending(false);
        setShowModal(false);
      })
      .catch((error) => {
        dispatch(updatePaymentMethodFail(error));
        setUpdatePaymentMethodPending(false);
      });
    // dispatch(updatePaymentMethod(teamId, paymentMethodId, done));
  };

  const done = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {showModal && (
        <Modal
          transitionName=""
          maskTransitionName=""
          title="Edit payment method"
          visible
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <ReactStripe
            submitBtnText="Change"
            onSubmit={onSubmit}
            onFail={updatePaymentMethodFail}
            onSuccess={() => {}}
            processing={updatePaymentMethodPending}
          />
        </Modal>
      )}

      <button
        className="btn btn-info"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Update Payment Method
      </button>

      {/* <span className="aqua-link" onClick={() => setShowModal(true)}>
        {' '}
        change{' '}
      </span> */}
    </React.Fragment>
  );
};

export default EditPaymentModal;
