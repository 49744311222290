import produce from 'immer';
import{ downlodsActionTypes } from './actions';

export const DownloadsReducer = produce((state = [], action) => {
    switch (action.type) {
      case downlodsActionTypes.ADD_DOWNLOAD_ITEM : {
        const itemIndex = state.findIndex(({ id }) => id === action.payload.id);
        if (itemIndex === -1)
            state.push(action.payload);
        else
            state[itemIndex] = action.payload;  
        return state;      
      }
      case downlodsActionTypes.TOGGLE_PAUSE : {
        const itemIndex = state.findIndex(({ id }) => id === action.payload.id);
        state[itemIndex].paused = !state[itemIndex].paused;
        return state;
      }
      default: return state;
    }
})