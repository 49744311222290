import React, { useState } from 'react';
import { CreateProjectModal } from '../../modals/newProjectModal/create-scene-modal/CreateProjectModal';
import { useDispatch } from 'react-redux';

const NewProject = () => {
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);

  return (
    <React.Fragment>
      <div
        className="widebar-container-content-box-open-text"
        onClick={() => {
          setShowNewProjectModal(true);
        }}
      >
        + New Project
      </div>

      {showNewProjectModal && (
        <CreateProjectModal onCancel={() => setShowNewProjectModal(false)} />
      )}
    </React.Fragment>
  );
};

export default NewProject;
