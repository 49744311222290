import './Members.css';
import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import MembersModal from 'backlot-menu/components/modals/membersModal/MembersModal';
import { useSelector } from 'react-redux';
import { inPersonalDriveSelector } from 'redux/slices/app/selectors';
import { SharePersonalProjectModal } from 'backlot-menu/components/share/personal-share/SharePersonalProjectModal';
import { ShareTeamProjectModal } from 'backlot-menu/components/share/team-share/ShareTeamProjectModal';

type propTypes = {
  members?: { id: string; name: string; thumbnail?: string }[];
  membersModalTitle: string;
  showAddMemberBtn?: boolean;
};

const Members = ({
  members = [],
  membersModalTitle,
  showAddMemberBtn = true,
}: propTypes) => {
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showShareProjectModal, setShowShareProjectModal] = useState(false);
  // const projectMembers = useSelector(projectMembersSelector);
  // const nonProjectMembers = useSelector(nonProjectMembersSelector);
  // const teamMembers = Object.values(useSelector(teamMembersSelector2) ?? {});

  const renderMembers = members.map((user, index) => {
    // if (index < 3) {
    if (true) {
      return (
        <Popup
          key={user.id}
          content={user.name}
          trigger={
            <div
              className="screen-top-icon"
              onClick={(e) => e.stopPropagation()}
            >
              <img className="screen-top-icon-element" src={user.thumbnail} />
            </div>
          }
          style={{ color: 'white', fontSize: '0.7vw' }}
          position="top center"
        />
      );
    } else {
      return (
        <Popup
          key={user.id}
          trigger={
            <div
              className="screen-top-icon"
              onClick={(e) => {
                e.stopPropagation();
                setShowMembersModal(true);
              }}
            >
              <div className="screen-top-icon-number-container screen-top-icon-element">
                <div className="screen-top-icon-number">
                  {' '}
                  + {members.length - 3}
                </div>
              </div>
            </div>
          }
          position="top right"
        />
      );
    }
  });

  return (
    <React.Fragment>
      {showShareProjectModal && inPersonalDrive && (
        <SharePersonalProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}

      {showShareProjectModal && !inPersonalDrive && (
        <ShareTeamProjectModal
          onClose={() => setShowShareProjectModal(false)}
        />
      )}
      <MembersModal
        title={membersModalTitle}
        visible={showMembersModal}
        onClose={() => setShowMembersModal(false)}
        members={members}
      />
      {/* <EditMembersModal
        visible={showAddPeopleModal}
        onClose={() => setShowAddPeopleModal(false)}
        title={selectedProjectName}
        members={teamMembers}
        nonMembers={nonProjectMembers}
        addMember={addProjectMember}
        removeMember={removeProjectMember}
      /> */}
      <div className="screen-top-icon-container">
        <div className="screen-top-profile-icon-container">
          {showAddMemberBtn && (
            <img
              className="new-member"
              src="/menu/new-member.svg"
              alt="new member"
              onClick={() => setShowShareProjectModal(true)}
            />
          )}
          {renderMembers}
        </div>
        {/* <div
          className="screen-top-icon-plus-container"
          onClick={() => setShowAddPeopleModal(true)}
        >
          <FontAwesomeIcon
            icon={faPlusSquare}
            className="screen-top-icon-plus icon"
          />
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Members;
