import { loginActionTypes } from 'redux/slices/auth/actions/login';
import { logoutActionTypes } from 'redux/slices/auth/actions/logout';

const initState = {
  loginPending: false,
  logoutPending: false,
};
export const authLoadersReducer = (state = initState, action) => {
  switch (action.type) {
    case loginActionTypes.LOGIN:
      return { ...state, loginPending: true };
    case loginActionTypes.LOGIN_SUCCESS:
    case loginActionTypes.LOGIN_FAIL:
      return { ...state, loginPending: false };

    case logoutActionTypes.LOGOUT:
      return { ...state, logoutPending: true };

    case logoutActionTypes.LOGOUT_SUCCESS:
    case logoutActionTypes.LOGOUT_FAIL:
      return { ...state, logoutPending: false };

    default:
      return state;
  }
};
