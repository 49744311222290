import { Action } from 'redux/common/types';
import { bundlesAction } from './types';

export const setBundles = (bundles): Action => ({
  type: bundlesAction('SET', 'BUNDLES'),
  payload: bundles,
});

export const removeBundle = (id): Action => ({
  type: bundlesAction('REMOVE', 'BUNDLE'),
  payload: id,
});
