import firebase from 'firebase-main';
import { restartApp } from 'redux/slices/app/actions';
import {
  loginActionTypes,
  loginFail,
  loginSuccess,
} from 'redux/slices/auth/actions/login';
import {
  logoutActionTypes,
  logoutFail,
  logoutSuccess,
} from 'redux/slices/auth/actions/logout';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { selectedProjectIdSelector } from 'redux/slices/app/selectors';

export const authMiddleware = (store) => (next) => async (action) => {
  next(action);

  if (action.type === loginActionTypes.LOGIN) {
    const { email = '', password = '' } = action.payload;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        store.dispatch(restartApp());
        store.dispatch(loginSuccess(res.user.uid));
        // firebase.analytics().setUserId(res.user.uid);
        // firebase.analytics().setUserProperties({ email });
      })
      .catch((err) => {
        store.dispatch(loginFail(err.message));
      });
  } else if (action.type === logoutActionTypes.LOGOUT) {
    const userId = userIdSelector(store.getState());
    const projectId = selectedProjectIdSelector(store.getState());

    try {
      if (userId && projectId) {
        await firebase
          .database()
          .ref(`online/${projectId}/${userId}/is_online`)
          .off();

        await firebase
          .database()
          .ref(`online/${projectId}/${userId}`)
          .update({ is_online: false });
      }
    } catch (err) {
      console.error(err);
    }

    firebase
      .auth()
      .signOut()
      .then(() => {
        store.dispatch(logoutSuccess());
        window.loggedOut();
        store.dispatch(restartApp());
      })
      .catch((err) => {
        store.dispatch(logoutFail(err.message));
      });
  }
};
