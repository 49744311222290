import './NotAvailable.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { themeSelector } from 'redux/slices/app/selectors';

const NotAvailable = () => {
  const theme = useSelector(themeSelector);
  return (
    <div className={`not-available ${theme}`}>
      <h1>You need to be either an editor or an admin to access this team</h1>
    </div>
  );
};

export default NotAvailable;
