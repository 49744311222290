import { mainActionTypes } from 'redux/common/types';

const namespace = 'character';

type characterActionsTypes = mainActionTypes;

type characterResource =
  | 'OPTIONS'
  | 'OPTIONS_TYPES'
  | 'SELECTED_OPTION'
  | 'CHARACTER_ID'
  | 'BASE_CHARACTERS';
//   | 'SHOES'
//   | 'ACCESSORY'
//   | 'JACKET'
//   | 'PANTS'
//   | 'HAIR'
//   | 'SHIRT'
//   | 'UNDERWEAR';

type characterStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const characterAction = (
  type: characterActionsTypes,
  resource?: characterResource,
  status?: characterStatus
) => {
  let action = `${namespace}/${type}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};

export type CharacterSlice = {
  characterId: string | null;
  optionTypes: { [id: string]: { name: string } } | null;
  options: {
    [optionName: string]: {
      [id: string]: {
        base_character: string;
        itemID: string;
        name: string;
        thumbnail?: string;
        packIDs: any[];
      };
    };
  };
  selectedOptions: {
    [optionName: string]: any;
  };
  baseCharacters: {
    [characterId: string]: {
      itemID: string;
      name: string;
      subcategory: string;
      thumbnail: string;
    };
  } | null;
};
