import React from 'react';

const FullscreenIcon = ({ className = '', size = 12 }) => {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 24 24"
      height={`${size}px`}
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={1}
    >
      <path d="M5 5L10 5 10 3 3 3 3 10 5 10zM10 19L5 19 5 14 3 14 3 21 10 21zM21 14L19 14 19 19 14 19 14 21 21 21zM19 10L21 10 21 3 14 3 14 5 19 5z"></path>
    </svg>
  );
};

export default FullscreenIcon;
