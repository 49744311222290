import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
  setProject,
  setSelectedProjectId,
  subscribeProject,
} from 'redux/slices/app/actions';
import {
  projectsArraySelector,
  recentScenesSelector,
  themeSelector,
  userRoleInTeam,
} from 'redux/slices/app/selectors';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import ScenesGrid from '../project/scenes/grid/ScenesGrid';
import ScenesList from '../project/scenes/list/ScenesList';
import { TopBar } from '../project/TopBar';

const RecentScenes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //   const scenes = useSelector(userRecentScenesArraySelector);
  const userId = useSelector(userIdSelector);
  const theme = useSelector(themeSelector);
  const userRole = useSelector(userRoleInTeam);
  const scenes = useSelector(recentScenesSelector);
  const projects = useSelector(projectsArraySelector);
  const [activeTab, setActiveTab] = useState(0);
  const [view, setView] = useState('grid');
  const [sceneOrderType, setSceneOrderType] = useState(0);

  useEffect(() => {
    dispatch(setSelectedProjectId(null));
    dispatch(setProject(null));
  }, [dispatch]);

  // Redirect to sample projects if there are no recent scenes
  useEffect(() => {
    if (scenes && scenes.length === 0) {
      if (projects && projects.length > 0) {
        let projectId: any = null;
        projects.forEach(({ id, is_public, members, name }) => {
          if (
            name === 'Sample Project' &&
            (is_public || Object.keys(members ?? {}).includes(userId))
          ) {
            projectId = id;
          }
        });

        if (projectId) {
          dispatch(setSelectedProjectId(projectId));
          dispatch(subscribeProject(projectId));
          history.push('/menu/project');
        }
      }
    }
  }, [dispatch, userId, scenes, projects]);

  let sortedScenes: typeof scenes | undefined = scenes;

  switch (sceneOrderType) {
    // Sort by recently updated
    case 0: {
      sortedScenes = scenes?.sort((scene1, scene2) => {
        return (scene2.date ?? 0) - (scene1.date ?? 0);
      });
      break;
    }

    // // Sort by scene name
    case 1: {
      sortedScenes = scenes?.sort((scene1, scene2) => {
        const a = ((scene1.name as string) ?? '').trim().substr(4).trim();
        const b = ((scene2.name as string) ?? '').trim().substr(4).trim();
        return a.localeCompare(b);
      });
      break;
    }
  }

  if (userRole === 'Reviewer') return <Redirect to="/menu/not-available" />;

  return (
    <div className={`screen ${theme}`}>
      <div className="screen-container">
        <TopBar
          title="Recents"
          members={null}
          view={view}
          setView={setView}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sceneOrderType={sceneOrderType}
          onSceneOrderTypeChange={setSceneOrderType}
          sceneOrderTypes={['Recently Updated', 'Scene Name']}
          showMembers={false}
          showProjectSettings={false}
          showShareBtn={false}
        />

        {view === 'grid' ? (
          <ScenesGrid
            scenes={sortedScenes ?? []}
            displayIndex={false}
            addSceneBtnPosition="top"
          />
        ) : (
          <ScenesList
            scenes={sortedScenes ?? []}
            displayIndex={false}
            addSceneBtnPosition="top"
          />
        )}
      </div>
    </div>
  );
};

export default RecentScenes;
