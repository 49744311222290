import './SwitchAccountModal.scss';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  inPersonalDriveSelector,
  personalDriveIdSelector,
  personalDriveIsActiveSelector,
  personalDriveNameSelector,
  selectedTeamIsActiveSelector,
  teamsSelector,
} from 'redux/slices/app/selectors';
import {
  setSelectedProjectId,
  setSelectedSceneId,
  setSelectedTeamId,
  setProject,
} from 'redux/slices/app/actions';
import { useHistory } from 'react-router-dom';

const SwitchAccountModal = ({ onClose = () => {}, firstSelectedTeam = '' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedLicence, setSelectedLicence] = useState(-1);
  const teams = useSelector(teamsSelector) ?? [];
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  const personalDriveId = useSelector(personalDriveIdSelector);
  const personalDriveName = useSelector(personalDriveNameSelector);
  const personalDriveIsActive = useSelector(personalDriveIsActiveSelector);
  const selectedTeamIsActive = useSelector(selectedTeamIsActiveSelector);
  const isActive = inPersonalDrive
    ? personalDriveIsActive
    : selectedTeamIsActive;
  const licenses: {
    id: string;
    thumbnail?: string;
    name: string;
    role?: string;
  }[] = [];

  if (personalDriveId) {
    licenses.push({
      id: personalDriveId,
      // thumbnail: personalDrive?.public?.thumbnail,
      name: personalDriveName ?? 'Personal Account',
    });
  }

  teams.forEach(({ id, name = 'unknown' }, index) => {
    licenses.push({ id, name });
  });
  useEffect(() => {
    if (selectedLicence === -1)
      setSelectedLicence(
        licenses.findIndex(({ id }) => id === firstSelectedTeam)
      );
  }, [setSelectedLicence, selectedLicence, licenses, firstSelectedTeam]);
  const onLicenseSelect = () => {
    // if (personalDrive && selectedLicence === 0) {
    //   // TODO Select personal license
    //   dispatch(selectPersonalDrive());
    //   dispatch(setSelectedTeamId(null));
    //   dispatch(setSelectedProjectId(null));
    //   dispatch(setSelectedSceneId(null));
    //   history.push('/menu/recent');
    //   onClose();
    // } else if (selectedLicence >= 0 && selectedLicence < licenses.length) {
    dispatch(setSelectedProjectId(null));
    dispatch(setSelectedSceneId(null));
    dispatch(setProject(null));
    dispatch(setSelectedTeamId(licenses[selectedLicence].id));
    history.push('/menu/recent');
    onClose();
    // }
  };
  const onBillingSelect = () => {
    history.push(`/settings/${firstSelectedTeam}?tab=billing`);
    onClose();
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="switch-account-modal"
      visible
      title="Select your account"
      footer={null}
      onCancel={!isActive ? undefined : onClose}
    >
      <div className="licenses">
        {licenses.map(({ id, thumbnail, name, role }, index) => (
          <div
            key={id}
            className={`license ${selectedLicence === index ? 'selected' : ''}`}
            onClick={() => {
              setSelectedLicence(index);
            }}
          >
            <div
              className="thumbnail"
              style={{
                background: thumbnail ? `url('${thumbnail}')` : '#c4c4c4',
              }}
            />
            <div className="info">
              <div className="name">{name}</div>
              <div className="role">{role}</div>
            </div>
          </div>
        ))}
      </div>
      {/* {!isActive &&
      (selectedLicence === -1 ||
        firstSelectedTeam === licenses[selectedLicence]?.id) ? (
        <div
          style={{
            color: 'red',
          }}
        >
          This drive's subscription expired.
        </div>
      ) : null} */}
      <div className="open-btn">
        <button
          onClick={onLicenseSelect}
          disabled={
            selectedLicence === -1 ||
            (!isActive && firstSelectedTeam === licenses[selectedLicence]?.id)
          }
        >
          Open
        </button>
        {!isActive &&
        (selectedLicence === -1 ||
          firstSelectedTeam === licenses[selectedLicence]?.id) ? (
          <button
            onClick={onBillingSelect}
            style={{
              marginLeft: '15px',
            }}
          >
            Go To Billing
          </button>
        ) : null}
      </div>
    </Modal>
  );
};

export default SwitchAccountModal;
