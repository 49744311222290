import './DownloadModal.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';

const DownloadModal = ({
  show,
  item,
  handleClose = () => {},
  handleOk = () => {},
}) => {
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      centered
      className="download-modal"
      visible={show}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
    >
      <div className="download-header">
        <img
          className="cloud-icon"
          src={`/backlot-library/download.svg`}
          alt={`cloud icon to download item`}
        />
        <h1>Download Pack</h1>
      </div>

      <div className="download-body">
        <p>
          {/* The prop <em>{item.name}</em> is part of the pack
          <em>{' ' + item.subcategory}</em>. To access this prop, you must
          download the bundle. */}
          Using this item requires a download
        </p>
      </div>

      {/* <div className="download-subcategory">{item.subcategory}</div> */}
      <div className="download-subcategory">{item.name}</div>

      <div className="download-footer">
        <div className="download-cancel">
          <button onClick={handleClose}>Cancel</button>
        </div>
        <div className="download-confirm">
          <button onClick={handleOk}>Download Pack</button>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
