import {
  subscribeTeamImage,
  unsubscribePendingInvites,
  unsubscribeTeamImage,
} from 'redux/slices/team/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  personalDriveIdSelector,
  selectedTeamIdSelector,
  teamSelector,
} from 'redux/slices/app/selectors';
import { subscribePendingInvites } from 'redux/slices/team/actions';
import { fetchTeamMembersData } from 'redux/slices/app/actions';
import { usePrevious } from './usePrevious';
import { fetchPaymentInfo } from 'redux/slices/stripe/actions';

export const useFetchTeamInfo = () => {
  const dispatch = useDispatch();
  const personalDriveId = useSelector(personalDriveIdSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const team = useSelector(teamSelector);
  const prevTeam = usePrevious(team);

  useEffect(() => {
    if (teamId) {
      dispatch(subscribeTeamImage(teamId));
      // dispatch(fetchTeamMembers(teamId));
      dispatch(subscribePendingInvites(teamId));
      // dispatch(getPaymentInfo(teamId));
      // dispatch(getPaymentMethodId(teamId));
      if (teamId) dispatch(fetchPaymentInfo(teamId));
      if (personalDriveId) dispatch(fetchPaymentInfo(personalDriveId));
    }

    return () => {
      dispatch(unsubscribeTeamImage());
      dispatch(unsubscribePendingInvites());
    };
  }, [dispatch, teamId, personalDriveId]);

  useEffect(() => {
    if (team?.members && teamId && !prevTeam) {
      dispatch(fetchTeamMembersData(teamId, Object.keys(team.members)));
    }
  }, [dispatch, teamId, team, prevTeam]);
};
