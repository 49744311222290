import './TeamProjectTab.scss';
import React, { useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import {
  projectMembersSelector,
  projectSelector,
  selectedProjectIdSelector,
  selectedTeamIdSelector,
  teamMembersSelector2,
} from 'redux/slices/app/selectors';
import {
  addProjectMember,
  removeProjectMember,
  transferProjectOwnership,
  updateProjectAccess,
} from 'redux/slices/app/actions';
import firebase from 'firebase-main';

export const TeamProjectTab = () => {
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);

  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const project = useSelector(projectSelector);
  const teamMembers = useSelector(teamMembersSelector2) ?? {};
  const projectMembers = useSelector(projectMembersSelector) ?? {};
  const projectIsPublic = project?.is_public;
  const projectOwnerId = project?.owner_id;
  const projectOwnerName = projectMembers[projectOwnerId ?? '']?.name;

  const userIsOwner = userId === projectOwnerId;

  const [newMember, setNewMember] = useState<any>(null);

  let members = Object.values(projectIsPublic ? teamMembers : projectMembers);
  members = members.filter(({ id }) => id !== projectOwnerId);
  members = members.map((member) => ({
    ...member,
    role: teamMembers[member.id]?.role,
  }));
  let nonProjectMembers = Object.values(teamMembers).filter(
    ({ id }) => !projectMembers[id] && id !== projectOwnerId
  );

  const editors = members.filter(({ role = 2 }) => role < 2);
  const reviewers = members.filter(({ role }) => role === 2);

  const updateProjectType = (isPublic) => {
    if (isPublic !== projectIsPublic)
      dispatch(
        updateProjectAccess(userId, userName, teamId, projectId, isPublic)
      );
  };

  const removeMember = (userId) => {
    dispatch(removeProjectMember(teamId, projectId, userId));
  };

  const addMember = (userId, userName) => {
    if (userId && userName) {
      firebase
        .functions()
        .httpsCallable('permissionNotification')({
          id: projectId,
          type: 'project',
          newMembers: { [userId]: userName },
        })
        .catch();
      dispatch(addProjectMember(teamId, projectId, userId, userName));
      setNewMember(null);
    }
  };

  const transferOwnership = (userId) => {
    dispatch(transferProjectOwnership(projectId, userId));
  };

  const renderDropArrow = (
    <img className="drop-arrow" src="/menu/dropdown.svg" alt="dropdown arrow" />
  );

  const teamProject = (
    <div className="project-type">
      <img src="/menu/public-scene.svg" alt="team project" />
      <p>
        <b>Team Project.</b> All users on your team can access this project
      </p>
    </div>
  );

  const privateProject = (
    <div className="project-type">
      <img
        src="/menu/private-scene.svg"
        alt="private project"
        style={{ marginRight: '0.25rem' }}
      />
      <p>
        <b>Private Project.</b> Only users invited by the Project Owner can
        access this project
      </p>
    </div>
  );

  const projectTypeMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => updateProjectType(true)}>
        {teamProject}
      </Menu.Item>
      <Menu.Item key="1" onClick={() => updateProjectType(false)}>
        {privateProject}
      </Menu.Item>
    </Menu>
  );

  const editorsOptionsMenu = (userId) => (
    <Menu>
      {userIsOwner && (
        <Menu.Item key="0" onClick={() => transferOwnership(userId)}>
          Transfer Project Ownership
        </Menu.Item>
      )}
      {userIsOwner && projectIsPublic === false && (
        <Menu.Item key="1" onClick={() => removeMember(userId)}>
          Remove User
        </Menu.Item>
      )}
    </Menu>
  );

  const reviewersOptionsMenu = (userId) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          removeMember(userId);
        }}
      >
        Remove User
      </Menu.Item>
    </Menu>
  );

  const renderEditors = editors.map(({ id, name }) => (
    <div key={id} className="member">
      <div className="member-name">
        {name}
        {id === userId && ' (You)'}
      </div>
      <div className="member-role">Editor</div>
      {userIsOwner && (
        <Dropdown
          transitionName=""
          overlay={editorsOptionsMenu(id)}
          trigger={['click']}
        >
          {renderDropArrow}
        </Dropdown>
      )}
    </div>
  ));

  const renderReviewers = reviewers.map(({ id, name }) => (
    <div key={id} className="member">
      <div className="member-name">
        {name}
        {id === userId && ' (You)'}
      </div>
      <div className="member-role">Web View-Only</div>
      {userIsOwner && projectIsPublic === false && (
        <Dropdown
          transitionName=""
          overlay={reviewersOptionsMenu(id)}
          trigger={['click']}
        >
          {renderDropArrow}
        </Dropdown>
      )}
    </div>
  ));

  return (
    <div className="team-project-tab">
      <div className="project-type-dropdown">
        {projectIsPublic ? teamProject : privateProject}
        {userIsOwner && (
          <Dropdown
            transitionName=""
            overlay={projectTypeMenu}
            trigger={['click']}
          >
            {renderDropArrow}
          </Dropdown>
        )}
      </div>

      {projectIsPublic === false && userIsOwner && (
        <div className="add-member">
          <div className="member-text-box">
            <Select
              value={newMember}
              placeholder="Add team members"
              // mode="multiple"
              style={{ width: '100%' }}
              onChange={(id, selectedValuesArray) => setNewMember(id)}
              options={nonProjectMembers.map(({ id, name }) => {
                return {
                  label: name,
                  key: id,
                  value: id,
                };
              })}
            />
          </div>

          <div className="add-member">
            <button
              disabled={!newMember}
              onClick={() => {
                addMember(newMember, teamMembers[newMember].name);
              }}
            >
              Add
            </button>
          </div>
        </div>
      )}

      <div className="all-members">
        <div className="member">
          <div className="member-name">
            {/* {sceneOwnerName} */}
            {projectOwnerName}
            {projectOwnerId === userId && ' (You)'}
          </div>

          <div className="member-role">Owner</div>
          {/* {renderDropArrow} */}
        </div>

        <div className="editors">{renderEditors}</div>

        <div className="reviewers">{renderReviewers}</div>
      </div>
    </div>
  );
};
