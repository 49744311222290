import firebase from 'firebase-main';
import _ from 'lodash';
import { Action } from 'redux/common/types';

export const firebaseStorageApiMiddleware = (store) => (next) => (
  action: Action
) => {
  next(action);
  if (action.meta?.firebaseStorageApi) {
    const {
      url,
      req,
      onSuccessDispatches = [],
      onFailDispatches = [],
      onEmptyResponseDispatches = [],
    } = action.meta.firebaseStorageApi;
    switch (req) {
      case 'GET_LINK': {
        firebase
          .storage()
          .ref(url)
          .getDownloadURL()
          .then((res) => {
            if (res) {
              onSuccessDispatches.forEach((action) =>
                store.dispatch(action(res))
              );
            } else {
              onEmptyResponseDispatches.forEach((action) =>
                store.dispatch(action(res))
              );
            }
          })
          .catch((err) => {
            onFailDispatches.forEach((action) => store.dispatch(action(err)));
          });
        break;
      }
      default:
        break;
    }
  }
};
