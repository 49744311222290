import './PurchaseTeamModal.scss';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentActionPendingSelector,
  paymentActionSelector,
  teamDrivePriceSelector,
} from 'redux/slices/stripe/selectors';
import {
  registrationErrorSelector,
  registrationPendingSelector,
} from 'redux/slices/auth/authSelectors';
import {
  confirmTeamDrivePayment,
  purchaseTeamDrive,
} from 'redux/slices/stripe/actions';
import { setRegistrationPending } from 'redux/slices/auth/actions/register';

export const PurchaseTeamModal = ({ onClose = () => {} }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [formSubmitted, setFormSubmitted] = useState(false);

  const price = useSelector(teamDrivePriceSelector);

  const paymentAction = useSelector(paymentActionSelector);
  const paymentActionPending = useSelector(paymentActionPendingSelector);

  const registrationPending = useSelector(registrationPendingSelector);
  const registrationError = useSelector(registrationErrorSelector);

  useLayoutEffect(() => {
    if (paymentAction && paymentAction.client_secret) {
      stripe
        ?.confirmCardPayment(paymentAction?.client_secret, {
          payment_method: paymentAction.paymentMethodId,
        })
        .then(() => {
          dispatch(confirmTeamDrivePayment(paymentAction.responseToken));
        });
    }
  }, [paymentAction]);

  useEffect(() => {
    if (formSubmitted && !registrationPending) {
      //   if (!registrationError) history.push('/dashboard');
      //   else {
      // setFormSubmitted(false);
      //   }
    }
  }, [formSubmitted, registrationPending, registrationError]);

  const onFinish = async (values: any) => {
    dispatch(setRegistrationPending(true));
    setFormSubmitted(true);
    console.log('Success:', values);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as any,
    });

    if (error) {
      console.log('[error]', error);
      dispatch(setRegistrationPending(false));
      setFormSubmitted(false);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      const { name } = values;
      if (paymentMethod?.id) {
        dispatch(purchaseTeamDrive(name, paymentMethod?.id));
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setFormSubmitted(false);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="buy-team-modal"
      visible
      title="Purchase New Team Drive"
      footer={null}
      onCancel={onClose}
      maskClosable={false}
    >
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <div>
          <Form.Item
            label="Team Name"
            name="name"
            rules={[{ required: true, message: 'Please input team name!' }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            label="Credit Card"
            name="creditCard"
            rules={[
              {
                required: true,
                message: 'Please input your credit card info!',
              },
            ]}
          >
            <CardElement />
          </Form.Item>
        </div>

        <div>
          {/* <button type="submit">Purchase</button> */}
          <Form.Item className="submit">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!stripe}
              loading={formSubmitted && !!registrationPending}
            >
              Purchase
              <div className="price">
                <h1>{`$${price?.toFixed(2)}`}</h1>
              </div>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
