import './TeamSharePanel.scss';
import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { TeamSceneTab } from './team-scene-tab/TeamSceneTab';
import { useSelector } from 'react-redux';
import { sceneNameSelector } from 'redux/slices/app/selectors';

export const ShareTeamSceneModal = ({ onClose = () => {} }) => {
  const sceneName = useSelector(sceneNameSelector);
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="personal-share-modal"
      title={`Share ${sceneName}`}
      visible
      onCancel={onClose}
      footer={null}
    >
      <TeamSceneTab />
    </Modal>
  );
};
