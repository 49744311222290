import React, { useEffect } from 'react';
import _ from 'lodash';
import './Blueprint.css';

import BlueprintEditor from './zoom-pan-pinch/BlueprintEditor';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import {
  // fetchBlueprints,
  selectObject,
  setMeasuringObjects,
  deselectObject,
  hoverObject,
  unHoverObject,
  setSelectedOBjectTab,
  fetchThumbnails,
  subscribeBlueprintsLink,
  unsubscribeBlueprintsLink,
  clearBlueprints,
} from 'redux/slices/blueprints/actions';
import BlueprintSlider from './blueprint-slider/BlueprintSlider';
import {
  selectedObjectDataSelector,
  interpolatedSceneObjectsSelector,
  blueprintDimensionsSelector,
  objectsPathsSelector,
  selectedObjectIdSelector,
  scaleAndxPositionsSelector,
  scaleAndzPositionsSelector,
  distancesSelector,
  hoveredObjectDataSelector,
  hoveredObjectIdSelector,
  sceneObjectsThumbnailsSelector,
} from 'redux/slices/blueprints/selectors';
import { selectedSceneIdSelector } from 'redux/slices/app/selectors';
import BlueprintTree from './blueprint-tree/BlueprintTree';
import BlueprintInfo from './blueprint-info/BlueprintInfo';
import BlueprintRuler from './blueprint-ruler/BlueprintRuler';
// import useKeyPress from 'library/hooks/useKeyPress';
import { blueprintsPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import { setMode } from 'redux/slices/app/actions';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { metersToPixels } from './blueprint.config';
// import { lastVersionClipsSelector } from 'redux/slices/clips/selectors';
import { commentsArraySelector } from 'redux/slices/comments/selectors';
import {
  subscribeComments,
  unsubscribeComments,
} from 'redux/slices/comments/actions';
import Loader from 'common/components/loader/Loader';
import { svgTypes } from 'common/config/blueprints';
import useKeyPress from 'common/hooks/useKeyPress';
import { lastVersionClipsSelector } from 'redux/slices/clips/selectors';
import {
  subscribeToClips,
  unSubscribeToClips,
} from 'redux/slices/clips/actions';
import { userIdSelector } from 'redux/slices/auth/authSelectors';

const Blueprint = (props) => {
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const blueprintsPending = useSelector(blueprintsPendingSelector);

  const match = useRouteMatch();

  // const sceneId = useSelector(selectedSceneIdSelector);
  const sceneId = (match.params as any).sceneId;

  const blueprintDimensions = useSelector(blueprintDimensionsSelector);

  const containerWidth = metersToPixels(
    blueprintDimensions.offset.x + blueprintDimensions.max.x
  );
  const containerHeight = metersToPixels(
    blueprintDimensions.offset.z + blueprintDimensions.max.z
  );

  const selectedObjectId = useSelector(selectedObjectIdSelector);
  const hoveredObjectId = useSelector(hoveredObjectIdSelector);

  const selectedObjectData = useSelector(selectedObjectDataSelector);
  const hoveredObjectData = useSelector(hoveredObjectDataSelector);

  const sceneObjects = useSelector(interpolatedSceneObjectsSelector);
  const sceneObjectsThumbnails = useSelector(sceneObjectsThumbnailsSelector);
  const objectsPaths = useSelector(objectsPathsSelector);
  const distances = useSelector(distancesSelector);

  const clips = useSelector(lastVersionClipsSelector);
  const comments = useSelector(commentsArraySelector);

  const shiftKeyPressed = useKeyPress('Shift');

  const infoData = !_.isEmpty(hoveredObjectData)
    ? hoveredObjectData
    : selectedObjectData;

  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const objectIdUrl = params.objectId;

  // Fetching blueprint & comments
  useEffect(() => {
    if (userId && sceneId) {
      // dispatch(subscribeComments('SCENE_COMMENT', sceneId));
      dispatch(subscribeToClips(sceneId));
      // dispatch(fetchUsersForScene(userId, sceneId));
      dispatch(fetchThumbnails(sceneId));
      dispatch(subscribeBlueprintsLink(sceneId));
      return () => {
        // dispatch(unsubscribeComments('SCENE_COMMENT'));
        // dispatch(setComments('SCENE_COMMENT')({}));
        dispatch(unSubscribeToClips());
        dispatch(unsubscribeBlueprintsLink());
        dispatch(clearBlueprints());
      };
    }
  }, [dispatch, userId, sceneId]);

  useEffect(() => {
    if (objectIdUrl) {
      dispatch(selectObject(objectIdUrl));
      dispatch(setSelectedOBjectTab('comments'));
      dispatch(subscribeComments('OBJECT_COMMENT', sceneId));
    }
  }, [objectIdUrl, props.match.params]);

  useEffect(() => {
    if (props.match.params.objectId) {
      dispatch(selectObject(props.match.params.objectId));
      dispatch(setSelectedOBjectTab('comments'));
    }
    dispatch(subscribeComments('OBJECT_COMMENT', sceneId));
    return () => {
      dispatch(unsubscribeComments('OBJECT_COMMENT'));
    };
  }, [props.match.params]);

  useEffect(() => {
    if (sceneId) {
      dispatch(setMode('Blueprint'));
    }

    return () => {
      // dispatch(clearBlueprints());
    };
  }, [dispatch, sceneId]);

  //Fetch cameras thumbnails
  useEffect(() => {
    if (!_.isEmpty(sceneObjects)) {
      let cameras = sceneObjects.filter((obj) => obj.type === 'CAMERA');
      let camerasIds = cameras.map((camera) => {
        return { itemID: camera.itemLibraryID };
      });
      // dispatch(fetchThumbnails(sceneId));
    }
  }, [sceneObjects.length]);

  const onObjectClick = (objectId) => {
    if (objectId === selectedObjectId) {
      dispatch(deselectObject());
    } else {
      dispatch(selectObject(objectId));
      dispatch(setSelectedOBjectTab('info'));
    }
    if (shiftKeyPressed === true) {
      dispatch(setMeasuringObjects(objectId));
    }
  };

  const onEmptyClick = () => {
    dispatch(deselectObject());
  };

  const onObjectHover = (objectId) => {
    dispatch(hoverObject(objectId));
    dispatch(setSelectedOBjectTab('info'));
  };

  const onObjectLeave = (objectId) => {
    dispatch(unHoverObject(objectId));
  };

  const editor = (
    <>
      {/* <div className="box" /> */}
      {/* <div className="rulerRow">
        <BlueprintRuler
          positionSelector={scaleAndxPositionsSelector}
          type="horizontal"
          height={30}
          containerPadding={50}
          offset={blueprintDimensions.offset.x}
        />
      </div>
      <div className="rulerCol">
        <BlueprintRuler
          positionSelector={scaleAndzPositionsSelector}
          type="vertical"
          width={30}
          // containerDimension={containerHeight}
          containerPadding={50}
          offset={blueprintDimensions.offset.z}
        />
      </div> */}
      {/* <div className="d-flex flex-column canvasArea"> */}
      {/* <Row className="m-0" style={{ height: '100%' }}> */}
      <BlueprintEditor
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        xOffset={blueprintDimensions.offset.x}
        yOffset={blueprintDimensions.offset.z}
        sceneObjects={sceneObjects}
        selectedObjectId={selectedObjectId}
        hoveredObjectId={hoveredObjectId}
        paths={objectsPaths}
        distances={distances}
        onObjectClick={onObjectClick}
        onObjectHover={onObjectHover}
        onObjectLeave={onObjectLeave}
        onEmptyClick={onEmptyClick}
      />
      <BlueprintSlider />
      {/* </Row> */}
      {/* </div> */}
    </>
  );

  const emptyEditor = (
    <div className="blueprint-editor-empty">
      <h2>No Blueprint is provided</h2>
    </div>
  );

  return (
    <div id="blueprint">
      <Row className="blueprint p-0 m-0">
        <Loader
          show={blueprintsPending}
          backgroundStyle={{ height: '100%', width: '100%' }}
          centerSpinner
          hideChildren
        >
          <Col sm={5} lg={2} className="blueprint-tree-container m-0 px-0">
            <BlueprintTree />
          </Col>
          <Col
            sm={7}
            lg={6}
            xl={7}
            style={{ height: '100%', backgroundColor: '#132434' }}
            className="px-0"
          >
            {!_.isEmpty(sceneObjects) ? editor : emptyEditor}
          </Col>
          <Col className="info px-4 py-2" sm={12} lg={4} xl={3}>
            <BlueprintInfo
              id={infoData.id}
              title={infoData.name}
              type={infoData.type}
              equipment={infoData.equipment}
              body={infoData.props}
              showVideo={infoData.type === svgTypes.CAMERA}
              thumbnail={sceneObjectsThumbnails[infoData.itemLibraryID]}
              clipUrl={
                infoData.instanceId
                  ? clips[infoData.instanceId]?.url
                  : undefined
              }
              comments={
                infoData.instanceId
                  ? comments.filter(
                      (comment) =>
                        comment.shotID === clips[infoData.instanceId]?.url
                    )
                  : []
              }
            />
          </Col>
        </Loader>
      </Row>
    </div>
  );
};

export default withRouter(Blueprint);
