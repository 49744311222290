export const downlodsActionTypes = {
    ADD_DOWNLOAD_ITEM: 'ADD_DOWNLOAD_ITEM',
    TOGGLE_PAUSE: 'TOGGLE_PAUSE',
};

export const addDownloadItem = (id, name, type, progress, downloaded, isOwner, isPack ) => {
  return {
    type: downlodsActionTypes.ADD_DOWNLOAD_ITEM,
    payload : {id, name, type, progress, downloaded, isOwner, isPack}
  };
};

export const togglePause = (id) => {
  return {
    type: downlodsActionTypes.TOGGLE_PAUSE,
    payload : {id}
  };
};