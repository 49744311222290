import React from 'react';
import './BlueprintSlider.css';
import { Slider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  durationSelector,
  timeFrameSelector,
  isPlayingSelector,
} from 'redux/slices/blueprints/selectors';
import {
  setTimeFrame,
  playBlueprint,
  pauseBlueprint,
} from 'redux/slices/blueprints/actions';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { BLUEPRINT_FPS } from 'common/config/blueprints';

const BlueprintSlider = () => {
  const dispatch = useDispatch();
  const min = 0;
  const max = useSelector(durationSelector);
  const timeFrame = useSelector(timeFrameSelector);

  const isPlaying = useSelector(isPlayingSelector);

  const onChange = (val) => {
    dispatch(setTimeFrame(val));
  };

  const tipFormatter = (val) => {
    return formatTimeFrame(val);
  };

  const formatTimeFrame = (frame) => {
    const totalSeconds = Math.floor(frame / BLUEPRINT_FPS);
    const seconds = Math.floor(totalSeconds % 60) + '';
    const minutes = Math.floor(totalSeconds / 60) + '';

    const secondsPadded = addPadding(seconds);
    const minutesPadded = addPadding(minutes);
    return `${minutesPadded}:${secondsPadded}`;
  };

  const addPadding = (number) => (number.length === 1 ? '0' + number : number);

  const play = () => {
    dispatch(playBlueprint());
  };

  const pause = () => {
    dispatch(pauseBlueprint());
  };

  const playerIcon = !isPlaying ? (
    <CaretRightOutlined
      className="slider-icon"
      onClick={play}
      style={{ color: 'white !important' }}
    />
  ) : (
    <PauseOutlined
      className="slider-icon"
      onClick={pause}
      style={{ color: 'white !important' }}
    />
  );

  return (
    <div className="slider-wrapper mt-3">
      {playerIcon}
      <Slider
        className="slider"
        min={min}
        max={max}
        onChange={onChange}
        defaultValue={0}
        value={timeFrame}
        tipFormatter={tipFormatter}
      />
      <div className="slider-time">{formatTimeFrame(timeFrame)}</div>
    </div>
  );
};

export default BlueprintSlider;
