import { projectActionTypes } from "redux/slices/project/actions";

const initState = {
    createScenePending: false,
    createProjectPending: false,
    deleteScenePending: false,
    editMembersListPending: false,
    revertSceneVersionPending: false,
    sceneVersionsPending: false,

};
export const projectLoadersReducer = (state = initState, action) => {
    switch (action.type) {
        case projectActionTypes.CREATE_PROJECT:
            return { ...state, createProjectPending: true };
        case projectActionTypes.CREATE_PROJECT_SUCCESS:
        case projectActionTypes.CREATE_PROJECT_FAIL:
            return { ...state, createProjectPending: false };
        case projectActionTypes.CREATE_SCENE:
            return { ...state, createScenePending: true }
        case projectActionTypes.CREATE_SCENE_SUCCESS:
        case projectActionTypes.CREATE_SCENE_FAIL:
            return { ...state, createScenePending: false }
        case projectActionTypes.DELETE_SCENE:
            return { ...state, deleteScenePending: true }
        case projectActionTypes.DELETE_SCENE_SUCCESS:
        case projectActionTypes.DELETE_SCENE_FAIL:
            return { ...state, deleteScenePending: false }

        case projectActionTypes.ADD_PROJECT_MEMBERS:
        case projectActionTypes.ADD_SCENE_MEMBERS:
        case projectActionTypes.REMOVE_PROJECT_MEMBERS:
        case projectActionTypes.REMOVE_SCENE_MEMBERS:
            return { ...state, editMembersListPending: true }

        case projectActionTypes.ADD_PROJECT_MEMBERS_SUCCESS:
        case projectActionTypes.ADD_PROJECT_MEMBERS_FAIL:
        case projectActionTypes.ADD_SCENE_MEMBERS_SUCCESS:
        case projectActionTypes.ADD_SCENE_MEMBERS_FAIL:
            return { ...state, editMembersListPending: false }
        case projectActionTypes.FETCH_SCENE_VERSIONS:
            return {...state, sceneVersionsPending: true }
        case projectActionTypes.FETCH_SCENE_VERSIONS_SUCCESS:
        case projectActionTypes.FETCH_SCENE_VERSIONS_FAIL:
            return {...state, sceneVersionsPending: false }
        case projectActionTypes.REVERT_SCENE_VERSION:
            return {...state, revertSceneVersionPending: true }
        case projectActionTypes.REVERT_SCENE_VERSION_SUCCESS:
        case projectActionTypes.REVERT_SCENE_VERSION_FAIL:
            return {...state, revertSceneVersionPending: false }
        default:
            return state;
    }
};
