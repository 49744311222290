import './Home.scss';
import React, { useEffect } from 'react';
import Project from '../project/Project';
import Team from '../team/Team';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from '../../util/route-types/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchRecentScenes,
  fetchTeamMembersData,
  subscribeProject,
  subscribeScene,
  subscribeTeam,
  subscribeTeams,
  unsubscribeProject,
  unsubscribeScene,
  unsubscribeTeam,
  unsubscribeTeams,
  fetchSceneTemplates,
  setSelectedTeamId,
  setSelectedProjectId,
  subscribePersonalDriveStatus,
  unsubscribeDriveStatus,
  subscribeTeamDriveStatus,
  fetchProjectStatus,
  setTheme,
} from 'redux/slices/app/actions';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import HeaderAndSidebar from '../headerAndSidebar/HeaderAndSidebar';
import Sidebar from '../sidebar/Sidebar';
import RecentScenes from '../recentScenes/RecentScenes';
import { teamsFetchPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import Loader from 'common/components/loader/Loader';
import {
  inPersonalDriveSelector,
  personalDriveIdSelector,
  selectedProjectIdSelector,
  selectedSceneIdSelector,
  selectedTeamIdSelector,
  selectedTeamSelector,
  sharedProjectsSelector,
  teamsSelector,
  themeSelector,
} from 'redux/slices/app/selectors';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import NotAvailable from '../not-available/NotAvailable';
import { libraryVersionSelector } from 'redux/slices/items/selectors';
import SharedScenes from '../shared-scenes/SharedScenes';
import {
  selectedTeamIsActivePendingSelector,
  selectedTeamIsActiveSelector,
} from 'redux/slices/app/selectors';
import { InvalidSubscriptionModal } from '../modals/invalid-subscription-modal/InvalidSubscriptionModal';
import { FaPaintBrush } from 'react-icons/fa';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { UpdateNotification } from '../updateNotification/UpdateNotification';

const Home = ({ match }) => {
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector);
  const userId = useSelector(userIdSelector);
  const personalDriveId = useSelector(personalDriveIdSelector);
  const inPersonalDrive = useSelector(inPersonalDriveSelector);
  // const teamIsActive = useSelector(selectedTeamIsActiveSelector);
  const fetchSubscriptionPending = useSelector(
    selectedTeamIsActivePendingSelector
  );

  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  const teams = useSelector(teamsSelector);

  const team = useSelector(selectedTeamSelector);

  const sharedProjects = useSelector(sharedProjectsSelector);

  const inSharedProject = sharedProjects && projectId in sharedProjects;

  const libVersion = useSelector(libraryVersionSelector);

  const teamsFetchPending = useSelector(teamsFetchPendingSelector);

  // Pre-fetch scene templates to display in scene-creation modal

  useEffect(() => {
    dispatch(fetchSceneTemplates());
  }, [libVersion]);

  useEffect(() => {
    if (userId) {
      // dispatch(fetchTeamsForUser(userId));
      dispatch(subscribeTeams(userId));
    }
    return () => {
      dispatch(unsubscribeTeams());
    };
  }, [userId, dispatch]);

  // useEffect(() => {
  //   if (personalDriveId) {
  //     dispatch(subscribeUserDrive(personalDriveId));
  //   }
  //   return () => {
  //     dispatch(unsubscribeUserDrive());
  //   };
  // }, [dispatch, personalDriveId]);

  useEffect(() => {
    if (teamId) {
      dispatch(subscribeTeam(teamId));
    }
    return () => {
      // dispatch(clearProjects());
      dispatch(setSelectedProjectId(null));
      dispatch(unsubscribeTeam());
    };
  }, [dispatch, teamId]);

  useEffect(() => {
    if (teamId) {
      if (inPersonalDrive) dispatch(subscribePersonalDriveStatus(teamId));
      else dispatch(subscribeTeamDriveStatus(teamId));
    }
    return () => {
      dispatch(unsubscribeDriveStatus());
    };
  }, [dispatch, teamId, inPersonalDrive]);

  useEffect(() => {
    if (userId && !teamId) {
      const storageTeamId = JSON.parse(
        localStorage.getItem(userId) ?? '{}'
      ).teamId;
      if (storageTeamId) {
        dispatch(setSelectedTeamId(storageTeamId));
      } else if (teams && teams.length > 0) {
        dispatch(setSelectedTeamId(teams[0].id));
      } else {
        dispatch(setSelectedTeamId(personalDriveId));
      }
    }
  }, [dispatch, userId, teams, teamId, personalDriveId]);

  // useEffect(() => {
  //   (scenes ?? []).forEach(({ id }) => {
  //     if ((scenesAccess ?? {})[id] === undefined)
  //       dispatch(checkSceneAccess(id));
  //     if (scenesIsPublic[id] === undefined)
  //       dispatch(subscribeSceneIsPublic(id));
  //   });
  //   // scenes.forEach(({ id }) => dispatch(checkSceneAccess(id)));
  //   return () => {
  //     // dispatch(setScenesAccess({}));
  //   };
  // }, [dispatch, scenes]);

  // useEffect(() => {
  //   if (allProjects) {
  //     Object.keys(allProjects).forEach((id) =>
  //       dispatch(subscribeProjectStatus(id))
  //     );
  //   }
  //   return () => {
  //     if (allProjects) {
  //       dispatch(clearProjects());
  //       Object.keys(allProjects).forEach((id) => unsubscribeProjectStatus(id));
  //     }
  //   };
  // }, [dispatch, allProjects]);

  useEffect(() => {
    if (teamId) {
      (window as any).onTeamChange(teamId);
    }
  }, [teamId]);

  // Fetch recent scenes
  useEffect(() => {
    if (userId && teamId) {
      dispatch(fetchRecentScenes(userId, teamId));
    }
  }, [dispatch, userId, teamId]);

  useEffect(() => {
    if (projectId) {
      dispatch(subscribeProject(projectId));
      if (inSharedProject) {
        dispatch(fetchProjectStatus(projectId));
      }
    }
    return () => {
      if (projectId) {
        dispatch(unsubscribeProject(projectId));
      }
    };
  }, [dispatch, projectId, inSharedProject]);

  useEffect(() => {
    if (sceneId) {
      dispatch(subscribeScene(sceneId));
    }
    return () => {
      if (sceneId) {
        dispatch(unsubscribeScene(sceneId));
      }
    };
  }, [dispatch, sceneId]);

  // useEffect(() => {
  //   if (scene?.members)
  //     dispatch(fetchSceneMembersData(sceneId, Object.keys(scene.members)));
  // }, [dispatch, scene]);

  // useEffect(() => {
  //   if (scene?.members && sceneId && !prevScene) {
  //     dispatch(fetchSceneMembersData(sceneId, Object.keys(scene.members)));
  //   }
  // }, [dispatch, sceneId, scene, prevScene]);

  useEffect(() => {
    // const prevTeamMembers = prevTeam?.members ?? {};
    // const curTeamMembers = team?.members ?? {};
    // let newMembersAvailable = false;

    // for (const userId in curTeamMembers)
    //   if (!prevTeamMembers[userId]) newMembersAvailable = true;

    if (team?.members) {
      // Check if there were new team members that were fetched

      // if (newMembersAvailable)
      dispatch(fetchTeamMembersData(teamId, Object.keys(team.members)));
    }
  }, [dispatch, team]);

  // useEffect(() => {
  //   Object.keys(project?.scenes ?? {}).forEach((id) => {
  //     // if (!(lastModifiedScenes ?? {})[id]) {
  //     dispatch(subscribeSceneLastModified(id));
  //     // }
  //   });
  // }, [dispatch, project]);

  return (
    <React.Fragment>
      {/* {teamIsActive === false && <InvalidSubscriptionModal />} */}
      <Loader
        centerSpinner={true}
        hideChildren={false}
        fullscreen={true}
        backgroundStyle={{ height: '100%' }}
        // show={teamsFetchPending || fetchSubscriptionPending}
        show={teamsFetchPending}
      />
      <UpdateNotification />
      <DndProvider backend={HTML5Backend}>
        {teamsFetchPending === false ? (
          <div className="home">
            {/* <HeaderAndSidebar /> */}
            <Sidebar />

            {/* <div
              className="close-btn"
              onClick={() => {
                (window as any).onExitApp();
              }}
            > */}

            {/* <FaPaintBrush
              className="paint-btn"
              onClick={() => {
                if (theme === 'black') dispatch(setTheme('original'));
                else dispatch(setTheme('black'));
              }}
            /> */}

            <IoMdCloseCircleOutline
              onClick={() => {
                (window as any).onExitApp();
              }}
              className="close-btn"
            />
            {/* </div> */}

            <Switch>
              <PrivateRoute
                path={`${match.url}/recent`}
                component={RecentScenes}
                exact={true}
              />
              <PrivateRoute path={`${match.url}/project`} component={Project} />
              <PrivateRoute
                path={`${match.url}/shared-scenes`}
                component={SharedScenes}
              />
              <PrivateRoute path={`${match.url}/team`} component={Team} exact />
              <PrivateRoute
                path={`${match.url}/not-available`}
                component={NotAvailable}
                exact
              />

              <PrivateRoute path="*">
                <Redirect to={`/not-found`} />
              </PrivateRoute>
            </Switch>
          </div>
        ) : null}
      </DndProvider>
    </React.Fragment>
  );
};

export default withRouter(Home);
