import produce from 'immer';
import _ from 'lodash';
import { commentAction } from './actions';

const initialState = {
  users: null,
  comments: {},
  commentsModalId: '',
  showCommentsMenu: true,
  scrollingComment: null,
  tagsNotifications: [],

  sceneComments: {},
  shotsComments: {},
  snapshotComments: {},
  objectComments: {},
};

export const commentsReducer = produce((state = initialState, action) => {
  // switch (action.type) {
  //   case commentsActionTypes.SET_COMMENTS:
  //     return { ...state, comments: action.payload };

  //   case commentsActionTypes.CLEAR_COMMENTS:
  //     return { ...state, sceneComments: {}, clipComments: {} };

  //   case commentsActionTypes.SET_TAGS:
  //     return { ...state, tagsNotifications: action.payload };

  //   case commentsActionTypes.TOGGLE_COMMENTS_MENU:
  //     return { ...state, showCommentsMenu: !state.showCommentsMenu };

  //   default:
  //     return state;
  // }

  switch (action.type) {
    case commentAction('SET', 'SCENE_COMMENT'):
      state.sceneComments = _.cloneDeep(action.payload ?? {});
      for (const commentId in action.payload) {
        state.sceneComments[commentId].id = commentId;
      }
      break;

    case commentAction('SET', 'SNAPSHOT_COMMENT'):
      state.snapshotComments = _.cloneDeep(action.payload ?? {});
      for (const snapshotId in action.payload)
        for (const commentId in action.payload[snapshotId])
          state.snapshotComments[snapshotId][commentId].id = commentId;

      break;

    case commentAction('SET', 'SHOT_COMMENT'):
      state.shotsComments = _.cloneDeep(action.payload ?? {});
      for (const shotId in action.payload)
        for (const commentId in action.payload[shotId])
          state.shotsComments[shotId][commentId].id = commentId;

      break;

    case commentAction('SET', 'OBJECT_COMMENT'):
      state.objectComments = _.cloneDeep(action.payload ?? {});
      for (const objectId in action.payload)
        for (const commentId in action.payload[objectId])
          state.objectComments[objectId][commentId].id = commentId;

      break;

    case commentAction('OPEN_COMMENTS_MODAL'):
      // state.showCommentsMenu = !state.showCommentsMenu;
      state.commentsModalId = action.payload.id;
      break;

    case commentAction('CLOSE_COMMENTS_MODAL'):
      state.commentsModalId = null;
      break;

    case commentAction('TOGGLE_COMMENTS_MENU'):
      state.showCommentsMenu = !state.showCommentsMenu;
      break;

    case commentAction('CLOSE_COMMENTS_MENU'):
      state.showCommentsMenu = false;
      break;

    default:
      break;
  }
  return state;
});

// const toggleEditComment = (commentID, clipsComments, sceneComments) => {
//   let comment = clipsComments.find((comment) => comment[0] === commentID);
//   if (comment) {
//     const index = clipsComments.findIndex(
//       (comment) => comment[0] === commentID
//     );
//     clipsComments[index][1].editing = !clipsComments[index][1].editing;
//     return {
//       clipsComments: [...clipsComments],
//     };
//   } else {
//     comment = sceneComments.find((comment) => comment[0] === commentID);
//     const index = sceneComments.findIndex(
//       (comment) => comment[0] === commentID
//     );
//     sceneComments[index][1].editing = !sceneComments[index][1].editing;
//     return {
//       sceneComments: [...sceneComments],
//     };
//   }
// };
