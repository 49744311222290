import { Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { teamMembersSelector2 } from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';

const AddMembersModal = ({ onCancel = () => {}, onSubmit = (s) => {} }) => {
  const userId = useSelector(userIdSelector);
  const userName = useSelector(userNameSelector);
  const teamMembers = Object.values(useSelector(teamMembersSelector2) ?? {});
  const [selectedMembers, setSelectedMembers] = useState<
    { name: string; id: string }[]
  >([]);
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="add-members-modal"
      title="Add Members"
      visible
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => {
        onSubmit(selectedMembers);
      }}
    >
      <div>
        Members:
        <Select
          mode="multiple"
          style={{ width: '80%', marginLeft: '1rem' }}
          options={teamMembers.map(({ id, name }) => ({
            key: id,
            label: name,
            value: id,
            disabled: id === userId,
          }))}
          //   value={selectedMembers}
          onChange={(v, values) => {
            setSelectedMembers(
              values.map(({ label, value }) => ({ id: value, name: label }))
            );
            console.log(values);
          }}
        />
      </div>
    </Modal>
  );
};

export default AddMembersModal;
