import './CheckMark.scss';
import React from 'react';

type propTypes = {
  checked?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
};

const CheckMark = ({
  checked = false,
  label = '',
  onChange = () => {},
}: propTypes) => {
  return (
    <label className="custom-checkbox">
      <span className="label">{label}</span>
      <input
        type="checkbox"
        name={label}
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <span className="checkmark" />
    </label>
  );
};

export default CheckMark;
