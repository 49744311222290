import React from 'react';
import { Menu, Dropdown, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './CommentOptions.css';
import { FaEllipsisV } from 'react-icons/fa';

const CommentOptions = (props) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const deleteCommentHandler = () => {
    if (props.onDeleteComment) {
      props.onDeleteComment(props.commentID);
    }
  };

  const editCommentHandler = () => {
    props.toggleEdit();
    setShowMenu(false);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={editCommentHandler}>Edit</Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowMenu(true);
        }}
      >
        <Popconfirm
          title="Are you sure delete this comment?"
          onConfirm={deleteCommentHandler}
          okText="Yes"
          cancelText="No"
          icon={
            <QuestionCircleOutlined style={{ color: 'red', zIndex: 5000 }} />
          }
        >
          <div>Delete</div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      transitionName=""
      overlay={menu}
      placement="bottomRight"
      className="ml-2"
      trigger={['click']}
      visible={showMenu}
      overlayStyle={{ zIndex: 5000 }}
      onVisibleChange={(visible) => setShowMenu(visible)}
    >
      <FaEllipsisV className="dots"></FaEllipsisV>
      {/* <i className="fas fa-ellipsis-h dots"></i> */}
    </Dropdown>
  );
};

export default CommentOptions;
