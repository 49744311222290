import { fireRealtimeDBRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/types';

const namespace = 'team';

export type invoice = {
  id: string;
  amount: number;
  status: string;
  date: number;
  reason: string;
};

type teamActionType = fireRealtimeDBRequestTypes;
type teamResource = 'INVOICES' | 'PENDING_INVITES';
type teamStatus = 'SUCCESS' | 'FAIL' | 'EMPTY';

export const teamAction = (
  actionType: teamActionType,
  resource?: teamResource,
  status?: teamStatus
) => {
  let action = `${namespace}/${actionType}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};
