import { itemNames } from 'redux/slices/items/actions';

export const navButtons = [
  {
    title: 'Cameras',
    src: '/backlot-library/home/camera.svg',
    className: 'cameras',
    srcHover: '/backlot-library/home/camera-hover.svg',
    redirectPath: `store/${itemNames.CAMERA_ITEMS}?category=All`,
  },
  {
    title: 'Lights',
    src: '/backlot-library/home/light.svg',
    srcHover: '/backlot-library/home/light-hover.svg',
    className: 'lights',
    redirectPath: `store/${itemNames.LIGHT_ITEMS}?category=All`,
  },
  {
    title: 'Characters',
    src: '/backlot-library/home/character.svg',
    className: 'characters',
    srcHover: '/backlot-library/home/character-hover.svg',
    redirectPath: `store/${itemNames.CHARACTER_ITEMS}?category=All`,
  },
  {
    title: 'Props',
    src: '/backlot-library/home/prop.svg',
    className: 'props',
    srcHover: '/backlot-library/home/prop-hover.svg',
    redirectPath: `store/${itemNames.PROP_ITEMS}?category=Featured`,
  },
  {
    title: 'Film Sets',
    src: '/backlot-library/home/environment.svg',
    className: 'film-sets',
    srcHover: '/backlot-library/home/environment-hover.svg',
    redirectPath: `store/${itemNames.ENVIRONMENTS}?category=All`,
  },
  {
    title: '2D Images',
    src: '/backlot-library/home/2d-images.svg',
    className: 'images',
    srcHover: '/backlot-library/home/animation-hover.svg',
    redirectPath: `gallery`,
  },
  {
    title: 'My Assets',
    src: '/backlot-library/home/template.svg',
    className: 'my-assets',
    srcHover: '/backlot-library/home/template-hover.svg',
    redirectPath: `store/${itemNames.ASSETS}?category=All`,
  },
  {
    title: 'Team Library',
    src: '/backlot-library/home/cloud.svg',
    className: 'team-library',
    srcHover: '/backlot-library/home/cloud.svg',
    redirectPath: `team-library`,
  },
];

export const items = [
  {
    category: 'Props',
    subCategory: 'Tables',
    name: 'Coffee Table',
    src: '/assets/images/tables/coffee-table.svg',
  },
  {
    category: 'Props',
    subCategory: 'Tables',
    name: 'Mid Century Table',
    src: '/assets/images/tables/midCentury-table.svg',
  },
  {
    category: 'Props',
    subCategory: 'Tables',
    name: 'Bar Table',
    src: '/assets/images/tables/bar-table.svg',
  },
  {
    category: 'Props',
    subCategory: 'Tables',
    name: 'Modern Table',
    src: '/assets/images/tables/modern-table.svg',
  },
  {
    category: 'Props',
    subCategory: 'Tables',
    name: 'Coffee Table BL',
    src: '/assets/images/tables/coffee-table-bl.svg',
  },

  {
    category: 'Cameras',
    subCategory: 'DSLR',
    name: 'Arri Alexa Mini',
    src: '/assets/images/cameras/arria.svg',
  },
  {
    category: 'Cameras',
    subCategory: 'DSLR',
    name: 'Panavision XMR',
    src: '/assets/images/cameras/panavision.svg',
  },
  {
    category: 'Cameras',
    subCategory: 'DSLR',
    name: 'Red Epic Dragon',
    src: '/assets/images/cameras/red-epic.svg',
  },
  {
    category: 'Cameras',
    subCategory: 'DSLR',
    name: 'Red Hydrogen',
    src: '/assets/images/cameras/red-hydrogen.svg',
  },
];
