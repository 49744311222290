export const loginPendingSelector = (state) =>
  state.loaders.authLoaders.loginPending;

export const logoutPendingSelector = (state) =>
  state.loaders.authLoaders.logoutPending;

export const teamsFetchPendingSelector = (state) =>
  state.loaders.appLoaders.teamsFetchPending;

export const projectsFetchPendingSelector = (state) =>
  state.loaders.appLoaders.projectsFetchPending;

export const duplicateScenePendingSelector = (state) =>
  state.loaders.appLoaders.duplicateScenePending;

export const renameScenePendingSelector = (state) =>
  state.loaders.appLoaders.renameScenePending;

export const bugReportPendingSelector = (state) =>
  state.loaders.appLoaders.bugReportPending;

export const scenesFetchPendingSelector = (state) =>
  state.loaders.appLoaders.scenesFetchPending;

export const createNewScenePendingSelector = (state) =>
  state.loaders.appLoaders.createScenePending;

export const createNewProjectPendingSelector = (state) =>
  state.loaders.projectLoaders.createProjectPending;

export const deleteScenePendingSelector = (state) =>
  state.loaders.projectLoaders.deleteScenePending;

export const editMembersListPendingSelector = (state) =>
  state.loaders.projectLoaders.editMembersListPending;

export const sceneVersionsPendingSelector = (state) =>
  state.loaders.projectLoaders.sceneVersionsPending;

export const revertSceneVersionPendingSelector = (state) =>
  state.loaders.projectLoaders.revertSceneVersionPending;

export const clipsFetchPendingSelector = (state) =>
  state.loaders.clipsLoaders.clipsPending;

export const sceneCommentsSubscriptionPendingSelector = (state) =>
  state.loaders.sceneCommentsLoaders.sceneCommentsSubscriptionPending;

export const blueprintsPendingSelector = (state) =>
  state.loaders.blueprints.blueprintsPending;

export const itemsPendingSelector = (store) =>
  store.loaders.libraryItemsLoaders.itemsLoadPending > 0;

export const searchItemsPendingSelector = (store) =>
  store.loaders.libraryItemsLoaders.searchItemsPending > 0;

export const favoriteItemsPendingSelector = (store) =>
  store.loaders.libraryItemsLoaders.favoriteItemsPending;

export const subcategoriesPendingSelector = (store) =>
  store.loaders.libraryItemsLoaders.subcategoriesPending > 0;
