import './FullscreenImage.scss';
import React from 'react';
import { Modal } from 'react-bootstrap';

const FullscreenImage = ({
  src = '',
  alt = 'fullscreen-image',
  onClose = () => {},
}) => {
  return (
    <Modal
      className="fullscreen-image-modal"
      show
      onHide={onClose}
      onExited={onClose}
    >
      <Modal.Body>
        <img src={src} alt={alt} />
      </Modal.Body>
    </Modal>
  );
};

export default FullscreenImage;
